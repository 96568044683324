import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { formattedDollarsToCents } from '@flock/utils'
import { useMutation } from '@apollo/client'
import {
  AdminCreateDelinquencyDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_CreatePropertyDelinquencyRequest,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'
import { cleanTimeFromDatetime } from '../../utils'

type CreateDelinquencyParams = {
  chargeDate: string
  chargeAmountCents: string
  delinquencyAmountCents: string
  delinquencyType: string
  paymentDate: string
}

type CreateDelinquencyModalParams = {
  propertyUuid: string
  isOpen: boolean
  close: () => void
}

const CreateDelinquencyModal = (props: CreateDelinquencyModalParams) => {
  const { propertyUuid, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createDelinquency, { loading }] = useMutation(
    AdminCreateDelinquencyDocument
  )

  const onSubmit = async (createDelinquencyParams: CreateDelinquencyParams) => {
    const {
      chargeDate,
      chargeAmountCents,
      delinquencyAmountCents,
      delinquencyType,
      paymentDate,
    } = createDelinquencyParams
    const createDelinquencyInput: Core_CreatePropertyDelinquencyRequest = {
      delinquencyParams: {
        propertyUuid,
        chargeDate: cleanTimeFromDatetime(chargeDate),
        chargeAmountCents: formattedDollarsToCents(chargeAmountCents),
        delinquencyAmountCents: formattedDollarsToCents(delinquencyAmountCents),
        delinquencyType,
        paymentDate: cleanTimeFromDatetime(paymentDate),
      },
    }

    try {
      await createDelinquency({
        variables: {
          input: createDelinquencyInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })

      notify('Successfully created delinquency.', 'success')
    } catch (e) {
      notify('Failed to create delinquency.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Create Delinquency"
      inputConfigs={[
        {
          inputName: 'chargeDate',
          inputType: InputType.DatePicker,
          required: true,
          props: {
            label: 'Charge Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'chargeAmountCents',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Charge Amount Cents',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },
        {
          inputName: 'delinquencyAmountCents',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Delinquency Amount Cents',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },
        {
          inputName: 'delinquencyType',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Delinquency Type',
            placeholder: 'Type should match appfolio codes',
            fullWidth: true,
          },
        },
        {
          inputName: 'paymentDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Payment Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
      ]}
    />
  )
}

export default CreateDelinquencyModal
