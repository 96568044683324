import React, { useState, useEffect } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import {
  AdminValuationHistoryModalSalesApproveValuationV1Document,
  AdminComputeValuationPageGetValuationHistoryDocument,
  Core_ValuationV1,
  Core_MultiFamilyValuation,
  Core_ValuationType,
  AdminGetValuationDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { formatDollarsAndCents } from '@flock/utils'
import { formatDateString } from '../../../../InvestorManagement/utils'
import { snakeToTitleCase } from '../../../../../utils'
import { ValuationHistoryModalProps } from './valuationHistoryModalTypes'

export const SALES_APPROVE_VALUATION_V1 = gql`
  mutation AdminValuationHistoryModalSalesApproveValuationV1(
    $input: Core_SalesApproveValuationV1RequestInput!
  ) {
    salesApproveValuationV1(input: $input) {
      _empty
    }
  }
`

const useValuationHistoryModal = (props: ValuationHistoryModalProps) => {
  const { leadUuid, operatorUuid, valuationHistory, valuationHistoryLoading } =
    props
  const { notify } = useSnackbar()

  const [valuationTrailOpen, setValuationTrailOpen] = useState(false)
  const [selectedValuation, setSelectedValuation] = useState<
    Core_ValuationV1 | Core_MultiFamilyValuation
  >()

  // we don't grab some of the fields in our query which causes an error if we explicitly type it.
  const [valuations, setValuations] = useState<any[]>([])

  const { data: offerPageValuationData } = useQuery(AdminGetValuationDocument, {
    variables: {
      input: {
        leadUuid,
      },
    },
  })

  useEffect(() => {
    const combinedValuations = [
      ...(valuationHistory?.getValuationHistory?.singleFamilyValuations || []),
      ...(valuationHistory?.getValuationHistory?.multiFamilyValuations || []),
    ]
    setValuations(combinedValuations)
  }, [valuationHistory])

  const [salesApproveValuation] = useMutation(
    AdminValuationHistoryModalSalesApproveValuationV1Document
  )
  const submitSalesApproval = async (
    valuation: Core_ValuationV1 | Core_MultiFamilyValuation
  ) => {
    try {
      await salesApproveValuation({
        variables: {
          input: {
            operatorUuid,
            valuationUuid: valuation.uuid,
          },
        },
        refetchQueries: [AdminComputeValuationPageGetValuationHistoryDocument],
      })
    } catch (e) {
      notify('Failed to submit sales approval.', 'error')
    }
  }

  const displayHeaders = [
    'Offer Price',
    'Type',
    'Valuation Details',
    'Expires At',
    'Sales Approved',
    'Live on Offer Page',
    'Rejection Reason',
    'Operator',
    'Breakdown',
    'Valuation UUID',
  ]

  const displayCells = valuations.map(
    (valuation: Core_MultiFamilyValuation | Core_ValuationV1) => {
      let jsonPretty = ''
      if ((valuation as Core_ValuationV1).inputs) {
        const valuationV1 = valuation as Core_ValuationV1
        jsonPretty = JSON.stringify(
          {
            ...valuationV1?.inputs,
            ...valuationV1?.outputs,
            property: valuationV1?.property,
          },
          null,
          2
        )
      } else {
        const multiFamilyValuation = valuation as Core_MultiFamilyValuation
        jsonPretty = JSON.stringify(
          {
            unitInputs: multiFamilyValuation?.unitInputs,
            propetyInput: multiFamilyValuation?.propertyInput,
            outputs: multiFamilyValuation?.outputs,
            property: valuation?.property,
          },
          null,
          2
        )
      }

      const salesApprovedData = (salesApproved: boolean) => {
        if (salesApproved) {
          return <Box>&#9989;</Box>
        }

        return (
          <Button
            variant="contained"
            size="mini"
            disabled={
              !valuation.finalOfferPrice ||
              valuation.type !== Core_ValuationType.ValuationTypeFinal
            }
            onClick={() => submitSalesApproval(valuation)}
          >
            Approve
          </Button>
        )
      }

      const isLiveValuation = (
        offerPageValuationUuid: string,
        valuationUuid: string
      ) => {
        if (offerPageValuationUuid === valuationUuid) {
          return <Box>&#9989;</Box>
        }

        return <Box />
      }

      return [
        formatDollarsAndCents(valuation.finalOfferPrice || 0),
        valuation.type === Core_ValuationType.ValuationTypeFinal
          ? 'Final'
          : 'Initial',
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <>Expand</>
            </AccordionSummary>
            <AccordionDetails>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{jsonPretty}</pre>
            </AccordionDetails>
          </Accordion>
        </>,
        formatDateString(valuation.expiresAt),
        salesApprovedData(valuation.salesApproved),
        isLiveValuation(
          offerPageValuationData?.valuation?.valuation?.valuationUuid!,
          valuation.uuid
        ),
        snakeToTitleCase(valuation?.rejectionReason || ''),
        valuation.operator?.fullName,
        valuation?.valuationTrail ? (
          <Button
            onClick={() => {
              setValuationTrailOpen(true)
              setSelectedValuation(valuation)
            }}
            size="mini"
            variant="outlined"
          >
            View
          </Button>
        ) : null,
        valuation?.uuid,
      ]
    }
  )

  return {
    valuationHistoryLoading,
    valuationTrailOpen,
    setValuationTrailOpen,
    selectedValuation,
    displayHeaders,
    displayCells,
  }
}

export default useValuationHistoryModal
