import { useMutation } from '@apollo/client'
import { ConfirmModal } from '@flock/flock-component-library'
import {
  AdminDeleteFeeDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_DeletePropertyFeeRequestInput,
  Core_PropertyFee,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import React from 'react'

type DeleteFeeModalParams = {
  fee: Core_PropertyFee
  isOpen: boolean
  close: () => void
}

const DeleteFeeModal = (props: DeleteFeeModalParams) => {
  const { fee, isOpen, close } = props
  const { notify } = useSnackbar()

  const [deleteFee, { loading }] = useMutation(AdminDeleteFeeDocument)

  const deleteFeeInput: Core_DeletePropertyFeeRequestInput = {
    uuid: fee.uuid,
  }

  const onSubmit = async () => {
    try {
      await deleteFee({
        variables: {
          input: deleteFeeInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify(`Successfully deleted fee for ${fee.feeType}.`, 'success')
      close()
    } catch (e) {
      notify('Failed to delete fee.', 'error')
    }
  }

  return (
    <ConfirmModal
      title="Delete Fee"
      content={
        loading
          ? 'deleting...'
          : `Are you sure you want to delete fee for ${fee.feeType}?`
      }
      open={isOpen}
      onClose={close}
      onSubmit={onSubmit}
    />
  )
}

export default DeleteFeeModal
