import { SowAsset } from '../individualSowProjectPageTypes'

export type Option = {
  value: number
  label: string
}

export const monthOptions = [
  {
    value: 0,
    label: 'January',
  },
  {
    value: 1,
    label: 'February',
  },
  {
    value: 2,
    label: 'March',
  },
  {
    value: 3,
    label: 'April',
  },
  {
    value: 4,
    label: 'May',
  },
  {
    value: 5,
    label: 'June',
  },
  {
    value: 6,
    label: 'July',
  },
  {
    value: 7,
    label: 'August',
  },
  {
    value: 8,
    label: 'September',
  },
  {
    value: 9,
    label: 'October',
  },
  {
    value: 10,
    label: 'November',
  },
  {
    value: 11,
    label: 'December',
  },
]

export type SowAssetPhoto = {
  url: string
  addPhotosIndex: number // - 1 means it's not an added photo
}

// Props that are used by the useSowAssetModal hook
export type SowAssetModalProps = {
  open: boolean
  onClose: () => void
  locationOptions: string[]
  categoryOptions: string[]
  asset?: SowAsset
  projectUUID?: string
}

// Props that are used by the presentational components (SowAssetModalDesktop.tsx, SowAssetModalMobile.tsx, SowAssetModalTablet.tsx)
export type SowAssetModalPresentationalProps = SowAssetModalProps & {
  category: string
  setCategory: (category: string) => void
  location: string
  setLocation: (location: string) => void
  assetText: string
  setAssetText: (asset: string) => void

  month: number
  setMonth: (month: number) => void
  year: number
  setYear: (year: number) => void
  status: string
  setStatus: (status: string) => void
  endOfLifeDate: Date | undefined | null
  setEndOfLifeDate: (date: Date | undefined | null) => void
  remainingLife: number
  recommendedAdjustment: number

  photos: SowAssetPhoto[]
  selectedPhoto: SowAssetPhoto
  setSelectedPhoto: (photo: SowAssetPhoto) => void
  onAddPhotos: (photos: File[]) => void
  onDeletePhoto: (photo: SowAssetPhoto) => void
  loading: boolean

  onSave: () => void
  onDelete: () => void
  setShowDeleteModal: (show: boolean) => void
  showDeleteModal: boolean
}
