import React from 'react'
import {
  AnyInputConfig,
  FileUploadFileType,
  FileUploadMimeType,
  flockTheme,
  GOOGLE_MAPS_API_KEY,
  GridForm,
  InputType,
  LibraryThemeProvider,
  TrackedLink,
} from '@flock/shared-ui'
import { ThemeProvider } from '@emotion/react'
import {
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material'

import useAddressCreationPage, {
  statusColorMap,
} from './useAddressCreationPage'
import { AddressCreationPageProps } from './addressCreationPageTypes'
import { SALESFORCE_URL } from '../../constants'

const inputConfigs: AnyInputConfig[] = [
  {
    name: 'addressInfo',
    type: InputType.Multiform,
    props: {
      title: 'Lead Address',
      maxForms: 1000,
      minForms: 1,
      incrementText: '+ Add Address',
      decrementText: '- Remove Address',
      inputConfigs: [
        {
          name: 'addressData',
          type: InputType.Address,
          required: false,
          props: {
            googleMapsApiKey: GOOGLE_MAPS_API_KEY,
            label: 'Please enter the address',
            fullWidth: true,
          },
        },
        {
          name: 'propertyType',
          type: InputType.Dropdown,
          required: false,
          gridItemProps: {
            sm: 3,
          },
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            watchedFields[`addressData${multiformParentIndex}`],
          props: {
            label: 'Property Type (please select)',
            options: [
              {
                label: 'single-family home',
                value: 'singlefamily',
              },
              {
                label: 'duplex',
                value: 'duplex',
              },
              {
                label: 'triplex',
                value: 'triplex',
              },
              {
                label: 'fourplex',
                value: 'fourplex',
              },
              {
                label: 'attached townhouse',
                value: 'townhouse',
              },
              {
                label: 'condo',
                value: 'condo',
              },
              {
                label: 'other',
                value: 'other',
              },
            ],
          },
        },
        {
          name: 'yearBuilt',
          required: false,
          type: InputType.Text,
          props: {
            label: 'Year Built',
            format: 'year',
          },
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            watchedFields[`addressData${multiformParentIndex}`],
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'mortgageAmount',
          type: InputType.Text,
          required: false,
          props: {
            label: 'Mortgage Amount',
            format: 'dollars',
            placeholder: '$100,000',
          },
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            watchedFields[`addressData${multiformParentIndex}`],
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'propertyCondition',
          type: InputType.Dropdown,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            watchedFields[`addressData${multiformParentIndex}`],
          props: {
            label: 'Property Condition (please select)',
            options: [
              {
                label: '5 - Pristine',
                value: '5 - pristine',
              },
              {
                label: '4 - Great',
                value: '4 - great',
              },
              {
                label: '3 - Average',
                value: '3 - average',
              },
              {
                label: '2 - Below Average',
                value: '2 - below average',
              },
              {
                label: '1 - Requires Renovation',
                value: '1 - requires complete renovation',
              },
            ],
          },
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'propertyConditionNotes',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            watchedFields[`addressData${multiformParentIndex}`],
          props: {
            label: 'Property Condition Notes',
            size: 'small',
          },
          gridItemProps: {
            sm: 12,
          },
        },
        {
          name: 'unitName1',
          type: InputType.Text,
          required: true,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            !['singlefamily'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) && !!watchedFields[`propertyType${multiformParentIndex}`],
          props: {
            label: 'Unit Name',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'beds1',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            watchedFields[`addressData${multiformParentIndex}`],
          props: {
            label: 'Beds',
            format: 'number',
            placeholder: '2',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'baths1',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            watchedFields[`addressData${multiformParentIndex}`],
          props: {
            label: 'Baths',
            format: 'number',
            placeholder: '2',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'sqft1',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            watchedFields[`addressData${multiformParentIndex}`],
          props: {
            label: 'Sqft',
            format: 'number',
            placeholder: '2000',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },

        {
          name: 'leaseType1',
          type: InputType.Dropdown,
          required: false,
          gridItemProps: {
            sm: 2.4,
          },
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            watchedFields[`addressData${multiformParentIndex}`],
          props: {
            label: 'Lease Type',
            size: 'mini',
            options: [
              {
                label: 'Long Term',
                value: 'long_term',
              },
              {
                label: 'Month-to-month',
                value: 'mtm',
              },
              {
                label: 'None',
                value: '',
              },
            ],
          },
        },
        {
          name: 'rentAmount1',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['long_term', 'mtm'].includes(
              watchedFields[`leaseType1${multiformParentIndex}`]
            ),
          props: {
            label: 'Rent Amount',
            format: 'dollars',
            size: 'mini',
          },
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'leaseEnd1',
          type: InputType.DatePicker,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['long_term'].includes(
              watchedFields[`leaseType1${multiformParentIndex}`]
            ),
          props: {
            label: 'Lease End',
            size: 'mini',
          },
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'rentAmount1Placeholder',
          type: InputType.CustomComponent,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['long_term', 'mtm'].includes(
              watchedFields[`leaseType1${multiformParentIndex}`]
            ),
          props: {},
          gridItemProps: {
            sm: 6,
          },
        },
        {
          name: 'leaseEnd1Placeholder',
          type: InputType.CustomComponent,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['mtm'].includes(
              watchedFields[`leaseType1${multiformParentIndex}`]
            ),
          props: {},
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'unitName2',
          type: InputType.Text,
          required: true,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['duplex', 'triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Unit Name',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'beds2',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['duplex', 'triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Beds',
            format: 'number',
            placeholder: '2',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'baths2',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['duplex', 'triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Baths',
            format: 'number',
            placeholder: '2',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'sqft2',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['duplex', 'triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Sqft',
            format: 'number',
            placeholder: '2000',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },

        {
          name: 'leaseType2',
          type: InputType.Dropdown,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['duplex', 'triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          gridItemProps: {
            sm: 2.4,
          },
          props: {
            label: 'Lease Type',
            size: 'mini',
            options: [
              {
                label: 'Long Term',
                value: 'long_term',
              },
              {
                label: 'Month-to-month',
                value: 'mtm',
              },
              {
                label: 'None',
                value: '',
              },
            ],
          },
        },
        {
          name: 'rentAmount2',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['duplex', 'triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) &&
            ['long_term', 'mtm'].includes(
              watchedFields[`leaseType2${multiformParentIndex}`]
            ),
          props: {
            label: 'Rent Amount',
            size: 'mini',
            format: 'dollars',
          },
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'leaseEnd2',
          type: InputType.DatePicker,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['duplex', 'triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) &&
            ['long_term'].includes(
              watchedFields[`leaseType2${multiformParentIndex}`]
            ),
          props: {
            label: 'Lease End',
            size: 'mini',
          },
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'rentAmount2Placeholder',
          type: InputType.CustomComponent,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            !['duplex', 'triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) ||
            ['long_term', 'mtm'].includes(
              watchedFields[`leaseType2${multiformParentIndex}`]
            ),
          props: {},
          gridItemProps: {
            sm: 6,
          },
        },
        {
          name: 'leaseEnd2Placeholder',
          type: InputType.CustomComponent,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            !['duplex', 'triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) ||
            ['mtm'].includes(
              watchedFields[`leaseType2${multiformParentIndex}`]
            ),
          props: {},
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'unitName3',
          type: InputType.Text,
          required: true,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Unit Name',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'beds3',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Beds',
            format: 'number',
            placeholder: '2',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'baths3',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Baths',
            format: 'number',
            placeholder: '2',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'sqft3',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Sqft',
            format: 'number',
            placeholder: '2000',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },

        {
          name: 'leaseType3',
          type: InputType.Dropdown,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          gridItemProps: {
            sm: 2.4,
          },
          props: {
            label: 'Lease Type',
            size: 'mini',

            options: [
              {
                label: 'Long Term',
                value: 'long_term',
              },
              {
                label: 'Month-to-month',
                value: 'mtm',
              },
              {
                label: 'None',
                value: '',
              },
            ],
          },
        },
        {
          name: 'rentAmount3',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) &&
            ['long_term', 'mtm'].includes(
              watchedFields[`leaseType3${multiformParentIndex}`]
            ),
          props: {
            label: 'Rent Amount',
            format: 'dollars',
            size: 'mini',
          },
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'leaseEnd3',
          type: InputType.DatePicker,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) &&
            ['long_term'].includes(
              watchedFields[`leaseType3${multiformParentIndex}`]
            ),
          props: {
            label: 'Lease End',
            size: 'mini',
          },
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'rentAmount3Placeholder',
          type: InputType.CustomComponent,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            !['triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) ||
            ['long_term', 'mtm'].includes(
              watchedFields[`leaseType3${multiformParentIndex}`]
            ),
          props: {},
          gridItemProps: {
            sm: 6,
          },
        },
        {
          name: 'leaseEnd3Placeholder',
          type: InputType.CustomComponent,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            !['triplex', 'fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) ||
            ['mtm'].includes(
              watchedFields[`leaseType3${multiformParentIndex}`]
            ),
          props: {},
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'unitName4',
          type: InputType.Text,
          required: true,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Unit Name',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'beds4',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Beds',
            format: 'number',
            placeholder: '2',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'baths4',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Baths',
            format: 'number',
            placeholder: '2',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },
        {
          name: 'sqft4',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          props: {
            label: 'Sqft',
            format: 'number',
            placeholder: '2000',
            size: 'mini',
          },
          gridItemProps: {
            sm: 2.4,
          },
        },

        {
          name: 'leaseType4',
          type: InputType.Dropdown,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ),
          gridItemProps: {
            sm: 2.4,
          },
          props: {
            label: 'Lease Type',
            size: 'mini',

            options: [
              {
                label: 'Long Term',
                value: 'long_term',
              },
              {
                label: 'Month-to-month',
                value: 'mtm',
              },
              {
                label: 'None',
                value: '',
              },
            ],
          },
        },
        {
          name: 'rentAmount4',
          type: InputType.Text,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) &&
            ['long_term', 'mtm'].includes(
              watchedFields[`leaseType4${multiformParentIndex}`]
            ),
          props: {
            label: 'Rent Amount',
            format: 'dollars',
            size: 'mini',
          },
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'leaseEnd4',
          type: InputType.DatePicker,
          required: false,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            ['fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) &&
            ['long_term'].includes(
              watchedFields[`leaseType4${multiformParentIndex}`]
            ),
          props: {
            label: 'Lease End',
            size: 'mini',
          },
          gridItemProps: {
            sm: 3,
          },
        },
        {
          name: 'rentAmount4Placeholder',
          type: InputType.CustomComponent,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            !['fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) ||
            ['long_term', 'mtm'].includes(
              watchedFields[`leaseType4${multiformParentIndex}`]
            ),
          props: {},
          gridItemProps: {
            sm: 6,
          },
        },
        {
          name: 'leaseEnd4Placeholder',
          type: InputType.CustomComponent,
          renderIf: (watchedFields: any, multiformParentIndex?: number) =>
            !['fourplex'].includes(
              watchedFields[`propertyType${multiformParentIndex}`]
            ) ||
            ['mtm'].includes(
              watchedFields[`leaseType4${multiformParentIndex}`]
            ),
          props: {},
          gridItemProps: {
            sm: 3,
          },
        },
      ],
    },
  },
]

const csvInputConfigs: AnyInputConfig[] = [
  {
    name: 'csvFile',
    type: InputType.FileUpload,
    props: {
      label: 'Upload a CSV file',
      accept: {
        [FileUploadMimeType.CSV]: [FileUploadFileType.CSV],
      },
    },
  },
]

const AddressCreationPage = (props: AddressCreationPageProps) => {
  const presentationalProps = useAddressCreationPage(props)
  const { onSubmit, onSubmitCsv, loading, results, currentTab, setCurrentTab } =
    presentationalProps

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <Box display="flex" flexDirection="column" gap="48px" p="64px">
          {results.length ? (
            <>
              <Typography variant="h3">Operation Results</Typography>
              <Typography variant="body1">
                Please verify these addresses match the uploaded addresses
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Link</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((result) => (
                    <TableRow key={result.formattedAddress}>
                      <TableCell>
                        <a
                          href={`${SALESFORCE_URL}/lightning/r/Address__c/${result.addressId}/view`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {result.addressId}
                        </a>
                      </TableCell>
                      <TableCell>{result.formattedAddress}</TableCell>
                      <TableCell
                        sx={{
                          backgroundColor:
                            statusColorMap[
                              (result.status as string) || 'Failed'
                            ] || flockTheme.palette.softGold.main,
                        }}
                      >
                        {result.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : null}
          <Tabs
            value={currentTab}
            onChange={(_, newValue) => setCurrentTab(newValue)}
          >
            <Tab label="Manual Entry" />
            <Tab label="CSV Upload" />
          </Tabs>
          {currentTab === 0 ? (
            <GridForm
              onSubmit={onSubmit}
              inputConfigs={inputConfigs}
              loading={loading}
              ctaText="Create"
              gridProps={{
                spacing: 3,
              }}
              ctaBoxProps={{
                pb: '32px',
              }}
            />
          ) : (
            <>
              <TrackedLink
                to="https://docs.google.com/spreadsheets/d/1ONrW2lOOyWThbovL7F8AWqsdnGbDjmC1tyNyzyNF2n0/edit?gid=2048499661#gid=2048499661"
                target="_blank"
              >
                Example Template
              </TrackedLink>
              <GridForm
                onSubmit={onSubmitCsv}
                inputConfigs={csvInputConfigs}
                loading={loading}
                ctaText="Create"
              />
            </>
          )}
        </Box>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default AddressCreationPage
