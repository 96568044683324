import { Core_AccountDocument } from '@flock/flock-gql-server/src/__generated__/graphql'

export enum AccountDocumentType {
  ADDITIONAL_OFFER_DETAILS = 'additional-offer-details',
  EDUCATIONAL = 'educational',
  PERFORMANCE = 'performance',
  LEGAL = 'legal',
  OTHER = 'other',
}

// Props that are used by the useAccountDocumentsTable hook
export type AccountDocumentsTableProps = {
  accountId: string
  title: string
  documents: Core_AccountDocument[]
}

// Props that are used by the presentational components (AccountDocumentsTableDesktop.tsx, AccountDocumentsTableMobile.tsx, AccountDocumentsTableTablet.tsx)
export type AccountDocumentsTablePresentationalProps =
  AccountDocumentsTableProps & {
    editModalOpen: boolean
    setEditModalOpen: (value: boolean) => void
    deleteModalOpen: boolean
    setDeleteModalOpen: (value: boolean) => void
    selectedDocument: any
    setSelectedDocument: (value: any) => void
  }
