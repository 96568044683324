import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { formattedDollarsToCents } from '@flock/utils'
import { useMutation } from '@apollo/client'
import {
  AdminCreateFeeDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_CreatePropertyFeeRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'

type CreateFeeParams = {
  feeType: string
  feeCents: string
}

type CreateFeeModalParams = {
  propertyUuid: string
  isOpen: boolean
  close: () => void
}

const CreateFeeModal = (props: CreateFeeModalParams) => {
  const { propertyUuid, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createFee, { loading }] = useMutation(AdminCreateFeeDocument)

  const onSubmit = async (createFeeParams: CreateFeeParams) => {
    const { feeType, feeCents } = createFeeParams
    const createFeeInput: Core_CreatePropertyFeeRequestInput = {
      feeParams: {
        propertyUuid,
        feeType,
        feeCents: formattedDollarsToCents(feeCents),
      },
    }

    try {
      await createFee({
        variables: {
          input: createFeeInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify('Successfully created property fee.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to create property fee.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Create Fee"
      inputConfigs={[
        {
          inputName: 'feeType',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Fee Type',
            fullWidth: true,
            options: [
              {
                text: 'Annual HOA Dues',
                value: 'AnnualHOADues',
              },
              {
                text: 'Annual Taxes',
                value: 'AnnualTaxes',
              },
              {
                text: 'Prorated Annual Taxes',
                value: 'ProratedAnnualTaxes',
              },
              {
                text: 'Property Tax Accrual',
                value: 'PropertyTaxAccrual',
              },
              {
                text: 'Annual Insurance Premium',
                value: 'AnnualInsurancePremium',
              },
              {
                text: 'Flood Insurance',
                value: 'FloodInsurance',
              },
            ],
          },
        },
        {
          inputName: 'feeCents',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Fee Amount',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },
      ]}
    />
  )
}

export default CreateFeeModal
