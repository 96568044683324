import React, { useState } from 'react'
import {
  AdminGetBrokerDocument,
  AdminSearchBrokersDocument,
  AdminUpdateBrokerDocument,
  Core_Broker as Broker,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'
import { Button, useSnackbar, InputType } from '@flock/flock-component-library'
import ActionFormModal from '../shared/ActionFormModal'

type UpdateBrokerParams = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  shortLink: string
  headshotUrl: string
  websiteUrl: string
}

type UpdateBrokerModalProps = {
  existingBroker: Broker | undefined
}

const UpdateBrokerModal = (props: UpdateBrokerModalProps) => {
  const { existingBroker } = props
  const [open, setOpen] = useState(false)

  const { notify } = useSnackbar()

  const [updateBroker, { loading }] = useMutation(AdminUpdateBrokerDocument)

  const onSubmit = async (updateBrokerParams: UpdateBrokerParams) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      shortLink,
      headshotUrl,
      websiteUrl,
    } = updateBrokerParams
    const requestInput = {
      brokerUuid: existingBroker?.uuid,
      operatorUuid: existingBroker?.operatorUuid,

      firstName,
      lastName,
      email,
      phoneNumber,
      shortLink,
      headshotUrl,
      websiteUrl,
    }
    try {
      await updateBroker({
        variables: {
          updateBrokerInput: requestInput,
        },
        refetchQueries: [AdminGetBrokerDocument, AdminSearchBrokersDocument],
      })
      notify('Successfully updated broker', 'success')
      setOpen(false)
    } catch (e) {
      notify('Failed to update broker', 'error')
    }
  }

  const defaultValues = {
    ...existingBroker,
  }

  // eslint-disable-next-line no-underscore-dangle
  delete defaultValues.__typename

  return (
    <>
      <Button
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => setOpen(true)}
      >
        Update Broker
      </Button>
      <ActionFormModal
        open={open}
        setOpen={setOpen}
        loading={loading}
        onSubmit={onSubmit}
        actionText="Update Broker"
        formProps={{
          defaultValues,
        }}
        inputConfigs={[
          {
            inputName: 'firstName',
            inputType: InputType.Text,
            required: true,
            props: {
              type: 'text',
              label: 'First Name',
            },
          },
          {
            inputName: 'lastName',
            inputType: InputType.Text,
            required: true,
            props: {
              type: 'text',
              label: 'Last Name',
            },
          },
          {
            inputName: 'email',
            inputType: InputType.Text,
            required: true,
            props: {
              type: 'email',
              label: 'Email',
            },
          },
          {
            inputName: 'phoneNumber',
            inputType: InputType.Phone,
            required: false,
            props: {
              label: 'Phone Number',
            },
          },
          {
            inputName: 'shortLink',
            inputType: InputType.Text,
            required: false,
            props: {
              label: 'Short Link (used for client referrals)',
              fullWidth: true,
            },
          },
          {
            inputName: 'websiteUrl',
            inputType: InputType.Text,
            props: {
              label: 'Website URL',
            },
          },
          {
            inputName: 'headshotUrl',
            inputType: InputType.Text,
            props: {
              label: 'Headshot Image URL',
            },
          },
        ]}
      />
    </>
  )
}

export default UpdateBrokerModal
