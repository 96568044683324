import React, { useState } from 'react'
import {
  FileUploadFileType,
  InputType,
  useSnackbar,
} from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'

import ActionFormModal from '../shared/ActionFormModal'
import { CREATE_ADDRESS_PHOTO } from '../../graphql/mutations/propertyAssets'

type UploadPropertyPhotoParams = {
  uploadedFile: File[]
}

type UploadPropertyPhotoProps = {
  propertyUuid: string
  addressUuid: string
}

const UploadPropertyPhotoModal = (props: UploadPropertyPhotoProps) => {
  const { addressUuid, propertyUuid } = props
  const { notify } = useSnackbar()
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [createAddressPhoto] = useMutation(CREATE_ADDRESS_PHOTO)

  const onSubmit = async (result: UploadPropertyPhotoParams) => {
    setLoading(true)
    const { uploadedFile } = result
    const name = `${addressUuid}_primary_photo`
    const notes = 'ingested from admin'
    const type = 'primary'
    const isPrimary = true
    const isPublic = true
    const files = uploadedFile
    try {
      const createAddressPhotoInput = {
        propertyUuid,
        addressUuid,
        name,
        notes,
        type,
        takenDate: new Date().toISOString(),
        files,
        isPrimary,
        isPublic,
      }
      await createAddressPhoto({
        variables: {
          input: createAddressPhotoInput,
        },
        refetchQueries: 'active',
      })
      notify('Successfully created property photo', 'success')
      setOpen(false)
    } catch (e) {
      notify(`Failed to create property photo ${e}`, 'error')
    }
    setLoading(false)
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
        Upload Property Hero Image
      </Button>
      <ActionFormModal
        open={open}
        setOpen={() => setOpen(false)}
        loading={loading}
        onSubmit={onSubmit}
        actionText="Upload Property Hero Image"
        inputConfigs={[
          {
            inputName: 'uploadedFile',
            inputType: InputType.FileUpload,
            required: true,
            props: {
              accept: [
                FileUploadFileType.PNG,
                FileUploadFileType.JPEG,
                FileUploadFileType.JPG,
              ],
              label: 'Upload Photo',
            },
          },
        ]}
      />
    </>
  )
}

export default UploadPropertyPhotoModal
