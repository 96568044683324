/* eslint-disable no-nested-ternary */
import {
  CircularProgress,
  OpacityLink,
  Typography,
  useSnackbar,
} from '@flock/flock-component-library'
import { Grid } from '@mui/material'
import _ from 'lodash'
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { isISO8601Date } from '@flock/utils'
import {
  AdminGetAddressDocumentDownloadUrlDocument,
  AdminGetDocumentDownloadUrlDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'

type JsonFieldProps = {
  jsonKey: string
  size?: number
  value?: any
  isDocument?: boolean
  legalEntityUuid?: string
  isAddressDocument?: boolean
}

const JsonField = (props: JsonFieldProps) => {
  const [loading, setLoading] = useState(false)
  const {
    jsonKey,
    value,
    isDocument,
    legalEntityUuid,
    size,
    isAddressDocument,
  } = props
  const { notify } = useSnackbar()

  const { refetch } = useQuery(AdminGetDocumentDownloadUrlDocument, {
    skip: true,
  })

  const { refetch: addressDocumentRefetch } = useQuery(
    AdminGetAddressDocumentDownloadUrlDocument,
    {
      skip: true,
    }
  )

  const downloadDocument = async (documentUuid: string) => {
    setLoading(true)
    try {
      const urlData = await refetch({
        input: { legalEntityUuid: legalEntityUuid as string, documentUuid },
      })
      window.open(
        urlData?.data?.legalEntityDocumentPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the file. Please refresh or try again.',
        'error'
      )
    }
    setLoading(false)
  }

  const downloadAddressDocument = async (addressDocumentUuid: string) => {
    setLoading(true)
    try {
      const urlData = await addressDocumentRefetch({
        addressDocumentUuid: { addressDocumentUuid },
      })
      window.open(
        urlData?.data?.addressDocumentPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      console.log(addressDocumentUuid)
      notify(
        'An error while downloading the file. Please refresh or try again.',
        'error'
      )
    }
    setLoading(false)
  }
  let renderedValue = value?.toString()
  if (isISO8601Date(renderedValue)) {
    renderedValue = new Date(value).toLocaleDateString('en-US', {
      timeZone: 'UTC',
    })
  }

  return (
    <Grid item xs={size || 4} key={jsonKey}>
      <Typography variant="h4">{_.startCase(jsonKey)}</Typography>
      {(isDocument || isAddressDocument) && value && value.length > 0 ? (
        <>
          {value.map((documentUuid: string, idx: number) => (
            <Typography variant="body1">
              {loading ? (
                <CircularProgress />
              ) : (
                <OpacityLink
                  onClick={() =>
                    isDocument
                      ? downloadDocument(documentUuid)
                      : downloadAddressDocument(documentUuid)
                  }
                >
                  Download File {idx + 1}
                </OpacityLink>
              )}
            </Typography>
          ))}
        </>
      ) : React.isValidElement(value) ? (
        <>{value}</>
      ) : (
        <Typography variant="body1">
          {value || value === false ? renderedValue : '-'}
        </Typography>
      )}
    </Grid>
  )
}

JsonField.defaultProps = {
  value: null,
  isDocument: false,
  legalEntityUuid: null,
  size: 4,
  isAddressDocument: false,
}

export default JsonField
