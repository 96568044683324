import React from 'react'
import {
  Typography,
  Paper,
  Button,
  useSnackbar,
} from '@flock/flock-component-library'
import {
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import {
  AdminDeleteOrderV3Document,
  AdminOrderOnboardingV3SearchOrdersV3Document,
} from '@flock/flock-gql-server/src/__generated__/graphql'

import { OrderMetadata, SubscriberNameRender } from './OrderV3Utils'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

const ButtonWrapper = styled('div')({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
})

const CancelButton = styled(Button)({
  marginRight: '0.5rem',
})

type DeleteOrderModalV3Params = {
  orderMetadata: OrderMetadata
  isOpen: boolean
  close: () => void
}

const DeleteOrderV3Modal = (props: DeleteOrderModalV3Params) => {
  const { orderMetadata: existingOrder, isOpen, close } = props
  const { notify } = useSnackbar()
  const createdAt = existingOrder?.createdAt || ''
  const createdAtDate = new Date(createdAt)

  const [deleteOrder, { loading }] = useMutation(AdminDeleteOrderV3Document)

  const onClick = async () => {
    try {
      await deleteOrder({
        variables: {
          deleteOrderV3Input: {
            uuid: existingOrder?.uuid || '',
          },
        },
        refetchQueries: [AdminOrderOnboardingV3SearchOrdersV3Document],
      })
      notify('Successfully deleted Order', 'success')
      close()
    } catch (e) {
      notify('Failed to Delete Order', 'error')
    }
  }

  return (
    <Modal open={isOpen} onClose={close}>
      <ModalWrapper>
        <Typography
          variant="h2"
          sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
        >
          Delete Order
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contributor</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <SubscriberNameRender
                  legalEntityUuid={existingOrder.legalEntityUuid}
                />
              </TableCell>
              <TableCell>{createdAtDate.toLocaleDateString('en-US')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ButtonWrapper>
          <CancelButton onClick={close}>Cancel</CancelButton>
          <Button variant="contained" color="error" onClick={onClick}>
            {loading ? 'Deleting...' : 'Delete Order'}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  )
}

export default DeleteOrderV3Modal
