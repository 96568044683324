import React, { useState } from 'react'
import {
  Typography,
  Paper,
  Button,
  Form,
  useSnackbar,
  LoadingCard,
  InputType,
} from '@flock/flock-component-library'
import {
  AdminGetCustomerDocument,
  AdminGetOperatorsDocument,
  AdminUpdateCustomerDocument,
  Core_Customer,
  Core_Operator,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { styled } from '@mui/material/styles'
import { Modal, Box } from '@mui/material'
import { useMutation, useQuery } from '@apollo/client'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type CustomerReassignmentProps = {
  customer: Core_Customer
}

type CustomerReassignmentParams = {
  operatorUuid: string
}

const CustomerReassignmentModal = (props: CustomerReassignmentProps) => {
  const { customer } = props
  const { notify } = useSnackbar()
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const [updateCustomer, { loading }] = useMutation(AdminUpdateCustomerDocument)
  const { loading: operatorsLoading, data: operatorsData } = useQuery(
    AdminGetOperatorsDocument,
    {
      variables: {},
      onError: () => {
        notify('Failed to get operators', 'error')
      },
    }
  )

  let operatorsDropdownOptions: any[] = []
  if (!operatorsLoading) {
    operatorsDropdownOptions =
      operatorsData?.operators?.operators?.map((operator: Core_Operator) => ({
        value: operator.uuid,
        text: operator.fullName,
      })) || []
  }

  const onSubmit = async (result: CustomerReassignmentParams) => {
    const updateCustomerInput = {
      customerUuid: customer.uuid,
      operatorUuid: result.operatorUuid,
    }
    try {
      await updateCustomer({
        variables: {
          input: updateCustomerInput,
        },
        refetchQueries: [AdminGetCustomerDocument],
      })
      notify(
        `Reassigned customer ${customer.fullName} with operator $${result.operatorUuid}`,
        'success'
      )
      setOpen(false)
    } catch (e) {
      notify('Failed to reassign customer', 'error')
    }
  }

  return (
    <>
      <Button
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => {
          openModal()
        }}
      >
        Customer Reassignment
      </Button>
      <Modal open={open} onClose={closeModal}>
        <ModalWrapper>
          <Typography
            variant="h2"
            sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
          >
            Reassign Customer
          </Typography>
          <Box sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
            <Typography variant="body1">{customer.fullName}</Typography>

            <Typography variant="body1">
              <br />
              This will reassign the customer.
            </Typography>
          </Box>
          {operatorsLoading ? (
            <LoadingCard text="" />
          ) : (
            <Form
              onSubmit={onSubmit}
              ctaText={loading ? 'Submitting...' : 'Reassign Customer'}
              ctaProps={{
                disabled: loading,
              }}
              inputConfigs={[
                {
                  inputName: 'operatorUuid',
                  inputType: InputType.Dropdown,
                  required: true,
                  props: {
                    fullWidth: true,
                    label: 'Operator',
                    options: operatorsDropdownOptions,
                  },
                },
              ]}
            />
          )}
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default CustomerReassignmentModal
