import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/shared-ui'
import {
  AdminCreateOrUpdateAccountDocumentOverridesDocument,
  AdminGetAccountDocumentOverridesDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { isEqual } from 'lodash'
import { useState, useEffect } from 'react'
import { useStaticDocuments } from '../../hooks/useStaticDocuments'
import {
  AccountStaticDocumentsTableProps,
  AccountStaticDocumentsTablePresentationalProps,
} from './accountStaticDocumentsTableTypes'

export const CREATE_OR_UPDATE_ACCOUNT_DOCUMENT_OVERRIDES = gql`
  mutation AdminCreateOrUpdateAccountDocumentOverrides(
    $input: Core_CreateOrUpdateAccountDocumentOverridesRequestInput!
  ) {
    createOrUpdateAccountDocumentOverrides(input: $input) {
      _empty
    }
  }
`

const useAccountStaticDocumentsTable: (
  props: AccountStaticDocumentsTableProps
) => AccountStaticDocumentsTablePresentationalProps = (
  props: AccountStaticDocumentsTableProps
) => {
  const { accountId, overrides } = props

  const [currentOverrides, setCurrentOverrides] = useState<{
    [key: string]: boolean
  }>(overrides)
  const [isDirty, setIsDirty] = useState(false)
  const [updateAccountDocumentOverrides] = useMutation(
    AdminCreateOrUpdateAccountDocumentOverridesDocument
  )
  const [saveLoading, setSaveLoading] = useState(false)
  const { loading, staticDocumentConfigs } = useStaticDocuments()

  const { notify } = useSnackbar()

  useEffect(() => {
    setCurrentOverrides(overrides)
  }, [overrides])

  useEffect(() => {
    if (!isEqual(currentOverrides, overrides)) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }, [currentOverrides, overrides])

  const onToggle = (documentUuid: string, newValue: boolean) => {
    const newOverrides = {
      ...currentOverrides,
      [documentUuid]: newValue,
    }

    setCurrentOverrides(newOverrides)

    if (!isEqual(overrides, newOverrides)) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }

  const onSave = async () => {
    setSaveLoading(true)
    try {
      const newOverrides = {
        ...overrides,
        ...currentOverrides,
      }

      await updateAccountDocumentOverrides({
        variables: {
          input: {
            accountId,
            overrides: JSON.stringify(newOverrides),
          },
        },
        refetchQueries: [AdminGetAccountDocumentOverridesDocument],
      })
      notify('Successfully updated document settings.', 'success')
    } catch (e) {
      notify('Failed to update document settings.', 'error')
    }
    setSaveLoading(false)
  }

  return {
    accountId,
    overrides,
    isDirty,
    saveLoading,
    loading,
    staticDocumentConfigs,
    currentOverrides,
    notify,
    onToggle,
    onSave,
  }
}

export default useAccountStaticDocumentsTable
