import React from 'react'
import { flockColors } from '@flock/flock-component-library'
import { TableCell, TableRow, Link, Button } from '@mui/material'
import { ArrowForwardIos, ContentCopy } from '@mui/icons-material'
import { Core_Customer } from '@flock/flock-gql-server/src/__generated__/graphql'
import { FLOCK_LANDING_URL } from '../../constants'

export const formatCustomer = (customer: Core_Customer | undefined | null) => ({
  name: customer?.fullName,
  email: customer?.email,
  phoneNumber: customer?.phoneNumber,
  copyToClipboard: {
    customer,
  },
  customerActionsParams: {
    customer,
  },
})

export const customerColumns = [
  { name: 'name', label: 'Name' },
  { name: 'email', label: 'Email' },
  { name: 'phoneNumber', label: 'Phone' },
  {
    name: 'copyToClipboard',
    options: {
      customHeadLabelRender: () => '',
      draggable: false,
      download: false,
      filter: false,
      print: false,
      sort: false,
    },
  },
  {
    name: 'customerActionsParams',
    options: {
      customHeadLabelRender: () => '',
      draggable: false,
      download: false,
      filter: false,
      print: false,
      sort: false,
    },
  },
]

export const CustomCustomerRowRender =
  (path: string, notify: any) => (data: [string, string, string, any, any]) => {
    const [name, email, phoneNumber, customerActionsParams, copyToClipboard] =
      data

    return (
      <TableRow
        style={{ cursor: 'pointer' }}
        data-cy="customerDetailsButton"
        sx={{
          '&:hover': {
            backgroundColor: flockColors.lighterGray,
            transition: 'background-color 0.5s ease',
          },
          textDecoration: 'none!important',
        }}
      >
        <TableCell
          // @ts-ignore
          component={Link}
          href={`${path}/${customerActionsParams.customer.uuid}`}
        >
          {name}
        </TableCell>
        <TableCell
          // @ts-ignore
          component={Link}
          href={`${path}/${customerActionsParams.customer.uuid}`}
        >
          {email}
        </TableCell>
        <TableCell
          // @ts-ignore
          component={Link}
          href={`${path}/${customerActionsParams.customer.uuid}`}
        >
          {phoneNumber}
        </TableCell>
        <TableCell align="right">
          <Button
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={() => {
              navigator.clipboard.writeText(
                `${FLOCK_LANDING_URL}/portfolio-estimate/${copyToClipboard.customer.uuid}`
              )
              notify(`${name} customer offer page copied to Clipboard`)
            }}
          >
            <ContentCopy />
          </Button>
        </TableCell>
        <TableCell
          // @ts-ignore
          component={Link}
          href={`${path}/${customerActionsParams.customer.uuid}`}
          align="right"
        >
          <ArrowForwardIos />
        </TableCell>
      </TableRow>
    )
  }
