import React, { useState, useMemo, ChangeEvent, useCallback } from 'react'
import {
  Typography,
  DataTable,
  LoadingCard,
  flockColors,
  useSnackbar,
} from '@flock/flock-component-library'
import { styled } from '@mui/material/styles'
import { TextField, InputAdornment, TableRow, TableCell } from '@mui/material'
import { Search, ArrowForwardIos } from '@mui/icons-material'
import { debounce } from 'lodash'
import { useQuery } from '@apollo/client'
import {
  AdminGetOperatorDocument,
  AdminSearchBrokersDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate, RouteComponentProps } from '@reach/router'
import { BROKER_MANAGEMENT_URL } from '../../constants'
import CreateBrokerModal from './CreateBrokerModal'
import { formatDateString } from '../InvestorManagement/utils'

const SearchWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '1rem',
})

const SearchField = styled(TextField)({
  width: '100%',

  '& > div > input': {
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
  },
})

const StyledTableRow = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

const columns = [
  { name: 'uuid', options: { display: false } },
  { name: 'firstName', label: 'Name' },
  { name: 'lastName', options: { display: false } },
  { name: 'phoneNumber', label: 'Phone' },
  { name: 'email', label: 'Email' },
  { name: 'outreachDate', label: 'Last Outreach Date' },
]

const CustomRowRender = (
  data: [string, string, string, string, string, string]
) => {
  const [uuid, firstName, lastName, phoneNumber, email, outreachDate] = data
  let date = formatDateString(outreachDate)
  if (outreachDate === '0001-01-01T00:00:00Z') {
    date = 'No outreach yet'
  }
  return (
    <StyledTableRow
      onClick={() => {
        navigate(`${BROKER_MANAGEMENT_URL}/${uuid}`)
      }}
      style={{ cursor: 'pointer' }}
      data-cy="leadDetailsButton"
    >
      <TableCell>
        {firstName} {lastName}
      </TableCell>
      <TableCell>{phoneNumber}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{date}</TableCell>
      <TableCell align="right">
        <ArrowForwardIos />
      </TableCell>
    </StyledTableRow>
  )
}

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
  display: 'flex',
  justifyContent: 'space-between',
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BrokerManagementPage = (_: RouteComponentProps) => {
  const { notify } = useSnackbar()

  const { data: ownOperatorData } = useQuery(AdminGetOperatorDocument, {
    variables: {
      input: {},
    },
    onError: () => {
      notify('Failed to get operator data', 'error')
    },
  })

  let querySearch = ''
  if (typeof window !== `undefined`) {
    const { search } = window.location
    const params = new URLSearchParams(search)
    querySearch = params.get('search') || ''
    querySearch = querySearch.toLowerCase()
  }

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    sort: true,
    selectableRows: 'none',
    responsive: 'standard',
    customRowRender: CustomRowRender,
    elevation: 0,
  }

  const [searchString, setSearchString] = useState(querySearch)

  const { loading, data } = useQuery(AdminSearchBrokersDocument, {
    variables: {
      input: {
        searchString,
      },
    },
    onError: () => {
      notify('Failed to get brokers', 'error')
    },
  })

  const brokers = data?.searchBrokers?.brokers || []

  const setSearchStringToSearchFieldInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value.toLowerCase())
    },
    []
  )

  const debounceSetSearchStringToSearchFieldInput = useMemo(
    () => debounce(setSearchStringToSearchFieldInput, 700),
    [setSearchStringToSearchFieldInput]
  )

  return (
    <>
      <PageTitleContainer>
        <Typography variant="h1">Broker Management</Typography>
        <CreateBrokerModal
          operatorUuid={ownOperatorData?.operator?.operator?.uuid as string}
        />
      </PageTitleContainer>
      <SearchWrapper>
        <SearchField
          variant="outlined"
          placeholder="Search brokers"
          defaultValue={querySearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={debounceSetSearchStringToSearchFieldInput}
          data-cy="brokerSearchField"
        />
      </SearchWrapper>
      {loading ? (
        <LoadingCard text="Loading..." />
      ) : (
        <DataTable
          title=""
          data={brokers}
          columns={columns}
          options={options as any}
        />
      )}
    </>
  )
}

export default BrokerManagementPage
