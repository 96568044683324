import React from 'react'
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Modal,
  Paper,
} from '@mui/material'

import useValuationHistoryModal from './useValuationHistoryModal'
import ValuationTrailModal from '../ValuationTrailModal/ValuationTrailModal'
import { ValuationHistoryModalProps } from './valuationHistoryModalTypes'

const LoadingWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

const ValuationHistoryModal = (props: ValuationHistoryModalProps) => {
  const { open, onClose } = props
  const {
    valuationHistoryLoading,
    valuationTrailOpen,
    setValuationTrailOpen,
    selectedValuation,
    displayHeaders,
    displayCells,
  } = useValuationHistoryModal(props)

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '80px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          maxHeight: '75vh',
          maxWidth: '95vw',
          overflowY: 'scroll',
          overflowX: 'scroll',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        {valuationHistoryLoading ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {displayHeaders.map((displayHeader) => (
                  <TableCell>{displayHeader}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayCells?.map((valuation) => (
                <TableRow>
                  {valuation.map((cell) => (
                    <TableCell>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {selectedValuation?.valuationTrail && (
          <ValuationTrailModal
            open={valuationTrailOpen}
            onClose={() => setValuationTrailOpen(false)}
            valuation={selectedValuation}
            valuationTrailData={selectedValuation?.valuationTrail}
          />
        )}
      </Paper>
    </Modal>
  )
}

export default ValuationHistoryModal
