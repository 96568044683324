import { Grid, Typography } from '@flock/flock-component-library'
import React from 'react'
import { prettyPrintJsonKeys } from '../../utils'

type DataMapTableProps = {
  fields: string[]
  dataMap: { [key: string]: any }
}

const DataMapTable = (props: DataMapTableProps) => {
  const { fields, dataMap } = props

  return (
    <>
      {fields.map((key) => (
        <Grid item xs={4}>
          <Typography variant="h4">{prettyPrintJsonKeys(key)}</Typography>
          <Typography variant="body1">
            {dataMap[key] || dataMap[key] === false
              ? JSON.stringify(dataMap[key])
              : '-'}
          </Typography>
        </Grid>
      ))}
    </>
  )
}

export default DataMapTable
