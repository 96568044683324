import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowHeightParams,
} from '@mui/x-data-grid'
import {
  InputAdornment,
  Typography,
  TextField,
  debounce,
  Box,
} from '@mui/material'

import { styled } from '@mui/material/styles'

import { Search } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import {
  AdminGetLeadEventsDocument,
  Core_LeadEvent,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import LoadingCard from '../LoadingCard'

type GroupedEvent = {
  id: number
  pageView: Core_LeadEvent
  otherEvents: Core_LeadEvent[]
}

const groupEvents = (events: Core_LeadEvent[]): GroupedEvent[] => {
  const grouped: GroupedEvent[] = []
  let currentGroup: GroupedEvent | undefined

  for (let i = 0; i < events.length; i += 1) {
    if (events[i].actionType === 'page_visit') {
      if (currentGroup) {
        grouped.push(currentGroup)
      }

      currentGroup = { pageView: events[i], otherEvents: [], id: i }
    } else if (
      currentGroup &&
      events[i].urlPath.includes(currentGroup.pageView.urlPath)
    ) {
      currentGroup.otherEvents.push(events[i])
    }
  }

  if (currentGroup) {
    grouped.push(currentGroup)
  }

  return grouped
}

const formatLeadEvent = (leadEvent: Core_LeadEvent | undefined | null) => {
  const additionalInfoMap = JSON.parse(leadEvent?.additionalInfo || '{}')

  return {
    slug: leadEvent?.slug,
    actionType: leadEvent?.actionType,
    urlPath: `https://flockhomes.com${leadEvent?.urlPath}`,
    additionalInfo: additionalInfoMap,
    createdAt: `  ${leadEvent?.createdAt.replace('T', ' ').split('.000Z')[0]}`,
  }
}

const formatGroupedEvent = (groupedEvent: GroupedEvent | undefined | null) => ({
  id: groupedEvent?.id,
  slug: groupedEvent?.pageView?.slug,
  actionType: groupedEvent?.pageView?.actionType,
  urlPath: groupedEvent?.pageView?.urlPath,
  additionalInfo: groupedEvent?.pageView?.additionalInfo,
  createdAt: groupedEvent?.pageView?.createdAt.toString(),
  otherEvents: groupedEvent?.otherEvents || [],
})

const SearchWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '1rem',
})

const SearchField = styled(TextField)({
  width: '100%',

  '& > div > input': {
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
  },
})

type LeadEventsSectionProps = {
  leadUuid: string
}

const LeadEventsSection = (props: LeadEventsSectionProps) => {
  const { notify } = useSnackbar()
  const { leadUuid } = props

  let querySearch = ''
  if (typeof window !== `undefined`) {
    const { search } = window.location
    const params = new URLSearchParams(search)
    querySearch = params.get('search') || ''
    querySearch = querySearch.toLowerCase()
  }

  const [searchString, setSearchString] = useState(querySearch)
  const [selectedID, setSelectedID] = useState('')

  const { loading, data } = useQuery(AdminGetLeadEventsDocument, {
    variables: {
      getLeadEventsInput: {
        leadUuid,
      },
    },
    onError: () => {
      notify('Failed to get lead events', 'error')
    },
  })

  const leadEvents: Core_LeadEvent[] =
    data?.getLeadEvents?.leadEvents?.map(formatLeadEvent as any) || []

  let groupedEvents = groupEvents(leadEvents)
  leadEvents.reverse()

  const groupedEventColumns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Event Date',
      width: 200,
      renderCell: (params: GridRenderCellParams<any, Date>) => {
        const { row } = params
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignSelf="flex-start"
            pt="16px"
          >
            <Box>
              <strong>{row?.createdAt}</strong>
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'urlPath',
      headerName: 'Page Viewed',
      width: 600,
      renderCell: (params: GridRenderCellParams<any, Date>) => {
        const { row } = params
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignSelf="flex-start"
            pt="16px"
            height="130px"
            sx={{ overflow: 'scroll' }}
          >
            <Box>
              <strong>{row?.urlPath!}</strong>
              {row?.otherEvents.length ? (
                <strong>
                  {'    '} [{row?.otherEvents.length}]
                </strong>
              ) : null}
            </Box>
            {selectedID === row?.id?.toLocaleString() && (
              <Box sx={{ overflow: 'scroll' }}>
                {row?.otherEvents.map((otherEvent: any) => (
                  <Box>
                    {otherEvent.actionType}, {otherEvent.slug}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'additionalInfo',
      headerName: 'Duration (s)',
      width: 350,
      renderCell: (params: GridRenderCellParams<any, Date>) => {
        const { row } = params
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignSelf="flex-start"
            pt="16px"
            sx={{ overflow: 'scroll' }}
          >
            <Box>
              <strong>{row?.additionalInfo?.timeOnPage}</strong>
            </Box>
            {selectedID === row?.id?.toLocaleString() && (
              <>
                {row?.additionalInfo?.utm_source && (
                  <Box>
                    UTM Source: {row?.additionalInfo?.utm_source?.split('?')[0]}
                  </Box>
                )}
                {row?.additionalInfo?.utm_medium && (
                  <Box>
                    UTM Medium: {row?.additionalInfo?.utm_medium?.split('?')[0]}
                  </Box>
                )}
                {row?.additionalInfo?.utm_campaign && (
                  <Box>
                    UTM Campaign:{' '}
                    {row?.additionalInfo?.utm_campaign?.split('?')[0]}
                  </Box>
                )}
              </>
            )}
          </Box>
        )
      },
    },
  ]

  if (searchString) {
    groupedEvents = groupedEvents?.filter((leadEvent) =>
      JSON.stringify(leadEvent.pageView.urlPath).includes(searchString)
    )
  }

  groupedEvents.sort((a, b) => {
    const dateA = new Date(a.pageView.createdAt)
    const dateB = new Date(b.pageView.createdAt)

    if (dateA > dateB) return -1
    if (dateA < dateB) return 1
    return 0
  })

  const setSearchStringToSearchFieldInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value.toLowerCase())
    },
    []
  )

  const debounceSetSearchStringToSearchFieldInput = useMemo(
    () => debounce(setSearchStringToSearchFieldInput, 700),
    [setSearchStringToSearchFieldInput]
  )

  return (
    <>
      <Typography sx={{ paddingBottom: '16px' }} variant="h2">
        Lead Events
      </Typography>
      <SearchWrapper>
        <SearchField
          variant="outlined"
          placeholder="Search lead events"
          defaultValue={querySearch}
          InputProps={{
            startAdornment: (
              // eslint-disable-next-line react/jsx-no-undef
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={debounceSetSearchStringToSearchFieldInput}
          data-cy="leadSearchField"
        />
      </SearchWrapper>
      {loading ? (
        <LoadingCard text="Loading..." />
      ) : (
        <>
          <DataGrid
            rows={groupedEvents.map(formatGroupedEvent)}
            columns={groupedEventColumns}
            pageSizeOptions={[10]}
            rowHeight={50}
            onRowSelectionModelChange={(ids) => {
              setSelectedID(ids[0].toLocaleString())
            }}
            getRowHeight={({ id }: GridRowHeightParams) => {
              if (selectedID === id.toLocaleString()) {
                return 150
              }

              return null
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </>
      )}
    </>
  )
}

export default LeadEventsSection
