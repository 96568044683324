import React from 'react'
import { Box, Typography } from '@mui/material'
import useTableOfContents from './useTableOfContents'
import { TableOfContentsProps } from './tableOfContentsTypes'
import SinglePagePrintWrapper from '../SinglePagePrintWrapper'
import { Header } from '../../SharedPrintCollateralComponents'

const TableOfContents = (props: TableOfContentsProps) => {
  const presentationalProps = useTableOfContents(props)
  const { pageConfigs, coverName, batchStart, batchEnd } = presentationalProps

  return (
    <>
      {pageConfigs.map((pageConfig, idx) => {
        const { pageNumber, tocEntries } = pageConfig
        const truePageNumber = 2 + pageNumber
        return (
          <SinglePagePrintWrapper
            shouldShow={
              truePageNumber >= batchStart && truePageNumber < batchEnd
            }
            coverName={coverName}
            pageNumber={truePageNumber}
          >
            <Box
              display="flex"
              flexDirection="column"
              id={`toc-page-${pageNumber}`}
            >
              {idx === 0 ? (
                <Header section={coverName} title="Table of Contents" />
              ) : (
                <Header section={coverName} title="Table of Contents (cont.)" />
              )}
              <Box display="flex" flexDirection="column" gap="4px">
                {tocEntries.map((entry, entryIdx) => {
                  const { title, displayedPageNumber, section } = entry
                  if (
                    entryIdx === 0 ||
                    (entryIdx > 0 &&
                      section !== tocEntries[entryIdx - 1].section)
                  ) {
                    return (
                      <>
                        <Typography variant="p4" fontWeight={500} pt="12px">
                          {section}
                        </Typography>
                        <Box
                          key={entry.title}
                          display="flex"
                          flexDirection="column"
                        >
                          <Box display="flex" gap="4px">
                            <Typography variant="p4" width="16px">
                              {displayedPageNumber}
                            </Typography>
                            <Typography variant="p4">{title}</Typography>
                          </Box>
                        </Box>
                      </>
                    )
                  }

                  return (
                    <Box
                      key={entry.title}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box display="flex" gap="4px">
                        <Typography variant="p4" width="16px">
                          {displayedPageNumber}
                        </Typography>
                        <Typography variant="p4">{title}</Typography>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </SinglePagePrintWrapper>
        )
      })}
    </>
  )
}

export default TableOfContents
