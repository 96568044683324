import React, { useEffect, useState } from 'react'
import { Core_SalesforceScenario } from '@flock/flock-gql-server/src/__generated__/graphql'
import { Box, Typography } from '@mui/material'
import { HouseOutlinedIcon, DoorIcon } from '@flock/shared-ui'
import { formatIntegerDollars } from '@flock/utils'
import SinglePagePrintWrapper from '../SinglePagePrintWrapper'
import PortfolioRow from './PortfolioRow'
import PortfolioSummary from './PortfolioSummary'
import {
  FilledBox,
  Header,
  OutlinedBox,
} from '../../SharedPrintCollateralComponents'
import { UpdateRenderStateParams } from '../collateralRendererTypes'
import { CheckboxLabel } from '../../printCollateralPageTypes'

const MAX_PAGE_HEIGHT = 654

type RowData = {
  address: string
  assumedRemodelCost: string
  valuation: string
}

type PortfolioRendererPageConfig = {
  pageNumber: number
  rows: RowData[]
  renderTotal: boolean
  renderSummary: boolean
}

type PortfolioRendererProps = {
  equityAmount: number
  scenario: Core_SalesforceScenario
  updateRenderState: (params: UpdateRenderStateParams) => void
  coverName: string
  pageOffset: number

  batchStart: number
  batchEnd: number
}

const PortfolioRenderer = (props: PortfolioRendererProps) => {
  const {
    equityAmount,
    scenario,
    updateRenderState,
    coverName,
    pageOffset,
    batchStart,
    batchEnd,
  } = props
  const { addresses } = scenario
  const [numDoors, setNumDoors] = useState(0)
  const [remodelTotal, setRemodelTotal] = useState('')
  const [totalValuation, setTotalValuation] = useState('')

  const [pageNumber, setPageNumber] = useState(0)
  const [renderedItemsIndex, setRenderedItemsIndex] = useState(0)
  const [pageConfigs, setPageConfigs] = useState<PortfolioRendererPageConfig[]>(
    []
  )

  useEffect(() => {
    const newNumDoors = addresses?.reduce(
      // If there are no units, this counts as one door
      (prev, address) => prev + (address?.units?.length || 1),
      0
    )

    const newTotalValuation = addresses?.reduce(
      (prev, address) => prev + (address?.valuation?.[0]?.offerPrice || 0),
      0
    )

    const newRemodelTotal = addresses?.reduce(
      (prev, address) =>
        prev + (address?.valuation?.[0]?.totalRemodelCost || 0),
      0
    )

    setNumDoors(newNumDoors as number)
    setTotalValuation(formatIntegerDollars(newTotalValuation as number))
    setRemodelTotal(formatIntegerDollars(newRemodelTotal as number))

    // Get the current page's height
    const currentPageHeight = document.getElementById(
      `portfolio-renderer-${pageNumber}`
    )?.clientHeight
    let newRenderedItemsIndex = renderedItemsIndex
    const newPageConfigs = [...pageConfigs]

    // Move the item to the next page and start rendering on that page
    if (currentPageHeight && currentPageHeight > MAX_PAGE_HEIGHT) {
      // If we're rendering the summary, move the summary to the next page
      if (newPageConfigs[pageNumber].renderSummary) {
        newPageConfigs[pageNumber].renderSummary = false
        setPageNumber(pageNumber + 1)
        setPageConfigs([
          ...newPageConfigs,
          {
            pageNumber: pageNumber + 1,
            rows: [],
            renderTotal: false,
            renderSummary: true,
          },
        ])
      } else if (newPageConfigs[pageNumber].renderTotal) {
        newPageConfigs[pageNumber].renderTotal = false
        setPageNumber(pageNumber + 1)
        setPageConfigs([
          ...newPageConfigs,
          {
            pageNumber: pageNumber + 1,
            rows: [],
            renderTotal: true,
            renderSummary: false,
          },
        ])
      } else {
        const itemToMoveToNextPage = newPageConfigs[pageNumber].rows.pop()

        setPageNumber(pageNumber + 1)
        setPageConfigs([
          ...newPageConfigs,
          {
            pageNumber: pageNumber + 1,
            rows: [itemToMoveToNextPage as RowData],
            renderTotal: false,
            renderSummary: false,
          },
        ])
      }
    } else if (addresses![renderedItemsIndex]) {
      const valuationData = addresses![renderedItemsIndex]?.valuation?.find(
        (val) => val?.isLiveOnOfferPage
      )

      if (newPageConfigs.length === 0) {
        newPageConfigs.push({
          pageNumber: 0,
          rows: [],
          renderTotal: false,
          renderSummary: false,
        })
      }

      newPageConfigs[pageNumber].rows.push({
        address: addresses![renderedItemsIndex]?.addressStreet as string,
        assumedRemodelCost: formatIntegerDollars(
          valuationData?.totalRemodelCost || 0
        ),
        valuation: formatIntegerDollars(valuationData?.offerPrice || 0),
      })
      newRenderedItemsIndex += 1
      setPageConfigs(newPageConfigs)

      // If we have rendered all the items, add the totals to the page configs
    } else if (
      !newPageConfigs[pageNumber].renderTotal &&
      !newPageConfigs[pageNumber].renderSummary
    ) {
      newPageConfigs[pageNumber].renderTotal = true
      setPageConfigs(newPageConfigs)
    } else if (!newPageConfigs[pageNumber].renderSummary) {
      newPageConfigs[pageNumber].renderSummary = true
      setPageConfigs(newPageConfigs)
    } else {
      // If we have rendered all the items and the totals, we are done rendering
      updateRenderState({
        section: CheckboxLabel.PORTOFOLIO_OVERVIEW,
        doneRendering: true,
        numPages: pageConfigs.length,
      })
    }
    setRenderedItemsIndex(newRenderedItemsIndex)
  }, [JSON.stringify(addresses), JSON.stringify(pageConfigs)])

  return (
    <>
      {pageConfigs.map((pageConfig, idx) => {
        const {
          pageNumber: configNumber,
          rows,
          renderTotal,
          renderSummary,
        } = pageConfig
        const truePageNumber = pageOffset + idx

        return (
          <SinglePagePrintWrapper
            shouldShow={
              truePageNumber >= batchStart && truePageNumber < batchEnd
            }
            pageNumber={pageOffset + idx}
            coverName={coverName}
            key={`portfolio-renderer-${configNumber}`}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              id={`portfolio-renderer-${configNumber}`}
            >
              {idx === 0 && (
                <Box display="flex" flexDirection="column" gap="12px">
                  <Header section="Valuation" title="Portfolio Overview" />
                  <FilledBox gap="8px">
                    <Box display="flex" flexDirection="column" gap="2px">
                      <Typography variant="p4" fontWeight="500">
                        Your portfolio valuation range is
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: '24px',
                          fontWeight: 600,
                          lineHeight: '32px',
                          color: 'moneyGreen.main',
                        }}
                      >
                        {totalValuation}
                      </Typography>
                    </Box>
                    <Box display="flex" gap="8px">
                      <Box display="flex" gap="2px" alignItems="center">
                        <HouseOutlinedIcon width="12px" height="12px" />
                        <Typography variant="p5">
                          {addresses?.length} assets
                        </Typography>
                      </Box>
                      <Box display="flex" gap="2px" alignItems="center">
                        <DoorIcon width="12px" height="12px" />
                        <Typography variant="p5">{numDoors} doors</Typography>
                      </Box>
                    </Box>
                  </FilledBox>
                </Box>
              )}
              <Box display="flex" flexDirection="column" gap="8px">
                {(rows.length > 0 || renderTotal) && (
                  <OutlinedBox gap="4px">
                    <Box display="flex" gap="8px" alignItems="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          flex: '1 0 0',
                        }}
                      >
                        <Typography variant="c1m">Address</Typography>
                      </Box>
                      <Box
                        width="160px"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Typography variant="c1m" display="flex">
                          Assumed Remodel Cost
                        </Typography>
                        <Typography variant="p6" sx={{ fontWeight: 'bold' }}>
                          1
                        </Typography>
                      </Box>
                      <Box
                        width="120px"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Typography variant="c1m">Valuation</Typography>
                      </Box>
                    </Box>

                    {rows.map((row) => (
                      <PortfolioRow
                        value1={row.address}
                        value2={row.assumedRemodelCost}
                        value3={row.valuation}
                        key={row.address}
                      />
                    ))}
                  </OutlinedBox>
                )}

                {renderTotal && (
                  <>
                    <FilledBox>
                      <PortfolioRow
                        value1="Total valuation range"
                        value2={remodelTotal}
                        value3={totalValuation}
                      />
                    </FilledBox>
                    <Typography variant="p6" color="gray5.main">
                      <sup>1</sup>Valuations are inclusive of the remodel costs.
                    </Typography>
                  </>
                )}
              </Box>

              {renderSummary && (
                <PortfolioSummary
                  equityAmount={equityAmount}
                  scenario={scenario}
                />
              )}
            </Box>
          </SinglePagePrintWrapper>
        )
      })}
    </>
  )
}

export default PortfolioRenderer
