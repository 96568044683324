import {
  Core_Lead,
  Core_LeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { flockTheme, LibraryThemeProvider } from '@flock/shared-ui'
import { Box, Button, ThemeProvider, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LeadDocumentType } from '../shared/sharedTypes'
import LeadStaticDocumentToggleTable from '../StaticDocuments/LeadStaticDocumentToggleTable'
import CreateLeadDocumentModal from './CreateLeadDocumentModal'
import LeadDocumentsTable from './LeadDocumentsTable'

type LeadDocumentConfig = {
  title: string
  documents: Core_LeadDocument[]
}

type LeadDocumentsSectionProps = {
  leadUuid: string
  lead: Core_Lead
  overrides: { documents: { [key: string]: boolean } }
}

const LeadDocumentsSection = (props: LeadDocumentsSectionProps) => {
  const { leadUuid, lead, overrides } = props
  const [createDocumentModalOpen, setCreateDocumentModalOpen] = useState(false)
  const [leadDocumentsConfigs, setLeadDocumentsConfigs] = useState<
    LeadDocumentConfig[]
  >([])

  useEffect(() => {
    const leadDocumentsMap: any = {
      [LeadDocumentType.ADDITIONAL_OFFER_DETAILS]: {
        title: 'Additional Valuation Details',
        documents: [],
      },
      [LeadDocumentType.OTHER]: {
        title: 'Other',
        documents: [],
      },
    }
    if (lead?.documents?.length) {
      lead.documents.forEach((leadDocument: any) => {
        Object.values(LeadDocumentType).forEach((documentType) => {
          if (leadDocument.documentType === documentType) {
            leadDocumentsMap[documentType as LeadDocumentType].documents.push(
              leadDocument
            )
          }
        })
      })
    }
    const leadDocumentsArray = [
      leadDocumentsMap[LeadDocumentType.ADDITIONAL_OFFER_DETAILS],
      leadDocumentsMap[LeadDocumentType.OTHER],
    ]

    setLeadDocumentsConfigs(leadDocumentsArray)
  }, [lead])

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <Box
          display="flex"
          flexDirection="column"
          gap="48px"
          sx={{ pt: '64px' }}
        >
          <Typography variant="h2">Lead Documents</Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            gap="32px"
          >
            <Typography variant="h3">Lead Specific Documents</Typography>
            <Box>
              <Button
                variant="secondary"
                size="smallForm"
                onClick={() => setCreateDocumentModalOpen(true)}
              >
                Upload
              </Button>
            </Box>
          </Box>
          {leadDocumentsConfigs.map((config) => (
            <LeadDocumentsTable
              key={config.title}
              leadUuid={leadUuid}
              {...config}
            />
          ))}
          <LeadStaticDocumentToggleTable
            leadUuid={leadUuid}
            overrides={overrides}
          />
          <CreateLeadDocumentModal
            open={createDocumentModalOpen}
            onClose={() => setCreateDocumentModalOpen(false)}
            leadUuid={leadUuid}
          />
        </Box>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default LeadDocumentsSection
