import React from 'react'
import { useMutation } from '@apollo/client'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import {
  AdminCreateEventDocument,
  AdminGetEventsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'

type CreateEventParams = {
  title: string
  eventType: string
  notes: string
  date: string
}

type CreateEventModalParams = {
  isOpen: boolean
  close: () => void
  entityUuid?: string
  entityType: string
}

const CreateEventModal = (props: CreateEventModalParams) => {
  const { entityUuid, entityType, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createEvent, { loading }] = useMutation(AdminCreateEventDocument)

  const onSubmit = async (result: CreateEventParams) => {
    const genericEventInput = result
    // strip html tags and turn note into string
    try {
      const createEventInput = {
        entityUuid,
        entityType,
        ...genericEventInput,
      }
      await createEvent({
        variables: {
          createEventInput,
        },
        refetchQueries: [AdminGetEventsDocument],
      })
      notify('Successfully created event', 'success')
      close()
    } catch (e) {
      notify('Failed to create event', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Create Event"
      inputConfigs={[
        {
          inputName: 'title',
          inputType: InputType.Text,
          required: true,
          props: {
            label: 'Title',
            type: 'text',
            fullWidth: true,
          },
        },
        {
          inputName: 'eventType',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            label: 'Event Type',
            fullWidth: true,
            options: [
              {
                text: 'Phone Call',
                value: 'phone call',
              },
              {
                text: 'Email',
                value: 'email',
              },
              {
                text: 'SMS',
                value: 'sms',
              },
              {
                text: 'General Note',
                value: 'general note',
              },
              {
                text: 'Portal Activity',
                value: 'portal activity',
              },
              {
                text: 'Referral',
                value: 'referral',
              },
              {
                text: 'Other',
                value: 'other',
              },
            ],
          },
        },
        {
          inputName: 'date',
          inputType: InputType.DatePicker,
          props: {
            label: 'Date',
            defaultValue: new Date(),
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'notes',
          inputType: InputType.Editor,
          props: {
            label: 'Notes',
            editorStyle: {
              border: 'solid #000',
              borderWidth: '0 1px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingTop: '5px',
            },
          },
        },
      ]}
    />
  )
}

CreateEventModal.defaultProps = {
  entityUuid: null,
}

export default CreateEventModal
