import React, { useState } from 'react'
import {
  Typography,
  Paper,
  Button,
  useSnackbar,
} from '@flock/flock-component-library'
import {
  AdminGetLeadDocument,
  AdminUpdateLeadDocument,
  Core_Lead,
  Core_UpdateLeadRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { styled } from '@mui/material/styles'
import { Modal, Box } from '@mui/material'
import { useMutation } from '@apollo/client'

import { formatAddressString, formatCityStateZip } from '@flock/utils'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type AddToMqlAutomationModalProps = {
  lead: Core_Lead
}

const AddToMqlAutomationModal = (props: AddToMqlAutomationModalProps) => {
  const { lead } = props
  const { notify } = useSnackbar()
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const [updateLead] = useMutation(AdminUpdateLeadDocument)

  const onSubmit = async () => {
    const updateLeadInput: Core_UpdateLeadRequestInput = {
      leadUuid: lead.uuid,
      addToMqlAutomation: true,
    }
    try {
      await updateLead({
        variables: {
          updateLeadInput,
        },
        refetchQueries: [AdminGetLeadDocument],
      })
      notify(`Added lead ${lead.fullName} to MQL Automation`, 'success')
      setOpen(false)
    } catch (e) {
      notify('Failed to reassign lead', 'error')
    }
  }

  return (
    <>
      <Button
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => {
          openModal()
        }}
      >
        Add To Mql Automation
      </Button>
      <Modal open={open} onClose={closeModal}>
        <ModalWrapper>
          <Typography
            variant="h2"
            sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
          >
            Add To MQL Automation
          </Typography>
          <Box sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
            <Typography variant="body1">{lead.fullName}</Typography>
            <Typography variant="body1">
              {lead.address ? formatAddressString(lead.address) : ''}
            </Typography>
            <Typography variant="body1">
              {formatCityStateZip(lead!.address!)}
            </Typography>
            <Typography variant="body1">{lead.email}</Typography>
            <Typography variant="body1">
              <br />
              This will add the lead to the MQL Automation List
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" onClick={onSubmit}>
              Add To MQL Automation
            </Button>
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default AddToMqlAutomationModal
