import React, { useState, useEffect } from 'react'
import { useParams } from '@reach/router'
import { gql, useQuery } from '@apollo/client'
import {
  AdminValuationsV1GetLeadDocument,
  AdminValuationsV1GetOperatorDocument,
  AdminComputeValuationPageGetValuationHistoryDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'

export const GET_OPERATOR = gql`
  query AdminValuationsV1GetOperator($input: Core_GetOperatorRequestInput!) {
    operator(input: $input) {
      operator {
        uuid
      }
    }
  }
`

export const GET_LEAD = gql`
  query AdminValuationsV1GetLead($input: Core_GetLeadRequestInput!) {
    lead(input: $input) {
      lead {
        fullName
        transactionType
        address {
          formattedAddress
          uuid
          street
          unit
          city
          state
          county
          zipcode
          units {
            uuid
            formattedAddress
            unit
            documents {
              uuid
              name
              notes
              type
            }
          }
          documents {
            uuid
            name
            notes
            presignedUrl
            type
          }
        }
        answers
        reitFit
      }
    }
  }
`

export const GET_VALUATION_HISTORY = gql`
  fragment ValuationTrailFields on Core_ValuationTrailNode {
    name
    description
    value
  }
  query AdminComputeValuationPageGetValuationHistory(
    $input: Core_GetValuationHistoryRequestInput!
  ) {
    getValuationHistory(input: $input) {
      multiFamilyValuations {
        expiresAt
        finalOfferPrice
        leadUuid
        operatorUuid
        operator {
          fullName
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
          netYieldAdjustedOfferPrice
          submarketRentDeduction
          grossYieldOnAdjustedOfferPrice
          unconstrainedMsaYieldMin
          inPlaceNetYield
          unconstrainedMsaYieldMinOfferPrice
          uwCashOnCashYield {
            customerUwCashOnCashYield
            currentUwCashOnCashYield
          }
          remodelCosts {
            score
            notes
            immediateAddressableCapex
            totalAddressableCapex
            healthAndSafetyCapex
          }
          daysInRemodelDeduction
        }
        property {
          uuid
          addressUuid
          propertyType
          beds
          baths
          bathsDouble
          halfBaths
          sqft
          yearBuilt
          notes
        }
        propertyInput {
          propertyRemodelCost {
            healthAndSafetyCapex
            notes
            immediateAddressableCapex
            score
            totalAddressableCapex
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            notes
            hoaExists
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            additionalMonthlyCost
            additionalMonthlyCostNotes
          }
          selfReportedValue
          cashToClose
          fiveYearTreasuryRate
        }
        rejectionReason
        salesApproved
        type
        unitInputs {
          remodelCost {
            score
            notes
            immediateAddressableCapex
            totalAddressableCapex
            healthAndSafetyCapex
          }
          monthsRemainingOnLease
          currentlyOccupied
          currentRent
          avmProjectedRent
          analystProjectedRent
          beds
          baths
          bathsDouble
          squareFootage
          addressUuid
          rentNotes
          marketRentComps {
            source
            marketRent
            url
            address
            notes
            squareFootage
            rentalDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
          }
          offerPriceComps {
            source
            similarityScore
            url
            avmPrice
            squareFootage
            address
            beds
            baths
            bathsDouble
            halfBaths
            yearBuild
            notes
            estimatedRemodelCost
            soldDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
            conditionScore
            numUnits
          }
          additionalMonthlyCost
          additionalMonthlyCostNotes
        }
        updatedAt
        uuid
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                      nodes {
                        ...ValuationTrailFields
                        nodes {
                          ...ValuationTrailFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      singleFamilyValuations {
        expiresAt
        finalOfferPrice
        uuid
        leadUuid
        type
        inputs {
          offerPrice {
            housecanaryCondition
            comps {
              source
              similarityScore
              url
              avmPrice
              squareFootage
              address
              beds
              baths
              bathsDouble
              halfBaths
              yearBuild
              notes
              estimatedRemodelCost
              soldDate
              uuid
              addressUuid
              selected
              updatedAt
              distance
              conditionScore
              numUnits
            }
            housecanaryAdjustedAvmPrice
            notes
            selfReportedValue
            useCma
            cashToClose
            fiveYearTreasuryRate
          }
          remodelCost {
            score
            notes
            immediateAddressableCapex
            totalAddressableCapex
            healthAndSafetyCapex
          }
          marketRent {
            housecanaryProjectedRent
            comps {
              source
              marketRent
              url
              address
              notes
              squareFootage
              rentalDate
              uuid
              addressUuid
              selected
              updatedAt
              distance
            }
            analystProjectedRent
            notes
            currentRent
            monthsRemainingOnLease
            currentlyOccupied
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            notes
            hoaExists
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            additionalMonthlyCost
            additionalMonthlyCostNotes
          }
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
          netYieldAdjustedOfferPrice
          submarketRentDeduction
          grossYieldOnAdjustedOfferPrice
          unconstrainedMsaYieldMin
          inPlaceNetYield
          uwCashOnCashYield {
            customerUwCashOnCashYield
            currentUwCashOnCashYield
          }
          unconstrainedMsaYieldMinOfferPrice
          remodelCosts {
            score
            notes
            immediateAddressableCapex
            totalAddressableCapex
            healthAndSafetyCapex
          }
          daysInRemodelDeduction
        }
        rejectionReason
        operatorUuid
        operator {
          fullName
        }
        salesApproved
        property {
          uuid
          addressUuid
          propertyType
          beds
          baths
          bathsDouble
          halfBaths
          sqft
          yearBuilt
          notes
        }
        updatedAt
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const useComputeLeadValuationV1Page = () => {
  let { leadUuid } = useParams()
  leadUuid = leadUuid as string
  const { notify } = useSnackbar()
  const [tabValue, setTabValue] = useState('1')

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  const { data: operatorData } = useQuery(
    AdminValuationsV1GetOperatorDocument,
    {
      variables: {
        input: {},
      },
      onError: () => {
        notify('Failed to get operator data', 'error')
      },
    }
  )

  const { data: leadData } = useQuery(AdminValuationsV1GetLeadDocument, {
    variables: {
      input: {
        leadUuid,
      },
    },
    onError: () => {
      notify('Failed to get lead data', 'error')
    },
  })

  const { data: valuationHistory, loading: valuationHistoryLoading } = useQuery(
    AdminComputeValuationPageGetValuationHistoryDocument,
    {
      variables: {
        input: {
          leadUuid,
        },
      },
    }
  )

  // set default value of tab on page load to multifamily if the address of the lead has units
  useEffect(() => {
    if (
      leadData?.lead?.lead?.address?.units &&
      leadData.lead.lead.address.units.length !== 0
    ) {
      setTabValue('2')
    }
  }, [leadData])

  return {
    tabValue,
    handleTabChange,
    leadData,
    operatorData,
    valuationHistory,
    valuationHistoryLoading,
  }
}

export default useComputeLeadValuationV1Page
