import { Core_AccountDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useState } from 'react'
import {
  AccountDocumentsTableProps,
  AccountDocumentsTablePresentationalProps,
} from './accountDocumentsTableTypes'

const useAccountDocumentsTable: (
  props: AccountDocumentsTableProps
) => AccountDocumentsTablePresentationalProps = (
  props: AccountDocumentsTableProps
) => {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedDocument, setSelectedDocument] =
    useState<Core_AccountDocument>()

  return {
    ...props,
    editModalOpen,
    setEditModalOpen,
    deleteModalOpen,
    setDeleteModalOpen,
    selectedDocument,
    setSelectedDocument,
  }
}

export default useAccountDocumentsTable
