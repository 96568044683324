import { styled } from '@mui/material/styles'
import React, { ChangeEvent, useState } from 'react'
import {
  Typography,
  LoadingCard,
  TextField,
  Button,
  useSnackbar,
} from '@flock/flock-component-library'
import {
  AdminOrderOnboardingV3SearchOrdersV3Document,
  Core_OrderV3,
  Core_SearchOrdersV3Response,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { Search } from '@mui/icons-material'
import { Box, InputAdornment } from '@mui/material'
import { RouteComponentProps } from '@reach/router'

import { useQuery } from '@apollo/client'
import OrderV3Table from './OrderV3Table'

const PageTitle = styled(Typography)({
  marginTop: '4rem',
  marginBottom: '3rem',
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OrderOnboardingV3Page = (_: RouteComponentProps) => {
  const [searchName, setSearchName] = useState<string>('')
  const { notify } = useSnackbar()
  const { error, loading, data, networkStatus, refetch } = useQuery(
    AdminOrderOnboardingV3SearchOrdersV3Document,
    {
      variables: {
        input: {},
      },
      onError: () => {
        notify('Failed to get orders', 'error')
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  if (error) {
    return (
      <LoadingCard
        text="Failed to fetch orders. Please refresh to try again."
        hideLoader
      />
    )
  }

  if (loading) {
    return <LoadingCard text="Loading orders..." />
  }

  const { orders }: { orders: Core_OrderV3[] } =
    data?.searchOrdersV3 as Core_SearchOrdersV3Response

  const onRefetch = () => {
    refetch({
      input: {
        searchString: searchName,
      },
    })
  }

  const isLoading = loading || networkStatus === 2
  return (
    <>
      <PageTitle variant="h1">Order Management</PageTitle>
      <Box sx={{ display: 'flex', width: '100%', marginBottom: '1rem' }}>
        <TextField
          fullWidth
          sx={{ flexGrow: 1 }}
          value={searchName}
          onKeyDown={(event: any) => {
            if (event.key === 'Enter') {
              onRefetch()
            }
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearchName(e.target.value)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          disabled={isLoading}
          sx={{ marginLeft: '0.5rem', width: '7rem' }}
          onClick={onRefetch}
        >
          Search
        </Button>
      </Box>

      <OrderV3Table orders={orders} />
    </>
  )
}

export default OrderOnboardingV3Page
