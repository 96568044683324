import React, { useState } from 'react'
import {
  Typography,
  Paper,
  Button,
  Form,
  useSnackbar,
  LoadingCard,
  InputType,
} from '@flock/flock-component-library'
import {
  AdminGetLeadDocument,
  AdminGetOperatorsDocument,
  AdminUpdateLeadDocument,
  Core_Address,
  Core_Lead,
  Core_Operator,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { styled } from '@mui/material/styles'
import { Modal, Box } from '@mui/material'
import { useMutation, useQuery } from '@apollo/client'

import { formatAddressString, formatCityStateZip } from '@flock/utils'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type LeadReassignmentProps = {
  lead: Core_Lead
}

type LeadReassignmentParams = {
  operatorUuid: string
}

const LeadReassignmentModal = (props: LeadReassignmentProps) => {
  const { lead } = props
  const { notify } = useSnackbar()
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const [updateLead, { loading }] = useMutation(AdminUpdateLeadDocument)
  const { loading: operatorsLoading, data: operatorsData } = useQuery(
    AdminGetOperatorsDocument,
    {
      variables: {},
      onError: () => {
        notify('Failed to get operators', 'error')
      },
    }
  )

  let operatorsDropdownOptions: any[] = []
  if (!operatorsLoading) {
    operatorsDropdownOptions =
      operatorsData?.operators?.operators?.map((operator: Core_Operator) => ({
        value: operator.uuid,
        text: operator.fullName,
      })) || []
  }

  const onSubmit = async (result: LeadReassignmentParams) => {
    const updateLeadInput = {
      leadUuid: lead.uuid,
      operatorUuid: result.operatorUuid,
    }
    try {
      await updateLead({
        variables: {
          updateLeadInput,
        },
        refetchQueries: [AdminGetLeadDocument],
      })
      notify(
        `Reassigned lead ${lead.fullName} with operator $${result.operatorUuid}`,
        'success'
      )
      setOpen(false)
    } catch (e) {
      notify('Failed to reassign lead', 'error')
    }
  }

  return (
    <>
      <Button
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => {
          openModal()
        }}
      >
        Lead Reassignment
      </Button>
      <Modal open={open} onClose={closeModal}>
        <ModalWrapper>
          <Typography
            variant="h2"
            sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
          >
            Reassign Lead
          </Typography>
          <Box sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
            <Typography variant="body1">{lead.fullName}</Typography>
            <Typography variant="body1">
              {lead.address ? formatAddressString(lead.address) : ''}
            </Typography>
            <Typography variant="body1">
              {formatCityStateZip(lead?.address as Core_Address)}
            </Typography>
            <Typography variant="body1">
              <br />
              This will reassign the lead.
            </Typography>
          </Box>
          {operatorsLoading ? (
            <LoadingCard text="" />
          ) : (
            <Form
              onSubmit={onSubmit}
              ctaText={loading ? 'Submitting...' : 'Reassign Lead'}
              ctaProps={{
                disabled: loading,
              }}
              inputConfigs={[
                {
                  inputName: 'operatorUuid',
                  inputType: InputType.Dropdown,
                  required: true,
                  props: {
                    fullWidth: true,
                    label: 'Operator',
                    options: operatorsDropdownOptions,
                  },
                },
              ]}
            />
          )}
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default LeadReassignmentModal
