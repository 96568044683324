import { IconButton, TableCell, TableRow } from '@mui/material'
import { DownloadSimple } from 'phosphor-react'
import React from 'react'

const CustomerDocumentsRowRenderer =
  (downloadDocument: (uuid: string) => void) =>
  (data: [string, string, string, string, string]) => {
    const [uuid, name, notes] = data

    return (
      <TableRow key={uuid}>
        <TableCell>{name}</TableCell>
        <TableCell>{notes}</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
          <IconButton
            aria-label="Download"
            onClick={() => {
              downloadDocument(uuid)
            }}
            size="large"
          >
            <DownloadSimple size="1.25rem" />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

export default CustomerDocumentsRowRenderer
