import React from 'react'

import {
  CircleUncheckedIcon,
  CircleCheckedIcon,
  TrackedButton,
  FormattedTextField,
  DatePicker,
} from '@flock/shared-ui'
import {
  Modal,
  Box,
  Autocomplete,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material'
import { Flag } from '@mui/icons-material'
import PhotoGallery from '../PhotoGallery/PhotoGallery'
import LabelledField from '../LabelledField'
import StatusButton from '../StatusButton'
import useSowAssetModal from './useSowAssetModal'
import { monthOptions, SowAssetModalProps } from './sowAssetModalTypes'

const SowAssetModal = (props: SowAssetModalProps) => {
  const presentationalProps = useSowAssetModal(props)
  const {
    open,
    locationOptions,
    categoryOptions,
    onClose,

    category,
    setCategory,
    location,
    setLocation,
    assetText,
    setAssetText,
    month,
    setMonth,
    year,
    setYear,
    status,
    setStatus,
    endOfLifeDate,
    setEndOfLifeDate,
    remainingLife,
    recommendedAdjustment,

    photos,
    selectedPhoto,
    setSelectedPhoto,
    onAddPhotos,
    onDeletePhoto,
    loading,
    showDeleteModal,
    setShowDeleteModal,

    onSave,
    onDelete,
  } = presentationalProps
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          display="flex"
          flexDirection="column"
          gap="24px"
          position="absolute"
          p="16px"
          sx={{
            top: '2.5vh',
            left: '50%',
            transform: 'translate(-50%, 0)',
            width: '95vw',
            maxHeight: '95vh',
            backgroundColor: 'gray1.main',
            borderRadius: '8px',
            overflowY: 'auto',
          }}
        >
          {/* Deficiency Section */}
          <Box display="flex" gap="24px">
            {/* Deficiency Inputs */}
            <Box display="flex" flexDirection="column" width="100%" gap="16px">
              <Box display="flex" gap="16px" width="100%">
                <LabelledField label="Location">
                  <Autocomplete
                    onChange={(_, value) => setLocation(value)}
                    value={location}
                    options={locationOptions}
                    disableClearable
                    freeSolo
                    placeholder="Location"
                    renderInput={(params) => (
                      <TextField {...params} variant="filled" fullWidth />
                    )}
                  />
                </LabelledField>
                <LabelledField label="Category">
                  <Autocomplete
                    onChange={(_, value) => setCategory(value)}
                    value={category}
                    options={categoryOptions}
                    disableClearable
                    freeSolo
                    placeholder="Category"
                    renderInput={(params) => (
                      <TextField {...params} variant="filled" fullWidth />
                    )}
                  />
                </LabelledField>
              </Box>
              <Box display="flex" gap="8px">
                <LabelledField label="Asset">
                  <TextField
                    onChange={(e) => setAssetText(e.target.value)}
                    value={assetText}
                    variant="filled"
                    placeholder="Asset"
                  />
                </LabelledField>
                <Box minWidth="164px">
                  <LabelledField label="Month">
                    <Autocomplete
                      onChange={(_, value) => setMonth(value.value)}
                      value={monthOptions.find((m) => m.value === month)}
                      options={monthOptions}
                      disableClearable
                      placeholder="Month"
                      renderInput={(params) => (
                        <TextField {...params} variant="filled" fullWidth />
                      )}
                    />
                  </LabelledField>
                </Box>

                <Box minWidth="96px">
                  <LabelledField label="Year">
                    <FormattedTextField
                      format="year"
                      onChange={(e) => setYear(parseInt(e.target.value, 10))}
                      value={year}
                      variant="filled"
                      placeholder="Notes for Reviewer"
                    />
                  </LabelledField>
                </Box>
              </Box>

              <Box display="flex" gap="8px">
                <LabelledField label="End of Life Date">
                  <DatePicker
                    variant="filled"
                    value={endOfLifeDate}
                    onChange={(e) => setEndOfLifeDate(e.target.value)}
                  />
                </LabelledField>

                <LabelledField label="Remaining Life">
                  <TextField
                    value={`${remainingLife}%`}
                    variant="filled"
                    placeholder="Remaining Life"
                    sx={{
                      borderBottom: 'none',
                    }}
                    disabled
                  />
                </LabelledField>
                <LabelledField label="Recommended Adjustment">
                  <TextField
                    value={`${recommendedAdjustment}%`}
                    variant="filled"
                    placeholder="Recommended Adjustment"
                    sx={{
                      borderBottom: 'none',
                    }}
                    disabled
                  />
                </LabelledField>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Box display="flex" gap="8px">
                  <StatusButton
                    label="FLAG"
                    selected={status === 'flagged'}
                    icon={
                      <Flag
                        sx={{ width: '16px', height: '16px' }}
                        color="moneyGreen"
                      />
                    }
                    onClick={() => {
                      setStatus('flagged')
                    }}
                  />
                  <StatusButton
                    label="WIP"
                    selected={status === 'in_progress'}
                    icon={<CircleUncheckedIcon width="16px" height="16px" />}
                    onClick={() => {
                      setStatus('in_progress')
                    }}
                  />
                  <StatusButton
                    label="DONE"
                    selected={status === 'completed'}
                    icon={<CircleCheckedIcon width="16px" height="16px" />}
                    onClick={() => {
                      setStatus('completed')
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {/* Image Viewer */}
            <PhotoGallery
              photos={photos}
              selectedPhoto={selectedPhoto}
              setSelectedPhoto={setSelectedPhoto}
              onAddPhotos={onAddPhotos}
              onDeletePhoto={onDeletePhoto}
              onClose={onClose}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" gap="16px">
            <TrackedButton
              size="smallForm"
              // @ts-ignore
              variant="error"
              sx={{ width: '88px' }}
              disabled={loading}
              onClick={() => setShowDeleteModal(true)}
            >
              Delete
            </TrackedButton>
            <TrackedButton
              size="smallForm"
              variant="primary"
              disabled={loading}
              onClick={onSave}
              sx={{ width: '88px' }}
            >
              {loading ? (
                <CircularProgress size="16px" color="white" />
              ) : (
                'Save'
              )}
            </TrackedButton>
          </Box>
        </Box>
      </Modal>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <Box
          position="absolute"
          width="480px"
          p="16px"
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'gray1.main',
            borderRadius: '8px',
          }}
        >
          <Box display="flex" flexDirection="column" gap="32px">
            <Typography variant="cta2">
              Are you sure you want to delete this deficiency?
            </Typography>
            <Box display="flex" justifyContent="flex-end" gap="16px">
              <TrackedButton
                size="smallForm"
                variant="secondary"
                disabled={loading}
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </TrackedButton>
              <TrackedButton
                size="smallForm"
                // @ts-ignore
                variant="error"
                disabled={loading}
                onClick={onDelete}
              >
                {loading ? (
                  <CircularProgress size="16px" color="white" />
                ) : (
                  'Delete'
                )}
              </TrackedButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default SowAssetModal
