import { InputType } from '@flock/flock-component-library'
import React from 'react'
import { RejectionOption } from './taskTypes'
import ActionFormModal from '../shared/ActionFormModal'

export type RejectionResult = {
  rejectionReason: string
  rejectionDetail: string
}

type TaskRejectionModalProps = {
  open: boolean
  onClose: () => void
  rejectionOptions: RejectionOption[]
  handleRejection: (result: RejectionResult) => void
}

const TaskRejectionModal = (props: TaskRejectionModalProps) => {
  const { open, onClose, rejectionOptions, handleRejection } = props
  const mappedOptions = rejectionOptions.map((option) => ({
    text: option.label,
    value: option.value,
  }))

  const onSubmit = (result: RejectionResult) => {
    onClose()
    handleRejection(result)
  }

  return (
    <ActionFormModal
      open={open}
      setOpen={onClose}
      onSubmit={onSubmit}
      loading={false}
      actionText="Reject Task"
      inputConfigs={[
        {
          inputName: 'rejectionReason',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            label: 'Rejection Reason',
            options: mappedOptions,
            fullWidth: true,
          },
        },
        {
          inputName: 'rejectionDetail',
          inputType: InputType.Text,
          props: {
            label: 'Rejection Detail',
            type: 'text',
            multiline: true,
            rows: 4,
            rowsMax: 4,
            fullWidth: true,
            placeholder:
              'Enter details of why this was rejected or additional info required to complete this task.',
            defaultValue: '',
          },
        },
      ]}
    />
  )
}

export default TaskRejectionModal
