import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import {
  AdminGetAccountDocumentsDocument,
  AdminUpdateAccountDocumentDocument,
  Core_AccountDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  AnyInputConfig,
  InputType,
  FileUploadFileType,
  FileUploadMimeType,
} from '@flock/shared-ui'
import React, { useState } from 'react'
import { AccountDocumentType } from './accountDocumentsTableTypes'
import GridFormModal from '../../shared/GridFormModal'

export const UPDATE_ACCOUNT_DOCUMENT = gql`
  mutation AdminUpdateAccountDocument($input: UpdateAccountDocumentInput!) {
    updateAccountDocument(input: $input) {
      uuid
    }
  }
`

const inputConfigs: AnyInputConfig[] = [
  {
    name: 'name',
    type: InputType.Text,
    props: {
      label: 'Display Name',
    },
  },
  {
    name: 'documentType',
    type: InputType.Dropdown,
    props: {
      label: 'Document Type',
      options: [
        {
          label: 'Additional Valuation Details',
          value: AccountDocumentType.ADDITIONAL_OFFER_DETAILS,
        },
        {
          label: 'Other',
          value: AccountDocumentType.OTHER,
        },
      ],
    },
  },
  {
    name: 'uploadedFile',
    type: InputType.FileUpload,
    props: {
      label: 'Upload an updated version of this file. (Only PDFs are accepted)',
      accept: {
        [FileUploadMimeType.PDF]: [FileUploadFileType.PDF],
      },
    },
  },
]

type EditAccountDocumentParams = {
  name: string
  documentType: string
  uploadedFile: File[]
}

type EditAccountDocumentModalProps = {
  accountId: string
  selectedDocument: Core_AccountDocument
  open: boolean
  onClose: () => void
}

const EditAccountDocumentModal = (props: EditAccountDocumentModalProps) => {
  const { open, onClose, selectedDocument, accountId } = props
  const { notify } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const [EditAccountDocument] = useMutation(AdminUpdateAccountDocumentDocument)

  const onSubmit = async (result: EditAccountDocumentParams) => {
    setLoading(true)
    const { name, documentType, uploadedFile } = result
    const editAccountDocumentInput: any = {
      documentUuid: selectedDocument.uuid,
      accountId,
      name,
      documentType,
    }
    if (uploadedFile) {
      const file = uploadedFile[0]
      editAccountDocumentInput.file = file
    }

    try {
      await EditAccountDocument({
        variables: {
          input: editAccountDocumentInput,
        },
        refetchQueries: [AdminGetAccountDocumentsDocument],
      })
      notify('Successfully updated Account document', 'success')
      onClose()
    } catch (e) {
      notify('Failed to update Account document', 'error')
    }
    setLoading(false)
  }

  return (
    <GridFormModal
      title="Update Document"
      open={open}
      onClose={onClose}
      inputConfigs={inputConfigs}
      onSubmit={onSubmit}
      formProps={{
        defaultValues: {
          ...selectedDocument,
        },
      }}
      gridProps={{
        spacing: 3,
      }}
      loading={loading}
    />
  )
}

export default EditAccountDocumentModal
