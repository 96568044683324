import React from 'react'
import { Box, Button, ThemeProvider, Typography } from '@mui/material'
import { flockTheme, LibraryThemeProvider } from '@flock/shared-ui'

import useAccountDocumentsPage from './useAccountDocumentsPage'
import { AccountDocumentsPageProps } from './accountDocumentsPageTypes'
import AccountDocumentsTable from './AccountDocumentsTable/AccountDocumentsTable'
import AccountStaticDocumentsTable from './AccountStaticDocumentsTable/AccountStaticDocumentsTable'
import CreateAccountDocumentModal from './AccountDocumentsTable/CreateAccountDocumentModal'

const AccountDocumentsPage = (props: AccountDocumentsPageProps) => {
  const presentationalProps = useAccountDocumentsPage(props)
  const {
    accountId,
    accountDocumentsConfigs,
    setCreateDocumentModalOpen,
    createDocumentModalOpen,
    overrides,
  } = presentationalProps
  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <Box display="flex" flexDirection="column" gap="48px" p="64px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            gap="32px"
          >
            <Typography variant="h3">Account Specific Documents</Typography>
            <Box>
              <Button
                variant="secondary"
                size="smallForm"
                onClick={() => setCreateDocumentModalOpen(true)}
              >
                Upload
              </Button>
            </Box>
          </Box>
          {accountDocumentsConfigs.map((config) => (
            <AccountDocumentsTable
              key={config.title}
              accountId={accountId}
              {...config}
            />
          ))}
          <AccountStaticDocumentsTable
            accountId={accountId}
            overrides={overrides}
          />
          <CreateAccountDocumentModal
            open={createDocumentModalOpen}
            onClose={() => setCreateDocumentModalOpen(false)}
            accountId={accountId}
          />
        </Box>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default AccountDocumentsPage
