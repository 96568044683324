import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  LoadingCard,
  flockTheme,
  PopoverMenu,
  DataTable,
} from '@flock/flock-component-library'
import { styled } from '@mui/material/styles'

import { RouteComponentProps, useParams } from '@reach/router'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from '@mui/material'
import {
  AdminGetCustomerDocument,
  AdminGetCustomerDocumentPresignedUrlDocument,
  AdminGetLeadDocument,
  AdminGetOperatorDocument,
  AdminUpdateLeadDocument,
  Core_CustomerDocument,
  Core_CustomerPreinspectionSurveyAnswers,
  Core_Lead,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { isEqual } from 'lodash'
import { useSnackbar } from '@flock/shared-ui'
import { FLOCK_LANDING_URL } from '../../constants'
import DataMapSection from './DataMapSection'
import DataMapTable from './DataMapTable'
import EditCustomerOverridesModal from './EditCustomerOverridesModal'
import LeadEventsSection from '../LeadManagementComponents/LeadEventsSection'
import LeadDocumentsSection from '../LeadManagementComponents/LeadDocumentsSection'
import CustomerReassignmentModal from './CustomerReassignmentModal'
import CustomerRequestContributionAgreementModal from '../TaskManagement/CustomerRequestContributionAgreementModal'
import { prettyPrintJsonKeys } from '../../utils'
import CustomerDocumentsRowRenderer from './CustomerDocumentsRowRenderer'

const Heading = styled('div')({
  marginTop: '4rem',
  marginBottom: '1rem',
})

const customerOfferPageOverrides = [
  'waiveOnboardingFee',
  'feeOverride',
  'customerStatusOverride',
  'customerNameOverride',
  'addressDisplayOverride',
  'cityStateZipDisplayOverride',
  'yearsToProject',
  'capexCosts',
  'remodelCosts',
  'offerDisclaimer',
  'cashTakeout',
  'rentReduction',
  'daysInRemodelDeduction',
  'closingCosts',
  'brokerCommission',
  'overrideNetYield',
  'netYieldOverride',
  'overrideHoldingPeriod',
  'holdingPeriodOverride',
  'overrideMortgage',
  'mortgageOverride',
  'showCashFlowDeductions',
  'hideRemodelCosts',
]

const TEST_PORTFOLIO_ULSTER_UUID = '672ed425-3fb1-4f55-8822-19b29d5a0e7d'

const preInspectionSurveyAnswerToString = (answer: any): string => {
  if (Array.isArray(answer)) {
    return answer
      .map((a: any) => preInspectionSurveyAnswerToString(a))
      .join(', ')
  }
  if (typeof answer === 'boolean') {
    return answer ? 'YES' : 'NO'
  }
  if (typeof answer === 'string') {
    return answer
  }
  return answer
}

export const customerDocumentColumns = [
  { name: 'uuid', options: { display: false } },
  { name: 'name', label: 'Name' },
  { name: 'notes', label: 'Notes' },
  { name: 'presignedUrl', label: 'Download' },
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IndividualCustomerPage = (_: RouteComponentProps) => {
  const { customerUuid } = useParams()
  const { notify } = useSnackbar()

  const [isDirty, setIsDirty] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [overridesModalOpen, setOverridesModalOpen] = useState(false)
  const [updateLead] = useMutation(AdminUpdateLeadDocument)
  const [initialSelections, setInitialSelections] = useState<{
    [uuid: string]: boolean
  }>({})
  const [currentSelections, setCurrentSelections] = useState<{
    [uuid: string]: boolean
  }>({})

  const { loading: customerLoading, data: customerData } = useQuery(
    AdminGetCustomerDocument,
    {
      variables: {
        input: {
          customerUuid,
        },
      },
      onError: () => {
        notify('Failed to get customer data', 'error')
      },
    }
  )

  const { loading: operatorLoading, data: operatorData } = useQuery(
    AdminGetOperatorDocument,
    {
      variables: {
        input: { operatorUuid: customerData?.customer?.customer?.operatorUuid },
      },
      onError: () => {
        notify('Failed to get operator', 'error')
      },
      skip: customerLoading || !customerData?.customer?.customer?.operatorUuid,
    }
  )

  const { refetch: getCustomerDocumentPreinspectionPresignedUrl } = useQuery(
    AdminGetCustomerDocumentPresignedUrlDocument,
    {
      skip: true,
      fetchPolicy: 'no-cache',
      onError: () => {
        notify('Failed to get customer document presigned url', 'error')
      },
    }
  )

  let customer: any
  let overridesMap: { [x: string]: any } = {}
  let firstLeadOverridesMap: {
    documents: {
      [x: string]: boolean
    }
  } = { documents: {} }
  if (!customerLoading) {
    customer = customerData?.customer?.customer || {}
    if (customer?.overrides) {
      overridesMap = JSON.parse(customer.overrides) || {}
    }
    if (customer?.leads?.length > 0) {
      const firstLead = customer?.leads[0]
      if (firstLead?.overrides) {
        firstLeadOverridesMap = JSON.parse(firstLead?.overrides) || {}
      }
    }
  } else {
    return <LoadingCard text="Loading details..." />
  }

  // Pre-inspection survey answers
  let preInspectionSurveyAnswers: Partial<Core_CustomerPreinspectionSurveyAnswers> =
    {}
  if (customer?.preinspectionSurveyAnswers) {
    preInspectionSurveyAnswers = customer.preinspectionSurveyAnswers
  }

  const customerDocuments = customer?.documents || []

  const leadSelections: { [uuid: string]: boolean } = {}
  customer?.leads?.forEach((lead: Core_Lead) => {
    leadSelections[lead?.uuid || ''] = lead?.isSelected || false
  })

  if (Object.keys(currentSelections).length === 0 && customer?.leads?.length) {
    setInitialSelections(leadSelections)
    setCurrentSelections(leadSelections)
  }

  const onToggle = (leadUuid: string, newValue: boolean) => {
    const newSelections = {
      ...currentSelections,
      [leadUuid]: newValue,
    }

    if (!isEqual(initialSelections, newSelections)) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }

    setCurrentSelections(newSelections)
  }

  const onSave = async () => {
    setSaveLoading(true)
    try {
      customer?.leads?.forEach((lead: Core_Lead) => {
        if (lead.isSelected !== currentSelections[lead?.uuid || '']) {
          updateLead({
            variables: {
              updateLeadInput: {
                leadUuid: lead.uuid,
                isSelected: currentSelections[lead?.uuid || ''],
              },
            },
            refetchQueries: [AdminGetLeadDocument],
          })
        }
      })

      notify('Successfully updated lead selection settings.', 'success')
    } catch (e) {
      notify('Failed to update lead selection settings.', 'error')
    }
    setSaveLoading(false)
  }

  const onDownload = async (uuid: string) => {
    try {
      const { data } = await getCustomerDocumentPreinspectionPresignedUrl({
        input: {
          documentUuid: uuid,
        },
      })
      window.open(
        data?.customerDocumentPresignedUrl?.presignedUrl || '',
        '_blank'
      )
    } catch (e) {
      notify('Failed to download document', 'error')
    }
  }

  const customerDocumentOptions = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    sort: true,
    selectableRows: 'none',
    responsive: 'standard',
    elevation: 0,
    customRowRender: CustomerDocumentsRowRenderer(onDownload),
  }

  const links = [
    {
      text: 'Open Customer Offer Page',
      onClick: () => {
        window.open(
          `${FLOCK_LANDING_URL}/portfolio-estimate/${customerUuid}`,
          '_blank'
        )
      },
    },
    {
      text: 'Copy Customer Offer Page Link',
      onClick: () => {
        navigator.clipboard.writeText(
          `${FLOCK_LANDING_URL}/portfolio-estimate/${customerUuid}`
        )
        notify('Offer page copied to Clipboard')
      },
    },
    {
      text: 'Open Pre-inspection Survey',
      onClick: () => {
        window.open(
          `${FLOCK_LANDING_URL}/pre-inspection/${customerUuid}`,
          '_blank'
        )
      },
    },
    {
      text: 'Copy Pre-inspection Survey Link',
      onClick: () => {
        navigator.clipboard.writeText(
          `${FLOCK_LANDING_URL}/pre-inspection/${customerUuid}`
        )
        notify('Offer page copied to Clipboard')
      },
    },
  ]

  const actions = [
    <CustomerReassignmentModal customer={customer} />,
    <CustomerRequestContributionAgreementModal customer={customer} />,
  ]

  return (
    <>
      <Heading sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4">Customer</Typography>
        <Box display="flex" gap="16px">
          <PopoverMenu
            text="Links"
            buttonProps={{
              variant: 'contained',
            }}
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            actions={links}
          />
          <PopoverMenu
            text="Actions"
            buttonProps={{
              variant: 'contained',
              'data-cy': 'actionsButton',
            }}
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            actions={actions}
          />
        </Box>
      </Heading>
      {customerLoading ? (
        <LoadingCard text="" />
      ) : (
        <ThemeProvider theme={flockTheme}>
          <Typography variant="body1">{customer.fullName}</Typography>
          <Typography variant="body1">{customer.email}</Typography>
          <Typography variant="body1">{customer.phoneNumber}</Typography>
          <Typography variant="body1">
            <b>Assignee:</b>
            {operatorLoading
              ? ''
              : ` ${
                  operatorData?.operator?.operator?.fullName || 'Unassigned'
                }`}
          </Typography>
        </ThemeProvider>
      )}

      <DataMapSection
        title="Customer Overrides"
        actionText="Edit"
        onAction={() => setOverridesModalOpen(true)}
      >
        <DataMapTable
          fields={customerOfferPageOverrides}
          dataMap={overridesMap}
        />
      </DataMapSection>
      <EditCustomerOverridesModal
        open={overridesModalOpen}
        onClose={() => setOverridesModalOpen(false)}
        customerUuid={customerUuid}
        overrides={overridesMap}
      />

      {/* Lead Selection Toggles */}
      <Box display="flex" flexDirection="column" gap="48px" pt="64px">
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            size="smallForm"
            onClick={onSave}
            disabled={!isDirty || saveLoading}
          >
            {saveLoading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>

        <Box display="flex" flexDirection="column" gap="16px">
          <Typography variant="h4">Lead Selection Toggles</Typography>
          <Table>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Most Recent Valuation</TableCell>
              <TableCell>Visible</TableCell>
            </TableHead>
            <TableBody>
              {/* selects all non-testing (ie 1553 ulster) addresses for the per-asset view */}
              {customer.leads
                .filter(
                  (lead: Core_Lead) =>
                    lead?.address !== null &&
                    lead?.address?.uuid !== TEST_PORTFOLIO_ULSTER_UUID
                )
                .sort(
                  (a: Core_Lead, b: Core_Lead) =>
                    b?.valuationObject?.finalOfferPrice ||
                    0 - (a?.valuationObject?.finalOfferPrice || 0)
                )
                .map((lead: Core_Lead) => {
                  const { uuid, address } = lead
                  const currentValue = currentSelections[uuid!] ?? true

                  return (
                    <TableRow key={uuid}>
                      <TableCell width="512px">
                        {address?.formattedAddress}
                      </TableCell>
                      <TableCell width="512px">
                        {lead?.valuationObject?.finalOfferPrice
                          ?.toLocaleString()
                          .replace(/^/, '$') || 'N/A'}
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={currentValue}
                          onChange={() => onToggle(uuid!, !currentValue)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Box>
      </Box>

      <DataMapSection title="Pre-Inspection Survey Answers">
        {Object.keys(preInspectionSurveyAnswers).map((key) =>
          key === '__typename' ? null : (
            <Grid item xs={6}>
              <Typography variant="h4">{prettyPrintJsonKeys(key)}</Typography>
              <Typography variant="body1">
                {preInspectionSurveyAnswerToString(
                  preInspectionSurveyAnswers[
                    key as keyof Core_CustomerPreinspectionSurveyAnswers
                  ]
                )}
              </Typography>
            </Grid>
          )
        )}
      </DataMapSection>
      <Typography
        variant="h4"
        style={{ marginTop: '2rem', marginBottom: '1rem' }}
      >
        Customer Documents
      </Typography>
      <Grid item xs={12}>
        <DataTable
          title=""
          data={customerDocuments as Core_CustomerDocument[]}
          columns={customerDocumentColumns}
          options={customerDocumentOptions as any}
        />
      </Grid>

      <Grid item xs={12}>
        <LeadDocumentsSection
          lead={customer?.leads[0]}
          leadUuid={customer?.leads[0]?.uuid}
          overrides={firstLeadOverridesMap}
        />
      </Grid>
      {customer?.leads.length ? (
        <Grid item xs={12} sx={{ paddingTop: '64px', paddingBottom: '256px' }}>
          <LeadEventsSection leadUuid={customer?.leads[0]?.uuid} />
        </Grid>
      ) : null}
    </>
  )
}

export default IndividualCustomerPage
