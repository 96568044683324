import React from 'react'
import { TrackedIconButton } from '@flock/shared-ui'
import { BorderColor } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import useAssetRow from './useAssetRow'
import { AssetRowProps, monthToText } from './assetRowTypes'
import StatusPicker from '../StatusPicker'

const AssetRow = (props: AssetRowProps) => {
  const presentationalProps = useAssetRow(props)
  const {
    uuid,
    location,
    category,
    item,
    status,
    month,
    year,
    remainingLife,
    openEditAsset,
    updateAsset,
  } = presentationalProps

  return (
    <Box
      display="flex"
      flexDirection="column"
      p="4px"
      borderRadius="8px"
      sx={{
        backgroundColor: 'gray1.main',
      }}
    >
      <Box
        display="flex"
        gap="4px"
        p="8px"
        sx={{
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          openEditAsset(uuid)
        }}
      >
        <StatusPicker status={status} updateStatus={updateAsset} />
        <Box py="4px" minWidth="120px" display="flex" alignItems="center">
          <Typography
            variant="c1m"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              openEditAsset(uuid)
            }}
          >
            {location}
          </Typography>
        </Box>
        <Box py="4px" width="100%" display="flex" alignItems="center">
          <Typography
            variant="c1m"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              openEditAsset(uuid)
            }}
          >
            {category} | {item}{' '}
            {year > 0 ? `- ${monthToText(month)} | ${year}` : ''} |{' '}
            {remainingLife}
          </Typography>
        </Box>
        <Box display="flex" gap="8px" alignItems="center">
          <TrackedIconButton
            color="primary"
            sx={{
              backgroundColor: 'green0.main',
              width: '24px',
              height: '24px',
            }}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              openEditAsset(uuid)
            }}
          >
            <BorderColor
              color="primary"
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
          </TrackedIconButton>
        </Box>
      </Box>
    </Box>
  )

  return null
}

export default AssetRow
