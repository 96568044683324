import React, { useState } from 'react'
import {
  Typography,
  Paper,
  Button,
  useSnackbar,
} from '@flock/flock-component-library'
import {
  AdminDeleteBrokerDocument,
  Core_Broker,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useMutation } from '@apollo/client'

import { navigate } from '@reach/router'
import { BROKER_MANAGEMENT_URL } from '../../constants'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

const ButtonWrapper = styled('div')({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
})

const CancelButton = styled(Button)({
  marginRight: '0.5rem',
})

type DeleteBrokerModalParams = {
  existingBroker: Core_Broker
  title: string
}

// TODO refactor to a DELETE Modal component too if this table format for displaying is reused

const DeleteBrokerModal = (props: DeleteBrokerModalParams) => {
  const { existingBroker, title } = props
  const { notify } = useSnackbar()

  const [isOpen, setOpen] = useState<boolean>(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleClose = () => {
    closeModal()
  }

  const [deleteBroker, { loading }] = useMutation(AdminDeleteBrokerDocument)

  const onClick = async () => {
    const deleteBrokerInput = {
      brokerUuid: existingBroker!.uuid,
    }
    try {
      await deleteBroker({
        variables: {
          deleteBrokerInput,
        },
      })
      navigate(`${BROKER_MANAGEMENT_URL}`)
      notify('Successfully deleted broker', 'success')
    } catch (e) {
      notify('Failed to delete broker', 'error')
    }
  }

  return (
    <>
      <Button
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => openModal()}
      >
        {title}
      </Button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalWrapper>
          <Typography
            variant="h2"
            sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
          >
            Delete Broker
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {`${existingBroker?.firstName} ${existingBroker?.lastName}` ||
                    ''}
                </TableCell>
                <TableCell>{existingBroker?.phoneNumber || ''}</TableCell>
                <TableCell>{existingBroker?.email || ''}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <ButtonWrapper>
            <CancelButton color="error" onClick={handleClose}>
              Cancel
            </CancelButton>
            <Button variant="contained" onClick={onClick}>
              {loading ? 'Deleting...' : 'Delete Broker'}
            </Button>
          </ButtonWrapper>
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default DeleteBrokerModal
