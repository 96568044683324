import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import {
  AdminEditEventDocument,
  AdminGetEventsDocument,
  Core_Event as Event,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'

import ActionFormModal from '../shared/ActionFormModal'

type EditEventParams = {
  title: string
  eventType: string
  notes: string
  date: string
}

type EditEventModalParams = {
  existingEvent: Event | undefined
  isOpen: boolean
  close: () => void
}

const EditEventModal = (props: EditEventModalParams) => {
  const { existingEvent, isOpen, close } = props
  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [editEvent, { loading }] = useMutation(AdminEditEventDocument)

  const onSubmit = async (editEventParams: EditEventParams) => {
    const { title, eventType, notes, date } = editEventParams
    const editEventInput = {
      uuid: existingEvent?.uuid,
      title,
      eventType,
      notes,
      date: date || '',
    }
    try {
      await editEvent({
        variables: {
          editEventInput,
        },
        refetchQueries: [AdminGetEventsDocument],
      })
      notify('Successfully edited event', 'success')
      close()
    } catch (e) {
      notify('Failed to edit event', 'error')
    }
  }
  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Edit Event"
      inputConfigs={[
        {
          inputName: 'title',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Title',
            defaultValue: existingEvent?.title || '',
            fullWidth: true,
          },
        },
        {
          inputName: 'eventType',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            defaultValue:
              // Do I need '|| "Phone Call"' here?
              existingEvent?.eventType,
            label: 'Event Type',
            fullWidth: true,
            options: [
              {
                text: 'Phone Call',
                value: 'phone call',
              },
              {
                text: 'Email',
                value: 'email',
              },
              {
                text: 'SMS',
                value: 'sms',
              },
              {
                text: 'General Note',
                value: 'general note',
              },
              {
                text: 'Portal Activity',
                value: 'portal activity',
              },
              {
                text: 'Referral',
                value: 'referral',
              },
              {
                text: 'Other',
                value: 'other',
              },
            ],
          },
        },
        {
          inputName: 'date',
          inputType: InputType.DatePicker,
          // @ts-ignore TODO existing issue fix later
          props: {
            label: 'Date',
            defaultValue: existingEvent?.date || new Date(),
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'notes',
          inputType: InputType.Editor,
          props: {
            label: 'Notes',
            defaultValue: existingEvent?.notes || '',
            editorStyle: {
              border: 'solid #000',
              borderWidth: '0 1px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingTop: '5px',
            },
          },
        },
      ]}
    />
  )
}

export default EditEventModal
