import { gql, useQuery } from '@apollo/client'
import {
  AdminGetAccountDocumentOverridesDocument,
  AdminGetAccountDocumentsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useEffect, useState } from 'react'
import {
  AccountDocumentsPageProps,
  AccountDocumentsPagePresentationalProps,
  AccountDocumentConfig,
} from './accountDocumentsPageTypes'
import { AccountDocumentType } from './AccountDocumentsTable/accountDocumentsTableTypes'

export const GET_ACCOUNT_DOCUMENTS = gql`
  query AdminGetAccountDocuments(
    $input: Core_GetAccountDocumentsRequestInput!
  ) {
    getAccountDocuments(input: $input) {
      documents {
        uuid
        documentType
        contentUrl
        name
        accountId
        createdAt
      }
    }
  }
`

export const GET_ACCOUNT_DOCUMENT_OVERRIDES = gql`
  query AdminGetAccountDocumentOverrides(
    $input: Core_GetAccountDocumentOverridesRequestInput!
  ) {
    getAccountDocumentOverrides(input: $input) {
      accountId
      overrides
    }
  }
`

const useAccountDocumentsPage: (
  props: AccountDocumentsPageProps
) => AccountDocumentsPagePresentationalProps = () => {
  const [accountId, setAccountId] = useState('')
  const [createDocumentModalOpen, setCreateDocumentModalOpen] = useState(false)
  const [accountDocumentsConfigs, setAccountDocumentsConfigs] = useState<
    AccountDocumentConfig[]
  >([])
  const [overrides, setOverrides] = useState<{ [key: string]: boolean }>({})

  const { data: accountDocumentsData } = useQuery(
    AdminGetAccountDocumentsDocument,
    {
      variables: {
        input: {
          accountId,
        },
      },
      skip: !accountId,
    }
  )

  const { data: accountDocumentOverridesData } = useQuery(
    AdminGetAccountDocumentOverridesDocument,
    {
      variables: {
        input: {
          accountId,
        },
      },
      skip: !accountId,
    }
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const accountIdParam = urlParams.get('accountId')
    setAccountId(accountIdParam as string)
  }, [])

  useEffect(() => {
    if (accountDocumentsData) {
      const accountDocumentsMap: any = {
        [AccountDocumentType.ADDITIONAL_OFFER_DETAILS]: {
          title: 'Additional Valuation Details',
          documents: [],
        },
        [AccountDocumentType.OTHER]: {
          title: 'Other',
          documents: [],
        },
      }

      if (accountDocumentsData.getAccountDocuments?.documents?.length) {
        accountDocumentsData.getAccountDocuments.documents.forEach(
          (accountDocument: any) => {
            Object.values(AccountDocumentType).forEach((documentType) => {
              if (accountDocument.documentType === documentType) {
                accountDocumentsMap[
                  documentType as AccountDocumentType
                ].documents.push(accountDocument)
              }
            })
          }
        )
      }

      const accountDocumentsArray = [
        accountDocumentsMap[AccountDocumentType.ADDITIONAL_OFFER_DETAILS],
        accountDocumentsMap[AccountDocumentType.OTHER],
      ]

      setAccountDocumentsConfigs(accountDocumentsArray)
    }
  }, [accountDocumentsData])

  useEffect(() => {
    const existingOverrides =
      accountDocumentOverridesData?.getAccountDocumentOverrides?.overrides ||
      '{}'
    setOverrides(JSON.parse(existingOverrides))
  }, [accountDocumentOverridesData])

  return {
    accountId,
    accountDocumentsConfigs,
    createDocumentModalOpen,
    setCreateDocumentModalOpen,
    overrides,
  }
}

export default useAccountDocumentsPage
