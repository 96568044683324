import { useEffect, useState } from 'react'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import { DropResult } from 'react-beautiful-dnd'
import {
  PrintCollateralPageGetSalesforceAccountDocument,
  PrintCollateralPageGetCaTemplatesDocument,
  Core_ValuationType,
  Core_SalesforceScenario,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { gql, useQuery } from '@apollo/client'
import { DEFAULT_CLOSING_AND_LEGAL_FEE } from '@flock/utils'
import { PDFDocument } from 'pdf-lib'
import {
  PrintCollateralPageProps,
  CheckboxOption,
  FormValues,
  CheckboxLabel,
  CollateralData,
} from './printCollateralPageTypes'

export const GET_ACCOUNT_INFO = gql`
  query PrintCollateralPageGetSalesforceAccount(
    $input: Core_GetSalesforceAccountRequestInput!
  ) {
    getSalesforceAccount(input: $input) {
      salesforceAccount {
        activeOpportunity {
          salesforceScenarios {
            addresses {
              formattedAddress
              addressStreet
              addressStateCode
              beds
              baths
              propertyCondition
              propertyType
              sqft
              valuation {
                offerComps {
                  formattedAddress
                  beds
                  baths
                  isSelected
                  propertyCondition
                  propertyType
                  soldDate
                  soldPrice
                  sqft
                  yearBuilt
                  numUnits
                }
                offerPrice
                uwRent
                valuationType
                totalRemodelCost
                isLiveOnOfferPage
              }
              units {
                unit
              }
            }
            cashPaydown
            cashTakeout
            brokerFee
            closingCosts
            offerDate
            offerPrice
            offerPriceHigh
            offerPriceLow
            onboardingFee
            portfolioFcfPercent
            remodelCosts
            showScenario
            valuationType
            submarketRentDeduction
            mortgage
            name
            minimumHoldPeriod
            daysInRenoDeduction
            costOfDebt
          }
        }
        fullName
      }
    }
  }
`

export const GET_CA_TEMPLATES = gql`
  query PrintCollateralPageGetCATemplates(
    $input: Core_GetContributionAgreementTemplatesRequestInput!
  ) {
    getContributionAgreementTemplates(input: $input) {
      files {
        state
        url
      }
    }
  }
`

const usePrintCollateralPage = (props: PrintCollateralPageProps) => {
  const { location } = props
  const [collateralData, setCollateralData] = useState<CollateralData>()
  const [collateralRendererOpen, setCollateralRendererOpen] = useState(false)
  const [CATemplatesLoading, setCATemplatesLoading] = useState(false)
  const [contributionAgreementsMap, setContributionAgreementsMap] = useState<
    Map<string, File>
  >(new Map())

  const searchParams = new URLSearchParams(location?.search)
  const accountId = searchParams.get('accountId')

  const { data: salesforceAccount, loading } = useQuery(
    PrintCollateralPageGetSalesforceAccountDocument,
    {
      variables: {
        input: {
          accountId,
        },
      },
      errorPolicy: 'all', // still return data if there are errors for now TODO: fix errors
    }
  )

  const { refetch: getCATemplates } = useQuery(
    PrintCollateralPageGetCaTemplatesDocument,
    {
      skip: true,
    }
  )

  const scenarios =
    salesforceAccount?.getSalesforceAccount?.salesforceAccount?.activeOpportunity?.salesforceScenarios?.map(
      (scenario) => ({
        label:
          `${scenario.name} - ${
            scenario.showScenario ? 'active' : 'unactive'
          }` || '',
        value: scenario.name || '',
      })
    ) || []

  const accountName =
    salesforceAccount?.getSalesforceAccount?.salesforceAccount?.fullName

  const {
    control: formControl,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>()

  const checkboxOptions: CheckboxOption[] = [
    { id: '1', label: CheckboxLabel.COVER, checked: true },
    { id: '2', label: CheckboxLabel.PORTOFOLIO_OVERVIEW, checked: true },
    { id: '3', label: CheckboxLabel.TOP_UP_CASH_FLOW, checked: true },
    { id: '4', label: CheckboxLabel.ALLOTMENT_CASH_FLOW, checked: false },
    { id: '5', label: CheckboxLabel.REINVESTED_CASH_FLOW, checked: false },
    { id: '6', label: CheckboxLabel.VALUATION_COMPS, checked: false },
    { id: '7', label: CheckboxLabel.FAQS, checked: true },
  ]
  const { fields: rawFields, move } = useFieldArray<FormValues>({
    control: formControl,
    name: 'checkboxes',
  })
  const checkboxFields = rawFields as CheckboxOption[] // useFieldArray somehow not correctly inferring type.

  useEffect(() => {
    setValue('checkboxes', checkboxOptions)
  }, [setValue])

  // Handle drag end event to reorder checkboxes
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return // If dropped outside the list
    move(result.source.index, result.destination.index)
  }

  const onSubmit = handleSubmit((data) => {
    const scenario: Core_SalesforceScenario =
      salesforceAccount?.getSalesforceAccount?.salesforceAccount?.activeOpportunity?.salesforceScenarios?.find(
        (scn) => scn.name === data.scenario
      )!

    const cleanedScenario = {
      ...scenario,
      closingCosts:
        scenario.closingCosts! / 100 || DEFAULT_CLOSING_AND_LEGAL_FEE,
    }

    setCollateralData({
      checkboxes: data.checkboxes,
      coverName: data.coverName,
      scenario: cleanedScenario,
      caTemplatesMap: Object.fromEntries(contributionAgreementsMap),
    })

    setCollateralRendererOpen(true)
  })

  const scenarioWatch = useWatch({
    control: formControl,
    name: 'scenario',
  })

  useEffect(() => {
    if (scenarioWatch) {
      // set cover name
      const currentScenario =
        salesforceAccount?.getSalesforceAccount?.salesforceAccount?.activeOpportunity?.salesforceScenarios?.find(
          (scenario) => scenario.name === scenarioWatch
        )
      const scenarioValuationType = currentScenario?.valuationType
      const coverName = `${accountName} Portfolio ${
        scenarioValuationType === Core_ValuationType.ValuationTypeInitial
          ? 'Initial'
          : 'Final'
      } Valuation`
      setValue('coverName', coverName)

      // next fetch the CA templates of the states corresponding to the selected scenario.
      const states = currentScenario?.addresses?.map(
        (address) => address?.addressStateCode
      )
      // remove undefineds
      const filteredStates = states?.filter((state) => state) as string[]
      // remove duplicates
      const uniqueStates = Array.from(new Set(filteredStates))

      const fetchCATemplates = async (statesToFetch: string[]) => {
        setCATemplatesLoading(true)

        // filter for states that currently aren't in the map
        const missingStates = statesToFetch.filter(
          (state) => !contributionAgreementsMap.has(state)
        )

        if (missingStates.length) {
          const { data: templateData } = await getCATemplates({
            input: {
              states: missingStates,
            },
          })

          const files =
            templateData?.getContributionAgreementTemplates?.files || []
          // copy existing map
          // this is because if we arleady have some files in the map, want to keep and not refetch.
          const newMap = new Map(contributionAgreementsMap)
          const filePromise = files.map(async (file) => {
            const res = await fetch(file.url)
            const blob = await res.blob()
            const fileObj = new File([blob], file.state)
            const pdfDoc = await PDFDocument.load(await fileObj.arrayBuffer())
            pdfDoc.removePage(0) // remove cover page
            const modifiedBlob = await pdfDoc.save()
            newMap.set(file.state, new File([modifiedBlob], file.state))
          })
          await Promise.all(filePromise)
          setContributionAgreementsMap(newMap)
          setCATemplatesLoading(false)
        }
      }
      fetchCATemplates(uniqueStates)

      // remove old checkbox options
      setValue('checkboxes', checkboxOptions)

      // add the states as checkbox options
      const newCheckboxOptions = uniqueStates.map((state) => ({
        id: state,
        label: `Contribution Agreement - ${state}` as CheckboxLabel, // questionable
        checked: false,
      }))
      setValue('checkboxes', [...checkboxOptions, ...newCheckboxOptions])
    }
  }, [scenarioWatch])

  const onCloseCollateralRenderer = () => {
    setCollateralRendererOpen(false)
  }

  return {
    accountId,
    accountName,
    scenarios,
    loading,
    formControl,
    onSubmit,
    errors,
    handleDragEnd,
    checkboxFields,
    collateralData,
    CATemplatesLoading,

    onCloseCollateralRenderer,
    collateralRendererOpen,
  }
}
export default usePrintCollateralPage
