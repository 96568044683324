import { useMutation } from '@apollo/client'
import { ConfirmModal } from '@flock/flock-component-library'
import {
  AdminDeleteDelinquencyDocument,
  Core_DeletePropertyDelinquencyRequestInput,
  AdminGetPropertyOccupancyDataDocument,
  Core_PropertyDelinquency,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import React from 'react'

type DeleteDelinquencyModalParams = {
  delinquency: Core_PropertyDelinquency
  isOpen: boolean
  close: () => void
}

const DeleteDelinquencyModal = (props: DeleteDelinquencyModalParams) => {
  const { delinquency, isOpen, close } = props
  const { notify } = useSnackbar()

  const [deleteDelinquency, { loading }] = useMutation(
    AdminDeleteDelinquencyDocument
  )

  const deleteDelinquencyInput: Core_DeletePropertyDelinquencyRequestInput = {
    delinquencyUuid: delinquency.uuid,
  }

  const onSubmit = async () => {
    try {
      await deleteDelinquency({
        variables: {
          input: deleteDelinquencyInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify(
        `Successfully deleted delinquency of type ${delinquency.delinquencyType}.`,
        'success'
      )
      close()
    } catch (e) {
      notify('Failed to delete delinquency.', 'error')
    }
  }

  return (
    <ConfirmModal
      title="Delete Delinquency"
      content={
        loading
          ? 'deleting delinquency...'
          : `Are you sure you want to delete delinquency of type: ${delinquency.delinquencyType}?`
      }
      open={isOpen}
      onClose={close}
      onSubmit={onSubmit}
    />
  )
}

export default DeleteDelinquencyModal
