import React, { createContext, useContext, useEffect, useState } from 'react'

type SowSaveContextProps = {
  saveLoading: boolean
  sowError: string
  sowLastSaved: string
  setSaveLoading: (loading: boolean) => void
  onSaveError: (error: string) => void
  onSaveSuccess: () => void
  refetchProject: () => Promise<any>
}

export const SowSaveContext = createContext({} as SowSaveContextProps)

export const useSowSaveContext = () => useContext(SowSaveContext)

type SowSaveContextProviderProps = {
  loadingData: boolean
  refetchProject: () => Promise<any>
  children: React.ReactNode
}

const SowSaveContextProvider = (props: SowSaveContextProviderProps) => {
  const { loadingData, refetchProject, children } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const [sowError, setSowError] = useState('')
  const [sowLastSaved, setSowLastSaved] = useState('')

  const onSaveSuccess = () => {
    setSaveLoading(false)
    setSowError('')
    setSowLastSaved(new Date().toLocaleString())
  }

  const onSaveError = (error: string) => {
    setSaveLoading(false)
    setSowError(error)
  }

  useEffect(() => {
    setSaveLoading(loadingData)
  }, [loadingData])

  return (
    <SowSaveContext.Provider
      value={{
        saveLoading,
        sowError,
        sowLastSaved,
        setSaveLoading,
        onSaveError,
        onSaveSuccess,
        refetchProject,
      }}
    >
      {children}
    </SowSaveContext.Provider>
  )
}

export default SowSaveContextProvider
