import { IconButton, IconButtonProps } from '@mui/material'
import React from 'react'
import slugify from 'slugify'
import { UserEventType } from '@flock/utils'
import { useTracking, TrackingConfig } from '../useTracking'
import { getNodeText } from '../../utils'

type TrackedIconButtonProps = IconButtonProps & {
  trackingConfig?: TrackingConfig
  to?: string
}

const TrackedIconButton = (props: TrackedIconButtonProps) => {
  const { onClick, trackingConfig, ...otherProps } = props
  const { track } = useTracking()

  const onClickTracked = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick) {
      let trackingName = 'icon-button-click'
      if (trackingConfig?.name || props['aria-label']) {
        trackingName =
          trackingConfig?.name || props['aria-label'] || trackingName
      } else if (props.children) {
        const nodeText = getNodeText(props.children)
        if (nodeText) {
          trackingName = slugify(nodeText.toLowerCase())
        }
      }
      track(trackingName, {
        type: 'button',
        ...trackingConfig?.properties,
        actionType: UserEventType.ICON_BUTTON_CLICK,
      })
      onClick(e)
    }
  }

  return <IconButton {...otherProps} onClick={onClickTracked} />
}

TrackedIconButton.defaultProps = {
  trackingConfig: null,
  to: undefined,
}

export default TrackedIconButton
