import React from 'react'
import {
  useSnackbar,
  Typography,
  Paper,
  Button,
} from '@flock/flock-component-library'
import {
  AdminDeleteInvestorAccountDocument,
  AdminGetAllInvestorAccountsDocument,
  Core_InvestorAccount,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'
import {
  Modal,
  Table,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

const ButtonWrapper = styled('div')({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
})

const CancelButton = styled(Button)({
  marginRight: '0.5rem',
})

type DeleteInvestorAccountModalParams = {
  investorAccount: Core_InvestorAccount | undefined
  isOpen: boolean
  close: () => void
}

const DeleteInvestorAccountModal = (
  props: DeleteInvestorAccountModalParams
) => {
  const { investorAccount, isOpen, close } = props
  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [deleteInvestorAccount, { loading }] = useMutation(
    AdminDeleteInvestorAccountDocument
  )

  const onSubmit = async () => {
    try {
      await deleteInvestorAccount({
        variables: {
          input: { investorAccountUuid: investorAccount?.uuid as string },
        },
        refetchQueries: [AdminGetAllInvestorAccountsDocument],
      })
      notify('Successfully deleted investor account', 'success')
      close()
    } catch (e) {
      notify('Failed to delete investor account', 'error')
    }
  }
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalWrapper>
        <Typography
          variant="h2"
          sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
        >
          Delete Investor Account
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{investorAccount?.firstName}</TableCell>
              <TableCell>{investorAccount?.email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ButtonWrapper>
          <CancelButton color="error" onClick={handleClose}>
            Cancel
          </CancelButton>
          <Button variant="contained" onClick={onSubmit}>
            {loading ? 'Deleting...' : 'Delete Investor Account'}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  )
}

export default DeleteInvestorAccountModal
