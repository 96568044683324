import React, { useState } from 'react'
import {
  Typography,
  Paper,
  Button,
  Form,
  useSnackbar,
  InputType,
} from '@flock/flock-component-library'
import {
  AdminCreateValuationDocument,
  AdminGetValuationDocument,
  Core_Lead as Lead,
  Core_ValidatedAddress,
  Core_Valuation as Valuation,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { styled } from '@mui/material/styles'

import { Box, Modal } from '@mui/material'
import { useMutation } from '@apollo/client'
import { formatAddressString, formatCityStateZip } from '@flock/utils'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type SubmitValuationResult = {
  valuationNumber: string
}

type ValuationModalProps = {
  lead: Lead
  valuation: Valuation
}

const ValuationModal = (props: ValuationModalProps) => {
  const { lead, valuation } = props
  const [open, setOpen] = useState<boolean>(false)
  const { notify } = useSnackbar()
  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const [createValuation, { loading }] = useMutation(
    AdminCreateValuationDocument
  )

  const onSubmit = async (result: SubmitValuationResult) => {
    const { valuationNumber } = result
    const createValuationInput = {
      leadUuid: lead.uuid,
      finalOfferPrice: parseInt(valuationNumber, 10),
    }

    try {
      await createValuation({
        variables: {
          createValuationInput,
        },
        refetchQueries: [AdminGetValuationDocument],
      })
      notify(
        `Updated lead ${lead.uuid} with a valuation of $${valuationNumber}`,
        'success'
      )
      setOpen(false)
    } catch (e) {
      notify('Failed to update valuation.', 'error')
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          openModal()
        }}
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        data-cy="updateValuationButton"
      >
        Update Final Valuation
      </Button>
      <Modal open={open} onClose={closeModal}>
        <ModalWrapper>
          <Typography
            variant="h2"
            sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
          >
            Update Final Valuation
          </Typography>
          <Box sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
            <Typography variant="body1">{lead.fullName}</Typography>
            <Typography variant="body1">
              {lead.address ? formatAddressString(lead.address) : ''}
            </Typography>
            <Typography variant="body1">
              {formatCityStateZip(lead.address as Core_ValidatedAddress)}
            </Typography>
          </Box>
          <Form
            onSubmit={onSubmit}
            ctaText={loading ? 'Submitting...' : 'Update Valuation'}
            ctaProps={{
              disabled: loading,
              'data-cy': 'submitValuation',
            }}
            inputConfigs={[
              {
                inputName: 'valuationNumber',
                inputType: InputType.Text,
                required: true,
                props: {
                  fullWidth: true,
                  type: 'number',
                  label: 'Valuation',
                  defaultValue: valuation?.finalOfferPrice,
                  'data-cy': 'valuationAmountInput',
                },
              },
            ]}
          />
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default ValuationModal
