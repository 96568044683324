import React, { useState, useEffect } from 'react'
import {
  Core_ValuationV1,
  Core_MultiFamilyValuation,
  Core_ValuationType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { formatDollarsAndCents } from '@flock/utils'
import { formatDateString } from '../../../../InvestorManagement/utils'
import { snakeToTitleCase } from '../../../../../utils'
import { ValuationHistoryModalProps } from './valuationHistoryModalTypes'

const useValuationHistoryModal = (props: ValuationHistoryModalProps) => {
  const { valuationHistory, valuationHistoryLoading } = props

  const [valuationTrailOpen, setValuationTrailOpen] = useState(false)
  const [selectedValuation, setSelectedValuation] = useState<
    Core_ValuationV1 | Core_MultiFamilyValuation
  >()

  // we don't grab some of the fields in our query which causes an error if we explicitly type it.
  const [valuations, setValuations] = useState<any[]>([])

  useEffect(() => {
    const combinedValuations = [
      ...(valuationHistory?.getAddressValuationHistory
        ?.singleFamilyValuations || []),
      ...(valuationHistory?.getAddressValuationHistory?.multiFamilyValuations ||
        []),
    ]
    setValuations(combinedValuations)
  }, [valuationHistory])

  const displayHeaders = [
    'Offer Price',
    'Type',
    'Valuation Details',
    'Expires At',
    'Rejection Reason',
    'Operator',
    'Breakdown',
    'Valuation UUID',
  ]

  const displayCells = valuations.map(
    (valuation: Core_MultiFamilyValuation | Core_ValuationV1) => {
      let jsonPretty = ''
      if ((valuation as Core_ValuationV1).inputs) {
        const valuationV1 = valuation as Core_ValuationV1
        jsonPretty = JSON.stringify(
          {
            ...valuationV1?.inputs,
            ...valuationV1?.outputs,
            property: valuationV1?.property,
          },
          null,
          2
        )
      } else {
        const multiFamilyValuation = valuation as Core_MultiFamilyValuation
        jsonPretty = JSON.stringify(
          {
            unitInputs: multiFamilyValuation?.unitInputs,
            propetyInput: multiFamilyValuation?.propertyInput,
            outputs: multiFamilyValuation?.outputs,
            property: valuation?.property,
          },
          null,
          2
        )
      }

      return [
        formatDollarsAndCents(valuation.finalOfferPrice || 0),
        valuation.type === Core_ValuationType.ValuationTypeFinal
          ? 'Final'
          : 'Initial',
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <>Expand</>
            </AccordionSummary>
            <AccordionDetails>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{jsonPretty}</pre>
            </AccordionDetails>
          </Accordion>
        </>,
        formatDateString(valuation.expiresAt),
        snakeToTitleCase(valuation?.rejectionReason || ''),
        valuation.operator?.fullName,
        valuation?.valuationTrail ? (
          <Button
            onClick={() => {
              setValuationTrailOpen(true)
              setSelectedValuation(valuation)
            }}
            size="mini"
            variant="outlined"
          >
            View
          </Button>
        ) : null,
        valuation?.uuid,
      ]
    }
  )

  return {
    valuationHistoryLoading,
    valuationTrailOpen,
    setValuationTrailOpen,
    selectedValuation,
    displayHeaders,
    displayCells,
  }
}

export default useValuationHistoryModal
