import { ButtonProps, Popover, PopoverProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import Button from '../Button/Button'
import TrackingContextProvider from '../TrackingContextProvider/TrackingContextProvider'
import useTracking, { TrackingConfig } from '../useTracking/useTracking'

const PopoverWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

type PopoverMenuOption = {
  text?: string
  onClick?: () => void
}

type PopoverMenuProps = {
  text: string
  actions: (PopoverMenuOption | React.ReactNode)[]
  buttonProps?: ButtonProps & { 'data-cy'?: string }
  popoverProps?: Partial<PopoverProps>
  trackingConfig?: TrackingConfig
}

const PopoverMenu = (props: PopoverMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { text, actions, buttonProps, popoverProps, trackingConfig } = props
  const trackingName = trackingConfig?.name || 'popover'

  const { track } = useTracking()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    track(`${trackingName}-opened`, trackingConfig?.properties)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <TrackingContextProvider
      name={trackingName}
      initialTrackingProperties={trackingConfig?.properties}
    >
      <Button {...buttonProps} onClick={handleClick}>
        {text}
      </Button>
      <Popover
        {...popoverProps}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        disableScrollLock
      >
        <PopoverWrapper>
          {actions.map((action) => {
            const { onClick, text: actionText } = action as PopoverMenuOption
            if (onClick && actionText) {
              return (
                <Button
                  onClick={() => {
                    onClick()
                    handleClose()
                  }}
                  sx={{
                    minWidth: '10rem',
                    width: '100%',
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                  }}
                >
                  {actionText}
                </Button>
              )
            }
            return action as React.ReactNode
          })}
        </PopoverWrapper>
      </Popover>
    </TrackingContextProvider>
  )
}

PopoverMenu.defaultProps = {
  buttonProps: {},
  popoverProps: {},
  trackingConfig: {
    name: 'popover',
  },
}

export default PopoverMenu
