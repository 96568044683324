import { InputType } from '@flock/flock-component-library'
import { Core_CreateInvestorAccountWithLegalEntitiesRequestInput } from '@flock/flock-gql-server/src/__generated__/graphql'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import ActionFormModal from '../../shared/ActionFormModal'

type CreateInvestorAccountProps = {
  setNewInvestorAccounts: (
    newAccount: Core_CreateInvestorAccountWithLegalEntitiesRequestInput,
    closeModal: (val: boolean) => void
  ) => void
  disabled: boolean
}
const CreateInvestorAccountModal = (props: CreateInvestorAccountProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { setNewInvestorAccounts, disabled } = props

  const onSubmit = async (
    createInvestorAccountParams: Core_CreateInvestorAccountWithLegalEntitiesRequestInput
  ) => {
    const { phoneNumber, ...otherProps } = createInvestorAccountParams

    setNewInvestorAccounts(
      {
        ...otherProps,
        phoneNumber: phoneNumber.replace(/[^0-9]/g, ''),
      },
      setIsOpen
    )
  }
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setIsOpen(true)}
        size="smallForm"
        sx={{ marginRight: '0.5rem' }}
        disabled={disabled}
      >
        Create Investor Account
      </Button>
      <ActionFormModal
        open={isOpen}
        setOpen={() => setIsOpen(false)}
        loading={false}
        onSubmit={onSubmit}
        actionText="Create Investor Account"
        inputConfigs={[
          {
            inputName: 'firstName',
            inputType: InputType.Text,
            required: true,
            props: {
              label: 'First Name',
              type: 'text',
              fullWidth: true,
              'data-cy': 'firstName',
            },
          },
          {
            inputName: 'lastName',
            inputType: InputType.Text,
            required: true,
            props: {
              label: 'last Name',
              type: 'text',
              fullWidth: true,
              'data-cy': 'lastName',
            },
          },

          {
            inputName: 'email',
            inputType: InputType.Text,
            required: true,
            props: {
              fullWidth: true,
              type: 'email',
              label: 'Email',
              'data-cy': 'email',
            },
          },
          {
            inputName: 'phoneNumber',
            inputType: InputType.Phone,
            required: true,
            props: {
              fullWidth: true,
              label: 'Phone Number',
              'data-cy': 'phone',
            },
          },
        ]}
      />
    </>
  )
}

export default CreateInvestorAccountModal
