import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  PageLayout,
  DarkHeader,
  flockColors,
  SnackbarProvider,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '@flock/flock-component-library'
import { SnackbarProvider as SharedUiSnackbarProvider } from '@flock/shared-ui'
import { Router, Redirect, Location, RouteComponentProps } from '@reach/router'
import { Box, CircularProgress, styled } from '@mui/material'
import { useFirebaseAuth } from '@flock/utils'

import { getHeaderConfigs, onLogoClick } from '../utils'
import {
  FLOCK_LANDING_URL,
  HOME_OPS_TASKS,
  HOME_OPS_URL,
  ORDER_ONBOARDING_URL,
  SALES_LEAD_MANAGEMENT_URL,
  SALES_OUTBOUND_TASKS,
  SALES_TASKS,
  INVESTOR_MANAGEMENT_URL,
  BROKER_MANAGEMENT_URL,
  GATSBY_ENV,
  DISTRIBUTIONS_URL,
  NEW_CONTRIBUTIONS_URL,
  SALES_LEAD_DOCUMENTS_CENTER_URL,
  REPORTS_URL,
  NEW_ORDER_CREATION_URL,
  OCCUPANCY_STATUS_URL,
  DATA_LAKE_REPORTING_URL,
  DATA_LAKE_SEARCH_URL,
  SALES_CUSTOMER_MANAGEMENT_URL,
  HOME_OPS_CUSTOMER_URL,
  ACCOUNT_DOCUMENTS_URL,
  ADDRESS_CREATION_URL,
  HOME_OPS_VALUATION_URL,
  SOW_HOME,
  VALUATION_PRINT_COLLATERAL_URL,
} from '../constants'
import SubHeader from '../components/SubHeader'
import LeadManagementPage from '../components/HomeOps/LeadManagementPage'
import InvestorManagementPage from '../components/InvestorManagement/InvestorManagementPage'
import DistributionsPage from '../components/InvestorManagement/DistributionsPage'
import IndividualInvestorPage from '../components/InvestorManagement/IndividualInvestorPage'
import IndividualLeadPage from '../components/HomeOps/IndividualLeadPage'
import IndividualOrderPage from '../components/OrderOnboarding/IndividualOrderPage'
import TaskManagementPage from '../components/TaskManagement/TaskManagementPage'
import IndividualTaskPage from '../components/TaskManagement/IndividualTaskPage'
import { TaskRole } from '../components/TaskManagement/taskTypes'
import OrderTaskPage from '../components/OrderOnboarding/OrderTaskPage'
import BrokerManagementPage from '../components/BrokerManagement/BrokerManagementPage'
import IndividualBrokerPage from '../components/BrokerManagement/IndividualBrokerPage'
import ComputeLeadValuationV1Page from '../components/HomeOps/ValuationsV1/ComputeLeadValuationV1Page/ComputeLeadValuationV1Page'
import ComputeAddressValuationPage from '../components/HomeOps/ValuationsV1/ComputeAddressValuationPage/ComputeAddressValuationPage'
import IndividualOrderPageV2 from '../components/OrderOnboardingV2/IndividualOrderPageV2'
import OrderOnboardingV3Page from '../components/OrderV3/OrderOnboardingV3Page'
import NewContributionsQueueV2 from '../components/OrderOnboardingV2/NewContributionsQueueV2'
import StaticDocumentsPage from '../components/StaticDocuments/StaticDocumentsPage'
import IndividualLegalEntityPage from '../components/InvestorManagement/IndividualLegalEntityPage'
import ReportsPage from '../components/InvestorManagement/ReportsPage'
import CreateOrderPage from '../components/CreateOrder/CreateOrderPage'
import IndividualOrderPageV3 from '../components/OrderV3/IndividualOrderPageV3'
import DataLakePage from '../components/DataLake/DataLakePage'
import OccupancyStatusPage from '../components/DataLake/OccupancyStatusPage'
import DataLakeReportsPage from '../components/DataLake/DataLakeReportsPage'
import CustomerManagementPage from '../components/HomeOps/CustomerManagementPage'
import IndividualCustomerPage from '../components/HomeOps/IndividualCustomerPage'
import PrintCollateralPage from '../components/CustomerManagement/PrintCollateralPage/PrintCollateralPage'
import CreateSalesforceOrderPage from '../components/CreateOrder/CreateSalesforceOrderPage/CreateSalesforceOrderPage'
import AccountDocumentsPage from '../components/AccountDocumentsPage/AccountDocumentsPage'
import AddressCreationPage from '../components/AddressCreationPage/AddressCreationPage'
import SowProjectsPage from '../components/SowProjectsPage/SowProjectsPage'
import IndividualSowProjectPage from '../components/SowProjectsPage/IndividualSowProjectPage/IndividualSowProjectPage'
import ValuationPrintCollateralPage from '../components/PrintCollateralPage/PrintCollateralPage'

const TopBorderWrapper = styled('div')({
  borderTop: '0.0625rem solid black',
})

const ContentContainer = styled('div')({
  maxWidth: '71.85rem',
  margin: 'auto',

  [TABLET_BREAKPOINT]: {
    margin: '2rem',
  },

  [MOBILE_BREAKPOINT]: {
    margin: '1rem',
  },
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InvestorManagement = (_: RouteComponentProps) => (
  <InvestorManagementPage />
)

const App = () => {
  const { user, logout, useValidateLoggedInOrRedirect } = useFirebaseAuth()
  const [hideNav, setHideNav] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        [
          ACCOUNT_DOCUMENTS_URL,
          ADDRESS_CREATION_URL,
          SOW_HOME,
          VALUATION_PRINT_COLLATERAL_URL,
        ].some((url) => window.location.href.includes(url))
      ) {
        setHideNav(true)
      }
    }
  }, [])

  useValidateLoggedInOrRedirect()

  if (!user) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <SnackbarProvider>
      <SharedUiSnackbarProvider>
        <PageLayout title="Admin - FLOCK">
          <Helmet>
            <link rel="canonical" href={FLOCK_LANDING_URL} />
          </Helmet>
          <Location>
            {(locationProps) =>
              !hideNav ? (
                <DarkHeader
                  onLogoClick={onLogoClick}
                  menuItemConfigs={getHeaderConfigs(async () => {
                    await logout()
                    window.location.href = '/login'
                  }, locationProps.location)}
                  hideGradient
                  selectable
                  color={
                    GATSBY_ENV === 'production'
                      ? flockColors.black
                      : flockColors.darkBlue
                  }
                />
              ) : null
            }
          </Location>
          <Box
            sx={{
              paddingTop: hideNav ? '0' : '4rem',
            }}
          >
            <Location>
              {(locationProps) => (
                <>
                  {!hideNav && <SubHeader location={locationProps.location} />}
                  <TopBorderWrapper
                    sx={{
                      borderTop: !hideNav ? '0.0625rem solid black' : 'none',
                    }}
                  >
                    <ContentContainer
                      sx={{
                        maxWidth: hideNav ? '100%' : '71.85rem',
                        margin: hideNav ? '0!important' : undefined,
                      }}
                    >
                      <Router>
                        <TaskManagementPage
                          path={SALES_TASKS}
                          role={TaskRole.SALES}
                        />
                        <IndividualTaskPage
                          path={`${SALES_TASKS}/:taskUuid`}
                          role={TaskRole.SALES}
                        />
                        <StaticDocumentsPage
                          path={SALES_LEAD_DOCUMENTS_CENTER_URL}
                        />
                        <LeadManagementPage path={HOME_OPS_URL} />
                        <CustomerManagementPage path={HOME_OPS_CUSTOMER_URL} />
                        <BrokerManagementPage path={BROKER_MANAGEMENT_URL} />
                        <IndividualBrokerPage
                          path={`${BROKER_MANAGEMENT_URL}/:brokerUuid`}
                        />
                        <TaskManagementPage
                          path={HOME_OPS_TASKS}
                          role={TaskRole.HOME_OPS}
                        />
                        <IndividualTaskPage
                          path={`${HOME_OPS_TASKS}/:taskUuid`}
                          role={TaskRole.HOME_OPS}
                        />
                        <IndividualLeadPage
                          path={`${HOME_OPS_URL}/:leadUuid`}
                        />
                        <IndividualCustomerPage
                          path={`${HOME_OPS_CUSTOMER_URL}/:customerUuid`}
                        />
                        <ComputeLeadValuationV1Page
                          path={`${HOME_OPS_URL}/:leadUuid/valuationsV1`}
                        />
                        <ComputeAddressValuationPage
                          path={`${HOME_OPS_VALUATION_URL}/:addressId`}
                        />
                        <TaskManagementPage
                          path={SALES_OUTBOUND_TASKS}
                          role={TaskRole.OUTBOUND_SALES}
                        />
                        <IndividualTaskPage
                          path={`${SALES_OUTBOUND_TASKS}/:taskUuid`}
                          role={TaskRole.OUTBOUND_SALES}
                        />
                        <LeadManagementPage path={SALES_LEAD_MANAGEMENT_URL} />
                        <CustomerManagementPage
                          path={SALES_CUSTOMER_MANAGEMENT_URL}
                        />
                        <IndividualLeadPage
                          path={`${SALES_LEAD_MANAGEMENT_URL}/:leadUuid`}
                        />
                        <IndividualCustomerPage
                          path={`${SALES_CUSTOMER_MANAGEMENT_URL}/:customerUuid`}
                        />
                        <PrintCollateralPage path="/app/print" />
                        <InvestorManagement path={INVESTOR_MANAGEMENT_URL} />
                        <DistributionsPage path={DISTRIBUTIONS_URL} />
                        <ReportsPage path={REPORTS_URL} />
                        <TaskManagementPage
                          path={SALES_OUTBOUND_TASKS}
                          role={TaskRole.OUTBOUND_SALES}
                        />
                        <IndividualTaskPage
                          path={`${SALES_OUTBOUND_TASKS}/:taskUuid`}
                          role={TaskRole.OUTBOUND_SALES}
                        />
                        <IndividualInvestorPage
                          path={`${INVESTOR_MANAGEMENT_URL}/:investorAccountUuid`}
                        />
                        <IndividualLegalEntityPage
                          path={`${INVESTOR_MANAGEMENT_URL}/:investorAccountUuid/legal-entity-management/:legalEntityUuid`}
                        />
                        <NewContributionsQueueV2 path={NEW_CONTRIBUTIONS_URL} />
                        <OrderOnboardingV3Page path={ORDER_ONBOARDING_URL} />
                        <IndividualOrderPage
                          path={`${ORDER_ONBOARDING_URL}v1/:orderUuid`}
                        />
                        <IndividualOrderPageV2
                          path={`${ORDER_ONBOARDING_URL}v2/:orderUuid`}
                        />
                        <IndividualOrderPageV3
                          path={`${ORDER_ONBOARDING_URL}/:orderUuid`}
                        />
                        <OrderTaskPage
                          path={`${ORDER_ONBOARDING_URL}/:orderUuid/tasks/:taskUuid`}
                        />
                        <CreateOrderPage
                          path={`${NEW_ORDER_CREATION_URL}/:taskUuid`}
                        />
                        <CreateSalesforceOrderPage
                          path={`${NEW_ORDER_CREATION_URL}/salesforce/:scenarioId`}
                        />
                        <DataLakePage path={DATA_LAKE_SEARCH_URL} />
                        <OccupancyStatusPage
                          path={`${OCCUPANCY_STATUS_URL}/:propertyUuid`}
                        />
                        <DataLakeReportsPage path={DATA_LAKE_REPORTING_URL} />
                        <AccountDocumentsPage path={ACCOUNT_DOCUMENTS_URL} />
                        <AddressCreationPage path={ADDRESS_CREATION_URL} />

                        <SowProjectsPage path={SOW_HOME} />
                        <IndividualSowProjectPage
                          path={`${SOW_HOME}/:projectUuid`}
                        />
                        <ValuationPrintCollateralPage
                          path={VALUATION_PRINT_COLLATERAL_URL}
                        />

                        <Redirect from="/*" to={HOME_OPS_URL} noThrow />
                      </Router>
                    </ContentContainer>
                  </TopBorderWrapper>
                </>
              )}
            </Location>
          </Box>
        </PageLayout>
      </SharedUiSnackbarProvider>
    </SnackbarProvider>
  )
}

export default App
