import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { Typography } from '@mui/material'
import { AdminAddressValuationsInvestmentsApproveAddressValuationDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { AnyInputConfig, InputType, useSnackbar } from '@flock/shared-ui'
import { formatIntegerDollars } from '@flock/utils'
import { SubmitValuationModalProps } from './submitValuationModalTypes'

export const INVESTMENTS_APPROVE_ADDRESS_VALUATION = gql`
  mutation AdminAddressValuationsInvestmentsApproveAddressValuation(
    $input: Core_InvestmentsApproveAddressValuationRequestInput!
  ) {
    investmentsApproveAddressValuation(input: $input) {
      _empty
    }
  }
`

const useSubmitValuationModal = (props: SubmitValuationModalProps) => {
  const {
    onClose,
    operatorUuid,
    valuationUuid,
    finalOfferPrice,
    canSubmitValuation,
    valuationTypeString,
  } = props

  const { notify } = useSnackbar()

  const [submitValuation, { loading }] = useMutation(
    AdminAddressValuationsInvestmentsApproveAddressValuationDocument
  )

  const submitValuationInputConfigs: AnyInputConfig[] = [
    {
      name: 'finalOfferPrice',
      type: InputType.CustomComponent,
      props: {
        component: (
          <Typography variant="h3">
            {`You will be submitting this ${valuationTypeString} valuation with an offer price of
            ${formatIntegerDollars(Math.trunc(finalOfferPrice))}`}
          </Typography>
        ),
      },
    },
  ]

  const onSubmit = async () => {
    if (!canSubmitValuation()) {
      return
    }
    try {
      submitValuation({
        variables: {
          input: {
            operatorUuid,
            valuationUuid,
            finalOfferPrice: Math.trunc(finalOfferPrice),
          },
        },
      })
      onClose()
      notify('Valuation submitted successfully', 'success')
    } catch (e) {
      notify('Submitting valuatino failed, please try again', 'error')
    }
  }
  return { onSubmit, submitValuationInputConfigs, loading }
}

export default useSubmitValuationModal
