import React from 'react'
import { Box, Typography } from '@mui/material'
import useCompsPage from './useCompsPage'
import { CompsPageProps } from './compsPageTypes'
import SinglePagePrintWrapper from '../SinglePagePrintWrapper'
import { FilledBox, Header } from '../../SharedPrintCollateralComponents'
import HousePlaceholder from '../../../../images/house-placeholder.svg'

const CompsPage = (props: CompsPageProps) => {
  const presentationalProps = useCompsPage(props)
  const {
    formattedAddress,
    pageConfigs,
    coverName,
    pageOffset,
    batchStart,
    batchEnd,
    unitNames,
  } = presentationalProps

  return (
    <>
      {pageConfigs.map((pageConfig, idx) => {
        const { pageNumber, comps } = pageConfig
        return (
          <SinglePagePrintWrapper
            shouldShow={pageOffset >= batchStart && pageOffset < batchEnd}
            coverName={coverName}
            pageNumber={pageOffset}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="12px"
              id={`comps-page-${formattedAddress}-${pageNumber}`}
            >
              {idx === 0 ? (
                <>
                  <Header section="Valuation Comps" title={formattedAddress} />
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography variant="p4">
                      Flock uses industry-standard data models and sale comps to
                      underwrite current fair market value.
                    </Typography>
                    <Typography variant="p4">
                      Flock provides tax-deferred cash flow without the hassle
                      of property management. Capture appreciation of the asset,
                      diversification of risk, and optional reinvestment of
                      proceeds for additional growth for years to come.
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography variant="p4" fontWeight={500}>
                    Valuation Comps
                  </Typography>
                  <Typography
                    variant="p2"
                    color="green3.main"
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {formattedAddress} (cont.)
                  </Typography>
                </Box>
              )}
              {comps.map((comp) => {
                const {
                  formattedAddress: compAddress,
                  beds,
                  baths,
                  sqft,
                  price,
                  soldDate,
                  imgUrl,
                  numUnits,
                  uwRent,
                } = comp
                return (
                  <FilledBox
                    gap="8px"
                    sx={{
                      backgroundColor:
                        idx === 0 ? 'rgba(225, 238, 239, 0.5)' : 'gray1.main',
                    }}
                  >
                    <Box display="flex" gap="12px">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                      >
                        <Box width="76px" height="76px" overflow="hidden">
                          <Box
                            width="384px"
                            height="384px"
                            sx={{
                              backgroundImage: `url(${
                                imgUrl || HousePlaceholder
                              })`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'left -40px top -40px',
                              transform: 'scale(0.25)',
                              transformOrigin: 'top left',
                            }}
                          />
                        </Box>
                      </Box>

                      <Box display="flex" flexDirection="column" gap="4px">
                        <Box display="flex" gap="8px" alignItems="baseline">
                          <Typography variant="p2" fontWeight={500}>
                            {price}
                          </Typography>
                          {soldDate && (
                            <Typography variant="p5" color="gray8.main">
                              {soldDate}
                            </Typography>
                          )}
                        </Box>
                        <Box display="flex" gap="24px">
                          <Box
                            display="flex"
                            flexDirection="column"
                            width="20px"
                          >
                            <Typography variant="p5">Bed</Typography>
                            <Typography variant="p4" fontWeight={500}>
                              {beds}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            width="24px"
                          >
                            <Typography variant="p5">Bath</Typography>
                            <Typography variant="p4" fontWeight={500}>
                              {baths}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            width="36px"
                          >
                            <Typography variant="p5">Sqft</Typography>
                            <Typography variant="p4" fontWeight={500}>
                              {sqft}
                            </Typography>
                          </Box>
                          {
                            // Only show numUnits if it exists
                            numUnits && (
                              <Box
                                display="flex"
                                flexDirection="column"
                                width="36px"
                              >
                                <Typography variant="p5">Units</Typography>
                                <Typography variant="p4" fontWeight={500}>
                                  {numUnits}
                                </Typography>
                              </Box>
                            )
                          }
                          {
                            // Only show uwRent if it exists (subject property)
                            uwRent && (
                              <Box
                                display="flex"
                                flexDirection="column"
                                width="100%"
                              >
                                <Typography variant="p5">
                                  Total Underwritten Rent {`(${unitNames}}`}
                                </Typography>
                                <Typography variant="p4" fontWeight={500}>
                                  {`$${uwRent}`}
                                </Typography>
                              </Box>
                            )
                          }
                        </Box>
                        <Typography variant="p5" color="gray8.main">
                          {compAddress}
                        </Typography>
                      </Box>
                    </Box>
                  </FilledBox>
                )
              })}
            </Box>
          </SinglePagePrintWrapper>
        )
      })}
    </>
  )
}

export default CompsPage
