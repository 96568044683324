import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import useSowRenderer from './useSowRenderer'
import { SowRendererProps } from './sowRendererTypes'

const Row = styled(Box)({
  display: 'flex',
})

const ColumnCategoryCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.white5.main,
  borderRadius: '4px',
}))

const LabelCell = styled(Box)({
  width: '116px',
})

const LabelHeaderCell = styled(LabelCell)(({ theme }) => ({
  backgroundColor: theme.palette.green1.main,
  display: 'flex',
}))

const LabelContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '4px 2px 4px 12px',
  gap: '2px',
  width: '116px',
})

const ValueCell = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '56px',
})

const ValueHeaderCell = styled(ValueCell)(({ theme }) => ({
  backgroundColor: theme.palette.green1.main,
  display: 'flex',
  justifyContent: 'flex-end',
}))

const ValueContent = styled(Typography)({
  padding: '4px 2px',
  textAlign: 'right',
})

const TableWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: theme.palette.gray3.main,
}))

// Renders the SOW for a single unit
const SowRenderer = (props: SowRendererProps) => {
  const presentationalProps = useSowRenderer(props)
  const {
    unitIdx,
    unitName,
    pageConfigs,
    FirstPageHeaderComponent,
    OtherPageHeaderComponent,
  } = presentationalProps

  return (
    <Box display="flex" flexDirection="column">
      {pageConfigs.map((pageConfig, pageIdx) => {
        const { pageNumber, items, summaryItems } = pageConfig

        return (
          <Box width="612px" height="712px">
            <Box width="452px" height="632px" paddingTop="80px">
              <Box
                id={`page-${unitName}-${pageNumber}`}
                className="page-wrapper"
                key={`page-${unitName}-${pageNumber}`}
                display="flex"
                flexDirection="column"
                // padding="80px"
                gap="8px"
              >
                {unitIdx === 0 && pageIdx === 0 ? (
                  <FirstPageHeaderComponent />
                ) : (
                  <OtherPageHeaderComponent />
                )}
                <Typography variant="p4">
                  {unitName} {pageIdx !== 0 && unitName && ' (cont.)'}
                </Typography>
                {items.length > 0 && (
                  <TableWrapper paddingBottom="4px">
                    <Box
                      display="flex"
                      gap="4px"
                      padding="0px 4px"
                      sx={{
                        paddingTop: '8px',
                        backgroundColor: 'green1.main',
                        borderRadius: '7px 7px 0px 0px',
                      }}
                    >
                      <Box width="110px" />
                      <ColumnCategoryCell width="108px">
                        <ValueContent variant="p6">IMMEDIATE</ValueContent>
                      </ColumnCategoryCell>
                      <ColumnCategoryCell width="52px">
                        <ValueContent variant="p6">0-3 YEAR</ValueContent>
                      </ColumnCategoryCell>
                      <ColumnCategoryCell width="162px">
                        <ValueContent variant="p6">NON-IMMEDIATE</ValueContent>
                      </ColumnCategoryCell>
                    </Box>
                    <Row>
                      <LabelHeaderCell sx={{ paddingLeft: '12px' }}>
                        <ValueContent variant="p6">Item</ValueContent>
                      </LabelHeaderCell>
                      <ValueHeaderCell>
                        <ValueContent variant="p6">
                          Health & Safety
                        </ValueContent>
                      </ValueHeaderCell>
                      <ValueHeaderCell>
                        <ValueContent variant="p6">First Turn</ValueContent>
                      </ValueHeaderCell>
                      <ValueHeaderCell>
                        <ValueContent variant="p6">
                          0-3 Years of Life Remaining
                        </ValueContent>
                      </ValueHeaderCell>
                      <ValueHeaderCell>
                        <ValueContent variant="p6">
                          Non-Immediately Addressable
                        </ValueContent>
                      </ValueHeaderCell>
                      <ValueHeaderCell>
                        <ValueContent variant="p6">
                          Remaining Useful Life
                        </ValueContent>
                      </ValueHeaderCell>
                      <ValueHeaderCell sx={{ paddingRight: '12px' }}>
                        <ValueContent variant="p6">Cost</ValueContent>
                      </ValueHeaderCell>
                    </Row>
                    <Box
                      height="4px"
                      mb="6px"
                      sx={{
                        backgroundColor: 'green1.main',
                      }}
                    />
                    {items.map((item, idx) => {
                      const {
                        category,
                        item: itemName,
                        description,
                        healthAndSafety,
                        firstTurn,
                        threeYearsOfRemainingLife,
                        nonImmediatelyAddressable,
                        remainingUsefulLifeAdjustment,
                        cost,
                      } = item

                      let renderCategory = false
                      let renderContCategory = false
                      if (idx === 0) {
                        renderCategory = true
                      } else if (idx !== 0) {
                        const previousItemCategory = items[idx - 1].category
                        if (previousItemCategory !== category) {
                          renderCategory = true
                        }
                      }

                      if (pageIdx > 0) {
                        const previousPageItems = pageConfigs[pageIdx - 1].items
                        const previousPageLastItemCategory =
                          previousPageItems[previousPageItems.length - 1]
                            .category
                        if (previousPageLastItemCategory === category) {
                          renderContCategory = true
                        }
                      }

                      return (
                        <>
                          {renderCategory && (
                            <Row key={category}>
                              <LabelCell sx={{ width: 'unset' }}>
                                <LabelContent sx={{ width: 'unset' }}>
                                  <Typography variant="p6" color="green3.main">
                                    {category}{' '}
                                    {renderContCategory && ' (CONT.)'}
                                  </Typography>
                                </LabelContent>
                              </LabelCell>
                            </Row>
                          )}
                          <Row key={itemName}>
                            <LabelCell>
                              <LabelContent>
                                <Typography variant="p5">{itemName}</Typography>
                                <Typography variant="p6">
                                  {description}
                                </Typography>
                              </LabelContent>
                            </LabelCell>
                            <ValueCell>
                              <ValueContent variant="p5">
                                {healthAndSafety}
                              </ValueContent>
                            </ValueCell>
                            <ValueCell>
                              <ValueContent variant="p5">
                                {firstTurn}
                              </ValueContent>
                            </ValueCell>
                            <ValueCell>
                              <ValueContent variant="p5">
                                {threeYearsOfRemainingLife}
                              </ValueContent>
                            </ValueCell>
                            <ValueCell>
                              <ValueContent variant="p5">
                                {nonImmediatelyAddressable}
                              </ValueContent>
                            </ValueCell>
                            <ValueCell>
                              <ValueContent variant="p5">
                                {remainingUsefulLifeAdjustment}
                              </ValueContent>
                            </ValueCell>
                            <ValueCell sx={{ paddingRight: '12px' }}>
                              <ValueContent variant="p5">{cost}</ValueContent>
                            </ValueCell>
                          </Row>
                        </>
                      )
                    })}
                  </TableWrapper>
                )}

                {summaryItems.length > 0 && (
                  <TableWrapper py="4px">
                    {summaryItems.map((summaryItem) => {
                      const {
                        item: itemName,
                        description,
                        healthAndSafety,
                        firstTurn,
                        threeYearsOfRemainingLife,
                        nonImmediatelyAddressable,
                        remainingUsefulLifeAdjustment,
                        cost,
                      } = summaryItem

                      return (
                        <Row key={itemName}>
                          <LabelCell>
                            <LabelContent>
                              <Typography variant="p5">{itemName}</Typography>
                              <Typography variant="p6">
                                {description}
                              </Typography>
                            </LabelContent>
                          </LabelCell>
                          <ValueCell>
                            <ValueContent variant="p5">
                              {healthAndSafety}
                            </ValueContent>
                          </ValueCell>
                          <ValueCell>
                            <ValueContent variant="p5">
                              {firstTurn}
                            </ValueContent>
                          </ValueCell>
                          <ValueCell>
                            <ValueContent variant="p5">
                              {threeYearsOfRemainingLife}
                            </ValueContent>
                          </ValueCell>
                          <ValueCell>
                            <ValueContent variant="p5">
                              {nonImmediatelyAddressable}
                            </ValueContent>
                          </ValueCell>
                          <ValueCell>
                            <ValueContent variant="p5">
                              {remainingUsefulLifeAdjustment}
                            </ValueContent>
                          </ValueCell>
                          <ValueCell sx={{ paddingRight: '12px' }}>
                            <ValueContent variant="p5">{cost}</ValueContent>
                          </ValueCell>
                        </Row>
                      )
                    })}
                  </TableWrapper>
                )}
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default SowRenderer
