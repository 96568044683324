import React from 'react'
import {
  Typography,
  Paper,
  Button,
  useSnackbar,
} from '@flock/flock-component-library'
import {
  AdminDeleteInvestmentTransactionDocument,
  AdminGetInvestmentInfoForLegalEntityDocument,
  AdminGetInvestmentTransactionInfoForLegalEntityDocument,
  Core_InvestmentTransaction,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  Modal,
  styled,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { formatDateString, formatCents } from './utils'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

const ButtonWrapper = styled('div')({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
})

const CancelButton = styled(Button)({
  marginRight: '0.5rem',
})

type DeleteInvestmentTransactionsModalParams = {
  existingInvestmentTransaction: Core_InvestmentTransaction | undefined
  isOpen: boolean
  close: () => void
}

// TODO refactor to a DELETE Modal component too if this table format for displaying is reused

const DeleteInvestmentTransactionModal = (
  props: DeleteInvestmentTransactionsModalParams
) => {
  const { existingInvestmentTransaction, isOpen, close } = props
  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [deleteInvestmentTransaction, { loading }] = useMutation(
    AdminDeleteInvestmentTransactionDocument
  )

  const onClick = async () => {
    const deleteInvestmentTransactionInput = {
      investmentTransactionUuid: existingInvestmentTransaction!.uuid || '',
    }
    try {
      await deleteInvestmentTransaction({
        variables: {
          deleteInvestmentTransactionInput,
        },
        refetchQueries: [
          AdminGetInvestmentInfoForLegalEntityDocument,
          AdminGetInvestmentTransactionInfoForLegalEntityDocument,
        ],
      })
      notify('Successfully deleted InvestmentTransaction', 'success')
      close()
    } catch (e) {
      notify('Failed to delete InvestmentTransaction', 'error')
    }
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalWrapper>
        <Typography
          variant="h2"
          sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
        >
          Delete InvestmentTransaction
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {formatCents(
                  existingInvestmentTransaction?.equityValueChangeCents || 0
                )}
              </TableCell>
              <TableCell>
                {formatDateString(
                  existingInvestmentTransaction?.effectiveDate || ''
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ButtonWrapper>
          <CancelButton color="error" onClick={handleClose}>
            Cancel
          </CancelButton>
          <Button variant="contained" onClick={onClick}>
            {loading ? 'Deleting...' : 'Delete InvestmentTransaction'}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  )
}

export default DeleteInvestmentTransactionModal
