import { FlockLogo } from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'
import VectorHouses from '../../../images/vector-houses.svg'

const SinglePageWrapper = (props: {
  shouldShow: boolean
  children: React.ReactNode
  showBackground?: boolean
  hideFooter?: boolean
  pageNumber?: number
  coverName?: string
}) => {
  const {
    shouldShow,
    children,
    showBackground,
    hideFooter,
    pageNumber,
    coverName,
  } = props
  if (!shouldShow) {
    return null
  }

  return (
    <Box
      width="612px"
      height="792px"
      display="flex"
      flexDirection="column"
      position="relative"
      alignItems="center"
    >
      {showBackground && (
        <Box
          component="img"
          src={VectorHouses}
          width="100%"
          position="absolute"
          top="118px"
        />
      )}
      <Box width="540px" height="792px" position="relative" paddingTop="36px">
        {children}
        {!hideFooter && (
          <Box
            position="absolute"
            width="540px"
            right="0px"
            bottom="24px"
            display="flex"
            justifyContent="space-between"
            alignSelf="flex-end"
          >
            {(pageNumber || 0) % 2 === 0 ? (
              <>
                <Typography
                  variant="c1m"
                  sx={{
                    fontSize: '10',
                    lineHeight: '12px',
                  }}
                >
                  {pageNumber}
                </Typography>
                <Typography
                  variant="c1m"
                  sx={{
                    fontSize: '10',
                    lineHeight: '12px',
                  }}
                >
                  {coverName}
                </Typography>
              </>
            ) : (
              <>
                <FlockLogo width="41px" height="12px" />
                <Typography
                  variant="c1m"
                  sx={{
                    fontSize: '10',
                    lineHeight: '12px',
                  }}
                >
                  {pageNumber}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SinglePageWrapper

SinglePageWrapper.defaultProps = {
  showBackground: false,
  hideFooter: false,
  pageNumber: 0,
  coverName: '',
}
