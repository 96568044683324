import { useQuery } from '@apollo/client'
import { AdminSearchSowProjectsDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import { debounce } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import {
  SowProjectsPageProps,
  SowProjectsPagePresentationalProps,
  DisplayedSowProject,
} from './sowProjectsPageTypes'

const useSowProjectsPage: (
  props: SowProjectsPageProps
) => SowProjectsPagePresentationalProps = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [sowProjects, setSowProjects] = useState<DisplayedSowProject[]>([])
  const [loading, setLoading] = useState(false)
  const { notify } = useSnackbar()

  const { refetch } = useQuery(AdminSearchSowProjectsDocument, {
    variables: {
      input: {
        searchString: '',
      },
    },
  })

  const onChange = async (value: string) => {
    setLoading(true)
    try {
      const searchResp = await refetch({
        input: {
          searchString: value,
        },
      })

      const searchResults =
        searchResp.data!.searchSOWProjects!.searchProjectResults!.map(
          (result) => {
            let displayedPropertyType = result!.address?.propertyType
            if (!displayedPropertyType) {
              displayedPropertyType = result!.address?.units?.length
                ? 'Multifamily'
                : 'Single family'
            }
            return {
              uuid: result!.uuid,
              status: result!.projectStatus as string,
              formattedAddress: result!.address?.formattedAddress || '',
              propertyType: displayedPropertyType,
              numUnits: result!.address?.units?.length || 0,
              inspectionDate:
                new Date(result!.inspectionDate).toLocaleDateString() || '-',
              dateModified: '-',
            }
          }
        )

      setSowProjects(searchResults)
    } catch (e) {
      notify('Error fetching search results', 'error')
    }
    setLoading(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchString = useCallback(debounce(onChange, 1000), [])

  useEffect(() => {
    debounceSetSearchString(searchQuery)
  }, [searchQuery, debounceSetSearchString])

  return {
    searchQuery,
    setSearchQuery,
    sowProjects,
    loading,
  }
}

export default useSowProjectsPage
