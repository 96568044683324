import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { formattedDollarsToCents } from '@flock/utils'
import { useMutation } from '@apollo/client'
import {
  AdminCreateLeaseDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_CreatePropertyLeaseRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'
import { cleanTimeFromDatetime } from '../../utils'

type CreateLeaseParams = {
  tenantName: string
  leaseStartDate: string
  leaseEndDate: string
  moveInDate: string
  leaseType: string
  leaseOriginator: string
  monthlyRentCents: string
  rentDueDateEachMonth: string
  gracePeriodDays: string
  isRenewed: string
  securityDepositCents: string
  renewalOfferSentDate: string
  renewalOfferAmountCents: string
  renewalOfferStatus: string
  renewalOfferResponseDate: string
  moveOutDate: string
  lastMoveOutDate: string
  isSectionEight: string
}

type CreateLeaseModalParams = {
  propertyUuid: string
  isOpen: boolean
  close: () => void
}

const CreateLeaseModal = (props: CreateLeaseModalParams) => {
  const { propertyUuid, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createLease, { loading }] = useMutation(AdminCreateLeaseDocument)

  const onSubmit = async (createLeaseParams: CreateLeaseParams) => {
    const {
      tenantName,
      leaseStartDate,
      leaseEndDate,
      moveInDate,
      leaseType,
      leaseOriginator,
      monthlyRentCents,
      rentDueDateEachMonth,
      gracePeriodDays,
      isRenewed,
      securityDepositCents,
      renewalOfferSentDate,
      renewalOfferAmountCents,
      renewalOfferStatus,
      renewalOfferResponseDate,
      moveOutDate,
      lastMoveOutDate,
      isSectionEight,
    } = createLeaseParams
    const createLeaseInput: Core_CreatePropertyLeaseRequestInput = {
      leaseParams: {
        propertyUuid,
        tenantName,
        leaseStartDate: cleanTimeFromDatetime(leaseStartDate),
        leaseEndDate: cleanTimeFromDatetime(leaseEndDate),
        moveInDate: cleanTimeFromDatetime(moveInDate),
        leaseType,
        leaseOriginator,
        monthlyRentCents: formattedDollarsToCents(monthlyRentCents),
        rentDueDateEachMonth: Number(rentDueDateEachMonth),
        gracePeriodDays: Number(gracePeriodDays),
        isRenewed: isRenewed === 'true',
        securityDepositCents: securityDepositCents
          ? formattedDollarsToCents(securityDepositCents)
          : null,
        renewalOfferSentDate: renewalOfferSentDate
          ? cleanTimeFromDatetime(renewalOfferSentDate)
          : null,
        renewalOfferAmountCents: renewalOfferAmountCents
          ? formattedDollarsToCents(renewalOfferAmountCents)
          : null,
        renewalOfferStatus: renewalOfferStatus || null,
        renewalOfferResponseDate: renewalOfferResponseDate
          ? formattedDollarsToCents(renewalOfferResponseDate)
          : null,
        moveOutDate: cleanTimeFromDatetime(moveOutDate),
        lastMoveOutDate: cleanTimeFromDatetime(lastMoveOutDate),
        isSectionEight: isSectionEight === 'true',
      },
    }

    try {
      await createLease({
        variables: {
          input: createLeaseInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify('Successfully created property lease.', 'success')
    } catch (e) {
      notify('Failed to create property lease.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Create Lease"
      inputConfigs={[
        {
          inputName: 'tenantName',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Tenant Name',
            placeholder: 'enter name here',
            fullWidth: true,
          },
        },
        {
          inputName: 'leaseStartDate',
          inputType: InputType.DatePicker,
          required: true,
          props: {
            label: 'Lease Start Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'leaseEndDate',
          inputType: InputType.DatePicker,
          required: true,
          props: {
            label: 'Lease End Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'moveInDate',
          inputType: InputType.DatePicker,
          required: true,
          props: {
            label: 'Move In Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'moveOutDate',
          inputType: InputType.DatePicker,
          props: {
            label: 'Move Out Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'leaseType',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Lease Type',
            fullWidth: true,
            options: [
              {
                text: 'Fixed',
                value: 'fixed',
              },
              {
                text: 'Month to Month',
                value: 'mtm',
              },
            ],
          },
        },
        {
          inputName: 'monthlyRentCents',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Monthly Rent',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },
        {
          inputName: 'leaseOriginator',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Lease Originator',
            fullWidth: true,
            options: [
              {
                text: 'Flock',
                value: 'Flock',
              },
              {
                text: 'Inherited',
                value: 'Inherited',
              },
            ],
          },
        },
        {
          inputName: 'isRenewed',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Is Renewed?',
            fullWidth: true,
            options: [
              {
                text: 'Yes',
                value: 'true',
              },
              {
                text: 'No',
                value: 'false',
              },
            ],
          },
        },
        {
          inputName: 'securityDepositCents',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Security Deposit',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },
        {
          inputName: 'renewalOfferSentDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Renewal Offer Sent Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'renewalOfferAmountCents',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Renewal Offer Amount',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },
        {
          inputName: 'renewalOfferStatus',
          inputType: InputType.Dropdown,
          required: false,
          props: {
            type: 'text',
            label: 'Renewal Offer Status?',
            fullWidth: true,
            options: [
              {
                text: '',
                value: undefined,
              },
              {
                text: 'Accepted',
                value: 'accepted',
              },
              {
                text: 'Pending',
                value: 'pending',
              },
              {
                text: 'Rejected',
                value: 'rejected',
              },
            ],
          },
        },
        {
          inputName: 'renewalOfferResponseDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Renewal Offer Response Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'isSectionEight',
          inputType: InputType.Dropdown,
          required: false,
          props: {
            type: 'text',
            label: 'Section 8?',
            fullWidth: true,
            options: [
              {
                text: '',
                value: undefined,
              },
              {
                text: 'No',
                value: 'false',
              },
              {
                text: 'Yes',
                value: 'true',
              },
            ],
          },
        },
      ]}
    />
  )
}

export default CreateLeaseModal
