import { gql, useMutation } from '@apollo/client'
import {
  AdminGetAccountDocumentsDocument,
  AdminCreateAccountDocumentDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  AnyInputConfig,
  InputType,
  FileUploadFileType,
  FileUploadMimeType,
  useSnackbar,
} from '@flock/shared-ui'
import React, { useState } from 'react'
import GridFormModal from '../../shared/GridFormModal'
import { AccountDocumentType } from './accountDocumentsTableTypes'

export const CREATE_ACCOUNT_DOCUMENT = gql`
  mutation AdminCreateAccountDocument($input: CreateAccountDocumentInput!) {
    createAccountDocument(input: $input) {
      uuid
      accountId
      documentType
      contentUrl
      createdAt
    }
  }
`

const inputConfigs: AnyInputConfig[] = [
  {
    name: 'name',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Display Name',
    },
  },
  {
    name: 'documentType',
    type: InputType.Dropdown,
    required: true,
    props: {
      label: 'Document Type',
      options: [
        {
          label: 'Additional Valuation Details',
          value: AccountDocumentType.ADDITIONAL_OFFER_DETAILS,
        },
        {
          label: 'Other',
          value: AccountDocumentType.OTHER,
        },
      ],
    },
  },
  {
    name: 'uploadedFile',
    type: InputType.FileUpload,
    required: true,
    props: {
      label: 'Upload your file. (Only PDFs are accepted)',
      accept: {
        [FileUploadMimeType.PDF]: [FileUploadFileType.PDF],
      },
    },
  },
]

type CreateAccountDocumentParams = {
  name: string
  documentType: string
  uploadedFile: File[]
}

type CreateAccountDocumentModalProps = {
  open: boolean
  onClose: () => void
  accountId: string
}

const CreateAccountDocumentModal = (props: CreateAccountDocumentModalProps) => {
  const { open, onClose, accountId } = props
  const { notify } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const [createAccountDocument] = useMutation(
    AdminCreateAccountDocumentDocument
  )

  const onSubmit = async (result: CreateAccountDocumentParams) => {
    setLoading(true)
    const { name, documentType, uploadedFile } = result
    const file = uploadedFile[0]
    const createAccountDocumentInput = {
      name,
      accountId,
      documentType,
      file,
      fileName: file.name,
    }

    try {
      await createAccountDocument({
        variables: {
          input: createAccountDocumentInput,
        },
        refetchQueries: [AdminGetAccountDocumentsDocument],
      })
      notify('Successfully created Account document', 'success')
      onClose()
    } catch (e) {
      notify('Failed to create Account document', 'error')
    }
    setLoading(false)
  }

  return (
    <GridFormModal
      title="Create Document"
      open={open}
      onClose={onClose}
      inputConfigs={inputConfigs}
      onSubmit={onSubmit}
      loading={loading}
      gridProps={{
        spacing: 3,
      }}
    />
  )
}

export default CreateAccountDocumentModal
