import React from 'react'
import { Box, Button, Modal, Paper, Typography } from '@mui/material'
import { FetchRentalCompsModalProps } from './fetchRentalCompsModalTypes'
import useFetchRentalCompsModal from './useFetchRentalCompsModal'

const FetchRentalCompsModal = (props: FetchRentalCompsModalProps) => {
  const { open, address, onClose } = props
  const { submitFetchRentalComps, loading } = useFetchRentalCompsModal(props)
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '80px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          width: '560px',
          maxHeight: '75vh',
          overflowY: 'scroll',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box display="flex" flexDirection="column" gap="32px">
          <Typography variant="h2">Fetch Rental Comps</Typography>
          <Typography variant="p2">
            Verify that you want to fetch rental comps for {address}. This may
            overwrite existing data on comps if they have the same address. This
            can take up to 30 seconds. If it fails, please try again once or
            twice or reach out to Eng.
          </Typography>
          <Button
            size="smallForm"
            onClick={submitFetchRentalComps}
            disabled={loading}
          >
            {loading ? 'Fetching...' : 'Fetch Comps'}
          </Button>
        </Box>
      </Paper>
    </Modal>
  )
}

export default FetchRentalCompsModal
