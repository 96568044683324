import { useMutation, useQuery } from '@apollo/client'
import {
  AdminCreateScopeOfWorkProjectDocument,
  AdminGetAllCostbooksDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import { useState } from 'react'
import { SOW_HOME } from '../../../constants'
import {
  CreateSowProjectModuleProps,
  CreateSowProjectModulePresentationalProps,
} from './createSowProjectModuleTypes'

const useCreateSowProjectModule: (
  props: CreateSowProjectModuleProps
) => CreateSowProjectModulePresentationalProps = (
  props: CreateSowProjectModuleProps
) => {
  const [projectType, setProjectType] = useState('inside_maps')
  const [addressID, setAddressID] = useState('')
  const [projectID, setProjectID] = useState('')
  const [inspectionDate, setInspectionDate] = useState<Date | null | undefined>(
    new Date()
  )
  const [costbookOptions, setCostbookOptions] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const [costbook, setCostbook] = useState('')
  const [loading, setLoading] = useState(false)

  const { notify } = useSnackbar()

  useQuery(AdminGetAllCostbooksDocument, {
    onCompleted: (data) => {
      const cbOptions = data?.getAllCostbooks?.costbooks?.map((cb) => ({
        label: cb?.name as string,
        value: cb?.uuid as string,
      }))
      if (cbOptions) {
        setCostbookOptions(cbOptions)
        setCostbook(cbOptions[0].value)
      }
    },
  })

  const [createSowProject] = useMutation(AdminCreateScopeOfWorkProjectDocument)

  const onSubmit = async () => {
    setLoading(true)
    const inputObj = {
      addressId: addressID,
      externalProjectId: projectID,
      externalProjectType: projectType,
      costbook,
      inspectionDate: undefined,
    }
    if (projectType === 'manual') {
      // @ts-ignore
      inputObj.inspectionDate = inspectionDate
    }
    try {
      const createProjectResponse = await createSowProject({
        variables: {
          input: inputObj,
        },
      })

      const projectUuid = createProjectResponse.data?.createSOWProject
        ?.uuid as string

      notify('Project created successfully', 'success')
      window.location.href = `${SOW_HOME}/${projectUuid}`
    } catch (e) {
      notify(
        `Failed to create project. Please verify Address ID and Project ID ${e.message}`,
        'error'
      )
    }
    setLoading(false)
  }

  return {
    ...props,
    projectType,
    setProjectType,
    inspectionDate,
    setInspectionDate,
    addressID,
    projectID,
    costbook,
    costbookOptions,

    loading,

    onSubmit,

    setAddressID,
    setProjectID,
    setCostbook,
  }
}

export default useCreateSowProjectModule
