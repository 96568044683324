import { useMutation } from '@apollo/client'
import { Button, useSnackbar, InputType } from '@flock/flock-component-library'
import { AdminCreateOperatorDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import React, { useState } from 'react'
import ActionFormModal from '../shared/ActionFormModal'

type CreateOperatorParams = {
  fullName: string
  email: string
  slackID: string
}

const CreateOperatorModal = () => {
  const { notify } = useSnackbar()

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [createNewOperator] = useMutation(AdminCreateOperatorDocument) // change this

  const onSubmit = async (inputParams: CreateOperatorParams) => {
    const { fullName, email, slackID } = inputParams
    setLoading(true)
    try {
      const data = await createNewOperator({
        variables: {
          input: {
            fullName,
            email,
            slackId: slackID,
          },
        },
      })
      window.open(
        data.data?.createOperator?.passwordResetUrl as string,
        '_blank'
      )
      notify('Successfully created new operator.', 'success')
      setOpen(false)
    } catch (e) {
      notify('Failed to create new operator.', 'error')
    }
    setLoading(false)
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Create New Operator
      </Button>
      <ActionFormModal
        open={open}
        setOpen={() => setOpen(false)}
        loading={loading}
        onSubmit={onSubmit}
        actionText="Create"
        inputConfigs={[
          {
            inputName: 'fullName',
            inputType: InputType.Text,
            required: true,
            props: {
              label: 'Full Name',
              type: 'text',
              fullWidth: true,
            },
          },
          {
            inputName: 'email',
            inputType: InputType.Text,
            required: true,
            props: {
              label: 'Email',
              type: 'text',
              fullWidth: true,
            },
          },
          {
            inputName: 'slackID',
            inputType: InputType.Text,
            required: true,
            props: {
              label: 'Slack ID',
              type: 'text',
              fullWidth: true,
            },
          },
        ]}
      />
    </>
  )
}

export default CreateOperatorModal
