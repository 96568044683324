import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const DoorIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.9714 1.01779C12.1494 0.988206 12.3315 0.995581 12.5065 1.03945C12.6815 1.08333 12.8456 1.16276 12.9885 1.27278C13.1314 1.38281 13.25 1.52107 13.3369 1.67893C13.4239 1.83678 13.4773 2.01084 13.4939 2.19024L13.5 2.31081V3.38453H15.25C15.6915 3.38439 16.1167 3.55088 16.4405 3.85063C16.7642 4.15037 16.9625 4.56121 16.9956 5.0008L17 5.13185V13.8684C16.9999 14.3091 16.8331 14.7335 16.5329 15.0565C16.2327 15.3796 15.8214 15.5775 15.3812 15.6105L15.25 15.6158H13.5V16.6904C13.4999 16.8705 13.4626 17.0486 13.3904 17.2137C13.3183 17.3788 13.2128 17.5273 13.0806 17.6498C12.9484 17.7724 12.7923 17.8664 12.6221 17.9261C12.4519 17.9857 12.2712 18.0097 12.0913 17.9965L11.9714 17.9825L4.46212 16.7332C4.07731 16.6692 3.72494 16.4786 3.46098 16.1918C3.19703 15.905 3.03662 15.5383 3.00525 15.1501L3 15.0094V3.99085C2.99996 3.60144 3.1302 3.22318 3.37005 2.91611C3.6099 2.60904 3.94561 2.39078 4.32387 2.29595L4.46212 2.26712L11.9714 1.01779ZM11.75 2.82626L4.75 3.99085V15.0094L11.75 16.174V2.82626ZM15.25 5.13185H13.5V13.8684H15.25V5.13185ZM9.5625 8.18965C9.9106 8.18965 10.2444 8.32772 10.4906 8.57349C10.7367 8.81925 10.875 9.15258 10.875 9.50014C10.875 9.8477 10.7367 10.181 10.4906 10.4268C10.2444 10.6726 9.9106 10.8106 9.5625 10.8106C9.2144 10.8106 8.88056 10.6726 8.63442 10.4268C8.38828 10.181 8.25 9.8477 8.25 9.50014C8.25 9.15258 8.38828 8.81925 8.63442 8.57349C8.88056 8.32772 9.2144 8.18965 9.5625 8.18965Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default DoorIcon
