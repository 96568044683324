import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

type PortfolioRowProps = {
  value1: ReactNode
  value2: ReactNode
  value3: ReactNode
}

const PortfolioRow = (props: PortfolioRowProps) => {
  const { value1, value2, value3 } = props
  return (
    <Box display="flex" gap="8px" alignItems="center" width="100%">
      <Box
        display="flex"
        alignItems="center"
        sx={{
          flex: '1 0 0',
        }}
      >
        <Typography variant="p4" display="flex">
          {value1}
        </Typography>
      </Box>
      <Box width="160px" display="flex" justifyContent="flex-end">
        <Typography variant="p4" color="green3.main">
          {value2}
        </Typography>
      </Box>
      <Box width="120px" display="flex" justifyContent="flex-end">
        <Typography variant="p4">{value3}</Typography>
      </Box>
    </Box>
  )
}

export default PortfolioRow
