import React from 'react'
import {
  Typography,
  Paper,
  Button,
  useSnackbar,
} from '@flock/flock-component-library'
import {
  AdminDeleteDistributionMutationDocument,
  AdminGetLegalEntitiesDistributionsDocument,
  Core_Distribution,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  Modal,
  styled,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { formatDateString, formatCents } from './utils'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

const ButtonWrapper = styled('div')({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
})

const CancelButton = styled(Button)({
  marginRight: '0.5rem',
})

type DeleteDistributionsModalParams = {
  existingDistribution: Core_Distribution | undefined
  isOpen: boolean
  close: () => void
}

// TODO refactor to a DELETE Modal component too if this table format for displaying is reused

const DeleteDistributionModal = (props: DeleteDistributionsModalParams) => {
  const { existingDistribution, isOpen, close } = props
  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [deleteDistribution, { loading }] = useMutation(
    AdminDeleteDistributionMutationDocument
  )

  const onClick = async () => {
    const deleteDistributionInput = {
      distributionUuid: existingDistribution!.uuid,
    }
    try {
      await deleteDistribution({
        variables: {
          deleteDistributionInput,
        },
        refetchQueries: [AdminGetLegalEntitiesDistributionsDocument],
      })
      notify('Successfully deleted distribution', 'success')
      close()
    } catch (e) {
      notify('Failed to Delete distribution', 'error')
    }
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalWrapper>
        <Typography
          variant="h2"
          sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
        >
          Delete Distribution
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {formatCents(existingDistribution?.amountCents || 0)}
              </TableCell>
              <TableCell>
                {formatDateString(existingDistribution?.distributionDate || '')}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ButtonWrapper>
          <CancelButton color="error" onClick={handleClose}>
            Cancel
          </CancelButton>
          <Button variant="contained" onClick={onClick}>
            {loading ? 'Deleting...' : 'Delete Distribution'}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  )
}

export default DeleteDistributionModal
