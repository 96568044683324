import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/shared-ui'
import {
  AdminSingleFamilyAddressValuationSummaryGenerateInvestmentMemoDocument,
  Core_ValuationV1,
  Core_ValuationType,
  Core_InvestmentMemoType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { formatDollarsAndCents } from '@flock/utils'
import { ValuationSummaryProps } from './valuationSummaryTypes'

export const GENERATE_INVESTMENT_MEMO = gql`
  mutation AdminSingleFamilyAddressValuationSummaryGenerateInvestmentMemo(
    $input: Core_GenerateAddressInvestmentMemoRequestInput!
  ) {
    generateAddressInvestmentMemo(input: $input) {
      url
    }
  }
`

const useValuationSummary = (props: ValuationSummaryProps) => {
  const { notify } = useSnackbar()

  const {
    computedValuationResult,
    operatorUuid: operatorUuidProp,
    valuationInputs,
    addressId,
  } = props

  const [valuationTrailOpen, setValuationTrailOpen] = useState(false)
  const [submitValuationModalOpen, setSubmitValuationModalOpen] =
    useState(false)
  const [computeCashFlowModalOpen, setComputeCashFlowModalOpen] =
    useState(false)

  const [generateInvestmentMemo, { loading: investmentMemoLoading }] =
    useMutation(
      AdminSingleFamilyAddressValuationSummaryGenerateInvestmentMemoDocument
    )

  const downloadInvestmentMemo = async () => {
    const operatorUuid =
      operatorUuidProp || computedValuationResult?.operatorUuid
    try {
      const urlData = await generateInvestmentMemo({
        variables: {
          input: {
            addressId,
            property: {
              uuid: valuationInputs?.property?.uuid,
              addressUuid: valuationInputs?.property?.addressUuid,
              baths: valuationInputs?.property?.baths,
              beds: valuationInputs?.property?.beds,
              propertyType: valuationInputs?.property?.propertyType,
              sqft: valuationInputs?.property?.sqft,
              yearBuilt: valuationInputs?.property?.yearBuilt,
              notes: valuationInputs?.property?.notes,
            },
            operatorUuid: operatorUuid as string,
            finalOfferPrice: Math.trunc(
              computedValuationResult?.outputs?.netYieldAdjustedOfferPrice || 0
            ),
            memoType: Core_InvestmentMemoType.InvestmentMemoTypeSingleFamily,
          },
        },
      })
      window.open(
        urlData?.data?.generateAddressInvestmentMemo?.url as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the document. Please refresh or try again.',
        'error'
      )
    }
  }

  const msaYieldDoesNotExist =
    computedValuationResult?.outputs?.msaLevelMinimums === 0

  const yieldConstrainedOfferPriceOutsideOfAvmOrCMAPriceDiff =
    ((computedValuationResult?.outputs?.finalOfferPrice || 0) -
      (computedValuationResult?.outputs?.netYieldAdjustedOfferPrice || 0)) /
    (computedValuationResult?.outputs?.finalOfferPrice || 0)

  const yieldConstrainedOfferPriceOutsideOfAvmOrCMAPrice =
    yieldConstrainedOfferPriceOutsideOfAvmOrCMAPriceDiff > 0.2

  let comparativeAnalysisRequired = msaYieldDoesNotExist
  comparativeAnalysisRequired =
    comparativeAnalysisRequired ||
    yieldConstrainedOfferPriceOutsideOfAvmOrCMAPrice

  let offerThresholdErrorMessage = ''
  if (yieldConstrainedOfferPriceOutsideOfAvmOrCMAPrice) {
    offerThresholdErrorMessage = `Yield constrained offer price differs by more than 20% from the AVM (or cma) value of ${formatDollarsAndCents(
      computedValuationResult?.outputs?.finalOfferPrice || 0
    )}.`
  } else if (msaYieldDoesNotExist) {
    offerThresholdErrorMessage =
      'MSA yield minimum does not exist. Please add the MSA to the assumptions.'
  }

  const getValuationTypeString = (valuation: Core_ValuationV1 | undefined) => {
    if (valuation?.type === Core_ValuationType.ValuationTypeFinal) {
      return 'final'
    }
    return 'initial'
  }

  return {
    downloadInvestmentMemo,
    investmentMemoLoading,
    valuationTrailOpen,
    setValuationTrailOpen,
    comparativeAnalysisRequired,
    submitValuationModalOpen,
    setSubmitValuationModalOpen,
    offerThresholdErrorMessage,
    valuationTypeString: getValuationTypeString(computedValuationResult),
    computeCashFlowModalOpen,
    setComputeCashFlowModalOpen,
  }
}

export default useValuationSummary
