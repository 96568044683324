import { Box, Modal } from '@mui/material'
import styled from '@mui/system/styled'
import React, { useState } from 'react'
import Button from '../Button/Button'
import CircularProgress from '../CircularProgress/CircularProgress'
import { MOBILE_BREAKPOINT } from '../constants/constants'
import Paper from '../Paper/Paper'
import TrackingContextProvider from '../TrackingContextProvider/TrackingContextProvider'
import useTracking, { TrackingConfig } from '../useTracking/useTracking'
import Typography from '../Typography/Typography'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',

  [MOBILE_BREAKPOINT]: {
    padding: '1rem',
    width: 'calc(100% - 2rem)',
  },
})

const ButtonWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
})

export type ConfirmModalProps = {
  title: string
  content: React.ReactNode
  open: boolean
  trackingConfig?: TrackingConfig
  onClose: () => void
  onSubmit: () => Promise<void>
}

const ConfirmModal = (props: ConfirmModalProps) => {
  const { title, content, trackingConfig, onSubmit, open, onClose } = props
  const [loading, setLoading] = useState(false)
  const { track } = useTracking()

  const trackingName = trackingConfig?.name || 'confirm-modal'

  const submitAndClose = async () => {
    setLoading(true)
    await onSubmit()
    setLoading(false)

    track(trackingName, trackingConfig?.properties)
  }

  return (
    <TrackingContextProvider
      name={trackingName}
      initialTrackingProperties={trackingConfig?.properties}
    >
      <Modal open={open}>
        <ModalWrapper>
          <Typography variant="h2" sx={{ paddingBottom: '1rem' }}>
            {title}
          </Typography>
          <Box sx={{ paddingBottom: '1rem' }}>
            <Typography>{content}</Typography>
          </Box>

          <ButtonWrapper>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  id={`${trackingName}-cancelled`}
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={submitAndClose}
                  sx={{
                    marginLeft: '1rem',
                  }}
                  id={`${trackingName}-confirmed`}
                >
                  Confirm
                </Button>
              </>
            )}
          </ButtonWrapper>
        </ModalWrapper>
      </Modal>
    </TrackingContextProvider>
  )
}

export default ConfirmModal
