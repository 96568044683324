import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import {
  Typography,
  LoadingCard,
  useSnackbar,
  Grid,
  DataTable,
  flockColors,
} from '@flock/flock-component-library'
import { Box, Button, TableCell, TableRow, ThemeProvider } from '@mui/material'

import { useMutation, useQuery } from '@apollo/client'
import { Checkbox, flockTheme } from '@flock/shared-ui'
import {
  AnyInputConfig,
  GridForm,
  InputType,
} from '@flock/shared-ui/src/components/GridForm'
import {
  AdminCreateOrderV3Document,
  AdminCreateOrderV3Mutation,
  AdminCreateTestContributionAgreementDocument,
  AdminSearchLeadsDocument,
  AdminSearchLegalEntitiesDocument,
  Core_CreateOrderV3Request_CreateOrderV3LegalEntityParamsInput,
  Core_CreateOrderV3Request_CreateOrderV3PropertyContributionParamsInput,
  Core_CreateOrderV3Request_CreateOrderV3SellerParamsInput,
  Core_CreateOrderV3Request_CreateOrderV3SellerParams_CreateOrderV3AuthorizedSignerParamsInput,
  Core_Lead,
  Core_LegalEntity,
  Core_ValidatedAddress,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  ALL_STATES,
  formatAddressString,
  formatCityStateZip,
} from '@flock/utils'
import { navigate, RouteComponentProps, useParams } from '@reach/router'
import { Add, Remove } from '@mui/icons-material'
import { GET_TASK } from '../../graphql/queries/task'
import { NEW_CONTRIBUTIONS_URL } from '../../constants'
import CreateLegalEntityModal from './CreateLegalEntity/CreateLegalEntityModal'
import OrderConfirmationModal from './OrderConfirmationModal/OrderConfirmationModal'

const PageTitle = styled(Typography)({
  marginTop: '4rem',
  marginBottom: '3rem',
})

export const selectedLegalEntityColumns = [
  {
    name: 'value',
    options: { display: false },
  },
  {
    name: '',
  },
  {
    name: 'label',
    label: 'Legal Entity Name',
    options: { sortThirdClickReset: true },
  },
]

export const legalEntityColumns = [
  {
    name: 'uuid',
    options: { display: false },
  },
  {
    name: '',
  },
  {
    name: 'name',
    label: 'Legal Entity Name',
    options: { sortThirdClickReset: true },
  },
]

const RemoveTableCell = styled(TableCell)({
  backgroundColor: flockColors.red,
  opacity: '75%',
  width: '1.5rem',
  textAlign: 'center',
  color: flockColors.white,
})
const AddTableCell = styled(TableCell)({
  opacity: '75%',
  width: '1.5rem',
  textAlign: 'center',
  color: flockColors.white,
})

const SectionTitleContainer = styled('div')({
  marginTop: '2rem',
  marginBottom: '1rem',
})

type CreateOrderRequestData = {
  request_detail: string
}

type AddressDataFields = {
  [key: string]: string[]
}

type TaskDataFields = {
  orderName: string
  addressUuidsWithLease: AddressDataFields
  lead_uuids: string[]
  offerPrice: number
  capex: number
  targetCloseDate: Date
}

type FormattedLeadsData = {
  UUID: string
  FormattedAddress: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CreateOrderPage = (_: RouteComponentProps) => {
  const { notify } = useSnackbar()

  const { taskUuid } = useParams()

  const [taskData, setTaskData] = useState<TaskDataFields>({} as TaskDataFields)
  const [formattedLeadsData, setFormattedLeadsData] = useState<
    FormattedLeadsData[]
  >([])

  const [createOrderV3, { loading: createOrderLoading }] = useMutation(
    AdminCreateOrderV3Document
  )
  const [createTestCA, { loading: createTestCALoading }] = useMutation(
    AdminCreateTestContributionAgreementDocument
  )
  const [orderConfirmationOpen, setOrderConfirmationOpen] = useState(false)
  const [order, setOrder] = useState({} as AdminCreateOrderV3Mutation)
  const [isTestDownload, setIsTestDownload] = useState(true)
  const { refetch: refetchLeads } = useQuery(AdminSearchLeadsDocument, {
    skip: true,
    fetchPolicy: 'no-cache',
    onError: () => {
      notify('Failed to get leads', 'error')
    },
  })

  useQuery(GET_TASK, {
    variables: {
      input: {
        taskUuid,
      },
    },
    fetchPolicy: 'no-cache',
    onError: () => {
      notify('Failed to get tasks', 'error')
    },
    onCompleted: async (task) => {
      const defaultEmptyJsonString = '{"request_detail": {"lead_uuids": []}}'
      const requestData = JSON.parse(
        task.task?.task?.requestData || defaultEmptyJsonString
      ) as CreateOrderRequestData

      if (requestData.request_detail === '') {
        return {} as TaskDataFields
      }

      const requestDetail = JSON.parse(
        requestData.request_detail
      ) as TaskDataFields

      const leadData = await refetchLeads({
        searchLeadsInput: {
          uuids: requestDetail.lead_uuids,
        },
      })

      const leads = leadData.data?.searchLeads?.leads || []

      const formattedLeadDataArr: FormattedLeadsData[] = []

      if (leads.length !== 0) {
        leads.forEach((leadEntry: Core_Lead | null) => {
          formattedLeadDataArr.push({
            UUID: leadEntry?.uuid || '',
            FormattedAddress: `${formatAddressString(
              leadEntry?.address as Core_ValidatedAddress
            )} ${formatCityStateZip(
              leadEntry?.address as Core_ValidatedAddress
            )}`,
          })
        })
      }

      setFormattedLeadsData(formattedLeadDataArr)
      setTaskData(requestDetail)
      return requestDetail
    },
  })

  const [selectedLegalEntities, setSelectedLegalEntities] = useState<
    { label: string; value: string }[]
  >([])

  const { loading: legalEntitiesLoading, data: legalEntitiesData } = useQuery(
    AdminSearchLegalEntitiesDocument,
    {
      variables: {
        searchLegalEntitiesInput: {},
      },
      onError: () => {
        notify('Failed to get investor account data', 'error')
      },
    }
  )
  const legalEntities =
    (legalEntitiesData?.searchLegalEntities
      ?.legalEntities as Core_LegalEntity[]) || []

  const legalEntityNumberTolegalEntity: {
    [key: number]: Core_LegalEntity
  } = {}

  // iterate through investments and create a map of investment number to investment uuid
  legalEntities?.forEach((legalEntity: Core_LegalEntity, index: number) => {
    legalEntityNumberTolegalEntity[index] = legalEntity
  })

  const removeLegalEntity = (legalEntityUuid: string) => {
    const selectedLegalEntitiesSet = new Set(selectedLegalEntities)
    Array.from(selectedLegalEntitiesSet).forEach((legalEntity) => {
      if (legalEntity.value === legalEntityUuid) {
        selectedLegalEntitiesSet.delete(legalEntity)
      }
    })

    const updatedSelectedLegalEntities = Array.from(selectedLegalEntitiesSet)
    setSelectedLegalEntities(updatedSelectedLegalEntities)
  }
  const addLegalEntity = (legalEntityToAdd: {
    label: string
    value: string
  }) => {
    if (
      !selectedLegalEntities.some((l) => l.value === legalEntityToAdd.value)
    ) {
      const selectedLegalEntitiesSet = new Set(selectedLegalEntities)
      selectedLegalEntitiesSet.add(legalEntityToAdd)
      const updatedSelectedLegalEntities = Array.from(selectedLegalEntitiesSet)
      setSelectedLegalEntities(updatedSelectedLegalEntities)
    }
  }

  const legalEntityTableOptions = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: true,
    sort: true,
    selectableRows: false,
    responsive: 'standard',
    elevation: 0,
    customRowRender: (data: any, dataIndex: number) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention, @typescript-eslint/no-shadow
      const [_1, _2, formattedAddress] = data
      const legalEntity = legalEntityNumberTolegalEntity[dataIndex]
      const legalEntityEntry = {
        label: legalEntity.name!,
        value: legalEntity.uuid!,
      }
      const isSelected = selectedLegalEntities.some(
        (l) => l.value === legalEntity.uuid
      )

      return (
        <TableRow>
          <AddTableCell
            onClick={() => {
              addLegalEntity(legalEntityEntry)
            }}
            style={{
              cursor: isSelected ? 'default' : 'pointer',
              backgroundColor: isSelected
                ? flockColors.darkGray
                : flockColors.green,
            }}
          >
            <Add />
          </AddTableCell>

          <TableCell>{formattedAddress}</TableCell>
        </TableRow>
      )
    },
    searchOpen: true,
  }

  const selectedLegalEntityTableOptions = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: true,
    responsive: 'standard',
    elevation: 0,
    customRowRender: (data: any) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow
      const [uuid, _, formattedAddress] = data
      return (
        <TableRow>
          <RemoveTableCell
            onClick={() => {
              removeLegalEntity(uuid)
            }}
            style={{ cursor: 'pointer' }}
          >
            <Remove />
          </RemoveTableCell>

          <TableCell>{formattedAddress}</TableCell>
        </TableRow>
      )
    },
  }

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'offerPrice',
      required: true,
      type: InputType.Text,
      gridItemProps: {
        sm: 6,
      },
      props: {
        label: 'Total Purchase Price',
        defaultValue: taskData.offerPrice,
        format: 'dollarsAndCents',
        placeholder: '$',
      },
    },
    {
      name: 'onboardingFee',
      type: InputType.Text,
      gridItemProps: {
        sm: 3,
      },
      props: {
        label: 'Onboarding Fee %',
        format: 'number',
      },
    },
    {
      name: 'isOverride',
      type: InputType.Checkbox,
      gridItemProps: {
        sm: 3,
      },
      props: {
        label: 'Override LE Deal Terms',
      },
    },
    {
      name: 'flexibleCashOverride',
      type: InputType.Text,
      renderIf: (watchedFields: any) => watchedFields.isOverride === true,
      required: true,
      gridItemProps: {
        sm: 4,
      },
      props: {
        label: 'Flexible Cash % Override',
        format: 'number',
      },
    },
    {
      name: 'holdingOverride',
      type: InputType.DatePicker,
      renderIf: (watchedFields: any) => watchedFields.isOverride === true,
      required: true,
      gridItemProps: {
        sm: 4,
      },
      props: {
        label: 'Holding Period Override',
      },
    },
    {
      name: 'placeholder',
      type: InputType.CustomComponent,
      renderIf: (watchedFields: any) => watchedFields.isOverride === true,
      gridItemProps: {
        sm: 4,
      },
      props: {},
    },
    {
      name: 'skipPropertyQuestionnaireTask',
      type: InputType.Checkbox,
      gridItemProps: {
        sm: 4,
      },
      props: {
        label: 'Skip Property Questionnaire Task',
      },
    },
    {
      name: 'suppressTaxInformation',
      type: InputType.Checkbox,
      gridItemProps: {
        sm: 4,
      },
      props: {
        label: 'Suppress Tax Information',
      },
    },
    {
      name: 'titleCompany',
      type: InputType.Dropdown,
      required: true,
      props: {
        label: 'Title Company',
        options: [
          {
            label: 'First American Title Insurance Company',
            value: 'First American Title Insurance Company',
          },
          {
            label: 'Blueprint Title Company',
            value: 'Blueprint Title Company',
          },
          {
            label: 'Prominent Escrow Services, Inc.',
            value: 'Prominent Escrow Services, Inc.',
          },
          {
            label: 'Endpoint Title, LLC',
            value: 'Endpoint Title, LLC',
          },
          {
            label: 'TitleVest Agency, LLC',
            value: 'TitleVest Agency, LLC',
          },
          {
            label: 'VanderVeur & Page',
            value: 'VanderVeur & Page',
          },
          {
            label: 'Cook & James LLC on behalf of Spruce Land Services LLC',
            value: 'Cook & James LLC on behalf of Spruce Land Services LLC',
          },
          {
            label: 'Hankin & Pack PLLC',
            value: 'Hankin & Pack PLLC',
          },
          {
            label: 'Hankin & Pack PLLC via Blueprint Title Company',
            value: 'Hankin & Pack PLLC via Blueprint Title Company',
          },
        ],
      },
    },
    {
      name: 'sellerEntities',
      required: true,
      type: InputType.Multiform,
      props: {
        title: 'Seller Entities',
        minForms: 1,
        incrementText: '+ Add Seller Entities',
        decrementText: '- Remove Seller Entities',
        inputConfigs: [
          {
            name: 'sellerEntityName',
            required: true,
            type: InputType.Text,
            props: {
              label: 'Seller Entity name',
            },
          },
          {
            name: 'sellerEntityUUID',
            required: true,
            type: InputType.Dropdown,
            props: {
              label: 'Legal Entity <> Seller Entity Association',
              options: selectedLegalEntities,
            },
          },
          {
            name: 'sellerEntityType',
            required: true,
            type: InputType.Dropdown,
            gridItemProps: {
              sm: 6,
            },
            props: {
              label: 'Seller Entity Type',
              options: [
                {
                  label: 'Individual',
                  value: 'individual',
                },
                {
                  label: 'LLC',
                  value: 'llc',
                },
                {
                  label: 'Corporation',
                  value: 'corporation',
                },
                {
                  label: 'Trust',
                  value: 'trust',
                },
              ],
            },
          },
          {
            name: 'sellerEntityState',
            type: InputType.Dropdown,
            required: true,
            gridItemProps: {
              sm: 6,
            },
            props: {
              label: 'Seller Entity State',
              options: ALL_STATES.map((state) => ({
                label: state,
                value: state,
              })),
            },
          },
          {
            name: 'authorizedSigners',
            required: true,
            type: InputType.Multiform,
            props: {
              title: 'Authorized Signers',
              minForms: 1,
              incrementText: '+ Add Authorized Signers',
              decrementText: '- Remove Authorized Signers',
              inputConfigs: [
                {
                  name: 'authorizedSignerName',
                  type: InputType.Text,
                  required: true,
                  props: {
                    label: 'Name',
                    format: 'fullName',
                    placeholder: 'First and last name',
                  },
                  gridItemProps: {
                    sm: 6,
                  },
                },
                {
                  name: 'authorizedSignerEmail',
                  type: InputType.Text,
                  required: true,
                  props: {
                    label: 'Email',
                    format: 'email',
                  },
                  gridItemProps: {
                    sm: 6,
                  },
                },
                {
                  name: 'authorizedSignerRelationToEntity',
                  type: InputType.Text,
                  required: true,
                  props: {
                    label: 'Relation to Entity',
                  },
                  gridItemProps: {
                    sm: 6,
                  },
                },
                {
                  name: 'authorizedSignerType',
                  required: true,
                  type: InputType.Dropdown,
                  gridItemProps: {
                    sm: 6,
                  },
                  props: {
                    label: 'Authorized Signer Type',
                    options: [
                      {
                        label: 'Individual',
                        value: 'individual',
                      },
                      {
                        label: 'LLC',
                        value: 'llc',
                      },
                      {
                        label: 'Corporation',
                        value: 'corporation',
                      },
                      {
                        label: 'Trust',
                        value: 'trust',
                      },
                    ],
                  },
                },
                {
                  name: 'authorizedSignerLegalEntityUUID',
                  required: true,
                  type: InputType.Dropdown,
                  gridItemProps: {
                    sm: 6,
                  },
                  props: {
                    label: 'Legal Entity <> Authorized Signer Association',
                    options: selectedLegalEntities,
                  },
                },
                {
                  name: 'authorizedSignerSkipCATask',
                  type: InputType.Checkbox,
                  gridItemProps: {
                    sm: 2,
                  },
                  props: {
                    label: 'Skip CA Task',
                  },
                },
                {
                  name: 'authorizedSignerSkipPersonalInfoTask',
                  type: InputType.Checkbox,
                  gridItemProps: {
                    sm: 2,
                  },
                  props: {
                    label: 'Skip Personal Info Task',
                  },
                },
                {
                  name: 'authorizedSignerIsPrimary',
                  type: InputType.Checkbox,
                  gridItemProps: {
                    sm: 2,
                  },
                  props: {
                    label: 'Is Primary Signer',
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      name: 'legalEntities',
      type: InputType.Multiform,
      props: {
        title: 'Legal Entities',
        minForms: 1,
        incrementText: '+ Add LE',
        decrementText: '- Remove LE',
        inputConfigs: [
          {
            name: 'legalEntityUUID',
            required: true,
            type: InputType.Dropdown,
            gridItemProps: {
              sm: 6,
            },
            props: {
              label: 'Legal Entity',
              options: selectedLegalEntities,
            },
          },
          {
            name: 'dealEquityOwnershipAmount',
            type: InputType.Text,
            required: true,
            props: {
              label: 'Deal Ownership Amount',
              format: 'dollarsAndCents',
              placeholder: '$',
            },
            gridItemProps: {
              sm: 6,
            },
          },
        ],
      },
    },
  ]
  // const loading = leadsLoading || legalEntitiesLoading
  const loading = legalEntitiesLoading
  if (loading) {
    return <LoadingCard text="Loading orders..." />
  }

  const onSubmit = async (result: any) => {
    // iterate through result.legalEntityParams and create a list of legal entities
    const legalEntitiesOrderParams: Core_CreateOrderV3Request_CreateOrderV3LegalEntityParamsInput[] =
      []

    result.legalEntities.forEach((legalEntity: any) => {
      legalEntitiesOrderParams.push({
        legalEntityUuid: legalEntity.legalEntityUUID,
        dealEquityOwnershipAmount: Number.parseFloat(
          legalEntity.dealEquityOwnershipAmount
        ),
      })
    })

    const sellerEntitiesOrderParams: Core_CreateOrderV3Request_CreateOrderV3SellerParamsInput[] =
      []

    result.sellerEntities.forEach((sellerEntity: any) => {
      const sellerEntityAuthorizedSigners: Core_CreateOrderV3Request_CreateOrderV3SellerParams_CreateOrderV3AuthorizedSignerParamsInput[] =
        []
      sellerEntity.authorizedSigners.forEach((authorizedSigner: any) => {
        sellerEntityAuthorizedSigners.push({
          name: authorizedSigner.authorizedSignerName,
          email: authorizedSigner.authorizedSignerEmail,
          type: authorizedSigner.authorizedSignerType,
          legalEntityUuid: authorizedSigner.authorizedSignerLegalEntityUUID,
          relationToEntity: authorizedSigner.authorizedSignerRelationToEntity,
          skipCaTask: authorizedSigner.authorizedSignerSkipCATask,
          skipPersonalInformationTask:
            authorizedSigner.authorizedSignerSkipPersonalInfoTask,
          isPrimary: authorizedSigner.authorizedSignerIsPrimary || false,
        })
      })

      sellerEntitiesOrderParams.push({
        legalEntityUuid: sellerEntity.sellerEntityUUID,
        type: sellerEntity.sellerEntityType,
        name: sellerEntity.sellerEntityName,
        legalEntityState: sellerEntity.sellerEntityState,
        authorizedSigners: sellerEntityAuthorizedSigners,
      })
    })

    const addressUuids: string[] = []
    Object.keys(taskData.addressUuidsWithLease).forEach((key: any) => {
      addressUuids.push(key)
    })

    const propertyContributionsOrderParams: Core_CreateOrderV3Request_CreateOrderV3PropertyContributionParamsInput[] =
      []
    addressUuids.forEach((addressUuid: string) => {
      const propertyContribution: Core_CreateOrderV3Request_CreateOrderV3PropertyContributionParamsInput =
        {
          addressUuid,
          addressUuidsWithLease: taskData.addressUuidsWithLease[addressUuid],
          titleCompany: result.titleCompany,
        }
      propertyContributionsOrderParams.push(propertyContribution)
    })

    try {
      const newOrder = await createOrderV3({
        variables: {
          createOrderV3Input: {
            offerPrice: Number.parseFloat(result.offerPrice),
            onboardingFee: result.onboardingFee
              ? Number.parseFloat(result.onboardingFee)
              : undefined,
            override: result.isOverride,
            flexibleCashOverride: result.flexibleCashOverride
              ? result.flexibleCashOverride
              : undefined,
            holdingOverride: result.holdingOverride,
            capitalExpenditure: taskData.capex,
            targetCloseDate: taskData.targetCloseDate,
            skipPropertyQuestionnaire: result.skipPropertyQuestionnaireTask,
            suppressTaxInformation: result.suppressTaxInformation,
            sellers: sellerEntitiesOrderParams,
            legalEntities: legalEntitiesOrderParams,
            propertyContributions: propertyContributionsOrderParams,
          },
        },
      })
      setOrder(newOrder.data!)
      setOrderConfirmationOpen(true)
      notify('Successfully Created Order', 'success')
    } catch (e) {
      notify(`Failed to create Order: ${e}.`, 'error')
    }
  }

  const testCA = async (result: any) => {
    // iterate through result.legalEntityParams and create a list of legal entities
    const legalEntitiesOrderParams: Core_CreateOrderV3Request_CreateOrderV3LegalEntityParamsInput[] =
      []

    result.legalEntities.forEach((legalEntity: any) => {
      legalEntitiesOrderParams.push({
        legalEntityUuid: legalEntity.legalEntityUUID,
        dealEquityOwnershipAmount: Number.parseFloat(
          legalEntity.dealEquityOwnershipAmount
        ),
      })
    })

    const sellerEntitiesOrderParams: Core_CreateOrderV3Request_CreateOrderV3SellerParamsInput[] =
      []

    result.sellerEntities.forEach((sellerEntity: any) => {
      const sellerEntityAuthorizedSigners: Core_CreateOrderV3Request_CreateOrderV3SellerParams_CreateOrderV3AuthorizedSignerParamsInput[] =
        []
      sellerEntity.authorizedSigners.forEach((authorizedSigner: any) => {
        sellerEntityAuthorizedSigners.push({
          name: authorizedSigner.authorizedSignerName,
          email: authorizedSigner.authorizedSignerEmail,
          type: authorizedSigner.authorizedSignerType,
          legalEntityUuid: authorizedSigner.authorizedSignerLegalEntityUUID,
          relationToEntity: authorizedSigner.authorizedSignerRelationToEntity,
          skipCaTask: authorizedSigner.authorizedSignerSkipCATask,
          skipPersonalInformationTask:
            authorizedSigner.authorizedSignerSkipPersonalInfoTask,
          isPrimary: authorizedSigner.authorizedSignerIsPrimary || false,
        })
      })

      sellerEntitiesOrderParams.push({
        legalEntityUuid: sellerEntity.sellerEntityUUID,
        type: sellerEntity.sellerEntityType,
        name: sellerEntity.sellerEntityName,
        legalEntityState: sellerEntity.sellerEntityState,
        authorizedSigners: sellerEntityAuthorizedSigners,
      })
    })

    const addressUuids: string[] = []
    Object.keys(taskData.addressUuidsWithLease).forEach((key: any) => {
      addressUuids.push(key)
    })

    const propertyContributionsOrderParams: Core_CreateOrderV3Request_CreateOrderV3PropertyContributionParamsInput[] =
      []
    addressUuids.forEach((addressUuid: string) => {
      const propertyContribution: Core_CreateOrderV3Request_CreateOrderV3PropertyContributionParamsInput =
        {
          addressUuid,
          addressUuidsWithLease: taskData.addressUuidsWithLease[addressUuid],
          titleCompany: result.titleCompany,
        }
      propertyContributionsOrderParams.push(propertyContribution)
    })

    try {
      const response = await createTestCA({
        variables: {
          createTestContributionAgreementInput: {
            offerPrice: Number.parseFloat(result.offerPrice),
            onboardingFee: result.onboardingFee
              ? Number.parseFloat(result.onboardingFee)
              : undefined,
            override: result.isOverride,
            flexibleCashOverride: result.flexibleCashOverride
              ? result.flexibleCashOverride
              : undefined,
            holdingOverride: result.holdingOverride,
            capitalExpenditure: taskData.capex,
            targetCloseDate: taskData.targetCloseDate,
            skipPropertyQuestionnaire: result.skipPropertyQuestionnaireTask,
            suppressTaxInformation: result.suppressTaxInformation,
            sellers: sellerEntitiesOrderParams,
            legalEntities: legalEntitiesOrderParams,
            propertyContributions: propertyContributionsOrderParams,
          },
        },
      })
      window.open(
        response.data?.createTestContributionAgreement?.presignedUrl as string,
        '_blank'
      )
      notify('Successfully Created Test CA', 'success')
    } catch (e) {
      notify(`Failed to create test CA: ${e}.`, 'error')
    }
  }

  return (
    <>
      <PageTitle variant="h1">
        Create Order Page: {taskData.orderName}
      </PageTitle>
      <Button
        variant="contained"
        onClick={() => navigate(NEW_CONTRIBUTIONS_URL)}
        sx={{ marginRight: '0.5rem' }}
      >
        Return to Contributions Queue
      </Button>
      <Typography variant="body1">
        Offer Price: {taskData.offerPrice}
      </Typography>
      <Typography variant="body1">Capex: {taskData.capex}</Typography>
      <Typography variant="body1">
        Close Date: {taskData.targetCloseDate}
      </Typography>
      {formattedLeadsData.map((lead: FormattedLeadsData) => (
        <Typography variant="body1">Lead: {lead.FormattedAddress}</Typography>
      ))}

      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: '1rem',
        }}
      >
        <Box display="flex" flexDirection="column" width="60%">
          <SectionTitleContainer>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h2">
                Search and Add Legal Entities
              </Typography>
              <CreateLegalEntityModal />
            </Box>
          </SectionTitleContainer>
          {loading ? (
            <LoadingCard text="Loading..." />
          ) : (
            <DataTable
              title=""
              data={legalEntities}
              columns={legalEntityColumns}
              options={legalEntityTableOptions as any}
            />
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <SectionTitleContainer>
            <Typography variant="h2">Selected Legal Entities</Typography>
          </SectionTitleContainer>
          {loading ? (
            <LoadingCard text="Loading..." />
          ) : (
            <DataTable
              title=""
              data={selectedLegalEntities}
              columns={selectedLegalEntityColumns}
              options={selectedLegalEntityTableOptions as any}
            />
          )}
        </Box>
      </Box>
      <Checkbox
        data-testid="isTestDownload"
        label="Test Contribution Agreement?"
        checked={isTestDownload}
        onChange={() => setIsTestDownload(!isTestDownload)}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          marginBottom: '1rem',
          marginTop: '2rem',
        }}
      >
        <ThemeProvider theme={flockTheme}>
          <Grid container>
            <GridForm
              onSubmit={isTestDownload ? testCA : onSubmit}
              inputConfigs={inputConfigs}
              loading={loading || createOrderLoading || createTestCALoading}
              ctaText={isTestDownload ? 'Test CA' : 'Submit'}
              gridProps={{
                spacing: 3,
              }}
              ctaBoxProps={{
                pb: '32px',
              }}
            />
          </Grid>
        </ThemeProvider>
      </Box>
      {orderConfirmationOpen && (
        <OrderConfirmationModal
          isOpen={orderConfirmationOpen}
          close={() => setOrderConfirmationOpen(false)}
          orderResponse={order}
        />
      )}
    </>
  )
}

export default CreateOrderPage
