import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const PlusIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M22.375 8H25.1875C25.4375 8 25.5625 8.11852 25.5625 8.35556V39.6444C25.5625 39.8815 25.4375 40 25.1875 40H22.375C22.125 40 22 39.8815 22 39.6444V8.35556C22 8.11852 22.125 8 22.375 8Z"
        fill={fillStyle || '#2B2E31'}
      />
      <path
        d="M8.37209 22H39.6279C39.876 22 40 22.125 40 22.375V25.1875C40 25.4375 39.876 25.5625 39.6279 25.5625H8.37209C8.12403 25.5625 8 25.4375 8 25.1875V22.375C8 22.125 8.12403 22 8.37209 22Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default PlusIcon
