import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'
import {
  AdminGetPropertyOccupancyDataDocument,
  AdminUpdateFinancingDocument,
  Core_PropertyFinancingDetail,
  Core_UpdatePropertyFinancingRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'

type UpdateFinancingParams = {
  legalHoldingEntity: string
  collateralOne: string
  collateralTwo: string
}

type UpdateFinancingModalParams = {
  financing: Core_PropertyFinancingDetail
  isOpen: boolean
  close: () => void
}

const UpdateFinancingModal = (props: UpdateFinancingModalParams) => {
  const { financing, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [updateFinancing, { loading }] = useMutation(
    AdminUpdateFinancingDocument
  )

  const onSubmit = async (updateFinancingParams: UpdateFinancingParams) => {
    const { legalHoldingEntity, collateralOne, collateralTwo } =
      updateFinancingParams

    const updatePropertyInput: Core_UpdatePropertyFinancingRequestInput = {
      financingParams: {
        uuid: financing.uuid,
        propertyUuid: financing.propertyUuid,
        legalHoldingEntity,
        collateralOne,
        collateralTwo,
      },
    }

    try {
      await updateFinancing({
        variables: {
          input: updatePropertyInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify('Successfully updated financing.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to update financing.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update Financing"
      inputConfigs={[
        {
          inputName: 'legalHoldingEntity',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Legal Holding Entity',
            fullWidth: true,
            defaultValue: financing.legalHoldingEntity,
          },
        },
        {
          inputName: 'collateralOne',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Collateral One',
            fullWidth: true,
            defaultValue: financing.collateralOne,
          },
        },
        {
          inputName: 'collateralTwo',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Collateral Two',
            fullWidth: true,
            defaultValue: financing.collateralTwo,
          },
        },
      ]}
    />
  )
}

export default UpdateFinancingModal
