import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import {
  AdminEditLegalEntityMutationDocument,
  AdminGetLegalEntitiesByInvestorAccountDocument,
  AdminGetLegalEntityDocument,
  AdminSearchLegalEntitiesDocument,
  Core_LegalEntity,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'
import ActionFormModal from '../shared/ActionFormModal'

type EditLegalEntityParams = {
  uuid: string
  name: string
  email: string
  phoneNumber: string
  cashFlowAllotment: string
  dateJoined: string
  holdingExpiryDate: string
}

type EditLegalEntityModalParams = {
  legalEntity: Core_LegalEntity | undefined
  isOpen: boolean
  close: () => void
}

const EditLegalEntityModal = (props: EditLegalEntityModalParams) => {
  const { legalEntity, isOpen, close } = props
  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [editLegalEntity, { loading }] = useMutation(
    AdminEditLegalEntityMutationDocument
  )

  const onSubmit = async (editLegalEntityParams: EditLegalEntityParams) => {
    const {
      name,
      email,
      phoneNumber,
      dateJoined,
      cashFlowAllotment,
      holdingExpiryDate,
    } = editLegalEntityParams
    const cashFlowMax = parseFloat(cashFlowAllotment)
    const editLegalEntityInput = {
      uuid: legalEntity?.uuid,
      name,
      email,
      phoneNumber,
      dateJoined,
      cashFlowAllotment: cashFlowMax,
      holdingExpiryDate:
        holdingExpiryDate && holdingExpiryDate !== ''
          ? holdingExpiryDate
          : null,
    }
    try {
      await editLegalEntity({
        variables: {
          editLegalEntityInput,
        },
        refetchQueries: [
          AdminGetLegalEntityDocument,
          AdminSearchLegalEntitiesDocument,
          AdminGetLegalEntitiesByInvestorAccountDocument,
        ],
      })
      notify('Successfully updated legal entity', 'success')
      close()
    } catch (e) {
      notify('Failed to update legal entity', 'error')
    }
  }
  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update Legal Entity"
      formProps={{
        defaultValues: {
          cashFlowAllotment: (legalEntity?.cashFlowAllotment || 0).toFixed(2),
        },
      }}
      inputConfigs={[
        {
          inputName: 'name',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Name',
            defaultValue: legalEntity?.name,
            fullWidth: true,
          },
        },
        {
          inputName: 'email',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Email',
            defaultValue: legalEntity?.email,
            fullWidth: true,
          },
        },
        {
          inputName: 'phoneNumber',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Phone Number',
            defaultValue: legalEntity?.phoneNumber,
            fullWidth: true,
          },
        },
        {
          inputName: 'dateJoined',
          inputType: InputType.DatePicker,
          props: {
            label: 'Date Joined',
            defaultValue: new Date(legalEntity?.dateJoined || Date()),
            fieldProps: {
              fullWidth: false,
            },
          },
        },
        {
          inputName: 'cashFlowAllotment',
          inputType: InputType.Text,
          props: {
            defaultValue: (legalEntity?.cashFlowAllotment || 0).toFixed(2),
            label: 'Cash Flow Allotment',
            pattern: /^(\d+)?(\.\d{1,2})?$/, // numbers to 2 decimal places
            fullWidth: false,
          },
        },
        {
          inputName: 'holdingExpiryDate',
          inputType: InputType.DatePicker,
          props: {
            label: 'Holding Expiry Date',
            defaultValue:
              legalEntity?.holdingExpiryDate &&
              legalEntity?.holdingExpiryDate !== '' &&
              legalEntity?.holdingExpiryDate !== '0001-01-01T00:00:00.000Z'
                ? new Date(legalEntity?.holdingExpiryDate)
                : '',
            fieldProps: {
              fullWidth: false,
            },
          },
        },
      ]}
    />
  )
}

export default EditLegalEntityModal
