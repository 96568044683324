import React from 'react'
import { navigate } from '@reach/router'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { formattedDollarsToCents } from '@flock/utils'
import { useMutation } from '@apollo/client'
import {
  AdminDeletePropertyDocument,
  AdminGetPropertyInfoDocument,
  AdminSearchPropertiesDocument,
  AdminUpdatePropertyDocument,
  Core_UpdatePropertyRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'
import { cleanTimeFromDatetime } from '../../utils'
import { DATA_LAKE_SEARCH_URL } from '../../constants'

type CreateDispositionParams = {
  dispositionDate: string
  dispositionNetProceedsCents: string
  dispositionPriceCents: string
}

type CreateDispositionModalParams = {
  propertyUuid: string
  propertyInfo: any
  isOpen: boolean
  close: () => void
}

const CreateDispositionModal = (props: CreateDispositionModalParams) => {
  const { propertyUuid, propertyInfo, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [updateProperty, { loading }] = useMutation(AdminUpdatePropertyDocument)
  const [deleteProperty, { loading: deleteLoading }] = useMutation(
    AdminDeletePropertyDocument
  )

  const onSubmit = async (updatePropertyParams: CreateDispositionParams) => {
    const {
      dispositionDate,
      dispositionNetProceedsCents,
      dispositionPriceCents,
    } = updatePropertyParams
    const updatePropertyInput: Core_UpdatePropertyRequestInput = {
      uuid: propertyUuid,
      isDisposed: true,
      dispositionDate: dispositionDate
        ? cleanTimeFromDatetime(dispositionDate)
        : undefined,
      dispositionNetProceedsCents: dispositionNetProceedsCents
        ? formattedDollarsToCents(dispositionNetProceedsCents)
        : undefined,
      dispositionPriceCents: dispositionPriceCents
        ? formattedDollarsToCents(dispositionPriceCents)
        : undefined,
    }

    try {
      await updateProperty({
        variables: {
          input: updatePropertyInput,
        },
        refetchQueries: [AdminGetPropertyInfoDocument],
      })
      await deleteProperty({
        refetchQueries: [AdminSearchPropertiesDocument],
        variables: {
          input: {
            propertyUuid,
          },
        },
      })
      notify('Successfully disposed property.', 'success')
      navigate(DATA_LAKE_SEARCH_URL)
      window.location.reload()
    } catch (e) {
      notify('Failed to delete property.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading || deleteLoading}
      onSubmit={onSubmit}
      actionText="Dispose Property"
      inputConfigs={[
        {
          inputName: 'dispositionDate',
          inputType: InputType.DatePicker,
          props: {
            label: 'Disposition Date',
            fieldProps: {
              fullWidth: true,
              defaultValue: propertyInfo?.dispositionDate,
            },
          },
        },
        {
          inputName: 'dispositionPriceCents',
          inputType: InputType.Text,
          props: {
            label: 'Disposition Price',
            placeholder: '$0.00',
            fullWidth: true,
            defaultValue: propertyInfo?.dispositionPriceCents,
          },
        },
        {
          inputName: 'dispositionNetProceedsCents',
          inputType: InputType.Text,
          props: {
            label: 'Disposition Net Proceeds',
            placeholder: '$0.00',
            fullWidth: true,
            defaultValue: propertyInfo?.dispositionNetProceedsCents,
          },
        },
      ]}
    />
  )
}

export default CreateDispositionModal
