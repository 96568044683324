import { SowAsset } from '../individualSowProjectPageTypes'

export const monthToText = (month: number): string => {
  switch (month) {
    case 0:
      return 'January'
    case 1:
      return 'February'
    case 2:
      return 'March'
    case 3:
      return 'April'
    case 4:
      return 'May'
    case 5:
      return 'June'
    case 6:
      return 'July'
    case 7:
      return 'August'
    case 8:
      return 'September'
    case 9:
      return 'October'
    case 10:
      return 'November'
    case 11:
      return 'December'
    default:
      return ''
  }
}

// Props that are used by the useAssetRow hook
export type AssetRowProps = SowAsset & {
  openEditAsset: (assetUuid: string) => void
}

// Props that are used by the presentational components (AssetRowDesktop.tsx, AssetRowMobile.tsx, AssetRowTablet.tsx)
export type AssetRowPresentationalProps = AssetRowProps & {
  remainingLife: string
  updateAsset: (status: string) => void
}
