import React from 'react'
import { useSnackbar } from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'
import {
  AdminCreatePropertyFromLeadDocument,
  AdminSearchPropertiesDocument,
  Core_CreatePropertyFromLeadRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { InputType, LibraryThemeProvider } from '@flock/shared-ui'
import { cleanTimeFromDatetime } from '../../utils'
import GridFormModal from '../shared/GridFormModal'

type CreatePropertyParams = {
  leadUuid: string
  closeDate: string
  transactionType: string
  salesforceAddressId: string
}

type CreatePropertyModalParams = {
  isOpen: boolean
  close: () => void
}

const CreatePropertyFromLeadModal = (props: CreatePropertyModalParams) => {
  const { isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createProperty, { loading }] = useMutation(
    AdminCreatePropertyFromLeadDocument
  )

  const onSubmit = async (createPropertyParams: CreatePropertyParams) => {
    const { leadUuid, transactionType, closeDate, salesforceAddressId } =
      createPropertyParams
    const createPropertyInput: Core_CreatePropertyFromLeadRequestInput = {
      closeDate: closeDate ? cleanTimeFromDatetime(closeDate) : undefined,
      transactionType,
      leadUuid,
      salesforceAddressId,
    }

    try {
      await createProperty({
        variables: {
          input: createPropertyInput,
        },
        refetchQueries: [AdminSearchPropertiesDocument],
      })
      notify('Successfully created property.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to create property.', 'error')
    }
  }

  return (
    <LibraryThemeProvider>
      <GridFormModal
        open={isOpen}
        loading={loading}
        title="Create Property From Lead"
        onClose={() => handleClose()}
        onSubmit={onSubmit}
        gridProps={{
          height: '300px',
          overflow: 'auto',
          spacing: 1,
        }}
        inputConfigs={[
          {
            name: 'closeDate',
            type: InputType.DatePicker,
            required: true,
            props: {
              label: 'Closing Date',
              fullWidth: true,
            },
          },
          {
            name: 'transactionType',
            type: InputType.Dropdown,
            required: true,
            props: {
              type: 'text',
              label: 'Transaction Type',
              fullWidth: true,
              options: [
                {
                  label: 'Contribution',
                  value: 'Contribution',
                },
                {
                  label: 'Purchase',
                  value: 'Purchase',
                },
                {
                  label: '1031 Replacement',
                  value: '1031 Replacement',
                },
              ],
            },
          },
          {
            name: 'leadUuid',
            type: InputType.Text,
            props: {
              type: 'text',
              label: 'Lead UUID',
              fullWidth: true,
            },
          },
          {
            name: 'salesforceAddressId',
            type: InputType.Text,
            props: {
              type: 'text',
              label: 'Salesforce Address ID',
              fullWidth: true,
            },
          },
        ]}
      />
    </LibraryThemeProvider>
  )
}

export default CreatePropertyFromLeadModal
