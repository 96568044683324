import React from 'react'
import {
  CloseIcon,
  flockTheme,
  GridForm,
  GridFormProps,
} from '@flock/shared-ui'
import {
  Box,
  IconButton,
  Modal,
  ThemeProvider,
  Typography,
} from '@mui/material'

type GridFormModalProps = GridFormProps & {
  title: string
  open: boolean
  onClose: () => void
}

const GridFormModal = (props: GridFormModalProps) => {
  const { title, open, onClose, ...gridFormProps } = props
  return (
    <ThemeProvider theme={flockTheme}>
      <Modal open={open} onClose={onClose}>
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            backgroundColor: '#ffffff',
            borderRadius: '24px',
            boxShadow: '0px 8px 24px 0px #45494D14',
            p: '32px',
          }}
        >
          <Typography variant="h3">{title}</Typography>
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: '32px', right: '32px' }}
          >
            <CloseIcon />
          </IconButton>
          <GridForm ctaText="Submit" {...gridFormProps} />
        </Box>
      </Modal>
    </ThemeProvider>
  )
}

export default GridFormModal
