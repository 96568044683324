import { useEffect, useState } from 'react'
import { PDFDocument } from 'pdf-lib'
import { CheckboxLabel, CollateralData } from '../printCollateralPageTypes'
import {
  CollateralRendererProps,
  CollateralRendererPresentationalProps,
  RenderStateMap,
  UpdateRenderStateParams,
} from './collateralRendererTypes'
import usePdfDownload from './usePdfDownload'

const useCollateralRenderer: (
  props: CollateralRendererProps
) => CollateralRendererPresentationalProps = (
  props: CollateralRendererProps
) => {
  const { onClose, collateralData } = props
  const { scenario, checkboxes, coverName, caTemplatesMap } =
    (collateralData as CollateralData) || {}
  const {
    offerPrice,
    mortgage,
    cashTakeout,
    submarketRentDeduction,
    onboardingFee,
    closingCosts,
    daysInRenoDeduction,
    brokerFee,
  } = scenario || {}
  const [renderStateMap, setRenderStateMap] = useState<RenderStateMap>({})
  const [totalPages, setTotalPages] = useState(0)

  const {
    doneGenerating,
    setDoneGenerating,
    refToPrint,
    batchStart,
    batchEnd,
    onStartDownload,
    downloading,
  } = usePdfDownload({
    filename: coverName!,
    totalPages,
    onDownloadComplete: onClose,
    caTemplatesMap,
    checkboxes,
  })

  useEffect(() => {
    const generateRenderStateMap = async () => {
      const newRenderStateMap: RenderStateMap = {}

      for (let i = 0; i < checkboxes.length; i += 1) {
        const checkbox = checkboxes[i]
        if (checkbox.checked) {
          if (checkbox.label === CheckboxLabel.VALUATION_COMPS) {
            const addressesLength = scenario.addresses?.length || 1
            newRenderStateMap[checkbox.label] = {
              doneRendering: new Array(addressesLength).fill(false),
              offset: new Array(addressesLength).fill(0),
              numPages: new Array(addressesLength).fill(0),
            }
          } else if (checkbox.label.startsWith('Contribution Agreement')) {
            const labelSplit = checkbox.label.split(' ')
            const state = labelSplit[labelSplit.length - 1]
            const caFile = caTemplatesMap[state]
            const fileBuffer = await caFile.arrayBuffer()
            const pdfDoc = await PDFDocument.load(fileBuffer)
            const numPages = pdfDoc.getPageCount()
            newRenderStateMap[checkbox.label] = {
              doneRendering: [true],
              offset: [0],
              numPages: [numPages],
            }
          } else if (checkbox.label !== CheckboxLabel.COVER) {
            newRenderStateMap[checkbox.label] = {
              doneRendering: [false],
              offset: [0],
              numPages: [0],
            }
          }
        }
      }
      setRenderStateMap(newRenderStateMap)
    }

    generateRenderStateMap()
  }, [JSON.stringify(checkboxes)])

  const updateRenderState = (params: UpdateRenderStateParams) => {
    setRenderStateMap((prevRenderStateMap) => {
      const { section, doneRendering, numPages, index } = params

      const newRenderStateMap = { ...prevRenderStateMap }
      newRenderStateMap[section].doneRendering[index || 0] = doneRendering
      newRenderStateMap[section].numPages[index || 0] = numPages || 0
      return newRenderStateMap
    })
  }

  const setRenderStatePageOffset = (
    renderStateMapKey: string,
    index: number,
    offset: number
  ) => {
    setRenderStateMap((prevRenderStateMap) => {
      const newRenderStateMap = { ...prevRenderStateMap }
      newRenderStateMap[renderStateMapKey].offset[index] = offset
      return newRenderStateMap
    })

    // The total number of pages is the offset for the last section plus the number of pages in
    // the last section
    const lastSection = Object.keys(renderStateMap).pop()
    const lastSectionOffset = renderStateMap[lastSection!].offset[0]
    const lastSectionNumPages = renderStateMap[lastSection!].numPages[0]
    setTotalPages(lastSectionOffset + lastSectionNumPages)
  }

  const feeModifier = 1 - 0.01 * onboardingFee! - 0.01 * brokerFee!
  const equityAmount = Math.round(
    Math.round(offerPrice! * feeModifier) -
      Math.round(offerPrice! * closingCosts!) -
      Math.round(mortgage!) -
      Math.round(submarketRentDeduction!) -
      Math.round(daysInRenoDeduction!) -
      Math.round(cashTakeout!)
  )

  return {
    ...props,
    renderStateMap,
    updateRenderState,
    setRenderStatePageOffset,
    equityAmount,

    doneGenerating,
    setDoneGenerating,

    batchStart,
    batchEnd,
    onStartDownload,
    downloading,
    refToPrint,
  }
}

export default useCollateralRenderer
