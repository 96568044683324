import React from 'react'
import { Core_PropertyWorkOrder } from '@flock/flock-gql-server/src/__generated__/graphql'
import { Box, Modal, Paper, styled, Typography } from '@mui/material'
import { formatCents, formatDateString } from '../InvestorManagement/utils'

const formatDateStringOrEmpty = (dateString: string | null | undefined) =>
  dateString ? formatDateString(dateString) : ''

const formatCentsOrEmpty = (cents: number | null | undefined) =>
  cents ? formatCents(cents) : ''

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type RowProps = {
  label: string
  value: string | number | undefined | null
}

const DataRow = (props: RowProps) => {
  const { label, value } = props
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          borderBottom: '1px dotted black',
        }}
      >
        <Typography variant="p2">{label}:</Typography>
        <Typography variant="p2">{value}</Typography>
      </Box>
    </>
  )
}

type DetailedWorkOrderModalParams = {
  workOrder: Core_PropertyWorkOrder
  isOpen: boolean
  close: () => void
}

const DetailedWorkOrderModal = (props: DetailedWorkOrderModalParams) => {
  const { workOrder, isOpen, close } = props

  const handleClose = () => {
    close()
  }

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalWrapper>
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="h3">Detailed Work Order Info </Typography>
            <DataRow label="Order Type" value={workOrder.orderType} />
            <DataRow label="Order Number" value={workOrder.orderNumber} />
            <DataRow
              label="Order Description"
              value={workOrder.orderDescription}
            />
            <DataRow
              label="Order Cost"
              value={formatCentsOrEmpty(workOrder.orderCostCents)}
            />
            <DataRow label="Order Status" value={workOrder.orderStatus} />
            <DataRow label="Order Vendor" value={workOrder.orderVendor} />
            <DataRow
              label="Order Creation Date"
              value={formatDateStringOrEmpty(workOrder.orderCreationDate)}
            />
            <DataRow label="Created By" value={workOrder.createdBy} />
            <DataRow
              label="Order Estimated Cost"
              value={formatCentsOrEmpty(workOrder.orderEstimatedCostCents)}
            />
            <DataRow
              label="Estimation Date"
              value={formatDateStringOrEmpty(workOrder.estimationDate)}
            />
            <DataRow
              label="Scheduled Start Date"
              value={formatDateStringOrEmpty(workOrder.scheduledStartDate)}
            />
            <DataRow
              label="Scheduled Completion Date"
              value={formatDateStringOrEmpty(workOrder.scheduledCompletionDate)}
            />
            <DataRow
              label="Work Start Date"
              value={formatDateStringOrEmpty(workOrder.workStartDate)}
            />
            <DataRow
              label="Work Completion Date"
              value={formatDateStringOrEmpty(workOrder.workCompletionDate)}
            />
            <DataRow
              label="Work Cancellation Date"
              value={formatDateStringOrEmpty(workOrder.workCancellationDate)}
            />
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default DetailedWorkOrderModal
