import {
  Core_OrderV3,
  Core_OrderV3Task,
  Core_OrderV3TaskKey,
  Core_OrderV3TaskSource,
  Core_OrderV3TaskStatus,
} from '@flock/flock-gql-server/src/__generated__/graphql'

type OrderV3TaskUserView = {
  [key: string]: {
    adminDescription: string
    customerDescription: string
    isSystemTask?: boolean
  }
}

export const orderV3TaskToUserView: OrderV3TaskUserView = {
  ORDER_V3_TASK_KEY_UNSPECIFIED: {
    adminDescription: 'Invalid or Outdated Task',
    customerDescription: '',
    isSystemTask: true,
  },

  ORDER_V3_TASK_KEY_CONTRIBUTION_AGREEMENT_PREPARED_AUTHORIZED_SIGNER: {
    adminDescription:
      'Automatically fill Contribution Agreement and surface to customer with link to sign via DocuSign.',
    customerDescription: '',
    isSystemTask: true,
  },
  ORDER_V3_TASK_KEY_CONTRIBUTION_AGREEMENT_SIGNED_AUTHORIZED_SIGNER: {
    adminDescription:
      'Confirm customer has signed contribution agreement in DocuSign.',
    customerDescription: 'Sign your contribution agreement.',
  },
  ORDER_V3_TASK_KEY_PERSONAL_INFORMATION_COMPLETED_AUTHORIZED_SIGNER: {
    adminDescription: 'Verify personal information completed',
    customerDescription: 'Complete your personal information.',
  },

  ORDER_V3_TASK_KEY_ONBOARDING_EMAIL_SENT_LEGAL_ENTITY: {
    adminDescription: 'Verify onboarding email completed',
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_SELLING_ENTITY_INFORMATION_COMPLETED_LEGAL_ENTITY: {
    adminDescription: 'Verify selling entity information completed',
    customerDescription: 'Complete your selling entity information.',
  },
  ORDER_V3_TASK_KEY_BASIC_INVESTOR_INFO_SUBMITTED_LEGAL_ENTITY: {
    adminDescription:
      '[Waiting For] Customer to start LP onboarding by providing some basic info.',
    customerDescription:
      'Get started with LP Onboarding by sharing your contact information.',
  },
  ORDER_V3_TASK_KEY_INVESTOR_QUALIFICATION_FORM_COMPLETED_LEGAL_ENTITY: {
    adminDescription:
      '[Waiting For] Customer to complete investor qualification statement',
    customerDescription:
      'Your next step of LP Onboarding is to fill out the Investor Qualification form.',
  },
  ORDER_V3_TASK_KEY_IDENTITY_AND_ACCREDITATION_VERIFICATION_REQUESTED_LEGAL_ENTITY:
    {
      adminDescription:
        '[Waiting For] Customer to complete Identity and Accreditation Verification',
      customerDescription:
        'Your next step of LP Onboarding is to go through Identity and Accreditation Verification.',
    },
  ORDER_V3_TASK_KEY_IDENTITY_AND_ACCREDITATION_VERIFICATION_COMPLETED_LEGAL_ENTITY:
    {
      adminDescription:
        '[Waiting For] Parallel Markets to complete Identity and Accreditation Verification',
      customerDescription:
        "We're completing your Identity and Accreditation Verification. For the next step, please review and sign the Subscription Booklet via the link in Pending Tasks below.",
    },
  ORDER_V3_TASK_KEY_SUBSCRIPTION_DOCUMENTS_SIGNATURE_REQUESTED_LEGAL_ENTITY: {
    adminDescription:
      'Automatically fill Subscription Booklet and email customer with link to sign via DocuSign.',
    customerDescription: '',
    isSystemTask: true,
  },
  ORDER_V3_TASK_KEY_SUBSCRIPTION_DOCUMENTS_SIGNED_LEGAL_ENTITY: {
    adminDescription:
      '[Waiting For] Customer to sign the Subscription Booklet in DocuSign.',
    customerDescription:
      'For the last piece of LP Onboarding, please review and sign the Subscription Booklet via DocuSign.',
  },
  ORDER_V3_TASK_KEY_SUBSCRIPTION_DOCUMENTS_SENT_LEGAL_ENTITY: {
    adminDescription:
      'Send the completed Subscription Booklet and Parallel Markets Identity and Accreditation documents to fund administrator for review.',
    customerDescription: '',
    isSystemTask: true,
  },
  ORDER_V3_TASK_KEY_SUBSCRIPTION_DOCUMENTS_COMPLETED_LEGAL_ENTITY: {
    adminDescription:
      "Confirm customer has completed the Subscription Documents with no outstanding issues from fund administrator's review.",
    customerDescription: '',
  },

  ORDER_V3_TASK_KEY_EARNEST_MONEY_WIRED_PROPERTY_CONTRIBUTION: {
    adminDescription: 'Wire earnest money to the title company.',
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_PROPERTY_QUESTIONNAIRE_COMPLETED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      'Customer has completed property questionnaire form with additional property information.',
    customerDescription:
      "Fill out the property questionnaire to share some information we'll need to manage the property.",
  },
  ORDER_V3_TASK_KEY_FINANCIAL_INFORMATION_COMPLETED_PROPERTY_CONTRIBUTION: {
    adminDescription: 'Customer has completed financial information form.',
    customerDescription:
      'Fill out the financial information related to the property.',
  },
  ORDER_V3_TASK_KEY_TAX_INFORMATION_COMPLETED_PROPERTY_CONTRIBUTION: {
    adminDescription: 'Customer has completed tax information form.',
    customerDescription:
      'Fill out the tax information related to the property.',
  },
  ORDER_V3_TASK_KEY_INSURANCE_CERTIFICATE_REQUESTED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      "Email Flock's insurance company a request to insure the property's address starting on the closing date.",
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_INSURANCE_CERTIFICATE_RECEIVED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      'Confirm insurance company sent a certificate of insurance.',
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_CUSTOMER_INSURANCE_CANCELLATION_SENT_PROPERTY_CONTRIBUTION:
    {
      adminDescription:
        'Email customer to remind them they can cancel their insurance starting on the closing date.',
      customerDescription: '',
    },
  ORDER_V3_TASK_KEY_INSPECTION_SCHEDULED_PROPERTY_CONTRIBUTION: {
    adminDescription: 'Schedule inspection.',
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_TITLE_COMMITMENT_REQUESTED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      'Email completed Contribution Agreement to the title company to initiate Title Commitment and Flock legal counsel for review.',
    customerDescription:
      'The title company is preparing the Title Commitment and will email it in the coming days.',
  },
  ORDER_V3_TASK_KEY_TITLE_COMMITMENT_SENT_PROPERTY_CONTRIBUTION: {
    adminDescription:
      'Confirm the title company has sent the Title Commitment to the customer.',
    customerDescription:
      'The title company is preparing the Title Commitment and will email it in the coming days.',
  },
  ORDER_V3_TASK_KEY_TITLE_COMMITMENT_REVIEWED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      "Review the Title Commitment with Flock's legal counsel as needed.",
    customerDescription:
      'You should have received a Title Commitment from the title company.',
  },
  ORDER_V3_TASK_KEY_CONTRIBUTION_AGREEMENT_SUBMITTED_TO_TITLE_PROPERTY_CONTRIBUTION:
    {
      adminDescription: 'Email/upload Contribution Agreement to title company.',
      customerDescription:
        'The title company will be reaching out via email to kick off the title process and collect information. Then title will work to prepare a clean transfer.',
    },
  ORDER_V3_TASK_KEY_SETTLEMENT_STATEMENT_COMPLETED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      'Work with title to ensure all requests and documents are completed.',
    customerDescription:
      'Flock will reach out once the settlement statement is ready for your review.',
  },
  ORDER_V3_TASK_KEY_TITLE_CLEAR_TO_CLOSE_PROPERTY_CONTRIBUTION: {
    adminDescription:
      'Confirm all outstanding requests are completed. CTC (clear to close)',
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_SETTLEMENT_STATEMENT_SIGNED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      '[Waiting for] Customer to review and sign settlement statement. Flock approves of settlement statement received from title company.',
    customerDescription:
      "Review the settlement statement sent by Flock and schedule a time to sign with the title company. If you're using Blueprint or Endpoint title, this may include reviewing your closing documents on their website.",
  },
  ORDER_V3_TASK_KEY_CLOSING_COMPLETED_PROPERTY_CONTRIBUTION: {
    adminDescription: '[Waiting For] Customer to schedule closing.',
    customerDescription: 'Schedule closing with the title company.',
  },
  ORDER_V3_TASK_KEY_GENERAL_WARRANTY_DEED_PREPARED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      'Confirm that the title company has prepared General Warranty Deed.',
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_BILL_OF_SALE_PREPARED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      'Confirm that the title company has prepared Bill of Sale.',
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_TITLE_AFFIDAVIT_PREPARED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      'Confirm that the title company has prepared Title Affadavit.',
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_OWNER_TITLE_INSURANCE_PREPARED_PROPERTY_CONTRIBUTION: {
    adminDescription:
      'Confirm that the title company has prepared Owner Title Insurance.',
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_LEASE_SENT_PROPERTY_CONTRIBUTION: {
    adminDescription: 'Email lease to the title company.',
    customerDescription: '',
  },
  ORDER_V3_TASK_KEY_TITLE_AUTHORIZATION_PREPARED_PROPERTY_CONTRIBUTION: {
    adminDescription: 'Title Prepared',
    customerDescription:
      'The title company is preparing the Title Commitment and will email it in the coming days.',
    isSystemTask: true,
  },
  ORDER_V3_TASK_KEY_TITLE_AUTHORIZATION_SIGNED_PROPERTY_CONTRIBUTION: {
    adminDescription: 'Title Signed',
    customerDescription:
      'The title company is preparing the Title Commitment and will email it in the coming days.',
  },
}

type FilterOrderV3TasksParams = {
  taskUuids?: string[]
  keys?: Core_OrderV3TaskKey[]
  sourceTypes?: Core_OrderV3TaskSource[]
  sourceUuids?: string[]
  statuses?: Core_OrderV3TaskStatus[]
}
// eslint-disable-next-line import/prefer-default-export
export const filterOrderV3Tasks = (
  order: Core_OrderV3,
  params: FilterOrderV3TasksParams
): Core_OrderV3Task[] => {
  const allTasks: Core_OrderV3Task[] = []

  const iterate = (obj: any) => {
    Object.keys(obj).forEach((key) => {
      if (key.endsWith('Process')) {
        allTasks.push(...obj[key])
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        iterate(obj[key])
      }
    })
  }

  iterate(order)

  const filteredTasks = allTasks.filter((task: Core_OrderV3Task) => {
    if (params.taskUuids && !params.taskUuids.includes(task.taskUuid)) {
      return false
    }
    if (params.keys && !params.keys.includes(task.key)) {
      return false
    }
    if (params.sourceTypes && !params.sourceTypes.includes(task.sourceType)) {
      return false
    }
    if (params.sourceUuids && !params.sourceUuids.includes(task.sourceUuid)) {
      return false
    }
    if (params.statuses && !params.statuses.includes(task.status)) {
      return false
    }

    return true
  })

  return filteredTasks
}
