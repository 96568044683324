import React from 'react'
import {
  useSnackbar,
  InputType,
  FileUploadFileType,
} from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'
import papa from 'papaparse'
import {
  AdminCreateInvestorUpdateDocument,
  AdminGetUpdatesDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { CREATE_BULK_UPDATE } from '../../graphql/mutations/legalEntity'
import ActionFormModal from '../shared/ActionFormModal'

type CreateUpdateParams = {
  title: string
  subtitle: string
  descriptionHtml: string
  tags: string
  sendUpdateEmail: boolean
  updateForASubset: boolean
  uploadedFile?: File[]
  legalEntityUuidsToUpdate: string[]
  investorAccountUuidsToUpdate: string[]
}

type CreateUpdateModalParams = {
  isOpen: boolean
  close: () => void
  investorAccountUuid?: string
  isBulk?: boolean
}

const GenericInputConfigs = [
  {
    inputName: 'title',
    inputType: InputType.Text,
    required: true,
    props: {
      label: 'Title',
      type: 'text',
      fullWidth: true,
    },
  },
  {
    inputName: 'subtitle',
    inputType: InputType.Text,
    required: true,
    props: {
      label: 'Subtitle',
      type: 'text',
      fullWidth: true,
    },
  },
  {
    inputName: 'descriptionHtml',
    inputType: InputType.Editor,
    required: true,
    props: {
      editorStyle: {
        border: 'solid #000',
        borderWidth: '0 1px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '5px',
      },
    },
  },
  {
    inputName: 'tags',
    inputType: InputType.Dropdown,
    required: true,
    props: {
      label: 'Tags',
      fullWidth: true,
      options: [
        {
          text: 'Info',
          value: 'INFO',
        },
        {
          text: 'Update',
          value: 'UPDATE',
        },
        {
          text: 'Tax',
          value: 'TAX',
        },
      ],
    },
  },
  {
    inputName: 'sendUpdateEmail',
    inputType: InputType.Checkbox,
    props: {
      label: 'Notify via Email',
    },
  },
]

const CreateUpdateModal = (props: CreateUpdateModalParams) => {
  const { investorAccountUuid, isBulk, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createUpdate, { loading }] = useMutation(
    AdminCreateInvestorUpdateDocument
  )
  const [createBulkUpdate, { loading: createBulkLoading }] =
    useMutation(CREATE_BULK_UPDATE)

  const onSubmit = async (result: CreateUpdateParams) => {
    const genericUpdateInput = result
    const { updateForASubset, uploadedFile } = result
    // strip html tags and turn update into string
    try {
      if (isBulk) {
        const createBulkUpdateInput = {
          ...genericUpdateInput,
        }
        if (updateForASubset) {
          const file = uploadedFile ? uploadedFile[0] : null
          if (file) {
            papa.parse(file, {
              header: true,
              complete: (results) => {
                const investorAccountUuids: string[] = (
                  results.data as { UUID: string }[]
                )
                  .filter((row) => row.UUID)
                  .map((row) => row.UUID)
                delete createBulkUpdateInput.uploadedFile
                createBulkUpdateInput.investorAccountUuidsToUpdate =
                  investorAccountUuids
                createBulkUpdate({
                  variables: {
                    createBulkUpdateInput,
                  },
                })
              },
            })
          }
        } else {
          await createBulkUpdate({
            variables: {
              createBulkUpdateInput,
            },
          })
        }
      } else {
        const input = {
          investorAccountUuid: investorAccountUuid as string,
          ...genericUpdateInput,
          tags: [genericUpdateInput.tags],
        }
        await createUpdate({
          variables: {
            input,
          },
          refetchQueries: [AdminGetUpdatesDocument],
        })
      }
      notify('Successfully created update', 'success')
      close()
    } catch (e) {
      notify('Failed to create update', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading || createBulkLoading}
      onSubmit={onSubmit}
      actionText="Create Investor Update"
      inputConfigs={
        isBulk
          ? [
              ...GenericInputConfigs,
              {
                inputName: 'updateForASubset',
                inputType: InputType.Checkbox,
                props: {
                  label: 'Update for a Subset',
                },
              },
              {
                inputName: 'uploadedFile',
                inputType: InputType.FileUpload,
                required: false,
                watchField: 'updateForASubset',
                props: {
                  label: 'Upload Investor Accounts Subset Csv',
                  accept: [FileUploadFileType.CSV],
                },
              },
            ]
          : GenericInputConfigs
      }
    />
  )
}

CreateUpdateModal.defaultProps = {
  isBulk: false,
  investorAccountUuid: null,
}

export default CreateUpdateModal
