import { jsPDF } from 'jspdf'
import { useEffect, useRef, useState } from 'react'
import generatePDF from 'react-to-pdf'
import { fetchImageToLocal, mergePdfs } from '../../../../utils'
import { SowAsset, SowItemDeficiency } from '../individualSowProjectPageTypes'
import {
  ExportModalProps,
  ExportModalPresentationalProps,
  SowPageConfig,
  FlattenedSowItemDeficiency,
  FlattenedSowAsset,
  SowPageAssetConfig,
  SowPageTOCConfig,
  TOCItem,
} from './exportModalTypes'

const MAX_PAGE_HEIGHT = 500
const BATCH_SIZE = 5
const PDF_SCALE = 4

// At a high level, we generate the PDF in 3 steps:
// 1. Generate the PDF for the deficiencies and assets
// 2. Generate the Table of Contents
// 3. Merge the PDFs together
// These are represented by a series of use effects that rely on re-rendering and checking the
// height of each page for overflow. If the page overflows, we move the last item to the next page.
// This is done for both the deficiencies and assets. Once the deficiencies and assets are completed,
// we then generate the table of contents. Once the table of contents is completed, we start generating
// the PDFs in batches. We have to generate in batches because react-to-pdf has a bug where it will not
// generate the PDF if there are too many pages (i.e around 20+ pages). Once all the PDFs are generated,
// we merge them together and download the final PDF.

const useExportModal: (
  props: ExportModalProps
) => ExportModalPresentationalProps = (props: ExportModalProps) => {
  const { sowProjects, formattedAddress, onClose } = props

  const [tocPageNumber, setTocPageNumber] = useState(0)
  const [tocRenderedItemsIndex, setTocRenderedItemsIndex] = useState(0)
  const [tocPageConfigs, setTocPageConfigs] = useState<SowPageTOCConfig[]>([])

  const [flattenedItems, setFlattenedItems] = useState<
    FlattenedSowItemDeficiency[]
  >([])
  const [pageNumber, setPageNumber] = useState(0)
  const [renderedItemsIndex, setRenderedItemsIndex] = useState(0)
  const [currentLocation, setCurrentLocation] = useState('')
  const [pageConfigs, setPageConfigs] = useState<SowPageConfig[]>([])

  const [assetPageNumber, setAssetPageNumber] = useState(0)
  const [assetRenderedItemsIndex, setAssetRenderedItemsIndex] = useState(0)
  const [assetCurrentLocation, setAssetCurrentLocation] = useState('')
  const [assetPageConfigs, setAssetPageConfigs] = useState<
    SowPageAssetConfig[]
  >([])
  const [flattenedAssets, setFlattenedAssets] = useState<FlattenedSowAsset[]>(
    []
  )

  const [deficienciesCompleted, setDeficienciesCompleted] = useState(false)
  const [assetsCompleted, setAssetsCompleted] = useState(false)
  const [tocCompleted, setTocCompleted] = useState(false)

  const [coverPhotoURL, setCoverPhotoURL] = useState('')

  const [batchToPrint, setBatchToPrint] = useState(-1)
  const [batchStart, setBatchStart] = useState(-9999)
  const [batchEnd, setBatchEnd] = useState(9999)
  const [downloading, setDownloading] = useState(false)

  const [builtPDFs, setBuiltPDFs] = useState<jsPDF[]>([])
  const [downloadComplete, setDownloadComplete] = useState(false)

  const targetRef = useRef<HTMLDivElement | null>()

  const onUpdateFiles = (files: File[]) => {
    if (files.length) {
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = () => {
        setCoverPhotoURL(reader.result as string)
      }
    }
  }

  const replaceS3URLs = async (
    items: FlattenedSowItemDeficiency[] | FlattenedSowAsset[]
  ) => {
    const updatedItems = [...items]
    const promises: Promise<{
      uuid: string
      localUrl: string
    }>[] = []

    items.forEach(async (item) => {
      item.media.forEach(async (media) => {
        const { uuid, s3Url } = media
        const searchObjPromise = fetchImageToLocal(s3Url).then((localUrl) => ({
          uuid,
          localUrl,
        }))
        promises.push(searchObjPromise)
      })
    })

    const resolvedSearchObj = await Promise.all(promises)
    resolvedSearchObj.forEach((searchObj) => {
      for (let i = 0; i < updatedItems.length; i += 1) {
        const item = updatedItems[i]
        const newMedia = item.media.map((media) => {
          if (media.uuid === searchObj.uuid) {
            return { ...media, s3Url: searchObj.localUrl }
          }
          return media
        })
        item.media = newMedia
      }
    })

    return updatedItems
  }

  useEffect(() => {
    const newFlattenedItems: FlattenedSowItemDeficiency[] = []
    sowProjects.forEach((sowProject) => {
      sowProject.deficiencies.forEach((deficiency) => {
        newFlattenedItems.push({
          ...deficiency,
          unitName: sowProject.projectName,
        })
      })
    })
    // Sort the flattened items by unit and then by location
    newFlattenedItems.sort((a, b) => {
      if (a.unitName < b.unitName) {
        return -1
      }
      if (a.unitName > b.unitName) {
        return 1
      }
      if (a.location < b.location) {
        return -1
      }
      if (a.location > b.location) {
        return 1
      }
      return 0
    })

    const newFlattenedAssets: FlattenedSowAsset[] = []
    sowProjects.forEach((sowProject) => {
      sowProject.assets.forEach((asset) => {
        newFlattenedAssets.push({
          ...asset,
          unitName: sowProject.projectName,
        })
      })
    })

    // Sort the flattened assets by unit and then by location
    newFlattenedAssets.sort((a, b) => {
      if (a.unitName < b.unitName) {
        return -1
      }
      if (a.unitName > b.unitName) {
        return 1
      }
      if (a.location < b.location) {
        return -1
      }
      if (a.location > b.location) {
        return 1
      }
      return 0
    })

    replaceS3URLs(newFlattenedItems).then((updatedFlattenedItems) => {
      replaceS3URLs(newFlattenedAssets).then((updatedFlattenedAssets) => {
        setPageNumber(0)
        setRenderedItemsIndex(0)
        setCurrentLocation('')
        setPageConfigs([])
        setFlattenedItems(updatedFlattenedItems as FlattenedSowItemDeficiency[])
        setDeficienciesCompleted(false)

        setAssetPageNumber(0)
        setAssetRenderedItemsIndex(0)
        setAssetCurrentLocation('')
        setAssetPageConfigs([])
        setFlattenedAssets(updatedFlattenedAssets as FlattenedSowAsset[])
        setAssetsCompleted(false)

        setTocPageNumber(0)
        setTocRenderedItemsIndex(0)
        setTocPageConfigs([])

        setBatchStart(-9999)
        setBatchEnd(9999)

        setDownloading(false)
        setDownloadComplete(false)
      })
    })
  }, [JSON.stringify(sowProjects)])

  // This useEffect is responsible for rendering the items into the pageConfigs
  // It will render the items into the pageConfigs until the page height is greater than the MAX_PAGE_HEIGHT
  // If the page height is greater than the MAX_PAGE_HEIGHT, it will move the last item to the next page
  useEffect(() => {
    if (renderedItemsIndex >= flattenedItems.length && flattenedItems.length) {
      setDeficienciesCompleted(true)
      return
    }

    if (flattenedItems.length === 0) {
      return
    }

    // Get current page's height
    const currentPageHeight = document.getElementById(
      `page-${currentLocation}-${pageNumber}`
    )?.clientHeight
    let newRenderedItemsIndex = renderedItemsIndex
    const newPageConfigs = [...pageConfigs]

    if (currentPageHeight && currentPageHeight > MAX_PAGE_HEIGHT) {
      // Create a new page for the same location
      const itemToMoveToNextPage = newPageConfigs[pageNumber].items.pop()
      setPageNumber(pageNumber + 1)
      setPageConfigs([
        ...newPageConfigs,
        {
          location: currentLocation,
          pageNumber: pageNumber + 1,
          items: [itemToMoveToNextPage as SowItemDeficiency],
          continued: true,
        },
      ])
    } else if (flattenedItems[renderedItemsIndex]) {
      // If the item's location is different from the current location, create a new page
      const currentItem = flattenedItems[renderedItemsIndex]
      const newLocation =
        sowProjects.length > 1
          ? `${currentItem.unitName} | ${currentItem.location}`
          : currentItem.location

      if (newPageConfigs.length === 0) {
        newPageConfigs.push({
          location: newLocation,
          pageNumber: 0,
          items: [currentItem],
          continued: false,
        })
        setCurrentLocation(newLocation)
        setPageConfigs(newPageConfigs)
      } else if (newLocation !== currentLocation) {
        // Create a new page since it's a new location
        setCurrentLocation(newLocation)
        setPageNumber(pageNumber + 1)
        setPageConfigs([
          ...newPageConfigs,
          {
            location: newLocation,
            pageNumber: pageNumber + 1,
            items: [currentItem],
            continued: false,
          },
        ])
      } else {
        // Add to the current page
        newPageConfigs[pageNumber].items.push(currentItem)
        setPageConfigs(newPageConfigs)
      }
      newRenderedItemsIndex += 1
    }
    setRenderedItemsIndex(newRenderedItemsIndex)
  }, [JSON.stringify(pageConfigs), JSON.stringify(flattenedItems)])

  // Same as above but for assets
  useEffect(() => {
    if (
      assetRenderedItemsIndex >= flattenedAssets.length &&
      flattenedAssets.length
    ) {
      setAssetsCompleted(true)
      return
    }

    if (flattenedAssets.length === 0) {
      return
    }

    const currentPageHeight = document.getElementById(
      `page-asset-${assetCurrentLocation}-${assetPageNumber}`
    )?.clientHeight
    let newAssetRenderedItemsIndex = assetRenderedItemsIndex
    const newAssetPageConfigs = [...assetPageConfigs]

    if (currentPageHeight && currentPageHeight > MAX_PAGE_HEIGHT) {
      const itemToMoveToNextPage =
        newAssetPageConfigs[assetPageNumber].items.pop()
      setAssetPageNumber(assetPageNumber + 1)
      setAssetPageConfigs([
        ...newAssetPageConfigs,
        {
          location: assetCurrentLocation,
          pageNumber: assetPageNumber + 1,
          items: [itemToMoveToNextPage as SowAsset],
          continued: true,
        },
      ])
    } else if (flattenedAssets[assetRenderedItemsIndex]) {
      const currentItem = flattenedAssets[assetRenderedItemsIndex]
      const newLocation =
        sowProjects.length > 1 ? `${currentItem.unitName} | Assets` : 'Assets'

      if (newAssetPageConfigs.length === 0) {
        newAssetPageConfigs.push({
          location: newLocation,
          pageNumber: 0,
          items: [currentItem],
          continued: false,
        })
        setAssetCurrentLocation(newLocation)
        setAssetPageConfigs(newAssetPageConfigs)
      } else if (newLocation !== assetCurrentLocation) {
        setAssetCurrentLocation(newLocation)
        setAssetPageNumber(assetPageNumber + 1)
        setAssetPageConfigs([
          ...newAssetPageConfigs,
          {
            location: newLocation,
            pageNumber: assetPageNumber + 1,
            items: [currentItem],
            continued: false,
          },
        ])
      } else {
        newAssetPageConfigs[assetPageNumber].items.push(currentItem)
        setAssetPageConfigs(newAssetPageConfigs)
      }
      newAssetRenderedItemsIndex += 1
    }
    setAssetRenderedItemsIndex(newAssetRenderedItemsIndex)
  }, [JSON.stringify(assetPageConfigs), JSON.stringify(flattenedAssets)])

  useEffect(() => {
    if (deficienciesCompleted && assetsCompleted) {
      // After the assets and deficiencies are completed, generate the table of contents
      const markedPageConfigs = pageConfigs.map((config) => ({
        ...config,
        isAsset: false,
      }))
      const markedAssetPageConfigs = assetPageConfigs.map((config) => ({
        ...config,
        isAsset: true,
      }))
      const combinedPageConfigs = [
        ...markedPageConfigs,
        ...markedAssetPageConfigs,
      ]

      const flattenedPageConfigs = combinedPageConfigs.filter(
        (config) => !config.continued
      )

      if (
        tocRenderedItemsIndex >= flattenedPageConfigs.length &&
        flattenedPageConfigs.length
      ) {
        setTocCompleted(true)
        return
      }

      if (flattenedPageConfigs.length === 0) {
        return
      }

      const currentPageHeight = document.getElementById(
        `page-toc-${tocPageNumber}`
      )?.clientHeight

      let newTocRenderedItemsIndex = tocRenderedItemsIndex
      const newTocPageConfigs = [...tocPageConfigs]

      if (currentPageHeight && currentPageHeight > MAX_PAGE_HEIGHT) {
        const itemToMoveToNextPage =
          newTocPageConfigs[tocPageNumber].items.pop()
        setTocPageNumber(tocPageNumber + 1)
        setTocPageConfigs([
          ...newTocPageConfigs,
          {
            pageNumber: tocPageNumber + 1,
            items: [itemToMoveToNextPage as TOCItem],
          },
        ])
      } else if (flattenedPageConfigs[tocRenderedItemsIndex]) {
        const currentItem = flattenedPageConfigs[tocRenderedItemsIndex]

        if (newTocPageConfigs.length === 0) {
          newTocPageConfigs.push({
            pageNumber: 0,
            items: [currentItem],
          })
          setTocPageConfigs(newTocPageConfigs)
        } else {
          newTocPageConfigs[tocPageNumber].items.push(currentItem)
          setTocPageConfigs(newTocPageConfigs)
        }
        newTocRenderedItemsIndex += 1
      }
      setTocRenderedItemsIndex(newTocRenderedItemsIndex)
    }
  }, [JSON.stringify(tocPageConfigs), assetsCompleted, deficienciesCompleted])

  const onStartDownload = () => {
    setBatchStart(0)
    setBatchEnd(BATCH_SIZE)
    setBatchToPrint(-1)
    setDownloading(true)
  }

  const generatePDFBatch = async () => {
    if (downloading) {
      const totalPages =
        2 + pageConfigs.length + assetPageConfigs.length + tocPageConfigs.length

      if (batchStart <= totalPages) {
        // If the batchToPrint is -1, it means that the PDF generation has not started,
        // otherwise, download the currently displayed PDF
        if (batchToPrint !== -1) {
          const builtPDF = await generatePDF(targetRef, {
            filename: `${formattedAddress.replace(
              /[^a-zA-Z0-9]/g,
              '_'
            )}-${batchToPrint}.pdf`,
            page: {
              margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: (12.7 * PDF_SCALE) / 4,
              },
              format: [(215.9 * PDF_SCALE) / 4, (279.4 * PDF_SCALE) / 4],
            },
            canvas: {},
            method: 'build',
            overrides: {
              canvas: {
                scale: PDF_SCALE,
              },
            },
          })

          setBuiltPDFs([...builtPDFs, builtPDF])
        }
        const newBatchToPrint = batchToPrint + 1

        const newBatchStart = newBatchToPrint * BATCH_SIZE
        const newBatchEnd = newBatchStart + BATCH_SIZE
        setBatchStart(newBatchStart)
        setBatchEnd(newBatchEnd)
        setBatchToPrint(newBatchToPrint)
      } else {
        setDownloadComplete(true)
      }
    } else {
      setDownloading(false)
      setBatchToPrint(-1)
      setBatchStart(-9999)
      setBatchEnd(9999)
      setDownloadComplete(false)
    }
  }

  const handleDownload = async () => {
    const arrayBuffers = builtPDFs.map((pdf) => pdf.output('arraybuffer'))
    const mergedPdf = await mergePdfs(arrayBuffers)
    const blob = new Blob([mergedPdf], { type: 'application/pdf' })
    // Download the blob
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${formattedAddress.replace(/[^a-zA-Z0-9]/g, '_')}.pdf`
    a.click()
    onClose()
  }

  // If TOC completed, start generating the PDF in batches. We have to generate in batches
  // because react-to-pdf has a bug where it will not generate the PDF if there are too many pages
  // (i.e around 20+ pages)
  useEffect(() => {
    generatePDFBatch()
  }, [downloading, batchToPrint])

  // Stitch the PDFs together
  useEffect(() => {
    if (downloadComplete) {
      handleDownload()
    }
  }, [downloadComplete])

  const doneGenerating =
    deficienciesCompleted && assetsCompleted && tocCompleted

  return {
    ...props,
    tocPageConfigs,
    pageConfigs,
    assetPageConfigs,
    refToPrint: targetRef,
    doneGenerating,
    onUpdateFiles,
    coverPhotoURL,
    batchStart,
    batchEnd,

    downloading,

    onStartDownload,
  }
}

export default useExportModal
