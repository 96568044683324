import React from 'react'

import { Edit, Delete } from '@mui/icons-material'
import {
  Box,
  Typography,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Table,
} from '@mui/material'
import { Download } from 'phosphor-react'
import useAccountDocumentsTable from './useAccountDocumentsTable'
import { AccountDocumentsTableProps } from './accountDocumentsTableTypes'
import EditAccountDocumentModal from './EditAccountDocumentModal'
import DeleteAccountDocumentModal from './DeleteAccountDocumentModal'

const AccountDocumentsTable = (props: AccountDocumentsTableProps) => {
  const presentationalProps = useAccountDocumentsTable(props)

  const {
    title,
    documents,
    accountId,
    editModalOpen,
    setEditModalOpen,
    deleteModalOpen,
    setDeleteModalOpen,
    selectedDocument,
    setSelectedDocument,
  } = presentationalProps

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Typography variant="h4">{title}</Typography>
      <Table>
        <TableHead>
          <TableCell width="512px">Name</TableCell>
          <TableCell>Actions</TableCell>
        </TableHead>
        <TableBody>
          {documents.map((document) => {
            const { uuid, name, contentUrl } = document
            return (
              <TableRow key={uuid}>
                <TableCell>{name}</TableCell>
                <TableCell>
                  <Box display="flex" gap="16px">
                    <IconButton
                      onClick={() => window.open(contentUrl!, '_blank')}
                    >
                      <Download />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedDocument(document)
                        setEditModalOpen(true)
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedDocument(document)
                        setDeleteModalOpen(true)
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <EditAccountDocumentModal
        accountId={accountId}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        selectedDocument={selectedDocument!}
      />
      <DeleteAccountDocumentModal
        accountId={accountId}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        selectedDocument={selectedDocument!}
      />
    </Box>
  )
}

export default AccountDocumentsTable
