import React from 'react'
import { formatDollarsAndCents, formatPercentage } from '@flock/utils'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Core_ValuationType } from '@flock/flock-gql-server/src/__generated__/graphql'
import useValuationSummary from './useValuationSummary'
import ValuationTrailModal from '../../ValuationTrailModal/ValuationTrailModal'
import SubmitValuationModal from '../../SubmitValuationModal/SubmitValuationModal'
import { ValuationSummaryProps } from './valuationSummaryTypes'
import { getHoldPeriod } from '../../valuationUtils'

const ValuationSummary = (props: ValuationSummaryProps) => {
  const {
    computedValuationResult,
    operatorUuid,
    leadUuid,
    canSubmitValuation,
    openValuationHistoryModal,
    openRejectValuationModal,
    selfReportedValue,
  } = props
  const {
    downloadInvestmentMemo,
    valuationTrailOpen,
    setValuationTrailOpen,
    comparativeAnalysisRequired,
    submitValuationModalOpen,
    setSubmitValuationModalOpen,
    finalOfferPriceOutsideThreshold,
    offerThresholdErrorMessage,
    valuationTypeString,
    investmentMemoLoading,
  } = useValuationSummary(props)
  let nonImmediateCapex =
    computedValuationResult?.outputs?.remodelCosts?.totalAddressableCapex || 0
  let immediateCapex =
    computedValuationResult?.outputs?.remodelCosts?.immediateAddressableCapex ||
    0
  if (
    computedValuationResult?.outputs?.remodelCosts?.immediateAddressableCapex &&
    computedValuationResult?.outputs?.remodelCosts?.totalAddressableCapex
  ) {
    nonImmediateCapex =
      computedValuationResult.outputs.remodelCosts.totalAddressableCapex -
      computedValuationResult.outputs.remodelCosts.immediateAddressableCapex
  }
  if (
    computedValuationResult?.outputs?.remodelCosts?.immediateAddressableCapex &&
    computedValuationResult?.outputs?.remodelCosts?.healthAndSafetyCapex
  ) {
    immediateCapex =
      computedValuationResult.outputs.remodelCosts.immediateAddressableCapex -
      computedValuationResult.outputs.remodelCosts.healthAndSafetyCapex
  }
  return (
    <Box display="flex" flexDirection="column" p="16px" gap="8px">
      <Typography variant="h3">Valuation Summary</Typography>
      {computedValuationResult && (
        <>
          <Box display="flex" flexDirection="column">
            <Typography variant="p2">
              NOI Margin:{' '}
              {formatPercentage(
                computedValuationResult.outputs?.noiMarginAverage || 0,
                2
              )}
            </Typography>
            <Typography variant="p2">
              Days in Remodel Deduction:{' '}
              {formatDollarsAndCents(
                computedValuationResult.outputs?.daysInRemodelDeduction || 0,
                true
              )}
            </Typography>
            <Typography variant="p2">
              Submarket Rent Deduction:{' '}
              {formatDollarsAndCents(
                computedValuationResult.outputs?.submarketRentDeduction || 0,
                true
              )}
            </Typography>
            <Typography variant="p2">
              Property Tax:{' '}
              {formatDollarsAndCents(
                computedValuationResult.propertyInput?.otherCosts
                  ?.propertyTaxes || 0,
                true
              )}
            </Typography>
            <Accordion>
              <AccordionSummary
                sx={{
                  margin: '0px',
                  marginTop: '-8px',
                  marginBottom: '-8px',
                  padding: '0px',
                  borderBottom: 'none',
                  '.MuiAccordionSummary-content': {
                    margin: '0px',
                    fontSize: '1rem',
                  },
                }}
                expandIcon={
                  <ExpandMore sx={{ height: '16px', width: '16px' }} />
                }
              >
                <>
                  Total Capex{' '}
                  {formatDollarsAndCents(
                    computedValuationResult.outputs?.remodelCosts
                      ?.totalAddressableCapex || 0,
                    true
                  )}
                </>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: '0px', pb: '8px' }}>
                <Typography variant="p2">
                  Health and Safety Capex:{' '}
                  {formatDollarsAndCents(
                    computedValuationResult.outputs?.remodelCosts
                      ?.healthAndSafetyCapex || 0,
                    true
                  )}
                </Typography>
              </AccordionDetails>
              <AccordionDetails sx={{ pt: '0px', pb: '8px' }}>
                <Typography variant="p2">
                  Immediate Capex: {formatDollarsAndCents(immediateCapex, true)}
                </Typography>
              </AccordionDetails>
              <AccordionDetails sx={{ pt: '0px', pb: '8px' }}>
                <Typography variant="p2">
                  Non-immediate Capex:{' '}
                  {formatDollarsAndCents(nonImmediateCapex, true)}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Typography variant="p2">
              HOA Fee:{' '}
              {formatDollarsAndCents(
                computedValuationResult.propertyInput?.otherCosts?.hoa || 0,
                true
              )}
            </Typography>
            <Typography variant="p2">
              MSA Level Minimum:{' '}
              {formatPercentage(
                computedValuationResult.outputs?.msaLevelMinimums || 0,
                2
              )}
            </Typography>
            <Typography variant="p2">
              MSA Offer Price:{' '}
              {formatDollarsAndCents(
                computedValuationResult.outputs
                  ?.unconstrainedMsaYieldMinOfferPrice || 0
              )}
            </Typography>
            {finalOfferPriceOutsideThreshold && (
              <Typography variant="p1" color="red">
                Self-Reported Value:{' '}
                {formatDollarsAndCents(selfReportedValue || 0)}
              </Typography>
            )}
            <Typography
              variant="p1"
              color={
                computedValuationResult.outputs?.finalOfferPrice ===
                computedValuationResult.outputs?.netYieldAdjustedOfferPrice
                  ? 'green'
                  : 'red'
              }
            >
              Offer Price:{' '}
              {computedValuationResult.outputs?.pass
                ? formatDollarsAndCents(
                    computedValuationResult.outputs?.finalOfferPrice
                  )
                : undefined}
            </Typography>
            <Typography variant="p1">
              Current Cash on Cash Yield:{' '}
              {formatPercentage(
                computedValuationResult.outputs?.uwCashOnCashYield
                  ?.currentUwCashOnCashYield || 0,
                2
              )}
            </Typography>
            <Typography variant="p1">
              Customer Cash on Cash Yield:{' '}
              {formatPercentage(
                computedValuationResult.outputs?.uwCashOnCashYield
                  ?.customerUwCashOnCashYield || 0,
                2
              )}
            </Typography>
            <Typography variant="p1">
              Hold period:{' '}
              {getHoldPeriod(
                computedValuationResult.outputs?.uwCashOnCashYield
                  ?.customerUwCashOnCashYield || 0
              )}
            </Typography>
            <Typography variant="p2">
              Gross Yield:{' '}
              {formatPercentage(
                computedValuationResult.outputs?.grossYield || 0,
                2
              )}
            </Typography>
            <Typography variant="p2">
              Net Yield:{' '}
              {formatPercentage(
                computedValuationResult.outputs?.netYield || 0,
                2
              )}
            </Typography>
          </Box>
          <Typography
            variant="p1"
            sx={{
              color: comparativeAnalysisRequired ? 'red' : 'green',
            }}
          >
            Action:{' '}
            {comparativeAnalysisRequired
              ? offerThresholdErrorMessage
              : `Proceed with ${valuationTypeString} valuation`}
          </Typography>
          {computedValuationResult.type ===
            Core_ValuationType.ValuationTypeFinal && (
            <Button
              size="smallForm"
              variant="secondary"
              disabled={investmentMemoLoading}
              onClick={() => {
                downloadInvestmentMemo()
              }}
            >
              {investmentMemoLoading ? (
                <CircularProgress size="24px" />
              ) : (
                'Create Investment Memo'
              )}
            </Button>
          )}
          {computedValuationResult?.valuationTrail && (
            <Button
              variant="secondary"
              onClick={() => setValuationTrailOpen(true)}
              size="smallForm"
            >
              View Valuation Trail
            </Button>
          )}
          {computedValuationResult?.valuationTrail && (
            <ValuationTrailModal
              open={valuationTrailOpen}
              onClose={() => setValuationTrailOpen(false)}
              valuation={computedValuationResult}
              valuationTrailData={computedValuationResult?.valuationTrail}
            />
          )}
          <SubmitValuationModal
            open={submitValuationModalOpen}
            onClose={() => setSubmitValuationModalOpen(false)}
            operatorUuid={operatorUuid}
            leadUuid={leadUuid}
            valuationUuid={computedValuationResult?.uuid}
            finalOfferPrice={
              computedValuationResult?.outputs?.finalOfferPrice || 0
            }
            canSubmitValuation={canSubmitValuation}
            valuationTypeString={valuationTypeString}
          />
          <Button
            onClick={() => setSubmitValuationModalOpen(true)}
            size="smallForm"
            disabled={comparativeAnalysisRequired}
          >
            Submit Valuation
          </Button>
        </>
      )}
      <Button
        variant="contained"
        size="smallForm"
        onClick={openValuationHistoryModal}
      >
        View Valuation History
      </Button>
      <Button
        variant="contained"
        size="smallForm"
        onClick={openRejectValuationModal}
      >
        Reject Valuation
      </Button>
    </Box>
  )
}

export default ValuationSummary
