import React, { useState } from 'react'
import {
  Typography,
  Form,
  DataTable,
  useSnackbar,
  CircularProgress,
  ConfirmModal,
  OpacityLink,
  FileUploadFileType,
  InputType,
} from '@flock/flock-component-library'
import { styled } from '@mui/material/styles'
import { TableRow, TableCell, Button } from '@mui/material'
import papa from 'papaparse'
import { useMutation, useQuery } from '@apollo/client'
import { formatIntegerCents } from '@flock/utils'
import fileDownload from 'js-file-download'
import * as Sentry from '@sentry/gatsby'
import { RouteComponentProps } from '@reach/router'
import {
  AdminBulkCreateQuarterEndTransactionsV2Document,
  AdminDeleteStagedTransactionsDocument,
  AdminGetAllInvestmentsInfoDocument,
  AdminGetQuarterEndProcessTemplateUrlDocument,
  AdminGetStagedInvestmentTransactionInfoDocument,
  AdminInitiatePaymentDocument,
  AdminPublishStagedTransactionsDocument,
  Core_InvestmentTransaction,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import StagedInvestmentTransactionRowRender, {
  stagedInvestmentTransactionColumns,
} from './StagedInvestmentTransactionRowRender'

const PageContainer = styled('div')({
  minWidth: '70vw',
})

const CustomPaymentRowRender = (data: [string, string, string]) => {
  const [legalEntityUuid, legalEntityName, distributionAmount] = data
  return (
    <TableRow>
      <TableCell>{legalEntityUuid}</TableCell>
      <TableCell>{legalEntityName}</TableCell>
      <TableCell>{distributionAmount}</TableCell>
    </TableRow>
  )
}

const CustomFailedPaymentRowRender = (
  data: [string, string, string, string]
) => {
  const [legalEntityUuid, legalEntityName, distributionAmount, errorMessage] =
    data
  return (
    <TableRow>
      <TableCell>{legalEntityUuid}</TableCell>
      <TableCell>{legalEntityName}</TableCell>
      <TableCell>{distributionAmount}</TableCell>
      <TableCell>{errorMessage}</TableCell>
    </TableRow>
  )
}

const stagedTableOptions = {
  filter: false,
  download: true,
  print: false,
  viewColumns: false,
  search: true,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender: StagedInvestmentTransactionRowRender,
  elevation: 0,
}

const options = {
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender: CustomPaymentRowRender,
  elevation: 0,
}

const TitleButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '0.75rem',
})

const ACHResultSectionContainer = styled('div')({
  marginBottom: '3rem',
})

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
})

const SubHeaderContainer = styled('div')({
  marginTop: '1rem',
  marginBottom: '1rem',
  paddingLeft: '3rem',
})

const UploadContainer = styled('div')({
  width: '800px',
})

type SubmitPaymentSpreadsheetResult = {
  uploadedFile: File[]
}
type QuarterEndTransactionInput = {
  uploadedFile: File[]
  quarter: number
  year: number
  newSharePriceInput: string
}

const columns = [
  { name: 'legalEntityUuid', label: 'Legal Entity UUID' },
  { name: 'legalEntityName', label: 'Legal Entity Name' },
  { name: 'distributionAmount', label: 'Distribution Amount' },
]

type PaymentRow = {
  legalEntityUuid: string
  legalEntityName: string
  distributionAmount: string
}

const failedPaymentColumns = [
  { name: 'legalEntityUuid', label: 'Legal Entity UUID' },
  { name: 'legalEntityName', label: 'Legal Entity Name' },
  { name: 'distributionAmount', label: 'Distribution Amount' },
  { name: 'errorMessage', label: 'Error' },
]

const FileUploadWrapper = styled('div')({
  width: '100%',
  maxHeight: '75vh',
  marginBottom: '5rem',
})

const PaymentTableWrapper = styled('div')({
  width: '100%',
})

enum DistributionsStep {
  Upload,
  GenerateACH,
  ViewACHResult,
  Loading,
  Error,
}

const currTime = new Date()

type UploadAndParseDistributionsProps = {
  onSubmit: (result: SubmitPaymentSpreadsheetResult) => void
}

type QuarterEndTransactionInputProps = {
  onSubmit: (result: QuarterEndTransactionInput) => void
}

const UploadAndParseDistributions = ({
  onSubmit,
}: UploadAndParseDistributionsProps) => (
  <FileUploadWrapper>
    <Form
      onSubmit={onSubmit}
      ctaText="Parse Cash Flow"
      inputConfigs={[
        {
          inputName: 'desc',
          inputType: InputType.NonInput,
          props: {
            component: (
              <Typography variant="body2">
                Upload a CSV file with three columns: legal_entity_uuid,
                legal_entity_name, and distribution_amount.
              </Typography>
            ),
          },
        },
        {
          inputName: 'uploadedFile',
          inputType: InputType.FileUpload,
          props: {
            accept: FileUploadFileType.CSV,
            label: 'Cash Flow Spreadsheet',
          },
        },
      ]}
    />
  </FileUploadWrapper>
)

type GenerateACHStepProps = {
  paymentRows: PaymentRow[]
  initiatePayment: () => void
}
const GenerateACHStep = ({
  paymentRows,
  initiatePayment,
}: GenerateACHStepProps) => (
  <PaymentTableWrapper>
    <Typography variant="body2">
      Confirm the amounts and generate the ACH File. Once generated, this file
      can be uploaded to the First Republic website under their Nacha Validator.
    </Typography>
    <DataTable
      title=""
      data={paymentRows}
      columns={columns}
      options={options as any}
    />
    <Button
      sx={{ width: '100%', justifyContent: 'center' }}
      variant="contained"
      color="primary"
      onClick={initiatePayment}
    >
      Generate ACH File
    </Button>
  </PaymentTableWrapper>
)

type ViewACHResultStepProps = {
  achPaymentFailedInputs: any[]
  achPaymentFileDownloadUrl: string
  achPaymentAmountCents: number
  paymentRows: PaymentRow[]
}

const ViewACHResultStep = ({
  achPaymentFailedInputs,
  achPaymentFileDownloadUrl,
  achPaymentAmountCents,
  paymentRows,
}: ViewACHResultStepProps) => {
  const errorsByLegalEntityUuids = achPaymentFailedInputs.reduce(
    (oldPreviousValue: any, currentValue: any) => {
      const previousValue = oldPreviousValue
      previousValue[currentValue.input.legalEntityUuid] =
        currentValue.errorMessage
      return previousValue
    },
    {}
  )

  const successfulAndFailed = paymentRows.reduce(
    (previousValue, currentValue) => {
      if (errorsByLegalEntityUuids[currentValue.legalEntityUuid]) {
        previousValue.failed.push({
          ...currentValue,
          errorMessage: errorsByLegalEntityUuids[currentValue.legalEntityUuid],
        })
      } else {
        previousValue.successful.push(currentValue)
      }

      return previousValue
    },
    { successful: [], failed: [] } as { successful: any[]; failed: any[] }
  )

  // generate meaningful file name for success / failure csvs
  let fileName: string
  try {
    // assumes something like https://path/to/file/fileName.txt?whatever=parameters"
    const x = achPaymentFileDownloadUrl.split('/')
    // eslint-disable-next-line prefer-destructuring
    fileName = x[x.length - 1].split('.txt')[0]
  } catch {
    Sentry.captureException(
      new Error(
        'Assumption for ACH file name broken, please fix if this has changed'
      ),
      {}
    )
    // usable format for admins: 05022022-11:17:46-AM
    fileName = new Date()
      .toLocaleString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      })
      .split(',')
      .join('')
      .split('/')
      .join('')
      .split(' ')
      .join('-')
  }

  const successfulCsv = papa.unparse(successfulAndFailed.successful)
  const failedCsv = papa.unparse(successfulAndFailed.failed)
  const successfulCsvDownload = () =>
    fileDownload(successfulCsv, `successful-distributions-${fileName}.csv`)
  const failedCsvDownload = () =>
    fileDownload(failedCsv, `successful-distributions-${fileName}.csv`)

  return (
    <>
      <ACHResultSectionContainer>
        <TitleButtonContainer>
          <Typography variant="h2">ACH Generation Result</Typography>
          <Button
            variant="contained"
            onClick={() => {
              window.open(achPaymentFileDownloadUrl, '_blank')
            }}
          >
            Download ACH File
          </Button>
        </TitleButtonContainer>
      </ACHResultSectionContainer>
      <ACHResultSectionContainer>
        <TitleButtonContainer>
          <Typography variant="h3">
            Successful: {formatIntegerCents(achPaymentAmountCents)}
          </Typography>
          <Button variant="outlined" onClick={successfulCsvDownload}>
            Download Successful Distributions
          </Button>
        </TitleButtonContainer>
        <Typography variant="body2">
          These investors are captured in the ACH file that should have
          automatically downloaded.
        </Typography>
        <DataTable
          title=""
          data={successfulAndFailed.successful}
          columns={columns}
          options={options as any}
        />
      </ACHResultSectionContainer>
      <ACHResultSectionContainer>
        <TitleButtonContainer>
          <Typography variant="h3">Failed</Typography>
          <Button variant="outlined" onClick={failedCsvDownload}>
            Download Failed Distributions
          </Button>
        </TitleButtonContainer>
        <Typography variant="body2">
          These investors may require wire payments for this round of
          distributions unless the errors can be resolved and we re-run the
          payment generation.
        </Typography>
        <DataTable
          title=""
          data={successfulAndFailed.failed}
          columns={failedPaymentColumns}
          options={
            {
              ...options,
              customRowRender: CustomFailedPaymentRowRender as any,
            } as any
          }
        />
      </ACHResultSectionContainer>
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DistributionsPage = (_: RouteComponentProps) => {
  const [paymentRows, setPaymentRows] = useState<PaymentRow[]>([])
  const [step, setStep] = useState<DistributionsStep>(DistributionsStep.Upload)
  const [achPaymentFileDownloadUrl, setAchPaymentFileDownloadUrl] = useState('')
  const [achPaymentAmountCents, setAchPaymentAmountCents] = useState(0)
  const [achPaymentFailedInputs, setAchPaymentFailedInputs] = useState<any[]>(
    []
  )
  const [bulkCreateQuarterEndTransactionsV2] = useMutation(
    AdminBulkCreateQuarterEndTransactionsV2Document
  )
  const [
    publishStagedTransactionsModalOpen,
    setPublishStagedTransactionsModalOpen,
  ] = useState(false)
  const [
    deleteStagedTransactionsModalOpen,
    setDeleteStagedTransactionsModalOpen,
  ] = useState(false)

  const { notify } = useSnackbar()

  const {
    loading: stagedInvestmentTransactionsLoading,
    data: stagedInvestmentTransactionsData,
  } = useQuery(AdminGetStagedInvestmentTransactionInfoDocument, {
    onError: () => {
      notify('Failed to get staged investment transactions', 'error')
    },
  })

  const stagedInvestmentTransactions: any[] =
    stagedInvestmentTransactionsData?.getStagedInvestmentTransactionInfo
      ?.investmentTransactions || []

  const { refetch } = useQuery(AdminGetQuarterEndProcessTemplateUrlDocument, {
    skip: true,
    onError: () => {
      notify('Failed to get quarter end process template csv s3 url', 'error')
    },
  })

  const getQuarterEndProcessTemplateUrl = async () => {
    try {
      const urlData = await refetch()
      const s3Url = urlData?.data?.quarterEndProcessTemplateUrl?.url as string
      window.open(s3Url, '_self')
    } catch (e) {
      console.log(e)
      notify(
        'An error occurred while downloading the document. Please refresh or try again.',
        'error'
      )
    }
  }

  const UploadAndParseQuarterEndTransactions = ({
    onSubmit,
  }: QuarterEndTransactionInputProps) => (
    <FileUploadWrapper>
      <Form
        onSubmit={onSubmit}
        ctaText="Upload Quarter End Transactions"
        inputConfigs={[
          {
            inputName: 'uploadedFile',
            inputType: InputType.FileUpload,
            props: {
              accept: FileUploadFileType.CSV,
              label: 'Quarter End Process Template Spreadsheet',
            },
          },
          {
            inputName: 'quarter',
            inputType: InputType.Dropdown,
            required: true,
            props: {
              type: 'number',
              label: 'Quarter',
              fullWidth: false,
              options: [
                {
                  text: 'Quarter 1',
                  value: 1,
                },
                {
                  text: 'Quarter 2',
                  value: 2,
                },
                {
                  text: 'Quarter 3',
                  value: 3,
                },
                {
                  text: 'Quarter 4',
                  value: 4,
                },
              ],
            },
          },
          {
            inputName: 'year',
            inputType: InputType.Text,
            required: true,
            props: {
              type: 'number',
              label: 'Year',
              placeholder: currTime.getFullYear(),
              fullWidth: false,
            },
          },
          {
            inputName: 'newSharePriceInput',
            inputType: InputType.Text,
            required: true,
            props: {
              type: 'text',
              label: 'New Share Price',
              placeholder: '$0.00',
              fullWidth: false,
            },
          },
        ]}
      />
    </FileUploadWrapper>
  )

  const uploadAndParseDistributions = async (
    result: SubmitPaymentSpreadsheetResult
  ) => {
    const { uploadedFile } = result
    const file = uploadedFile ? uploadedFile[0] : null
    if (file) {
      papa.parse(file, {
        header: true,
        complete: (results) => {
          const parsedPaymentRows: PaymentRow[] = results.data
            .map((row: any) => ({
              legalEntityUuid: row.legal_entity_uuid,
              legalEntityName: row.legal_entity_name,
              distributionAmount: row.distribution_amount,
            }))
            .filter(
              (row: PaymentRow) =>
                row.legalEntityUuid ||
                row.legalEntityName ||
                row.distributionAmount
            ) // remove totally empty rows
          setPaymentRows(parsedPaymentRows)
          setStep(DistributionsStep.GenerateACH)
        },
      })
    }
  }

  const uploadAndParseQuarterEndTransactions = async (
    result: QuarterEndTransactionInput
  ) => {
    const { uploadedFile, quarter, year, newSharePriceInput } = result
    const file = uploadedFile ? uploadedFile[0] : null
    if (file) {
      const bulkCreateQuarterEndTransactionsV2Input = {
        file,
        quarter,
        year,
        newSharePriceInput,
      }
      try {
        const data = await bulkCreateQuarterEndTransactionsV2({
          variables: {
            input: bulkCreateQuarterEndTransactionsV2Input as any,
          },
          refetchQueries: [AdminGetStagedInvestmentTransactionInfoDocument],
        })

        const s3Url = data?.data?.bulkCreateQuarterEndTransactionsV2
        if (s3Url === '') {
          notify('Successfully staged investment transactions', 'success')
        } else {
          notify(
            'Parsing error with uploaded CSV, error file downloaded',
            'error'
          )
          window.open(s3Url as string, '_self')
        }
      } catch (e) {
        notify(
          'Failed to stage investment transactions unrelated to csv parsing, please reach out to eng',
          'error'
        )
      }
    }
  }

  const [initiateDistributionsPayment] = useMutation(
    AdminInitiatePaymentDocument
  )

  const initiatePayment = async () => {
    setStep(DistributionsStep.Loading)
    try {
      const initiatePaymentParams = paymentRows.map((payment) => ({
        legalEntityUuid: payment.legalEntityUuid,
        amountCents: Math.round(parseFloat(payment.distributionAmount) * 100), // string to int: '98.76' -> 9876
      }))
      let tempAchFileDownloadUrl
      let amountCentsToPay
      let failedInputs
      try {
        const { data } = await initiateDistributionsPayment({
          variables: {
            initiatePaymentInput: { initiatePaymentParams },
          },
        })
        tempAchFileDownloadUrl = data?.initiatePayment?.tempAchFileDownloadUrl
        amountCentsToPay = data?.initiatePayment?.amountCentsToPay
        failedInputs = data?.initiatePayment?.failedInputs || []
      } catch (e) {
        setStep(DistributionsStep.Upload)
        notify('Failed to initiate distribution payment', 'error')
        return
      }

      window.open(tempAchFileDownloadUrl as string, '_blank') // download the file and effectively stay on the same page
      setAchPaymentFileDownloadUrl(tempAchFileDownloadUrl as string)
      setAchPaymentAmountCents(amountCentsToPay as number)
      setAchPaymentFailedInputs(failedInputs)
      setStep(DistributionsStep.ViewACHResult)
      notify('Successfully generated ACH file for distributions', 'success')
    } catch (e) {
      setStep(DistributionsStep.Error)
      notify('Failed to initiate distribution payment', 'error')
    }
  }

  let DisplayStep
  switch (step) {
    case DistributionsStep.Loading:
      DisplayStep = () => <CircularProgress />
      break
    case DistributionsStep.Upload:
      DisplayStep = () => (
        <>
          <UploadAndParseDistributions onSubmit={uploadAndParseDistributions} />
        </>
      )
      break
    case DistributionsStep.GenerateACH:
      DisplayStep = () => (
        <GenerateACHStep
          paymentRows={paymentRows}
          initiatePayment={initiatePayment}
        />
      )
      break
    case DistributionsStep.ViewACHResult:
      DisplayStep = () => (
        <ViewACHResultStep
          achPaymentFailedInputs={achPaymentFailedInputs}
          achPaymentFileDownloadUrl={achPaymentFileDownloadUrl}
          achPaymentAmountCents={achPaymentAmountCents}
          paymentRows={paymentRows}
        />
      )
      break
    default:
      DisplayStep = () => (
        <>
          Error: If you were unable to initiate the payment, please refresh the
          page and try again. Let Engineering know if the issue persists!
        </>
      )
  }

  const [deleteStagedTransactions] = useMutation(
    AdminDeleteStagedTransactionsDocument
  )
  const [publishStagedTransactions] = useMutation(
    AdminPublishStagedTransactionsDocument
  )

  type StagedTransactionsModalParams = {
    isOpen: boolean
    close: () => void
  }

  const PublishStagedTransactionsModal = (
    props: StagedTransactionsModalParams
  ) => {
    const { isOpen, close } = props
    const handleClose = () => {
      close()
    }

    const onSubmit = async () => {
      try {
        await publishStagedTransactions({
          refetchQueries: [
            AdminGetStagedInvestmentTransactionInfoDocument,
            AdminGetAllInvestmentsInfoDocument,
          ],
        })
        notify('Successfully published quarter end transactions.', 'success')
        close()
      } catch (e) {
        notify('Failed to publish quarter end transactions.', 'error')
      }
    }

    return (
      <ConfirmModal
        title="Publish Staged Transactions"
        content="This action will affect the investor portal"
        open={isOpen}
        onClose={handleClose}
        onSubmit={onSubmit}
      />
    )
  }

  const DeleteStagedTransactionsModal = (
    props: StagedTransactionsModalParams
  ) => {
    const { isOpen, close } = props
    const handleClose = () => {
      close()
    }

    const onSubmit = async () => {
      try {
        await deleteStagedTransactions({
          refetchQueries: [
            AdminGetStagedInvestmentTransactionInfoDocument,
            AdminGetAllInvestmentsInfoDocument,
          ],
        })
        notify('Successfully unstaged quarter end transactions.', 'success')
        close()
      } catch (e) {
        notify('Failed to unstage quarter end transactions.', 'error')
      }
    }

    return (
      <ConfirmModal
        title="Delete Staged Transactions"
        content="Confirm deletion of staged transactions"
        open={isOpen}
        onClose={handleClose}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <PageContainer>
      <br />

      <PageTitleContainer>
        <Typography variant="h1"> Quarter End Processes</Typography>
      </PageTitleContainer>
      <PageTitleContainer>
        <Typography variant="h2">Quarter End Transactions</Typography>
      </PageTitleContainer>

      <SubHeaderContainer>
        <Typography variant="h3">
          Step 1: Download and fill out the Quarter End Transactions template
        </Typography>
        <SubHeaderContainer>
          <Typography variant="body2">
            Download the&nbsp;
            <OpacityLink onClick={getQuarterEndProcessTemplateUrl}>
              Quarter End Process Template here.
            </OpacityLink>
          </Typography>
        </SubHeaderContainer>
        <br />
        <Typography variant="h3">
          Step 2: Upload the filled out template
        </Typography>
        <SubHeaderContainer>
          <UploadContainer>
            <UploadAndParseQuarterEndTransactions
              onSubmit={uploadAndParseQuarterEndTransactions}
            />
          </UploadContainer>
        </SubHeaderContainer>
        <Typography variant="h3">
          Step 3: Verify and publish staged quarter end investment transactions
        </Typography>
        {stagedInvestmentTransactionsLoading ? (
          <CircularProgress size={32} />
        ) : (
          <DataTable
            title=""
            data={stagedInvestmentTransactions as Core_InvestmentTransaction[]}
            columns={stagedInvestmentTransactionColumns}
            options={stagedTableOptions as any}
          />
        )}
        <br />
        <SubHeaderContainer>
          <Button
            variant="contained"
            onClick={() => setDeleteStagedTransactionsModalOpen(true)}
            sx={{ marginLeft: '0.5rem' }}
          >
            Delete Staged Transactions
          </Button>
          <Button
            variant="contained"
            onClick={() => setPublishStagedTransactionsModalOpen(true)}
            sx={{ marginLeft: '0.5rem' }}
          >
            Publish Staged Transactions
          </Button>
        </SubHeaderContainer>
      </SubHeaderContainer>

      <DeleteStagedTransactionsModal
        isOpen={deleteStagedTransactionsModalOpen}
        close={() => setDeleteStagedTransactionsModalOpen(false)}
      />
      <PublishStagedTransactionsModal
        isOpen={publishStagedTransactionsModalOpen}
        close={() => setPublishStagedTransactionsModalOpen(false)}
      />

      <PageTitleContainer>
        <Typography variant="h2">Wire Cash Flow</Typography>
      </PageTitleContainer>
      <UploadContainer>
        <DisplayStep />
      </UploadContainer>
    </PageContainer>
  )
}

export default DistributionsPage
