import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import {
  AdminCreateAddressDocumentDocument,
  AdminCreateOrUpdateAddressDetailsDocument,
  Core_ValidatedAddress,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'

import { Typography } from '@mui/material'
import ActionFormModal from '../shared/ActionFormModal'

type LeadReassignmentProps = {
  address: Core_ValidatedAddress
  open: boolean
  onClose: () => void
}

const AddLeaseAndTenantInfoModal = (props: LeadReassignmentProps) => {
  const { address, open, onClose } = props
  const { notify } = useSnackbar()

  const [
    createOrUpdateAddressDetails,
    { loading: createOrUpdateAddressDetailsLoading },
  ] = useMutation(AdminCreateOrUpdateAddressDetailsDocument)
  const [createAddressDocument, { loading: createAddressDocumentLoading }] =
    useMutation(AdminCreateAddressDocumentDocument)

  const loading =
    createOrUpdateAddressDetailsLoading || createAddressDocumentLoading
  if (!address) {
    onClose()
  }

  const inputConfigs = [
    {
      inputName: 'currentLeaseFile',
      inputType: InputType.FileUpload,
      props: {
        label: 'Please upload the current lease agreement.',
      },
    },
    {
      inputName: 'tenantInformation',
      inputType: InputType.MultiForm,
      props: {
        label: 'Tenant Information',
        minForms: 1,
        inputConfigs: [
          {
            inputName: 'tenantName',
            inputType: InputType.Text,
            props: {
              label: 'Name',
              type: 'text',
            },
          },
          {
            inputName: 'tenantEmail',
            inputType: InputType.Text,
            props: {
              label: 'Email',
              type: 'email',
            },
          },
          {
            inputName: 'tenantPhone',
            inputType: InputType.Phone,
            props: {
              label: 'Phone Number',
            },
          },
        ],
        incrementText: 'Add Additional Tenant',
        decrementText: 'Remove Tenant',
      },
    },
    {
      inputName: 'leaseStartDate',
      inputType: InputType.DatePicker,
      props: {
        label: 'Lease Start Date',
        defaultValue: new Date(),
      },
    },
    {
      inputName: 'leaseEndDate',
      inputType: InputType.DatePicker,
      props: {
        label: 'Lease End Date',
        defaultValue: new Date(),
      },
    },
    {
      inputName: 'monthlyRent',
      inputType: InputType.Text,
      props: {
        label: 'Monthly Rent',
        type: 'money',
      },
    },
    {
      inputName: 'utilitiesPaidByTenant',
      inputType: InputType.Radio,
      required: true,
      props: {
        label: 'Utilities Paid by Tenant?',
        noDefault: true,
        options: [
          {
            text: 'No',
            value: 'N',
          },
          {
            text: 'Yes',
            value: 'Y',
          },
        ],
      },
    },
    {
      inputName: 'utilitiesAmount',
      inputType: InputType.Text,
      watchField: 'utilitiesPaidByTenant',
      renderOn: 'Y',
      props: {
        label: 'Utilities Amount',
        type: 'money',
      },
    },
  ]

  const onSubmit = async (addTenantAndLeaseParams: any) => {
    const createAddressDocumentInput = {
      addressUuid: address?.uuid,
      name: `lease-file-${address?.formattedAddress}`,
      type: 'current_lease',
      files: addTenantAndLeaseParams?.currentLeaseFile,
    }
    try {
      if (addTenantAndLeaseParams?.currentLeaseFile?.length > 0) {
        await createAddressDocument({
          variables: {
            input: createAddressDocumentInput,
          },
        })
      }
      await createOrUpdateAddressDetails({
        variables: {
          addressDetailsInput: {
            addressUuid: address?.uuid,
            leaseInformation: JSON.stringify(addTenantAndLeaseParams),
          },
        },
        refetchQueries: 'active',
      })
      notify('Successfully updated tenant and lease info', 'success')
      onClose()
    } catch (e) {
      notify('Error updating tenant and lease Info', 'error')
    }
  }

  return (
    <>
      <ActionFormModal
        open={open}
        setOpen={onClose}
        loading={loading}
        onSubmit={onSubmit}
        actionText="Add Tenant and Lease Info"
        extraContent={
          <Typography
            variant="h5"
            sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
          >
            {address?.formattedAddress}
          </Typography>
        }
        formProps={{
          defaultValues: {
            tenantInformation: 1,
          },
        }}
        inputConfigs={inputConfigs}
      />
    </>
  )
}

export default AddLeaseAndTenantInfoModal
