import { useEffect, useState } from 'react'
import { CheckboxLabel } from '../../printCollateralPageTypes'
import {
  TableOfContentsProps,
  TableOfContentsPresentationalProps,
  TocPageConfig,
  TocEntry,
} from './tableOfContentsTypes'

const MAX_PAGE_HEIGHT = 654

const checkboxLabelToTitle = (label: string) => {
  switch (label) {
    case CheckboxLabel.PORTOFOLIO_OVERVIEW:
      return 'Portfolio Overview'
    case CheckboxLabel.TOP_UP_CASH_FLOW:
      return 'Top-Up Cash Flow'
    case CheckboxLabel.ALLOTMENT_CASH_FLOW:
      return 'Allotment Cash Flow'
    case CheckboxLabel.REINVESTED_CASH_FLOW:
      return 'Reinvested Cash Flow'
    case CheckboxLabel.VALUATION_COMPS:
      return 'Valuation Comps'
    case CheckboxLabel.FAQS:
      return 'Frequently Asked Questions'
    default:
      if (label.startsWith('Contribution Agreement')) {
        return label
      }
      return ''
  }
}

const checkboxLabelToSectionName = (label: string) => {
  switch (label) {
    case CheckboxLabel.PORTOFOLIO_OVERVIEW:
    case CheckboxLabel.TOP_UP_CASH_FLOW:
    case CheckboxLabel.ALLOTMENT_CASH_FLOW:
    case CheckboxLabel.REINVESTED_CASH_FLOW:
      return 'Valuation'
    case CheckboxLabel.VALUATION_COMPS:
      return 'Valuation Comps'
    case CheckboxLabel.FAQS:
      return 'FAQs'
    default:
      if (label.startsWith('Contribution Agreement')) {
        return 'Contribution Agreements'
      }
      return ''
  }
}

const useTableOfContents: (
  props: TableOfContentsProps
) => TableOfContentsPresentationalProps = (props: TableOfContentsProps) => {
  const {
    checkboxes,
    addresses,
    renderStateMap,
    setRenderStatePageOffset,
    setDoneGenerating,
  } = props

  const [flattenedEntries, setFlattenedEntries] = useState<TocEntry[]>([])

  const [pageNumber, setPageNumber] = useState(0)
  const [renderedItemsIndex, setRenderedItemsIndex] = useState(0)
  const [pageConfigs, setPageConfigs] = useState<TocPageConfig[]>([])

  useEffect(() => {
    // Check if all entries in the map are rendered
    const allRendered = Object.values(renderStateMap).every((state) =>
      state.doneRendering.every((done) => done)
    )
    if (allRendered) {
      // Flatten the entries
      const newFlattenedEntries: TocEntry[] = []
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          if (checkbox.label === CheckboxLabel.VALUATION_COMPS) {
            addresses.forEach((address, idx) => {
              newFlattenedEntries.push({
                title: address.addressStreet as string,
                displayedPageNumber: 0,
                section: checkboxLabelToSectionName(checkbox.label),
                numPages: renderStateMap[checkbox.label].numPages[idx],

                renderStateMapKey: checkbox.label,
                renderStateMapIndex: idx,
              })
            })
          } else if (checkbox.label === CheckboxLabel.FAQS) {
            newFlattenedEntries.push({
              title: 'Exchange Qualifications',
              displayedPageNumber: 0,
              section: checkboxLabelToSectionName(checkbox.label),
              numPages: 1,

              renderStateMapKey: checkbox.label,
              renderStateMapIndex: 0,
            })
            newFlattenedEntries.push({
              title: `The Exchange Process + Entering Flock's Fund`,
              displayedPageNumber: 0,
              section: checkboxLabelToSectionName(checkbox.label),
              numPages: 2,
            })
            newFlattenedEntries.push({
              title: `Owning with Flock`,
              displayedPageNumber: 0,
              section: checkboxLabelToSectionName(checkbox.label),
              numPages: 1,
            })
            newFlattenedEntries.push({
              title: `Taxes`,
              displayedPageNumber: 0,
              section: checkboxLabelToSectionName(checkbox.label),
              numPages: 1,
            })
          } else if (checkbox.label !== CheckboxLabel.COVER) {
            newFlattenedEntries.push({
              title: checkboxLabelToTitle(checkbox.label),
              displayedPageNumber: 0,
              section: checkboxLabelToSectionName(checkbox.label),
              numPages: renderStateMap[checkbox.label].numPages[0],

              renderStateMapKey: checkbox.label,
              renderStateMapIndex: 0,
            })
          }
        }
      })
      setFlattenedEntries(newFlattenedEntries)
    }
  }, [addresses, checkboxes, renderStateMap])

  useEffect(() => {
    if (flattenedEntries.length) {
      const currentPageHeight = document.getElementById(
        `toc-page-${pageNumber}`
      )?.clientHeight
      let newRenderedItemsIndex = renderedItemsIndex

      let newPageConfigs = [...pageConfigs]

      if (currentPageHeight && currentPageHeight > MAX_PAGE_HEIGHT) {
        const itemToMoveToNextPage = newPageConfigs[pageNumber].tocEntries.pop()
        setPageNumber(pageNumber + 1)
        setPageConfigs([
          ...newPageConfigs,
          {
            pageNumber: pageNumber + 1,
            tocEntries: [itemToMoveToNextPage as TocEntry],
          },
        ])
      } else if (flattenedEntries[renderedItemsIndex]) {
        if (newPageConfigs.length === 0) {
          newPageConfigs.push({
            pageNumber: 0,
            tocEntries: [],
          })
        }

        newPageConfigs[pageNumber].tocEntries.push(
          flattenedEntries[renderedItemsIndex]
        )
        newRenderedItemsIndex += 1
        setPageConfigs(newPageConfigs)
      } else {
        // At this point, we should have the base offset for each page.
        // We can now go through each page and update the displayedPageNumber
        const baseOffset = 2 + pageConfigs.length
        let offset = baseOffset

        newPageConfigs = pageConfigs.map((pageConfig) => {
          const { tocEntries } = pageConfig
          return {
            ...pageConfig,
            tocEntries: tocEntries.map((entry) => {
              const { renderStateMapKey, renderStateMapIndex } = entry
              const newEntry = {
                ...entry,
                displayedPageNumber: offset,
              }

              if (renderStateMapKey) {
                setRenderStatePageOffset(
                  renderStateMapKey,
                  renderStateMapIndex || 0,
                  offset
                )
              }

              offset += entry.numPages

              return newEntry
            }),
          }
        })

        setDoneGenerating(true)

        // Prevent an infinite render loop
        if (JSON.stringify(newPageConfigs) !== JSON.stringify(pageConfigs)) {
          setPageConfigs(newPageConfigs)
        }
      }
      setRenderedItemsIndex(newRenderedItemsIndex)
    }
  }, [JSON.stringify(flattenedEntries), JSON.stringify(pageConfigs)])

  return {
    ...props,
    pageConfigs,
  }
}

export default useTableOfContents
