import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { Typography } from '@mui/material'
import { AdminSubmitValuationModalInvestmentsApproveValuationV1Document } from '@flock/flock-gql-server/src/__generated__/graphql'
import { AnyInputConfig, InputType, useSnackbar } from '@flock/shared-ui'
import { formatIntegerDollars } from '@flock/utils'
import {
  SubmitValuationModalProps,
  SubmitValuationFormParams,
} from './submitValuationModalTypes'

export const INVESTMENTS_APPROVE_VALUATION_V1 = gql`
  mutation AdminSubmitValuationModalInvestmentsApproveValuationV1(
    $input: Core_InvestmentsApproveValuationV1RequestInput!
  ) {
    investmentsApproveValuationV1(input: $input) {
      _empty
    }
  }
`

const useSubmitValuationModal = (props: SubmitValuationModalProps) => {
  const {
    onClose,
    operatorUuid,
    valuationUuid,
    finalOfferPrice,
    leadUuid,
    canSubmitValuation,
    valuationTypeString,
  } = props

  const { notify } = useSnackbar()

  const [submitValuation, { loading }] = useMutation(
    AdminSubmitValuationModalInvestmentsApproveValuationV1Document
  )

  const submitValuationInputConfigs: AnyInputConfig[] = [
    {
      name: 'finalOfferPrice',
      type: InputType.CustomComponent,
      props: {
        component: (
          <Typography variant="h3">
            {`You will be submitting this ${valuationTypeString} valuation with an offer price of
            ${formatIntegerDollars(Math.trunc(finalOfferPrice))}`}
          </Typography>
        ),
      },
    },
  ]

  const onSubmit = async (formState: SubmitValuationFormParams) => {
    if (!canSubmitValuation()) {
      return
    }
    try {
      submitValuation({
        variables: {
          input: {
            operatorUuid,
            valuationUuid,
            finalOfferPrice: Math.trunc(finalOfferPrice),
            leadUuid,
            reitFit: formState.reitFit === 'true',
          },
        },
      })
      onClose()
      notify('Valuation submitted successfully', 'success')
    } catch (e) {
      notify('Submitting valuatino failed, please try again', 'error')
    }
  }
  return { onSubmit, submitValuationInputConfigs, loading }
}

export default useSubmitValuationModal
