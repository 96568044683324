import React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { Box, Button, ThemeProvider, Typography } from '@mui/material'
import {
  Dropdown,
  FormattedTextField,
  flockSquareTheme,
  LibraryThemeProvider,
  Checkbox,
} from '@flock/shared-ui'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import usePrintCollateralPage from './usePrintCollateralPage'
import { PrintCollateralPageProps } from './printCollateralPageTypes'
import CollateralRenderer from './CollateralRenderer/CollateralRenderer'

// separate it so that usePrintCollateralPage is called inside of the theme provider.
const PrintCollateralPageContent = (props: PrintCollateralPageProps) => {
  const {
    accountId,
    scenarios,
    loading,
    formControl,
    onSubmit,
    errors,
    handleDragEnd,
    checkboxFields,
    accountName,
    collateralData,

    onCloseCollateralRenderer,
    collateralRendererOpen,
    CATemplatesLoading,
  } = usePrintCollateralPage(props)

  if (loading) {
    return <Typography>Loading...</Typography>
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p="24px"
    >
      <Box
        width="560px"
        height="756px"
        display="flex"
        flexDirection="column"
        gap="24px"
      >
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="h3" color="moh">
            Print Collateral
          </Typography>
          <Typography variant="p2" color="green4.main">
            Account Name: {accountName}
          </Typography>
          <Typography variant="p2" color="green4.main">
            Salesforce Account ID: {accountId}
          </Typography>
        </Box>

        <form onSubmit={onSubmit}>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Controller
                control={formControl}
                name="scenario"
                rules={{ required: 'Please select a scenario' }}
                render={({ field: { ref: _ref, ...fieldProps } }) => (
                  <Dropdown
                    {...fieldProps}
                    label="Choose a scenario"
                    options={scenarios}
                    error={!!errors.scenario}
                    helperText={(errors.scenario as FieldError)?.message}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                control={formControl}
                name="coverName"
                defaultValue=""
                rules={{ required: 'Cover Name is required' }}
                render={({ field: { ref: _ref, ...fieldProps } }) => (
                  <FormattedTextField
                    {...fieldProps}
                    sx={{ width: '100%' }}
                    label="Cover Name"
                    error={!!errors.coverName}
                    helperText={errors.coverName?.message}
                  />
                )}
              />
            </Box>
            <Box>
              <Typography variant="h6">Reorderable Checkboxes</Typography>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="checkboxes">
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                      {checkboxFields.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(draggableProvided) => (
                            <Box
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 8,
                                ...draggableProvided.draggableProps.style,
                              }}
                            >
                              <Controller
                                name={`checkboxes.${index}.checked`}
                                control={formControl}
                                render={({
                                  field: { ref: _ref, ...fieldProps },
                                }) => (
                                  <Checkbox
                                    {...fieldProps}
                                    label={item.label}
                                    checked={fieldProps.value}
                                  />
                                )}
                              />
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>

            <Button type="submit" disabled={CATemplatesLoading}>
              {CATemplatesLoading ? 'Loading...' : 'Submit'}
            </Button>
          </Box>
        </form>
      </Box>
      {collateralRendererOpen && (
        <CollateralRenderer
          open={collateralRendererOpen}
          onClose={onCloseCollateralRenderer}
          collateralData={collateralData}
        />
      )}
    </Box>
  )
}

const PrintCollateralPage = (props: PrintCollateralPageProps) => (
  <ThemeProvider theme={flockSquareTheme}>
    <LibraryThemeProvider theme={flockSquareTheme}>
      <PrintCollateralPageContent {...props} />
    </LibraryThemeProvider>
  </ThemeProvider>
)

export default PrintCollateralPage
