import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useCallback, useEffect, useState, useMemo } from 'react'

import {
  AnyInputConfig,
  InputType,
  useSnackbar,
  InputConfig,
} from '@flock/shared-ui'
import { useParams } from '@reach/router'
import { Box, Button, Typography } from '@mui/material'
import {
  AdminMultiFamilyValuationTabGetMultiFamilyValuationDocument,
  AdminMultiFamilyValuationTabComputeMultiFamilyValuationDocument,
  AdminMultiFamilyValuationTabGetAddressDocumentDownloadUrlDocument,
  Core_ValuationType,
  Core_CompSource,
  Core_ComputeMultiFamilyValuationRequestInput,
  Core_MultiFamilyValuation,
  Core_AddressDocument,
  Core_ValidatedAddress,
  Core_Lead,
  Core_Property,
  Core_MarketRentCompInput,
  Core_OfferValuationCompInput,
  Core_MultiFamilyValuationUnitInputInput,
  Core_MultiFamilyValuationPropertyInputInput,
  AdminComputeValuationPageGetValuationHistoryDocument,
  Core_ValuationCategory,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  formatAddressString,
  formatCityStateZip,
  formatDollarsAndCents,
} from '@flock/utils'
import { isEqual } from 'lodash'

import UploadAddressDocumentModal from '../UploadAddressDocumentModal/UploadAddressDocumentModal'
import AddLeaseAndTenantInfoModal from '../AddLeaseAndTenantInfoModal/AddLeaseAndTenantInfoModal'
import {
  supervisorUuids,
  finalSubmitUuids,
  formatAttribution,
  isComputeValuationRequestInputValid,
} from '../valuationUtils'
import { MultiFamilyComputeLeadValuationTabProps } from './multiFamilyComputeLeadValuationTabTypes'

export const GET_MULTIFAMILY_VALUATION = gql`
  fragment ValuationTrailFields on Core_ValuationTrailNode {
    name
    description
    value
  }
  query AdminMultiFamilyValuationTabGetMultiFamilyValuation(
    $input: Core_GetMultiFamilyValuationRequestInput!
  ) {
    getMultiFamilyValuation(input: $input) {
      valuation {
        uuid
        leadUuid
        type
        unitInputs {
          remodelCost {
            healthAndSafetyCapex
            immediateAddressableCapex
            notes
            score
            totalAddressableCapex
          }
          monthsRemainingOnLease
          currentlyOccupied
          currentRent
          avmProjectedRent
          analystProjectedRent
          beds
          baths
          bathsDouble
          squareFootage
          addressUuid
          rentNotes
          marketRentComps {
            source
            marketRent
            url
            address
            notes
            squareFootage
            rentalDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
          }
          offerPriceComps {
            source
            similarityScore
            url
            avmPrice
            squareFootage
            address
            beds
            baths
            bathsDouble
            halfBaths
            yearBuild
            notes
            estimatedRemodelCost
            soldDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
            conditionScore
            numUnits
            flatAvmAdjustment
          }
          additionalMonthlyCost
          additionalMonthlyCostNotes
          isSectionEight
        }
        propertyInput {
          propertyRemodelCost {
            healthAndSafetyCapex
            immediateAddressableCapex
            notes
            score
            totalAddressableCapex
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            inPlacePropertyTaxes
            notes
            hoaExists
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            additionalMonthlyCost
            additionalMonthlyCostNotes
          }
          selfReportedValue
          cashToClose
          mortgageAmount
          cashTakeout
          fiveYearTreasuryRate
          propertyAvmValue
          propertyFlatAvmAdjustment
          offerPriceNotes
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
          netYieldAdjustedOfferPrice
          submarketRentDeduction
          daysInRemodelDeduction
          grossYieldOnAdjustedOfferPrice
          unconstrainedMsaYieldMin
          unconstrainedMsaYieldMinOfferPrice
          inPlaceNetYield
          uwCashOnCashYield {
            customerUwCashOnCashYield
            currentUwCashOnCashYield
          }
          remodelCosts {
            score
            notes
            immediateAddressableCapex
            totalAddressableCapex
            healthAndSafetyCapex
          }
          insuranceExpense
        }
        rejectionReason
        expiresAt
        finalOfferPrice
        operatorUuid
        salesApproved
        property {
          uuid
          addressUuid
          propertyType
          beds
          baths
          bathsDouble
          halfBaths
          sqft
          yearBuilt
          notes
        }
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                      nodes {
                        ...ValuationTrailFields
                        nodes {
                          ...ValuationTrailFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        updatedAt
      }
    }
  }
`

export const COMPUTE_MULTIFAMILY_VALUATION = gql`
  fragment ValuationTrailFields on Core_ValuationTrailNode {
    name
    description
    value
  }
  mutation AdminMultiFamilyValuationTabComputeMultiFamilyValuation(
    $input: Core_ComputeMultiFamilyValuationRequestInput!
  ) {
    computeMultiFamilyValuation(input: $input) {
      valuation {
        uuid
        leadUuid
        type
        unitInputs {
          remodelCost {
            healthAndSafetyCapex
            immediateAddressableCapex
            notes
            totalAddressableCapex
            score
          }
          monthsRemainingOnLease
          currentlyOccupied
          currentRent
          avmProjectedRent
          analystProjectedRent
          beds
          baths
          bathsDouble
          squareFootage
          addressUuid
          rentNotes
          marketRentComps {
            source
            marketRent
            url
            address
            notes
            squareFootage
            rentalDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
          }
          offerPriceComps {
            source
            similarityScore
            url
            avmPrice
            squareFootage
            address
            beds
            baths
            bathsDouble
            halfBaths
            yearBuild
            notes
            estimatedRemodelCost
            soldDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
            conditionScore
            numUnits
          }
          additionalMonthlyCost
          additionalMonthlyCostNotes
        }
        propertyInput {
          propertyRemodelCost {
            healthAndSafetyCapex
            immediateAddressableCapex
            notes
            score
            totalAddressableCapex
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            inPlacePropertyTaxes
            notes
            hoaExists
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            additionalMonthlyCost
            additionalMonthlyCostNotes
          }
          selfReportedValue
          cashToClose
          mortgageAmount
          cashTakeout
          fiveYearTreasuryRate
          propertyAvmValue
          propertyFlatAvmAdjustment
          offerPriceNotes
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
          netYieldAdjustedOfferPrice
          submarketRentDeduction
          grossYieldOnAdjustedOfferPrice
          unconstrainedMsaYieldMin
          unconstrainedMsaYieldMinOfferPrice
          inPlaceNetYield
          uwCashOnCashYield {
            customerUwCashOnCashYield
            currentUwCashOnCashYield
          }
          daysInRemodelDeduction
          remodelCosts {
            healthAndSafetyCapex
            immediateAddressableCapex
            notes
            score
            totalAddressableCapex
          }
        }
        rejectionReason
        expiresAt
        finalOfferPrice
        operatorUuid
        salesApproved
        property {
          yearBuilt
          sqft
        }
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                    }
                  }
                }
              }
            }
          }
        }
        updatedAt
      }
    }
  }
`

export const GET_ADDRESS_DOCUMENT_DOWNLOAD_URL = gql`
  query AdminMultiFamilyValuationTabGetAddressDocumentDownloadUrl(
    $addressDocumentUuid: Core_GetAddressDocumentPresignedUrlRequestInput!
  ) {
    addressDocumentPresignedUrl(input: $addressDocumentUuid) {
      presignedUrl
    }
  }
`

const useMultiFamilyComputeLeadValuationTab = (
  props: MultiFamilyComputeLeadValuationTabProps
) => {
  const { leadData, operatorData, valuationHistory, valuationHistoryLoading } =
    props
  let { leadUuid } = useParams()
  leadUuid = leadUuid as string
  const { notify } = useSnackbar()
  const [loading, setLoading] = useState(true)
  const [selfReportedValue, setSelfReportedValue] = useState(0)
  const [computedValuationResult, setComputedValuationResult] =
    useState<Core_MultiFamilyValuation>()
  // we want to use 1 month away - rounded up -> so we add 2 months and use the month value
  const contributionMonth = useMemo(() => {
    const tempMonth = new Date(new Date().setMonth(new Date().getMonth() + 2))
    tempMonth.setDate(1)
    return tempMonth
  }, [])

  const [valuationHistoryModalOpen, setValuationHistoryModalOpen] =
    useState(false)
  const [rejectValuationModalOpen, setRejectValuationModalOpen] =
    useState(false)
  const [valuationInputs, setValuationInputs] =
    useState<Core_ComputeMultiFamilyValuationRequestInput | null>(null)

  // prefilled comps from the previous valuation
  const [rentalCompsPrefill, setRentalCompsPrefill] = useState<any>({})
  const [offerCompsPrefill, setOfferCompsPrefill] = useState<any>({})
  const [unitInputsPrefill, setUnitInputsPrefill] = useState<any>({})
  const [numPrefillOfferComps, setNumPrefillOfferComps] = useState(3)
  const [numPrefillRentalComps, setNumPrefillRentalComps] = useState(3)
  const [hasSubmittedInitialValuation, setHasSubmittedInitialValuation] =
    useState(false)
  // since we can have multiple units, store array of analystprojectedrent vs housecanary projected rent ratios
  const [
    analystProjectedRentVsHousecanaryProjectedRent,
    setAnalystProjectedRentVsHousecanaryProjectedRent,
  ] = useState<number[]>([])
  const [valuationType, setValuationType] = useState('')

  // map of unit's addressUuid to unit name
  const [addressUnitMap, setAddressUnitMap] = useState<Map<string, string>>(
    new Map()
  )
  const [numUnits, setNumUnits] = useState(0)

  // map of address uuid to lease uuid -> can also use to see if a lease was uploaded for  unit
  const [addressLeaseUuidMap, setAddressLeaseUuidMap] = useState<
    Map<string, string>
  >(new Map())

  // map of address uuid to lease modal being open
  const [addressLeaseModalOpenMap, setAddressLeaseModalOpenMap] = useState<
    Map<string, boolean>
  >(new Map())

  const [offerCompsErrorMessage, setOfferCompsErrorMessage] = useState('')
  const [rentCompsErrorMessage, setRentCompsErrorMessage] = useState('')

  // https://stackoverflow.com/a/47136047/13877993
  const getByValue = (map: Map<any, any>, searchValue: any) => {
    let foundValue = null
    Array.from(map.entries()).forEach(([key, value]) => {
      if (value === searchValue) foundValue = key
    })
    return foundValue
  }

  // map of unit name vs ratio of analyst projected rent vs the average of the rent comps for that unit per sqft.
  const [
    perUnitRentalCompsAverageVsAvmRentMap,
    setPerUnitRentalCompsAverageVsAvmRentMap,
  ] = useState<Map<string, number>>(new Map())

  // used to prefill the valuation input from the lead answers if there is no preexisting valuation
  const getPrefilledValuationFieldsFromLeadAnswers = useCallback(
    (
      lead: Core_Lead,
      prefilledValuationInputs: Core_ComputeMultiFamilyValuationRequestInput
    ) => {
      let newPrefilledValuationInputs = { ...prefilledValuationInputs }
      if (lead?.answers) {
        const parsedLeadAnswers = JSON.parse(lead.answers)

        // temp issue in admin create lead where value of condition score is a string -> should be fixed sometime in the future
        // e.g. 4 - great, 5 - excellent, etc.
        let conditionScore = parsedLeadAnswers.propertyCondition
        if (typeof conditionScore === 'string') {
          conditionScore = parseInt(conditionScore, 10)
        }
        let unitInputs = []
        // currently unit details could potentially be none -> this allows us to pass and setLoading to false
        // so that the error message about not having enough units will show.
        if (parsedLeadAnswers.unitDetails) {
          unitInputs = parsedLeadAnswers.unitDetails.map((unitDetail: any) => {
            const unitAddressUuid = getByValue(addressUnitMap, unitDetail.name)

            // we use the contribution date as the default lease end date if we didnt get a response
            // this will show 0 months remaining and we will use MTM for the calculation
            const leaseEnd = unitDetail.leaseEnd
              ? new Date(Date.parse(unitDetail.leaseEnd))
              : contributionMonth

            // subtract the contribution month from the lease end and convert the timestamp to a month value
            let monthsRemainingOnLeaseValue = Math.round(
              (leaseEnd.getTime() - contributionMonth.getTime()) /
                (1000 * 3600 * 24 * 30)
            )

            // currently exists bug on admin create lead where if it is month to month lease, it still allows you to input lease end.
            // so if month to month, set months remaining on lease to 0
            if (unitDetail.leaseType === 'mtm') {
              monthsRemainingOnLeaseValue = 0
            }

            // because if value is negative, it still gets prefilled as positive on the inputs, so set to 0
            monthsRemainingOnLeaseValue = Math.max(
              0,
              monthsRemainingOnLeaseValue
            )

            let currentlyOccupied = false
            currentlyOccupied = unitDetail.hasTenant === 'true'

            return {
              addressUuid: unitAddressUuid,
              currentlyOccupied,
              currentRent: unitDetail.rentAmount,
              monthsRemainingOnLease: monthsRemainingOnLeaseValue || 0,
              beds: parseInt(unitDetail.bedCount || 0, 10),
              baths: parseInt(unitDetail.bathCount || 0, 10),
              bathsDouble: unitDetail.bathCount || 0,
              squareFootage: unitDetail.squareFootage,
              remodelCost: {
                score: conditionScore,
              },
            } as Core_MultiFamilyValuationUnitInputInput
          })
        }

        newPrefilledValuationInputs = {
          ...newPrefilledValuationInputs,
          propertyInput: {
            ...newPrefilledValuationInputs.propertyInput,
            selfReportedValue: parsedLeadAnswers.selfReportedValue,
          },
          unitInputs,
          property: {
            ...newPrefilledValuationInputs.property,
            propertyType: parsedLeadAnswers.propertyType,
            beds: parsedLeadAnswers.bedCount || parsedLeadAnswers.bedrooms,
            baths:
              parsedLeadAnswers.bathCount || parsedLeadAnswers.fullBathrooms,
            bathsDouble:
              parsedLeadAnswers.bathCount || parsedLeadAnswers.fullBathrooms,
            halfBaths:
              parsedLeadAnswers.halfBathCount ||
              parsedLeadAnswers.halfBathrooms,
            //   no prefill for sqft
            yearBuilt: parsedLeadAnswers.prefillYearBuilt,
          },
        }
        setSelfReportedValue(parsedLeadAnswers.selfReportedValue)
      }
      return newPrefilledValuationInputs
    },
    [contributionMonth, addressUnitMap]
  )

  const parseExistingRentalComps = (
    unitInputs: Core_MultiFamilyValuationUnitInputInput[] | null | undefined
  ) => {
    if (!unitInputs) {
      return
    }
    const comps: any[] = []
    unitInputs.forEach((unitInput) => {
      if (unitInput.marketRentComps) {
        unitInput.marketRentComps.forEach((comp) => {
          // find the comp with the same address in the list
          // problem is that key uses idx so we just need to iterate through all of the values.
          const existingComp = comps.find((comp2) =>
            Array.from(Object.values(comp2)).some((v) => v === comp.address)
          )
          if (existingComp) {
            const idx = comps.indexOf(existingComp)
            const unitName = addressUnitMap.get(unitInput.addressUuid as string)
            const prefix = `${
              ((unitInput.addressUuid as string) + unitName) as string
            }`
            comps[idx][`${prefix}RentalCompSelected${idx}`] = true
          } else {
            // get the address unit name with the same address uuid
            const idx = comps.length
            const unitName = addressUnitMap.get(unitInput.addressUuid as string)
            const prefix = `${
              ((unitInput.addressUuid as string) + unitName) as string
            }`
            const newComp = {
              [`rentCompSource${idx}`]: comp.source,
              [`rentCompMarketRent${idx}`]: comp.marketRent,
              [`rentCompRentalDate${idx}`]: comp.rentalDate,
              [`rentCompSquareFootage${idx}`]: comp.squareFootage,
              [`rentCompUrl${idx}`]: comp.url,
              [`rentCompAddressMulti${idx}`]: comp.address,
              [`rentCompNotes${idx}`]: comp.notes,
              [`rentCompDistance${idx}`]: comp.distance,
              [`${prefix}RentalCompSelected${idx}`]: true,
            }
            comps.push(newComp)
          }
        })
      }
    })
    setNumPrefillRentalComps(comps.length)
    const prefill = Object.assign({}, ...comps)
    setRentalCompsPrefill(prefill)
  }

  const parseUnitInputs = (
    unitInputs: Core_MultiFamilyValuationUnitInputInput[] | null | undefined
  ) => {
    if (!unitInputs) {
      return
    }
    const prefill = Object.assign(
      {},
      ...(unitInputs.map((unitInput) => ({
        [`${unitInput.addressUuid}Beds`]: unitInput?.beds,
        [`${unitInput.addressUuid}Baths`]: unitInput?.baths,
        [`${unitInput.addressUuid}BathsDouble`]: unitInput?.baths,
        [`${unitInput.addressUuid}Sqft`]: unitInput?.squareFootage,
        [`${unitInput.addressUuid}CurrentRent`]: unitInput?.currentRent,
        [`${unitInput.addressUuid}AnalystProjectedRent`]:
          unitInput?.analystProjectedRent,
        [`${unitInput.addressUuid}AvmProjectedRent`]:
          unitInput?.avmProjectedRent,
        [`${unitInput.addressUuid}MonthsRemainingOnLease`]:
          unitInput?.monthsRemainingOnLease,
        [`${unitInput.addressUuid}CurrentlyOccupied`]:
          unitInput?.currentlyOccupied ? 'yes' : 'no',
        [`${unitInput.addressUuid}RentNotes`]: unitInput?.rentNotes,
        [`${unitInput.addressUuid}AdditionalMonthlyCost`]:
          unitInput?.additionalMonthlyCost,
        [`${unitInput.addressUuid}AdditionalMonthlyCostNotes`]:
          unitInput?.additionalMonthlyCostNotes,
        [`${unitInput.addressUuid}IsSectionEight`]: unitInput?.isSectionEight
          ? 'yes'
          : 'no',
        [`${unitInput.addressUuid}HealthAndSafetyCapex`]:
          unitInput?.remodelCost?.healthAndSafetyCapex,
        [`${unitInput.addressUuid}ImmediateAddressableCapex`]:
          (unitInput?.remodelCost?.immediateAddressableCapex || 0) -
          (unitInput?.remodelCost?.healthAndSafetyCapex || 0),
        [`${unitInput.addressUuid}TotalAddressableCapex`]:
          (unitInput?.remodelCost?.totalAddressableCapex || 0) -
          (unitInput?.remodelCost?.immediateAddressableCapex || 0),
        [`${unitInput.addressUuid}RemodelCostScore`]:
          unitInput?.remodelCost?.score,
      })) || [])
    )
    setUnitInputsPrefill(prefill)
  }

  const parseExistingOfferComps = (
    unitInputs: Core_MultiFamilyValuationUnitInputInput[] | null | undefined
  ) => {
    if (!unitInputs) {
      return
    }
    const comps: any[] = []
    unitInputs.forEach((unitInput) => {
      if (unitInput.offerPriceComps) {
        unitInput.offerPriceComps.forEach((comp) => {
          // find the comp with the same address in the list
          const existingComp = comps.find((comp2) =>
            Array.from(Object.values(comp2)).some((v) => v === comp.address)
          )
          if (existingComp) {
            const idx = comps.indexOf(existingComp)
            const unitName = addressUnitMap.get(unitInput.addressUuid as string)
            const prefix = `${
              ((unitInput.addressUuid as string) + unitName) as string
            }`
            comps[idx][`${prefix}OfferCompSelected${idx}`] = true
          } else {
            // get the address unit name with the same address uuid
            const idx = comps.length
            const unitName = addressUnitMap.get(unitInput.addressUuid as string)
            const prefix = `${
              ((unitInput.addressUuid as string) + unitName) as string
            }`
            const newComp = {
              [`offerCompSource${idx}`]: comp.source,
              [`similarityScore${idx}`]: comp.similarityScore,
              [`offerCompUrl${idx}`]: comp.url,
              [`avmPrice${idx}`]: comp.avmPrice,
              [`squareFootage${idx}`]: comp.squareFootage,
              [`offerCompAddressMulti${idx}`]: comp.address,
              [`beds${idx}`]: comp.beds,
              [`baths${idx}`]: comp.baths,
              [`bathsDouble${idx}`]: comp.baths,
              [`yearBuilt${idx}`]: comp.yearBuild,
              [`offerCompSoldDate${idx}`]: comp.soldDate,
              [`offerCompNotes${idx}`]: comp.notes,
              [`conditionScore${idx}`]: comp.conditionScore,
              [`numUnits${idx}`]: comp.numUnits,
              [`offerCompDistance${idx}`]: comp.distance,
              [`${prefix}OfferCompSelected${idx}`]: true,
              [`flatAvmAdjustment${idx}`]: comp.flatAvmAdjustment,
            }
            comps.push(newComp)
          }
        })
      }
    })
    setNumPrefillOfferComps(comps.length)
    const prefill = Object.assign({}, ...comps)
    setOfferCompsPrefill(prefill)
  }

  const { data: multiFamilyValuation } = useQuery(
    AdminMultiFamilyValuationTabGetMultiFamilyValuationDocument,
    {
      fetchPolicy: 'no-cache', // necessary to cause refetch when switching tabs.
      variables: {
        input: {
          leadUuid,
        },
      },
      onError: () => {
        let prefilledValuationInputs = {
          ...valuationInputs,
        } as Core_ComputeMultiFamilyValuationRequestInput
        const lead = leadData?.lead?.lead as Core_Lead
        prefilledValuationInputs = getPrefilledValuationFieldsFromLeadAnswers(
          lead,
          prefilledValuationInputs
        )
        setValuationInputs({
          ...prefilledValuationInputs,
        })
        parseUnitInputs(prefilledValuationInputs.unitInputs)
        setLoading(false)
      },
      onCompleted: async () => {
        const previousValuation: Core_MultiFamilyValuation =
          multiFamilyValuation?.getMultiFamilyValuation
            ?.valuation as Core_MultiFamilyValuation

        const lead = leadData?.lead?.lead as Core_Lead
        let prefilledValuationInputs = {
          ...valuationInputs,
        } as Core_ComputeMultiFamilyValuationRequestInput

        prefilledValuationInputs = getPrefilledValuationFieldsFromLeadAnswers(
          lead,
          prefilledValuationInputs
        )

        if (previousValuation) {
          prefilledValuationInputs = {
            ...prefilledValuationInputs,
            propertyInput: previousValuation.propertyInput,
            unitInputs: previousValuation.unitInputs,
            valuationType: previousValuation.type,
            property: previousValuation.property,
          }

          if (previousValuation.finalOfferPrice === 0) {
            prefilledValuationInputs.valuationUuid = previousValuation.uuid
            prefilledValuationInputs.valuationType = previousValuation.type
            setComputedValuationResult(previousValuation)
          }

          parseExistingOfferComps(previousValuation.unitInputs)
          parseExistingRentalComps(previousValuation.unitInputs)
          parseUnitInputs(previousValuation.unitInputs)
          setValuationType(previousValuation.type || '')
        }
        setValuationInputs(prefilledValuationInputs)

        setLoading(false)
      },
      skip: !leadData,
    }
  )

  const [computeValuation, { loading: computeValuationLoading }] = useMutation(
    AdminMultiFamilyValuationTabComputeMultiFamilyValuationDocument,
    {
      fetchPolicy: 'no-cache',
      onError: () => {
        notify(
          'An error occurred while computing the valuation. Please try again.',
          'error'
        )
      },
      onCompleted: () => {
        notify('Valuation successfully computed', 'success')
      },
      refetchQueries: [
        AdminMultiFamilyValuationTabGetMultiFamilyValuationDocument,
        AdminComputeValuationPageGetValuationHistoryDocument,
      ],
    }
  )

  const { refetch: getAddressDocumentUrl } = useQuery(
    AdminMultiFamilyValuationTabGetAddressDocumentDownloadUrlDocument,
    {
      skip: true,
    }
  )

  const downloadAddressDocument = useCallback(
    async (addressDocumentUuid: string) => {
      try {
        const urlData = await getAddressDocumentUrl({
          addressDocumentUuid: {
            addressDocumentUuid,
          },
        })
        window.open(
          urlData?.data?.addressDocumentPresignedUrl?.presignedUrl as string,
          '_blank'
        )
      } catch (e) {
        notify(
          'An error while downloading the document. Please refresh or try again.',
          'error'
        )
      }
    },
    [getAddressDocumentUrl, notify]
  )

  const computeValuationFormFormProps = useMemo(
    () => ({
      defaultValues: {
        valuationType:
          valuationInputs?.valuationType ||
          Core_ValuationType.ValuationTypeInitial,
        ...rentalCompsPrefill,
        ...offerCompsPrefill,
        ...unitInputsPrefill,
      },
    }),
    [valuationInputs, rentalCompsPrefill, offerCompsPrefill, unitInputsPrefill]
  )

  useEffect(() => {
    if (valuationHistoryLoading) return
    const multiFamilyValuations =
      valuationHistory?.getValuationHistory?.multiFamilyValuations
    if (multiFamilyValuations) {
      const existsInitialSubmittedValuation = multiFamilyValuations.some(
        (valuation) =>
          valuation?.finalOfferPrice && valuation.finalOfferPrice > 0
      )
      if (existsInitialSubmittedValuation) {
        setHasSubmittedInitialValuation(true)
      }
    }
  }, [valuationHistory, valuationHistoryLoading])

  useEffect(() => {
    const lead = leadData?.lead?.lead as Core_Lead
    const units = lead?.address?.units || []
    const unitMap = new Map()
    const newAddressLeaseMap = new Map()
    const newAddressLeaseModalOpenMap = new Map()
    const parsedLeadAnswers = JSON.parse(lead?.answers || '{}')
    const leadAnswersUnitNames = parsedLeadAnswers.unitDetails?.map(
      (unitDetail: any) => unitDetail.name
    )

    units.forEach((unit: any) => {
      // the reason for this is that in our db addresses could have multiple units from previous incorrect answers
      // so we need to keep updated with the most recent responses of the lead
      if (!leadAnswersUnitNames.includes(unit.unit)) {
        return
      }
      // set up map of address uuid to unit name
      unitMap.set(unit.uuid, unit.unit)

      // set up map of address uuid to lease document uuid
      if (unit?.documents?.length > 0) {
        unit.documents.forEach((document: any) => {
          if (document?.type === 'current_lease') {
            newAddressLeaseMap.set(unit.uuid, document.uuid)
          }
        })
      }

      // set up map of address uuid to lease modal open
      newAddressLeaseModalOpenMap.set(unit.uuid, false)
    })
    setAddressUnitMap(unitMap)
    setNumUnits(units.length)
    setAddressLeaseUuidMap(newAddressLeaseMap)
    setAddressLeaseModalOpenMap(newAddressLeaseModalOpenMap)
  }, [leadData])

  const operatorUuid = operatorData?.operator?.operator?.uuid || ''
  let lead: any
  let leadAnswers: { [x: string]: any } = {}
  let addressDocuments: Core_AddressDocument[]
  let addressUnits: Core_ValidatedAddress[]
  let inspectionReportUploaded = false
  let scopeOfWorkUploaded = false
  let housecanaryUploaded = false
  let inspectionReportUuid = ''
  let scopeOfWorkUuid = ''
  let housecanaryUuid = ''
  let formattedAddress = ''
  if (leadData) {
    lead = leadData?.lead?.lead as Core_Lead
    if (lead?.answers) {
      leadAnswers = JSON.parse(lead.answers)
    }
    addressDocuments = lead?.address?.documents || []
    addressUnits = lead?.address?.units || []
    if (addressUnits?.length > 0) {
      addressUnits?.forEach((unit: any) => {
        addressDocuments = [...addressDocuments, ...unit?.documents]
      })
    }
    // specifically need to do the lease documents separately.
    addressDocuments?.forEach((doc: Core_AddressDocument) => {
      if (doc?.type === 'current_lease') {
        return null
      } else if (doc?.type === 'scope_of_work') {
        scopeOfWorkUploaded = true
        scopeOfWorkUuid = doc?.uuid
      } else if (doc?.type === 'housecanary') {
        housecanaryUploaded = true
        housecanaryUuid = doc?.uuid
      } else if (doc?.type === 'report') {
        inspectionReportUploaded = true
        inspectionReportUuid = doc?.uuid
      }
      return null
    })

    formattedAddress = lead?.address?.formattedAddress || ''
  }

  const getSupervisorReviewRequiredMessage = (): string => {
    if (computedValuationResult?.outputs?.finalOfferPrice! > 600000) {
      return 'Final Offer price is greater than 600k.'
    }
    if (
      analystProjectedRentVsHousecanaryProjectedRent.some(
        (ratio: number) => ratio < 0.9 || ratio > 1.1
      )
    ) {
      return "A unit's Analyst Projected Rent is not within 10% of the HouseCanary Projected Rent."
    }
    const unitsWithAvmCompRentPerSqftRatiosOutsideRange: string[] = []
    Array.from(perUnitRentalCompsAverageVsAvmRentMap.entries()).forEach(
      ([unitName, ratio]) => {
        if (ratio < 0.9 || ratio > 1.1) {
          unitsWithAvmCompRentPerSqftRatiosOutsideRange.push(unitName)
        }
      }
    )
    if (unitsWithAvmCompRentPerSqftRatiosOutsideRange.length > 0) {
      const unitNames = unitsWithAvmCompRentPerSqftRatiosOutsideRange.join(', ')
      return `Units: ${unitNames} Analyst Projected Rent per sqft is not within 10% of the average of the Rent Comps for that unit.`
    }
    if (
      computedValuationResult?.unitInputs.some(
        (unitInput) => unitInput.marketRentComps.length !== 3
      )
    ) {
      return 'Some units do not have 3 market rent comps.'
    }
    return ''
  }

  const canSubmitValuation = () => {
    const supervisorRequiredReason = getSupervisorReviewRequiredMessage()
    if (supervisorRequiredReason) {
      if (!supervisorUuids.includes(operatorUuid as string)) {
        notify(
          `Supervisor must review and submit this valuation. ${supervisorRequiredReason}`
        )
        return false
      }
    }
    if (valuationType === Core_ValuationType.ValuationTypeFinal) {
      if (!finalSubmitUuids.includes(operatorUuid as string)) {
        notify('Only supervisor can submit final valuation')
        return false
      }
    }
    return true
  }

  const canRejectValuation = () => {
    const supervisorRequiredReason = getSupervisorReviewRequiredMessage()
    if (supervisorRequiredReason) {
      if (!supervisorUuids.includes(operatorUuid as string)) {
        notify(
          `Supervisor must review and reject this valuation. ${supervisorRequiredReason}`
        )
        return false
      }
    }
    return true
  }

  // sets any state variables used for live validate state of the form
  const validateFormattedFormData = useCallback(
    (currentFormState: any) => {
      const newAnalystProjectedRentVsHousecanaryProjectedRent =
        currentFormState.offerPriceComps.map((comp: any) => {
          const { analystProjectedRent, housecanaryProjectedRent } = comp
          return analystProjectedRent / housecanaryProjectedRent
        })
      setAnalystProjectedRentVsHousecanaryProjectedRent(
        newAnalystProjectedRentVsHousecanaryProjectedRent
      )

      if (currentFormState.valuationType) {
        setValuationType(currentFormState.valuationType || '')
      }
      // compute per unit analyst projecte rent vs average of comps for that unit.
      const newPerUnitRentalCompsAverageVsAvmRentMap = new Map()
      addressUnitMap.forEach((unitName, addressUuid) => {
        const unitAnalystProjectedRent =
          currentFormState[`${addressUuid}AnalystProjectedRent`] || 0
        const unitSqft = currentFormState[`${addressUuid}Sqft`] || 0
        const unitRentPerSqft = unitAnalystProjectedRent / unitSqft

        let cumulativeCompRentPerSqft = 0
        let numComps = 0
        currentFormState.marketRentComps.forEach((comp: any) => {
          if (comp[`${addressUuid + unitName}RentalCompSelected`]) {
            const compRentPerSqft =
              comp.rentCompMarketRent / comp.rentCompSquareFootage
            cumulativeCompRentPerSqft += compRentPerSqft
            numComps += 1
          }
        })
        const averageCompRentPerSqft =
          numComps > 0 ? cumulativeCompRentPerSqft / numComps : 0

        const ratio = unitRentPerSqft / averageCompRentPerSqft
        newPerUnitRentalCompsAverageVsAvmRentMap.set(unitName, ratio)
      })
      // to prevent infinite loop since the input configs depend on the map which would rerender the form
      // and then call this method again. We only want to update the map if it is different.
      if (
        !isEqual(
          newPerUnitRentalCompsAverageVsAvmRentMap,
          perUnitRentalCompsAverageVsAvmRentMap
        )
      ) {
        setPerUnitRentalCompsAverageVsAvmRentMap(
          newPerUnitRentalCompsAverageVsAvmRentMap
        )
      }
    },
    [addressUnitMap, perUnitRentalCompsAverageVsAvmRentMap]
  )

  const generateUnitCheckboxesInputConfigsForOfferComps = useCallback(() => {
    const val: InputConfig<InputType.Checkbox>[] = Array.from(
      addressUnitMap.entries()
    ).map(([addressUuid, unitName]) => ({
      name: `${addressUuid + unitName}OfferCompSelected`,
      type: InputType.Checkbox,
      props: {
        label: unitName,
      },
    }))
    return val
  }, [addressUnitMap])

  const generateUnitCheckboxesInputConfigsForRentalComps = useCallback(() => {
    const val: InputConfig<InputType.Checkbox>[] = Array.from(
      addressUnitMap.entries()
    ).map(([addressUuid, unitName]) => ({
      name: `${addressUuid + unitName}RentalCompSelected`,
      type: InputType.Checkbox,
      props: {
        label: unitName,
      },
    }))
    return val
  }, [addressUnitMap])

  const generatePerUnitInputConfigSections = useCallback(() => {
    const val: AnyInputConfig[] = Array.from(addressUnitMap.entries()).map(
      ([addressUuid, unitName]) => ({
        name: `${unitName}Section`,
        type: InputType.Section,
        gridItemProps: {
          xs: 12,
          spacing: 2,
          pt: '16px',
        },
        props: {
          title: unitName,
          collapsible: true,
          inputConfigs: [
            {
              name: `${unitName}Column1`,
              type: InputType.Section,
              gridItemProps: {
                xs: 3,
              },
              props: {
                inputConfigs: [
                  {
                    name: `${addressUuid}Beds`,
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Beds',
                      format: 'number',
                    },
                  },
                  {
                    name: `${addressUuid}Baths`,
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Baths',
                      format: 'number',
                    },
                  },
                  {
                    name: `${addressUuid}Sqft`,
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Sqft',
                      format: 'number',
                    },
                  },
                ],
              },
            },
            {
              name: `${unitName}Column2`,
              type: InputType.Section,
              gridItemProps: {
                xs: 3,
              },
              props: {
                inputConfigs: [
                  {
                    name: `${addressUuid}AvmProjectedRent`,
                    type: InputType.Text,
                    required: true,
                    props: {
                      size: 'small',
                      label: 'Avm Projected Rent',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: `${addressUuid}AnalystProjectedRent`,
                    type: InputType.Text,
                    required: true,
                    props: {
                      size: 'small',
                      label: 'Analyst Projected Rent',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: `${addressUuid}RentNotes`,
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Rent Notes',
                      format: 'text',
                    },
                  },
                  {
                    name: `${addressUuid}AdditionalMonthlyCostNotes`,
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Add. Monthly Cost Notes',
                      format: 'text',
                    },
                  },
                ],
              },
            },
            {
              name: `${unitName}Column3`,
              type: InputType.Section,
              gridItemProps: {
                xs: 3,
              },
              props: {
                inputConfigs: [
                  {
                    name: `${addressUuid}RemodelCostScore`,
                    type: InputType.Dropdown,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType !==
                      Core_ValuationType.ValuationTypeFinal,
                    required: true,
                    props: {
                      size: 'small',
                      label:
                        'Remodel Cost Score (Verify with Zillow/HouseCanary photos)',
                      options: [
                        {
                          label: '(5) Pristine',
                          value: 5,
                        },
                        {
                          label: '(4) Great',
                          value: 4,
                        },
                        {
                          label: '(3) Average',
                          value: 3,
                        },
                        {
                          label: '(2) Below Average',
                          value: 2,
                        },
                        {
                          label: '(1) Requires Complete Renovation',
                          value: 1,
                        },
                      ],
                    },
                  },
                  {
                    name: `${addressUuid}HealthAndSafetyCapex`,
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    required: true,
                    props: {
                      size: 'small',
                      label: 'Health And Safety Capex',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: `${addressUuid}ImmediateAddressableCapex`,
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    required: true,
                    props: {
                      size: 'small',
                      label: 'Immediate Capex',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: `${addressUuid}TotalAddressableCapex`,
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    required: true,
                    props: {
                      size: 'small',
                      label: 'Non Immediate Capex',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: `${addressUuid}AdditionalMonthlyCost`,
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Add. Monthly Cost',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: `${addressUuid}IsSectionEight`,
                    type: InputType.Dropdown,
                    props: {
                      InputLabelProps: { shrink: true },
                      label: 'Is Section Eight?',
                      size: 'small',
                      options: [
                        {
                          label: 'No',
                          value: 'no',
                        },
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              name: `${unitName}Column4`,
              type: InputType.Section,
              gridItemProps: {
                xs: 3,
              },
              props: {
                inputConfigs: [
                  {
                    name: `${addressUuid}CurrentlyOccupied`,
                    type: InputType.Dropdown,
                    props: {
                      InputLabelProps: { shrink: true },
                      size: 'small',
                      label: 'Currently Occupied?',
                      options: [
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ],
                    },
                  },
                  {
                    name: `${addressUuid}CurrentRent`,
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields[`${addressUuid}CurrentlyOccupied`] ===
                      'yes',
                    required: true,
                    props: {
                      size: 'small',
                      label: `Current Monthly Rent - Lead reported ${
                        leadAnswers.rentAmount || 'nothing'
                      }`,
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: `${addressUuid}MonthsRemainingOnLease`,
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields[`${addressUuid}CurrentlyOccupied`] ===
                      'yes',
                    required: true,
                    props: {
                      size: 'small',
                      label: 'Months on Lease Left',
                      format: 'number',
                    },
                  },
                  {
                    name: `${addressUuid}leaseUpload`,
                    type: InputType.CustomComponent,
                    renderIf: (watchedFields: any) =>
                      watchedFields[`${addressUuid}CurrentlyOccupied`] ===
                        'yes' &&
                      valuationType === Core_ValuationType.ValuationTypeFinal,
                    props: {
                      component: (
                        <>
                          {addressLeaseUuidMap.get(addressUuid) ? (
                            <Button
                              size="mini"
                              onClick={() => {
                                downloadAddressDocument(
                                  addressLeaseUuidMap.get(addressUuid) as string
                                )
                              }}
                            >
                              Download Current Lease
                            </Button>
                          ) : (
                            <>
                              <Button
                                size="mini"
                                onClick={() => {
                                  const map = new Map(addressLeaseModalOpenMap)
                                  map.set(addressUuid, true)
                                  setAddressLeaseModalOpenMap(map)
                                }}
                              >
                                Add Lease / Tenant Info (required)
                              </Button>
                              <AddLeaseAndTenantInfoModal
                                address={lead?.address?.units.find(
                                  (unit: Core_ValidatedAddress) =>
                                    unit.uuid === addressUuid
                                )}
                                open={
                                  !!addressLeaseModalOpenMap.get(addressUuid)
                                }
                                onClose={() => {
                                  const map = new Map(addressLeaseModalOpenMap)
                                  map.set(addressUuid, false)
                                  setAddressLeaseModalOpenMap(map)
                                }}
                              />
                            </>
                          )}
                        </>
                      ),
                    },
                  },
                ],
              },
            },
          ],
        },
      })
    )
    return val
  }, [
    addressUnitMap,
    valuationType,
    addressLeaseUuidMap,
    addressLeaseModalOpenMap,
    downloadAddressDocument,
  ])

  const computeValuationFormInputConfigs: AnyInputConfig[] = useMemo(
    () => [
      {
        name: 'propertyDetails',
        type: InputType.Section,
        gridItemProps: { xs: 12, pt: '16px' },
        props: {
          title: 'Property Details',
          collapsible: true,
          inputConfigs: [
            {
              name: 'propertyDetailsFirstColumn',
              type: InputType.Section,
              gridItemProps: { xs: 6 },
              props: {
                title: '',
                sectionGridProps: { spacing: 2 },
                inputConfigs: [
                  {
                    name: 'address',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Address:{' '}
                          {lead?.address
                            ? formatAddressString(lead?.address)
                            : ''}{' '}
                          <br />
                          {formatCityStateZip(lead?.address || {})}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'county',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          County: {lead?.address?.county}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'propertyType',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Property Type:{' '}
                          {valuationInputs?.property?.propertyType}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'beds',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Beds: {valuationInputs?.property?.beds}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'baths',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Baths: {valuationInputs?.property?.baths}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'propertyCondition',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Self-Reported Condition:{' '}
                          {leadAnswers.propertyCondition || 'None'}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'selfReportedValue',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Self-Reported Value:{' '}
                          {valuationInputs?.propertyInput?.selfReportedValue ||
                            selfReportedValue ||
                            'None'}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'mortgageAmount',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Mortgage Amount:{' '}
                          {(leadAnswers.mortgageAmount &&
                            formatDollarsAndCents(
                              leadAnswers.mortgageAmount
                            )) ||
                            'None'}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'transactionType',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Transaction Type: {lead?.transactionType || 'None'}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'additionalInfo',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Additional Info:{' '}
                          {leadAnswers.additionalInfo || 'None'}
                        </Typography>
                      ),
                    },
                  },
                ],
              },
            },
            {
              name: 'propertyDetailsSecondColumn',
              type: InputType.Section,
              gridItemProps: { xs: 6 },
              props: {
                title: '',
                sectionGridProps: { spacing: 2 },
                inputConfigs: [
                  {
                    name: 'leadName',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Lead Name: {lead?.fullName}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'attributionAndLeadType',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="p2">
                            Attribution:{' '}
                            {formatAttribution(leadAnswers.attribution)}
                          </Typography>
                          <Typography variant="p2">
                            Lead Type: {leadAnswers.dstLead ? 'DST' : '721'}
                          </Typography>
                        </Box>
                      ),
                    },
                  },
                  {
                    name: 'yearBuilt',
                    type: InputType.Text,
                    defaultValue: valuationInputs?.property?.yearBuilt,
                    props: {
                      sx: { width: '112px' },
                      size: 'small',
                      label: 'Year Built',
                      format: 'year',
                    },
                  },
                  {
                    name: 'taxes',
                    type: InputType.Text,
                    required: true,
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts?.propertyTaxes,
                    props: {
                      sx: { width: '160px' },
                      size: 'small',
                      label: 'Property Taxes',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'inPlacePropertyTaxes',
                    type: InputType.Text,
                    required: true,
                    gridItemProps: { xs: 6 },
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts
                        ?.inPlacePropertyTaxes,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    props: {
                      sx: { width: '160px' },
                      size: 'small',
                      label: 'In Place Property Taxes',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'hoaExists',
                    type: InputType.Dropdown,
                    defaultValue: valuationInputs?.propertyInput?.otherCosts
                      ?.hoaExists
                      ? 'yes'
                      : 'no',
                    props: {
                      InputLabelProps: { shrink: true },
                      sx: { width: '128px' },
                      label: 'Is there an HOA?',
                      size: 'small',
                      options: [
                        {
                          label: 'No',
                          value: 'no',
                        },
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                      ],
                    },
                  },
                  {
                    name: 'yearlyHoaFee',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.hoaExists === 'yes',
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts?.hoa || 0,
                    props: {
                      sx: { width: '258px' },
                      size: 'small',
                      label: 'HOA Fee (yearly)',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'nearbyHoaAddress',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.hoaExists === 'yes',
                    defaultValue: `${
                      valuationInputs?.propertyInput?.otherCosts
                        ?.addressNearbyHomeHoa || ''
                    }`,
                    props: {
                      sx: { width: '100%' },
                      size: 'small',
                      label: 'Address of Nearby Home in same HOA',
                      format: 'text',
                    },
                  },
                  {
                    name: 'nearbyHoaUrl',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.hoaExists === 'yes',
                    defaultValue: `${
                      valuationInputs?.propertyInput?.otherCosts
                        ?.urlNearbyHomeHoa || ''
                    }`,
                    props: {
                      sx: { width: '100%' },
                      size: 'small',
                      label: 'URL of Nearby Home in same HOA',
                      format: 'text',
                    },
                  },
                  {
                    name: 'computationDate',
                    type: InputType.DatePicker,
                    defaultValue: '',
                    props: {
                      size: 'small',
                      label: 'Computation Date',
                      sx: { width: '160px' },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        name: 'genericValuationInputs',
        type: InputType.Section,
        gridItemProps: { xs: 12, pt: '16px' },
        props: {
          title: 'Generic Property Level Inputs',
          collapsible: true,
          inputConfigs: [
            {
              name: 'genericValuationColumn1',
              type: InputType.Section,
              gridItemProps: { xs: 3, spacing: 2 },
              props: {
                title: '',
                inputConfigs: [
                  {
                    name: 'valuationType',
                    type: InputType.Dropdown,
                    required: true,
                    renderIf: () => hasSubmittedInitialValuation,
                    defaultValue: valuationType,
                    props: {
                      size: 'small',
                      InputLabelProps: { shrink: true },
                      label: 'Valuation Type',
                      options: [
                        {
                          label: 'Initial',
                          value: Core_ValuationType.ValuationTypeInitial,
                        },
                        {
                          label: 'Final',
                          value: Core_ValuationType.ValuationTypeFinal,
                        },
                      ],
                    },
                  },
                  {
                    name: 'cashToClose',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.cashToClose || 0,
                    props: {
                      size: 'small',
                      label: 'Cash to Close',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'mortgageAmount',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.mortgageAmount ||
                      undefined,
                    props: {
                      size: 'small',
                      label: 'Mortgage Amount',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'cashTakeout',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.cashTakeout || undefined,
                    props: {
                      size: 'small',
                      label: 'Cash Takeout',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'contributionMonth',
                    type: InputType.DatePicker,
                    defaultValue: contributionMonth,
                    props: {
                      size: 'small',
                      label: `Expected contrib. mo. ${contributionMonth.toLocaleString(
                        'en-US',
                        {
                          month: 'long',
                        }
                      )}`,
                    },
                  },
                  {
                    name: 'fiveYearTreasuryRate',
                    type: InputType.Text,
                    required: false,
                    props: {
                      size: 'small',
                      label: '5 Year Treasury Rate',
                      format: 'percent',
                    },
                  },
                  {
                    name: 'propertyAvmValue',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.propertyAvmValue || 0,
                    required: false,
                    props: {
                      size: 'small',
                      label: 'Property AVM Value',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                ],
              },
            },
            {
              name: 'genericValuationColumn2',
              type: InputType.Section,
              gridItemProps: { xs: 3, spacing: 2 },
              props: {
                title: '',
                inputConfigs: [
                  {
                    name: 'miscellaneousCosts',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts?.miscellaneous,
                    props: {
                      size: 'small',
                      label: 'Misc. Costs',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'acquisitionCosts',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts?.acquisition,
                    props: {
                      size: 'small',
                      label: 'Acquisition Costs',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'additionalMonthlyCost',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts
                        ?.additionalMonthlyCost,
                    props: {
                      size: 'small',
                      label: 'Additional Monthly Cost',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'additionalCostNotes',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts
                        ?.additionalMonthlyCostNotes,
                    props: {
                      size: 'small',
                      label: 'Additional Cost Notes',
                      format: 'text',
                    },
                  },
                  {
                    name: 'propertyFlatAvmAdjustment',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.propertyFlatAvmAdjustment,
                    props: {
                      size: 'small',
                      label: 'Property Flat AVM Adjustment',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                ],
              },
            },
            {
              name: 'genericValuationColumn3',
              type: InputType.Section,
              gridItemProps: { xs: 3, spacing: 2 },
              props: {
                title: '',
                inputConfigs: [
                  {
                    name: 'otherCostNotes',
                    type: InputType.Text,
                    defaultValue: `${
                      valuationInputs?.propertyInput?.otherCosts?.notes || ''
                    }`,
                    props: {
                      size: 'small',
                      multiline: true,
                      minRows: 4,
                      maxRows: 4,
                      label: 'Other Cost Notes',
                    },
                  },
                  {
                    name: 'remodelCostNotes',
                    type: InputType.Text,
                    defaultValue: `${
                      valuationInputs?.propertyInput?.propertyRemodelCost
                        ?.notes || ''
                    }`,
                    props: {
                      size: 'small',
                      multiline: true,
                      minRows: 4,
                      maxRows: 4,
                      label: 'Remodel Cost Notes',
                    },
                  },
                  {
                    name: 'offerPriceNotes',
                    type: InputType.Text,
                    defaultValue: `${
                      valuationInputs?.propertyInput?.offerPriceNotes || ''
                    }`,
                    props: {
                      size: 'small',
                      multiline: true,
                      minRows: 4,
                      maxRows: 4,
                      label: 'Offer Price Notes',
                    },
                  },
                ],
              },
            },
            {
              name: 'genericValuationColumn4',
              type: InputType.Section,
              gridItemProps: { xs: 3, spacing: 2 },
              props: {
                title: '',
                inputConfigs: [
                  {
                    name: 'propertyHealthAndSafetyCapex',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    defaultValue: valuationInputs?.propertyInput
                      ?.propertyRemodelCost?.healthAndSafetyCapex
                      ? valuationInputs?.propertyInput?.propertyRemodelCost
                          ?.healthAndSafetyCapex
                      : null,
                    props: {
                      size: 'small',
                      label: 'Health & Safety Capex',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'propertyImmediateAddressableCapex',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    defaultValue:
                      valuationInputs?.propertyInput?.propertyRemodelCost
                        ?.immediateAddressableCapex &&
                      valuationInputs?.propertyInput?.propertyRemodelCost
                        ?.healthAndSafetyCapex
                        ? valuationInputs?.propertyInput?.propertyRemodelCost
                            ?.immediateAddressableCapex -
                          valuationInputs?.propertyInput?.propertyRemodelCost
                            ?.healthAndSafetyCapex
                        : null,
                    props: {
                      size: 'small',
                      label: 'Immed. Addressable Capex',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'propertyTotalAddressableCapex',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    defaultValue:
                      valuationInputs?.propertyInput?.propertyRemodelCost
                        ?.totalAddressableCapex &&
                      valuationInputs?.propertyInput?.propertyRemodelCost
                        ?.immediateAddressableCapex
                        ? valuationInputs?.propertyInput?.propertyRemodelCost
                            ?.totalAddressableCapex -
                          valuationInputs?.propertyInput?.propertyRemodelCost
                            ?.immediateAddressableCapex
                        : null,
                    props: {
                      size: 'small',
                      label: 'Total Addressable Capex',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                ],
              },
            },
            {
              name: 'houseCanaryReport',
              type: InputType.CustomComponent,
              gridItemProps: { xs: 4 },
              props: {
                component: (
                  <>
                    {housecanaryUploaded ? (
                      <Button
                        variant="outlined"
                        size="mini"
                        onClick={() => {
                          downloadAddressDocument(housecanaryUuid)
                        }}
                      >
                        Download Housecanary Report
                      </Button>
                    ) : (
                      <>
                        <UploadAddressDocumentModal
                          addressUuid={lead?.address?.uuid}
                          buttonLabel="Upload HC Report (optional)"
                          ctaText="Upload"
                          defaultType="housecanary"
                        />
                      </>
                    )}
                  </>
                ),
              },
            },
            {
              name: 'sowReport',
              type: InputType.CustomComponent,
              gridItemProps: { xs: 4 },
              renderIf: (watchedFields: any) =>
                watchedFields.valuationType ===
                Core_ValuationType.ValuationTypeFinal,
              props: {
                component: (
                  <>
                    {scopeOfWorkUploaded ? (
                      <Button
                        size="mini"
                        variant="secondary"
                        onClick={() => {
                          downloadAddressDocument(scopeOfWorkUuid)
                        }}
                      >
                        Download SOW
                      </Button>
                    ) : (
                      <>
                        <UploadAddressDocumentModal
                          addressUuid={lead?.address?.uuid}
                          buttonLabel="Upload SOW (optional)"
                          defaultType="scope_of_work"
                          ctaText="Upload"
                        />
                      </>
                    )}
                  </>
                ),
              },
            },
            {
              name: 'inspectionReport',
              type: InputType.CustomComponent,
              gridItemProps: { xs: 4 },
              renderIf: (watchedFields: any) =>
                watchedFields.valuationType ===
                Core_ValuationType.ValuationTypeFinal,
              props: {
                component: (
                  <>
                    {inspectionReportUploaded ? (
                      <Button
                        size="mini"
                        variant="secondary"
                        onClick={() => {
                          downloadAddressDocument(inspectionReportUuid)
                        }}
                      >
                        Download Inspection
                      </Button>
                    ) : (
                      <>
                        <UploadAddressDocumentModal
                          addressUuid={lead?.address?.uuid}
                          buttonLabel="Upload Inspection (optional}"
                          defaultType="report"
                          ctaText="Upload"
                        />
                      </>
                    )}
                  </>
                ),
              },
            },
          ],
        },
      },
      ...generatePerUnitInputConfigSections(),
      {
        name: 'offerPriceCompsSection',
        type: InputType.Section,
        gridItemProps: { xs: 12, pt: '16px' },
        props: {
          title: 'Offer Price Comps',
          collapsible: true,
          inputConfigs: [
            {
              name: 'offerPriceCompsErrorMessage',
              type: InputType.CustomComponent,
              props: {
                component: (
                  <>
                    {offerCompsErrorMessage && (
                      <Typography variant="p2" color="error">
                        {offerCompsErrorMessage}
                      </Typography>
                    )}
                  </>
                ),
              },
            },
            {
              name: 'offerPriceComps',
              type: InputType.Multiform,
              gridItemProps: { sx: { minWidth: '160px' }, spacing: 4 },
              defaultValue: numPrefillOfferComps,
              props: {
                title: 'Offer Price Comp',
                minForms: 3,
                maxForms: numUnits * 3,
                horizontal: true,
                itemContainerProps: { spacing: 4 },
                incrementText: 'Add Offer Comp',
                decrementText: 'Remove Offer Comp',
                inputConfigs: [
                  ...generateUnitCheckboxesInputConfigsForOfferComps(),
                  {
                    name: 'offerCompSource',
                    type: InputType.Dropdown,
                    props: {
                      size: 'small',
                      label: 'Source',
                      InputLabelProps: { shrink: true },
                      options: [
                        {
                          label: 'Housecanary',
                          value: Core_CompSource.CompSourceHousecanary,
                        },
                        {
                          label: 'Zillow',
                          value: Core_CompSource.CompSourceZillow,
                        },
                      ],
                    },
                  },
                  {
                    // since we render both single family and multi family at same time
                    // an issue where the address prefills on the single family form
                    // instead of the multi family form due to name collision
                    name: 'offerCompAddressMulti',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Address',
                    },
                  },
                  {
                    name: 'beds',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Beds',
                      format: 'number',
                    },
                  },
                  {
                    name: 'baths',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Baths',
                      format: 'number',
                    },
                  },
                  {
                    name: 'numUnits',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Num Units',
                      format: 'number',
                    },
                  },
                  {
                    name: 'squareFootage',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Square Footage (property)',
                      format: 'number',
                    },
                  },
                  {
                    name: 'similarityScore',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Similarity Score',
                      format: 'number',
                    },
                  },
                  {
                    name: 'offerCompSoldDate',
                    type: InputType.DatePicker,
                    props: {
                      size: 'small',
                      label: 'Sold Date',
                    },
                  },
                  {
                    name: 'avmPrice',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Sold Price (property)',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'yearBuilt',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Year Built',
                      format: 'year',
                    },
                  },
                  {
                    name: 'conditionScore',
                    type: InputType.Dropdown,
                    props: {
                      size: 'small',
                      InputLabelProps: { shrink: true },
                      label: 'Estimated Condition',
                      options: [
                        {
                          label: '(5) Pristine // Well-Maintained',
                          value: 5,
                        },
                        {
                          label: '(4) Great // Well-Maintained',
                          value: 4,
                        },
                        {
                          label: '(3) Average // Worn but adequate',
                          value: 3,
                        },
                        {
                          label: '(2) Below Average // Needs Work',
                          value: 2,
                        },
                        {
                          label:
                            '(1) Requires Complete Renovation // Needs Work',
                          value: 1,
                        },
                      ],
                    },
                  },
                  {
                    name: 'offerCompUrl',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'URL',
                      format: 'text',
                    },
                  },
                  {
                    name: 'offerCompDistance',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Distance (mi)',
                      format: 'number',
                    },
                  },
                  {
                    name: 'flatAvmAdjustment',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Flat AVM Adjustment (property)',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'offerCompNotes',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Notes',
                      multiline: true,
                      maxRows: 3,
                      format: 'text',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        name: 'marketRentCompsSection',
        type: InputType.Section,
        gridItemProps: { xs: 12, pt: '16px' },
        props: {
          title: 'Market Rent Comps',
          collapsible: true,
          inputConfigs: [
            {
              name: 'marketRentCompsErrorMessage',
              type: InputType.CustomComponent,
              props: {
                component: (
                  <>
                    {rentCompsErrorMessage && (
                      <Typography variant="p2" color="error">
                        {rentCompsErrorMessage}
                      </Typography>
                    )}
                  </>
                ),
              },
            },
            {
              name: 'marketRentComps',
              type: InputType.Multiform,
              gridItemProps: { sx: { minWidth: '160px' } },
              defaultValue: numPrefillRentalComps,
              props: {
                title: 'Market Rent Comp',
                minForms: 3,
                maxForms: numUnits * 3,
                horizontal: true,
                itemContainerProps: { spacing: 1 },
                incrementText: 'Add Rental Comp',
                decrementText: 'Remove Rental Comp',
                inputConfigs: [
                  ...generateUnitCheckboxesInputConfigsForRentalComps(),
                  {
                    name: 'rentCompSource',
                    type: InputType.Dropdown,
                    props: {
                      label: 'Source',
                      InputLabelProps: { shrink: true },
                      size: 'small',
                      options: [
                        {
                          label: 'Housecanary',
                          value: Core_CompSource.CompSourceHousecanary,
                        },
                        {
                          label: 'Zillow',
                          value: Core_CompSource.CompSourceZillow,
                        },
                      ],
                    },
                  },
                  {
                    // name collision with single family form
                    // so the address on the single family form will be prefilled instead
                    // without the difference in input name
                    name: 'rentCompAddressMulti',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Address',
                    },
                  },
                  {
                    name: 'rentCompSquareFootage',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Square Footage',
                      format: 'number',
                    },
                  },
                  {
                    name: 'rentCompRentalDate',
                    type: InputType.DatePicker,
                    props: {
                      size: 'small',
                      label: 'Rental Date',
                    },
                  },
                  {
                    name: 'rentCompMarketRent',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Market Rent',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'rentCompUrl',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'URL',
                      format: 'text',
                    },
                  },
                  {
                    name: 'rentCompDistance',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Distance (mi)',
                      format: 'number',
                    },
                  },
                  {
                    name: 'rentCompNotes',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Notes',
                      multiline: true,
                      maxRows: 3,
                      format: 'text',
                    },
                  },
                ],
              },
            },
            {
              name: 'marketRentCompRatioMessage',
              type: InputType.CustomComponent,
              props: {
                component: (
                  <>
                    {Array.from(
                      perUnitRentalCompsAverageVsAvmRentMap.entries()
                    ).map(([unitName, ratio]) => (
                      <Typography
                        key={unitName}
                        color={Math.abs(1 - ratio) > 0.1 ? 'error' : 'green'}
                      >
                        Per unit avm/sqft to average comp avm/sqft ratio for
                        unit &quot;
                        {unitName}&quot;: {ratio?.toFixed(2)}
                      </Typography>
                    ))}
                  </>
                ),
              },
            },
          ],
        },
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      generatePerUnitInputConfigSections,
      generateUnitCheckboxesInputConfigsForOfferComps,
      generateUnitCheckboxesInputConfigsForRentalComps,
      numUnits,
      contributionMonth,
      valuationInputs,
      downloadAddressDocument,
      housecanaryUploaded,
      housecanaryUuid,
      inspectionReportUploaded,
      inspectionReportUuid,
      scopeOfWorkUploaded,
      scopeOfWorkUuid,
      selfReportedValue,
      lead,
      valuationType,
      offerCompsPrefill,
      rentalCompsPrefill,
      perUnitRentalCompsAverageVsAvmRentMap,
    ]
  )

  const parseAndSetInputs = (formInputs: any) => {
    // parse property level inputs
    const contributionMonthDate = new Date(formInputs.contributionMonth)
    const contributionMonthMonth = contributionMonthDate.getMonth()
    // best way is prob map of <addressUuid, array> then for each comp, check if addressUuidSelected is true, if so push into the apppropriate array.
    const rentalCompsMap = new Map<string, Core_MarketRentCompInput[]>()
    const offerCompsMap = new Map<string, Core_OfferValuationCompInput[]>()

    const propertyVars = valuationInputs?.property as Core_Property
    const propertyImmediateAddressableCapex =
      (formInputs.propertyImmediateAddressableCapex || 0) +
      (formInputs.propertyHealthAndSafetyCapex || 0)
    const propertyTotalAddressableCapex =
      (formInputs.propertyImmediateAddressableCapex || 0) +
      (formInputs.propertyHealthAndSafetyCapex || 0) +
      (formInputs.propertyTotalAddressableCapex || 0)

    // generate the offer price comps per unit
    // using stored json to prefill so we don't need to set the address uuid on these comps. (the uuid for the comp itself, not the subject property/unit)
    formInputs.offerPriceComps.forEach((offerComp: any) => {
      Array.from(addressUnitMap.entries()).forEach(
        ([addressUuid, unitName]) => {
          if (offerComp[`${addressUuid + unitName}OfferCompSelected`]) {
            const offerCompInput: Core_OfferValuationCompInput = {
              address: offerComp.offerCompAddressMulti,
              avmPrice: offerComp.avmPrice,
              baths: offerComp.baths,
              beds: offerComp.beds,
              conditionScore: offerComp.conditionScore,
              distance: offerComp.offerCompDistance,
              notes: offerComp.offerCompNotes,
              numUnits: offerComp.numUnits,
              selected: true,
              similarityScore: offerComp.similarityScore,
              soldDate: offerComp.offerCompSoldDate,
              source: offerComp.offerCompSource,
              squareFootage: offerComp.squareFootage,
              url: offerComp.offerCompUrl,
              yearBuild: offerComp.yearBuilt,
              flatAvmAdjustment: offerComp.flatAvmAdjustment || 0,
            }
            if (offerCompsMap.has(addressUuid)) {
              offerCompsMap.get(addressUuid)?.push(offerCompInput)
            } else {
              offerCompsMap.set(addressUuid, [offerCompInput])
            }
          }
        }
      )
    })
    // generate the rental comps per unit.
    formInputs.marketRentComps.forEach((rentalComp: any) => {
      Array.from(addressUnitMap.entries()).forEach(
        ([addressUuid, unitName]) => {
          if (rentalComp[`${addressUuid + unitName}RentalCompSelected`]) {
            const rentalCompInput: Core_MarketRentCompInput = {
              address: rentalComp.rentCompAddressMulti,
              distance: rentalComp.rentCompDistance,
              marketRent: rentalComp.rentCompMarketRent,
              notes: rentalComp.rentCompNotes,
              rentalDate: rentalComp.rentCompRentalDate,
              selected: true,
              source: rentalComp.rentCompSource,
              squareFootage: rentalComp.rentCompSquareFootage,
              url: rentalComp.rentCompUrl,
            }
            if (rentalCompsMap.has(addressUuid)) {
              rentalCompsMap.get(addressUuid)?.push(rentalCompInput)
            } else {
              rentalCompsMap.set(addressUuid, [rentalCompInput])
            }
          }
        }
      )
    })

    // for each entry in address map
    const unitInputs: Core_MultiFamilyValuationUnitInputInput[] = Array.from(
      addressUnitMap.entries()
    ).map(([addressUuid, _]) => ({
      additionalMonthlyCost:
        formInputs[`${addressUuid}AdditionalMonthlyCost`] || 0,
      additionalMonthlyCostNotes:
        formInputs[`${addressUuid}AdditionalMonthlyCostNotes`],
      isSectionEight: formInputs[`${addressUuid}IsSectionEight`] === 'yes',
      addressUuid,
      analystProjectedRent: formInputs[`${addressUuid}AnalystProjectedRent`],
      avmProjectedRent: formInputs[`${addressUuid}AvmProjectedRent`],
      baths: formInputs[`${addressUuid}Baths`],
      bathsDouble: formInputs[`${addressUuid}Baths`],
      beds: formInputs[`${addressUuid}Beds`],
      currentRent: formInputs[`${addressUuid}CurrentRent`] || 0,
      currentlyOccupied:
        formInputs[`${addressUuid}CurrentlyOccupied`] === 'yes',
      marketRentComps: rentalCompsMap.get(addressUuid) || [],
      monthsRemainingOnLease:
        formInputs[`${addressUuid}MonthsRemainingOnLease`] || 0,
      offerPriceComps: offerCompsMap.get(addressUuid) || [],
      remodelCost: {
        score: formInputs[`${addressUuid}RemodelCostScore`],
        healthAndSafetyCapex:
          formInputs[`${addressUuid}HealthAndSafetyCapex`] || 0,
        immediateAddressableCapex:
          formInputs[`${addressUuid}ImmediateAddressableCapex`] +
            formInputs[`${addressUuid}HealthAndSafetyCapex`] || 0,
        totalAddressableCapex:
          formInputs[`${addressUuid}TotalAddressableCapex`] +
            formInputs[`${addressUuid}ImmediateAddressableCapex`] +
            formInputs[`${addressUuid}HealthAndSafetyCapex`] || 0,
      },
      rentNotes: formInputs[`${addressUuid}RentNotes`],
      squareFootage: formInputs[`${addressUuid}Sqft`],
    }))
    const propertyInput: Core_MultiFamilyValuationPropertyInputInput = {
      cashToClose: formInputs.cashToClose,
      mortgageAmount:
        formInputs.mortgageAmount !== ''
          ? formInputs.mortgageAmount
          : undefined,
      cashTakeout:
        formInputs.cashTakeout !== '' ? formInputs.cashTakeout : undefined,
      otherCosts: {
        acquisition: formInputs.acquisitionCosts || 0,
        miscellaneous: formInputs.miscellaneousCosts || 0,
        hoaExists: formInputs.hoaExists === 'yes',
        hoa: formInputs.yearlyHoaFee || 0,
        addressNearbyHomeHoa: formInputs.nearbyHoaAddress,
        urlNearbyHomeHoa: formInputs.nearbyHoaUrl,
        propertyTaxes: formInputs.taxes || 0,
        notes: formInputs.otherCostNotes,
        additionalMonthlyCost: formInputs.additionalMonthlyCost || 0,
        additionalMonthlyCostNotes: formInputs.additionalCostNotes,
        inPlacePropertyTaxes: formInputs.inPlacePropertyTaxes || 0,
      },
      propertyRemodelCost: {
        // only for final valuation
        healthAndSafetyCapex: formInputs.propertyHealthAndSafetyCapex || 0,
        immediateAddressableCapex: propertyImmediateAddressableCapex,
        totalAddressableCapex: propertyTotalAddressableCapex,
        notes: formInputs.remodelCostNotes,
      },
      selfReportedValue,
      fiveYearTreasuryRate: formInputs.fiveYearTreasuryRate / 100,
      propertyAvmValue: formInputs.propertyAvmValue,
      propertyFlatAvmAdjustment: formInputs.propertyFlatAvmAdjustment || 0,
      offerPriceNotes: formInputs.offerPriceNotes,
    }

    // get total property level values
    let baths = 0
    let beds = 0
    let sqft = 0
    Array.from(addressUnitMap.entries()).forEach(([addressUuid, _]) => {
      baths += formInputs[`${addressUuid}Baths`] || 0
      beds += formInputs[`${addressUuid}Beds`] || 0
      sqft += formInputs[`${addressUuid}Sqft`] || 0
    })
    let computationDate = null
    if (formInputs.computationDate) {
      computationDate = formInputs.computationDate
    }
    const parsedInputs: Core_ComputeMultiFamilyValuationRequestInput = {
      contributionMonth: contributionMonthMonth,
      leadUuid,
      property: {
        baths,
        bathsDouble: baths,
        beds,
        propertyType: propertyVars?.propertyType,
        sqft,
        yearBuilt: formInputs.yearBuilt,
      },
      propertyInput,
      unitInputs,
      valuationType: formInputs.valuationType,
      valuationUuid:
        computedValuationResult?.uuid || valuationInputs?.valuationUuid,
      computationDate,
    }
    setValuationInputs(parsedInputs)
    return parsedInputs
  }

  // returns a boolean representing whether the inputs are falz
  const validateInputs = (
    parsedInputs: Core_ComputeMultiFamilyValuationRequestInput
  ) => {
    // make sure all currently occupied leases have a lease uploaded

    const isFinalValuation =
      parsedInputs?.valuationType === Core_ValuationType.ValuationTypeFinal
    if (isFinalValuation && parsedInputs.unitInputs) {
      for (let i = 0; i < parsedInputs.unitInputs.length; i += 1) {
        const unitInput = parsedInputs.unitInputs[i]
        if (unitInput.currentlyOccupied) {
          if (!addressLeaseUuidMap.get(unitInput.addressUuid || '')) {
            notify(
              'A currently occupied unit does not have a lease. Must upload current lease for final valuation - upload and try again',
              'error'
            )
            return false
          }
        }
      }
    }
    // validate that all units have 3 offer and rental comps
    for (let i = 0; i < (parsedInputs?.unitInputs?.length || 0); i += 1) {
      // this if line solely for typescript purposes.
      if (parsedInputs?.unitInputs) {
        const unitInput = parsedInputs?.unitInputs[i]
        if (
          unitInput.offerPriceComps?.length !== 3 &&
          parsedInputs?.propertyInput?.propertyAvmValue === 0
        ) {
          notify('Not all units have 3 offer price comps selected', 'error')
          return false
        }
        // we don't want to validate all 3 market rent comps because sometimes we stress test initial.
        if (
          parsedInputs?.propertyInput?.propertyAvmValue === 0 &&
          unitInput.marketRentComps?.length !== 3
        ) {
          notify('Not all units have 3 market rent comps selected', 'error')
          return false
        }
        if (
          parsedInputs?.propertyInput?.propertyAvmValue === 0 &&
          !isComputeValuationRequestInputValid(
            Core_ValuationCategory.ValuationCategoryMultiFamily,
            unitInput.marketRentComps,
            unitInput.offerPriceComps,
            setOfferCompsErrorMessage,
            setRentCompsErrorMessage,
            true
          )
        ) {
          notify('Some comps are missing required inputs')
          return false
        }
      }
    }

    return true
  }

  const submitComputeValuation = async (formInputs: any) => {
    const parsedInputs = parseAndSetInputs(formInputs)
    const inputsValid = validateInputs(parsedInputs)
    if (!inputsValid) {
      return null
    }
    try {
      const result = await computeValuation({
        variables: {
          input: parsedInputs,
        },
      })
      setComputedValuationResult(
        result.data?.computeMultiFamilyValuation
          ?.valuation as Core_MultiFamilyValuation
      )
    } catch (e) {
      notify('Failed to compute the valuation - refresh and try again', 'error')
    }
    return null
  }

  return {
    computeValuationFormFormProps,
    computeValuationFormInputConfigs,
    loading,
    leadUuid,
    operatorUuid,
    rejectValuationModalOpen,
    setRejectValuationModalOpen,
    valuationHistoryModalOpen,
    setValuationHistoryModalOpen,
    submitComputeValuation,
    computedValuationResult,
    valuationInputs,
    validateFormattedFormData,
    canSubmitValuation,
    canRejectValuation,
    selfReportedValue,
    openValuationHistoryModal: () => setValuationHistoryModalOpen(true),
    openRejectValuationModal: () => setRejectValuationModalOpen(true),
    formattedAddress,
    numUnits,
    computeValuationLoading,
  }
}

export default useMultiFamilyComputeLeadValuationTab
