import {
  Core_SalesforceOfferComp,
  Core_SalesforceValuation,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useEffect, useState } from 'react'
import { generateGoogleMapUrl, GOOGLE_MAPS_API_KEY } from '@flock/shared-ui'

import {
  CompsPageProps,
  CompsPagePresentationalProps,
  CompsPageConfig,
  CompData,
} from './compsPageTypes'
import { CheckboxLabel } from '../../printCollateralPageTypes'

const MAX_PAGE_HEIGHT = 654

const useCompsPage: (props: CompsPageProps) => CompsPagePresentationalProps = (
  props: CompsPageProps
) => {
  const { scenarioValuationType, address, updateRenderState, index } = props

  const { formattedAddress, valuation, beds, baths, sqft } = address

  const [pageNumber, setPageNumber] = useState(0)
  const [renderedItemsIndex, setRenderedItemsIndex] = useState(0)
  const [pageConfigs, setPageConfigs] = useState<CompsPageConfig[]>([])
  const [flattenedAddresses, setFlattenedAddresses] = useState<CompData[]>([])
  const [subjectPropertyUnitNames, setSubjectPropertyUnitNames] =
    useState<string>()

  useEffect(() => {
    const targetValuation = valuation?.find(
      (val) => val?.valuationType === scenarioValuationType
    ) as Core_SalesforceValuation

    const { offerComps, offerPrice, uwRent } = targetValuation

    if (address?.units?.length) {
      const sortedUnits = [...address?.units]
      sortedUnits.sort((a, b) => {
        if (a?.unit && b?.unit) {
          return a.unit.localeCompare(b.unit)
        }
        return 0
      })
      const unitNames = sortedUnits.map((unit) => unit?.unit).join('|')
      setSubjectPropertyUnitNames(unitNames)
    }
    if (offerComps) {
      const newFlattenedAddresses = offerComps?.map((comp) => {
        const {
          formattedAddress: compAddress,
          beds: compBeds,
          baths: compBaths,
          sqft: compSqft,
          soldPrice,
          soldDate,
          numUnits,
        } = comp as Core_SalesforceOfferComp

        let imgUrl = ''

        if (compAddress) {
          imgUrl = generateGoogleMapUrl({
            type: 'streetview',
            location: `${compAddress}`,
            size: '144x144',
            key: GOOGLE_MAPS_API_KEY,
            fov: 100,
          })
        }
        // subject property has uwRent but not numUnits.
        // comps have numUnits but not nwRent
        return {
          formattedAddress: compAddress,
          beds: compBeds,
          baths: compBaths,
          sqft: `${compSqft!.toLocaleString()}`,
          price: `$${soldPrice!.toLocaleString()}`,
          soldDate: `SOLD ${new Date(soldDate).toLocaleDateString()}`,
          imgUrl,
          numUnits,
          uwRent: null as number | null,
        }
      })

      const imgUrl = generateGoogleMapUrl({
        type: 'streetview',
        location: `${formattedAddress}`,
        size: '144x144',
        key: GOOGLE_MAPS_API_KEY,
        fov: 100,
      })
      // this adds the subject property to the top of the comps list.
      newFlattenedAddresses?.unshift({
        formattedAddress,
        beds,
        baths,
        sqft: `${sqft!.toLocaleString()}`,
        price: `$${offerPrice!.toLocaleString()}`,
        soldDate: '',
        imgUrl,
        numUnits: null,
        uwRent: uwRent ?? null,
      })

      setFlattenedAddresses(newFlattenedAddresses as CompData[])
    }
  }, [formattedAddress, beds, baths, sqft, valuation, scenarioValuationType])

  useEffect(() => {
    if (flattenedAddresses.length) {
      const currentPageHeight = document.getElementById(
        `comps-page-${formattedAddress}-${pageNumber}`
      )?.clientHeight
      let newRenderedItemsIndex = renderedItemsIndex

      const newPageConfigs = [...pageConfigs]

      if (currentPageHeight && currentPageHeight > MAX_PAGE_HEIGHT) {
        const itemToMoveToNextPage = newPageConfigs[pageNumber].comps.pop()
        setPageNumber(pageNumber + 1)
        setPageConfigs([
          ...newPageConfigs,
          {
            pageNumber: pageNumber + 1,
            comps: [itemToMoveToNextPage as CompData],
          },
        ])
      } else if (flattenedAddresses[renderedItemsIndex]) {
        if (newPageConfigs.length === 0) {
          newPageConfigs.push({
            pageNumber: 0,
            comps: [],
          })
        }

        newPageConfigs[pageNumber].comps.push(
          flattenedAddresses[renderedItemsIndex]
        )
        newRenderedItemsIndex += 1
        setPageConfigs(newPageConfigs)
      } else {
        updateRenderState({
          section: CheckboxLabel.VALUATION_COMPS,
          doneRendering: true,
          numPages: pageConfigs.length,
          index,
        })
      }
      setRenderedItemsIndex(newRenderedItemsIndex)
    }
  }, [JSON.stringify(flattenedAddresses), JSON.stringify(pageConfigs)])

  return {
    ...props,
    formattedAddress: formattedAddress as string,
    pageConfigs,
    unitNames: subjectPropertyUnitNames,
  }
}

export default useCompsPage
