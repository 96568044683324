import { InfoIcon } from '@flock/shared-ui'
import { Box, Typography, Tooltip } from '@mui/material'
import React from 'react'

const LabelledField = (props: {
  label: string
  width?: string
  tooltip?: React.ReactNode
  children: React.ReactNode
}) => {
  const { label, children, width, tooltip } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      width={width || '100%'}
    >
      <Box display="flex" gap="4px" alignItems="center">
        <Typography variant="ctam" height="24px">
          {label}
        </Typography>
        {tooltip && (
          <Tooltip
            title={tooltip}
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'green0.main',
                  maxWidth: '304px',
                  borderRadius: '9px',
                  boxShadow:
                    '0px 8px 32px 0px rgba(91, 115, 139, 0.24), 0px 0px 2px 0px rgba(91, 115, 139, 0.16)',
                  color: 'gray8.main',
                  fontFamily: 'Outfit',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px' /* 142.857% */,
                  '& .MuiTooltip-arrow': {
                    color: 'gray1.main',
                  },
                },
              },
            }}
          >
            <Box sx={{ cursor: 'pointer' }}>
              {/* @ts-ignore */}
              <InfoIcon fontSize="small" color="#FFB029" />
            </Box>
          </Tooltip>
        )}
      </Box>

      {children}
    </Box>
  )
}

LabelledField.defaultProps = {
  width: '100%',
  tooltip: '',
}

export default LabelledField
