import React from 'react'
import {
  BaseModal,
  AuditLogTable,
  useSnackbar,
  CircularProgress,
  AuditTableEntry,
} from '@flock/flock-component-library'
import { useQuery } from '@apollo/client'
import pluralize from 'pluralize'
import {
  AdminGetAuditLogsDocument,
  AdminGetOperatorDocument,
  Core_AuditLog,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import _ from 'lodash'
import { styled } from '@mui/material'

const LoadingWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

type AuditLogModalProps = {
  open: boolean
  onClose: () => void
  entityUuid: string
}

const AuditLogModal = (props: AuditLogModalProps) => {
  const { open, onClose, entityUuid } = props

  const { refetch: fetchOperator } = useQuery(AdminGetOperatorDocument, {
    skip: true,
  })

  const { data, loading } = useQuery(AdminGetAuditLogsDocument, {
    skip: !entityUuid || !open,
    variables: {
      input: {
        tags: [entityUuid],
      },
    },
  })

  const processedData = data?.auditLogs?.auditLogs?.map(
    (log: Core_AuditLog | null | undefined) => {
      const previousJson = JSON.parse(log?.previousValue || '{}')
      const updatedJson = JSON.parse(log?.updatedValue || '{}')
      const { operation, tableName } = log as Core_AuditLog

      let eventName = ''
      // For changes on legal entities, we want to alter the event name based on what was updated
      const alteredKeys = Object.keys(updatedJson)
      const singleFieldAltered = alteredKeys.length === 1
      const cleanedTableName = pluralize(
        tableName?.replace('legal_entities_', '') || '',
        1
      )

      switch (tableName) {
        case 'legal_entities':
          // Multiple fields updated
          if (
            singleFieldAltered &&
            (alteredKeys.includes('plaidAccessToken') ||
              alteredKeys.includes('basistheoryToken'))
          ) {
            eventName = 'Bank Account Information Updated'
          } else if (
            singleFieldAltered &&
            alteredKeys.includes('cashDistributionEnabled')
          ) {
            eventName = 'Cash Distribution Preference Updated'
          } else {
            eventName = 'Legal Entity Information Updated'
          }
          break
        default:
          // Add a 'd' at the end to make the operation past tense
          eventName = `${cleanedTableName} ${operation}d`
          break
      }
      const result = {
        ...log,
        eventName: _.startCase(eventName),
        previousValue: previousJson,
        updatedValue: updatedJson,
      }
      return result
    }
  )

  const { notify } = useSnackbar()

  const fetchUserName = async (uuid: string, entityType: string) => {
    if (entityType === 'operator') {
      try {
        const { data: operatorData } = await fetchOperator({
          input: { operatorUuid: uuid },
        })
        return operatorData?.operator?.operator?.fullName || 'Unknown'
      } catch (e) {
        notify(
          'An error occurred while looking up the associated user for this audit log.'
        )
        return 'Error'
      }
    } else {
      return 'User'
    }
  }

  return (
    <BaseModal title="Event Log" open={open} onClose={onClose} closeOnClickoff>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <AuditLogTable
          auditLogEntries={processedData as AuditTableEntry[]}
          fetchUserName={fetchUserName}
          showDetails
          isTransaction={false}
        />
      )}
    </BaseModal>
  )
}

export default AuditLogModal
