import React from 'react'
import { Modal, Paper } from '@mui/material'
import { GridForm } from '@flock/shared-ui'
import useSubmitValuationModal from './useSubmitValuationModal'
import { SubmitValuationModalProps } from './submitValuationModalTypes'

const SubmitValuationModal = (props: SubmitValuationModalProps) => {
  const { open, onClose } = props
  const { onSubmit, submitValuationInputConfigs, loading } =
    useSubmitValuationModal(props)
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '80px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          width: '560px',
          maxHeight: '75vh',
          overflowY: 'scroll',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <GridForm
          inputConfigs={submitValuationInputConfigs}
          onSubmit={onSubmit}
          ctaText="Submit"
          loading={loading}
          ctaButtonProps={{ sx: { width: '140px' } }}
        />
      </Paper>
    </Modal>
  )
}

export default SubmitValuationModal
