import React, { useEffect, useState } from 'react'
import {
  CircleCheckedIcon,
  CircleUncheckedIcon,
  Dropdown,
  FormattedTextField,
  TrackedButton,
  useSnackbar,
} from '@flock/shared-ui'
import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import { useMutation } from '@apollo/client'
import { AdminUpdateScopeOfWorkProjectDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { SowProject } from './individualSowProjectPageTypes'
import LabelledField from './LabelledField'
import { useSowSaveContext } from './SowSaveContextProvider'
import StatusButton from './StatusButton'

type EditPropertyDataModalProps = {
  open: boolean
  onClose: () => void

  sowProjects: SowProject[]
}

const EditPropertyDataModal = (props: EditPropertyDataModalProps) => {
  const { open, onClose, sowProjects } = props
  const [selectedProject, setSelectedProject] = useState<number>(0)

  const [curBeds, setCurBeds] = useState<number>(
    sowProjects[selectedProject].beds
  )
  const [curBaths, setCurBaths] = useState<number>(
    sowProjects[selectedProject].baths
  )
  const [curSquareFootage, setCurSquareFootage] = useState<number>(
    sowProjects[selectedProject].squareFootage
  )
  const [curYearBuilt, setCurYearBuilt] = useState<number>(
    sowProjects[selectedProject].yearBuilt
  )
  const [curProjectNotes, setCurProjectNotes] = useState<string>(
    sowProjects[selectedProject].notes
  )

  const [curProjectStatus, setCurProjectStatus] = useState(
    sowProjects[selectedProject].projectStatus
  )
  const [curIsOccupied, setCurIsOccupied] = useState<boolean>(
    sowProjects[selectedProject].isOccupied
  )
  const [loading, setLoading] = useState(false)
  const [updateSOWProject] = useMutation(AdminUpdateScopeOfWorkProjectDocument)

  const { setSaveLoading, onSaveSuccess, onSaveError, refetchProject } =
    useSowSaveContext()
  const { notify } = useSnackbar()

  const onSave = async () => {
    setSaveLoading(true)
    setLoading(true)
    try {
      await updateSOWProject({
        variables: {
          input: {
            uuid: sowProjects[selectedProject].uuid,
            numBeds: curBeds,
            numBaths: curBaths,
            numSqft: curSquareFootage,
            projectStatus: curProjectStatus,
            yearBuilt: curYearBuilt,
            isOccupied: curIsOccupied,
            notes: curProjectNotes,
          },
        },
      })
      await refetchProject()
      notify('Property data saved', 'success')
      onSaveSuccess()
      onClose()
    } catch (e) {
      notify(`Error saving property data: ${e.message}`, 'error')
      onSaveError(`Failed to save property data: ${e.message}`)
    }
    setLoading(false)
  }

  useEffect(() => {
    setCurBeds(sowProjects[selectedProject].beds)
    setCurBaths(sowProjects[selectedProject].baths)
    setCurSquareFootage(sowProjects[selectedProject].squareFootage)
    setCurYearBuilt(sowProjects[selectedProject].yearBuilt)
    setCurProjectNotes(sowProjects[selectedProject].notes)
    setCurProjectStatus(sowProjects[0].projectStatus)
    setCurIsOccupied(sowProjects[selectedProject].isOccupied)
  }, [selectedProject, sowProjects])

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        position="absolute"
        width="560px"
        p="16px"
        sx={{
          top: '25%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'gray1.main',
          borderRadius: '8px',
        }}
      >
        <Box display="flex" flexDirection="column" gap="16px">
          <Typography variant="ctam">
            {sowProjects[selectedProject].formattedAddress}
          </Typography>
          <Box display="flex" gap="8px">
            {sowProjects.map((project, index) => (
              <Box
                display="flex"
                justifyContent="center"
                gap="8px"
                padding="8px"
                borderRadius="8px"
                width="100%"
                onClick={() => setSelectedProject(index)}
                sx={{
                  cursor: 'pointer',
                  backgroundColor:
                    index === selectedProject ? 'green2.main' : 'green0.main',
                }}
              >
                <Typography variant="ctam">{project.projectName}</Typography>
              </Box>
            ))}
          </Box>
          <Box display="flex" gap="16px">
            <LabelledField label="Beds">
              <FormattedTextField
                format="number"
                onChange={(e) => setCurBeds(parseInt(e.target.value, 10))}
                value={curBeds}
                variant="filled"
                placeholder="0"
              />
            </LabelledField>
            <LabelledField label="Baths">
              <FormattedTextField
                format="number"
                onChange={(e) => setCurBaths(parseFloat(e.target.value))}
                value={curBaths}
                variant="filled"
                placeholder="0"
              />
            </LabelledField>
            <LabelledField label="Square Footage">
              <FormattedTextField
                format="number"
                onChange={(e) =>
                  setCurSquareFootage(parseFloat(e.target.value))
                }
                value={curSquareFootage}
                variant="filled"
                placeholder="0"
              />
            </LabelledField>
            <LabelledField label="Year Built">
              <FormattedTextField
                format="year"
                onChange={(e) => setCurYearBuilt(parseFloat(e.target.value))}
                value={curYearBuilt}
                variant="filled"
                placeholder="0"
              />
            </LabelledField>
          </Box>
          {selectedProject !== 0 && (
            <LabelledField label="Is Occupied">
              <Dropdown
                variant="outlined"
                size="small"
                InputProps={{
                  style: {
                    height: '50px',
                    padding: '0px',
                  },
                }}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                value={curIsOccupied ? 'Yes' : 'No'}
                onChange={(e) => setCurIsOccupied(e.target.value === 'Yes')}
              />
            </LabelledField>
          )}
          <Box display="flex" gap="16px">
            <LabelledField label="Notes">
              <FormattedTextField
                onChange={(e) => setCurProjectNotes(e.target.value)}
                value={curProjectNotes}
                variant="filled"
                placeholder=""
                fullWidth
              />
            </LabelledField>
          </Box>

          <Box display="flex" gap="8px">
            <StatusButton
              label="WIP"
              selected={curProjectStatus === 'in_progress'}
              icon={<CircleUncheckedIcon width="16px" height="16px" />}
              onClick={() => {
                setCurProjectStatus('in_progress')
              }}
            />
            <StatusButton
              label="DONE"
              selected={curProjectStatus === 'completed'}
              icon={<CircleCheckedIcon width="16px" height="16px" />}
              onClick={() => {
                setCurProjectStatus('completed')
              }}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <TrackedButton
              variant="primary"
              size="smallForm"
              onClick={onSave}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size="24px" color="white" />
              ) : (
                'Save'
              )}
            </TrackedButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default EditPropertyDataModal
