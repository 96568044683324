import { useMutation } from '@apollo/client'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import {
  AdminGetLeadDocument,
  AdminUpdateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { Typography } from '@mui/material'
import React, { useState } from 'react'
import zipToTz from 'zip-to-tz'
import ActionFormModal from '../shared/ActionFormModal'

type EditInspectionDateModalProps = {
  open: boolean
  onClose: () => void
  leadUuid: string
  inspectionDate: Date | null
  zipcode: string
}

const EditInspectionDateModal = (props: EditInspectionDateModalProps) => {
  const { open, onClose, leadUuid, inspectionDate, zipcode } = props
  const [loading, setLoading] = useState(false)
  const [updateLead] = useMutation(AdminUpdateLeadDocument)
  const [currentLeadTime, setCurrentLeadTime] = useState('')

  const { notify } = useSnackbar()

  const onSubmit = async (params: { inspectionDate: string }) => {
    const { inspectionDate: newInspectionDate } = params
    setLoading(true)
    try {
      await updateLead({
        variables: {
          updateLeadInput: {
            leadUuid,
            inspectionDate: newInspectionDate,
          },
        },
        refetchQueries: [AdminGetLeadDocument],
      })
      notify('Successfully set inspection date.', 'success')
      onClose()
    } catch (e) {
      notify('Failed to update inspection date.', 'error')
    }
    setLoading(false)
  }

  const onUpdated = async (params: { inspectionDate: string }) => {
    const { inspectionDate: currentInspectionDate } = params
    const tempDate = new Date(currentInspectionDate)
    let leadTimeZone = ''
    try {
      leadTimeZone = zipToTz(zipcode) || ''
    } catch (e) {
      console.error(e)
    }
    const formattedTime = tempDate?.toLocaleString(undefined, {
      timeZoneName: 'short',
      timeZone: leadTimeZone,
    })
    setCurrentLeadTime(formattedTime as string)
  }

  const inputConfigs = [
    {
      inputName: 'inspectionDate',
      inputType: InputType.DatePicker,
      props: {
        label: 'Inspection Date (Local Timezone)',
        defaultValue: inspectionDate,
        selectTime: true,
      },
    },
    {
      inputName: 'leadTime',
      inputType: InputType.NonInput,
      props: {
        component: (
          <>
            <Typography>Inspection Date (Lead Timezone)</Typography>
            <Typography>{currentLeadTime}</Typography>
          </>
        ),
      },
    },
  ]

  return (
    <ActionFormModal
      open={open}
      setOpen={onClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Edit Inspection Date"
      onUpdated={onUpdated}
      formProps={{
        defaultValues: {
          inspectionDate,
        },
        mode: 'onChange',
      }}
      inputConfigs={inputConfigs}
    />
  )
}

export default EditInspectionDateModal
