import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, Typography } from '@mui/material'
import {
  MinusIcon,
  PlusIcon,
  TrackedIconButton,
  TrackedLink,
} from '@flock/shared-ui'
import { BorderColor } from '@mui/icons-material'

import useDeficiencyRow from './useDeficiencyRow'
import { DeficiencyRowProps } from './deficiencyRowTypes'
import SolutionRow from './SolutionRow'
import StatusPicker from '../StatusPicker'

const DeficiencyRow = (props: DeficiencyRowProps) => {
  const presentationalProps = useDeficiencyRow(props)
  const {
    uuid,
    expanded,
    setExpanded,
    status,
    location,
    category,
    deficiency,
    solutions,
    openEditDeficiency,
    costCatalog,

    updateDeficiency,
  } = presentationalProps

  return (
    <Box
      display="flex"
      flexDirection="column"
      p="4px"
      borderRadius="8px"
      sx={{
        backgroundColor: 'gray1.main',
      }}
    >
      <Box
        display="flex"
        gap="4px"
        p="8px"
        sx={{
          cursor: 'pointer',
        }}
      >
        <StatusPicker status={status} updateStatus={updateDeficiency} />
        <Box
          py="4px"
          minWidth="120px"
          display="flex"
          alignItems="center"
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant="c1m"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              openEditDeficiency(uuid)
            }}
          >
            {location}
          </Typography>
        </Box>
        <Box
          py="4px"
          width="100%"
          display="flex"
          alignItems="center"
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant="c1m"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              openEditDeficiency(uuid)
            }}
          >
            {category} | {deficiency}
          </Typography>
        </Box>
        <Box
          display="flex"
          gap="8px"
          alignItems="center"
          onClick={() => setExpanded(!expanded)}
        >
          <TrackedIconButton
            color="primary"
            sx={{
              backgroundColor: 'green0.main',
              width: '24px',
              height: '24px',
            }}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              openEditDeficiency(uuid)
            }}
          >
            <BorderColor
              color="primary"
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
          </TrackedIconButton>
          <TrackedIconButton
            color="primary"
            sx={{
              backgroundColor: 'green0.main',
              width: '24px',
              height: '24px',
            }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? (
              <MinusIcon width="16px" height="16px" />
            ) : (
              <PlusIcon width="16px" height="16px" />
            )}
          </TrackedIconButton>
        </Box>
      </Box>
      {expanded && (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            px="8px"
            py="4px"
          >
            <Box
              width="100%"
              height="1px"
              sx={{
                backgroundColor: 'gray5.main',
              }}
            />
          </Box>
          {solutions.length === 0 && (
            <Box
              px="8px"
              py="8px"
              sx={{
                cursor: 'pointer',
              }}
            >
              <TrackedLink
                variant="p4"
                onClick={() => openEditDeficiency(uuid)}
              >
                No solutions, edit to add solutions
              </TrackedLink>
            </Box>
          )}
          {solutions.map((itemSolution) => {
            const { uuid: solutionUuid } = itemSolution
            const genUuid = uuidv4()
            return (
              <SolutionRow
                key={`${solutionUuid}-${genUuid}`}
                costCatalog={costCatalog}
                {...itemSolution}
              />
            )
          })}
        </>
      )}
    </Box>
  )
}

export default DeficiencyRow
