import React, { useState } from 'react'
import {
  Typography,
  DataTable,
  LoadingCard,
  flockColors,
  PopoverMenu,
  useSnackbar,
} from '@flock/flock-component-library'
import {
  AdminGetAllInvestorAccountsCsvUrlDocument,
  AdminGetAllInvestorAccountsDocument,
  AdminGetAllLegalEntitiesCsvUrlDocument,
  Core_InvestorAccount,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { styled } from '@mui/material/styles'
import { TableRow, TableCell } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'
import { useQuery } from '@apollo/client'

import { navigate } from '@reach/router'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { INVESTOR_MANAGEMENT_URL } from '../../constants'
import InitiateInvestorOnboardingModal from './InitiateInvestorOnboardingModal'
import CreateInvestorLegalEntityModal from './CreateInvestorLegalEntityModal'
import CreateDocumentModal from './CreateDocumentModal'
import CreateUpdateModal from './CreateUpdateModal'
import DeleteInvestorAccountModal from './DeleteInvestorAccountModal'
import EditInvestorAccountModal from './EditInvestorAccountModal'

const columns = [
  { name: 'uuid', label: 'UUID', options: { display: false } },
  { name: 'firstName', label: 'First Name' },
  { name: 'lastName', label: 'Last Name' },
  { name: 'email', label: 'Email' },
  { name: 'phoneNumber', label: 'Phone' },
  { name: 'dateJoined', lable: 'Date Joined', options: { display: false } },
  { name: '', label: '' },
  { name: '', label: '' },
]

const HoveredTableCell = styled(TableCell)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

const CustomRowRender =
  (
    openEditInvestorAccount: (investorAccount: Core_InvestorAccount) => void,
    openDeleteInvestorAccount: (investorAccount: Core_InvestorAccount) => void
  ) =>
  (data: [string, string, string, string, string, string]) => {
    const [uuid, firstName, lastName, email, phoneNumber] = data
    const editInvestorAccount = {
      uuid,
      firstName,
      lastName,
      email,
      phoneNumber,
    }

    return (
      <TableRow>
        <TableCell>{firstName}</TableCell>
        <TableCell>{lastName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{phoneNumber}</TableCell>
        <HoveredTableCell
          onClick={() => openEditInvestorAccount(editInvestorAccount)}
        >
          <EditIcon />
        </HoveredTableCell>
        <HoveredTableCell
          onClick={() => openDeleteInvestorAccount(editInvestorAccount)}
        >
          <DeleteIcon />
        </HoveredTableCell>
        <HoveredTableCell
          align="right"
          onClick={() => {
            navigate(`${INVESTOR_MANAGEMENT_URL}/${uuid}`)
          }}
          data-cy="investorAccount"
        >
          <ArrowForwardIos />
        </HoveredTableCell>
      </TableRow>
    )
  }

// ToDo: figure out how to have a custom search bar
// const CustomSearchRender = (props) => {
//   const { classes, onSearch, searchText } = props
//   const handleTextChange = (event) => {
//     onSearch(event.target.value)
//   }

//   return (
//     <Grow appear in={true} timeout={300}>
//       <TextField
//         placeholder="Search"
//         size="medium"
//         className={classes.searchText}
//         value={searchText || ''}
//         onChange={handleTextChange}
//         fullWidth
//         variant="outlined"
//         InputProps={{
//           startAdornment: (
//             <InputAdornment position="start">
//               <SearchOutlined />
//             </InputAdornment>
//           ),
//         }}
//       />
//     </Grow>
//   )
// }

const options = (
  customRowRender: (
    data: [string, string, string, string, string, string]
  ) => any
) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: true,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender,
  elevation: 0,
  // customSearchRender: (searchText, handleSearch) => (
  //   <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
  // ),
})

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
})

const InvestorManagementPage = () => {
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false)
  const [documentModalOpen, setDocumentModalOpen] = useState<boolean>(false)
  const [
    initiateInvestorOnboardingModalOpen,
    setInitiateInvestorOnboardingModalOpen,
  ] = useState(false)
  const [
    createInvestorLegalEntityModalOpen,
    setCreateInvestorLegalEntityModalOpen,
  ] = useState(false)
  const [editInvestorAccountModalOpen, setEditInvestorAccountModalOpen] =
    useState<boolean>(false)
  const [investorAccount, setInvestorAccount] = useState<Core_InvestorAccount>()
  const [deleteInvestorAccountModalOpen, setDeleteInvestorAccountModalOpen] =
    useState(false)
  const openEditInvestorAccountModal = (
    editingInvestorAccount: Core_InvestorAccount
  ) => {
    setEditInvestorAccountModalOpen(true)
    setInvestorAccount(editingInvestorAccount)
  }
  const closeEditInvestorAccountModal = () => {
    setEditInvestorAccountModalOpen(false)
  }

  const { notify } = useSnackbar()

  const closeUpdateModal = () => {
    setUpdateModalOpen(false)
  }
  const closeDocumentModal = () => {
    setDocumentModalOpen(false)
  }

  const openDeleteInvestorAccountModal = (
    deletingInvestorAccount: Core_InvestorAccount
  ) => {
    setDeleteInvestorAccountModalOpen(true)
    setInvestorAccount(deletingInvestorAccount)
  }
  const closeDeleteInvestorAccountModal = () => {
    setDeleteInvestorAccountModalOpen(false)
  }
  const closeInitiateInvestorOnboardingModal = () => {
    setInitiateInvestorOnboardingModalOpen(false)
  }
  const closeCreateInvestorLegalEntityModal = () => {
    setCreateInvestorLegalEntityModalOpen(false)
  }

  const { loading, data } = useQuery(AdminGetAllInvestorAccountsDocument, {
    onError: () => {
      notify('Failed to get all investor accounts', 'error')
    },
  })

  const { refetch } = useQuery(AdminGetAllLegalEntitiesCsvUrlDocument, {
    skip: true,
    onError: () => {
      notify('Failed to get legal entities csv s3 url', 'error')
    },
  })

  const { refetch: refetchInvestors } = useQuery(
    AdminGetAllInvestorAccountsCsvUrlDocument,
    {
      skip: true,
      onError: () => {
        notify('Failed to get investor accounts csv s3 url', 'error')
      },
    }
  )

  const getAllInvestorAccountsCsvUrl = async () => {
    try {
      const urlData = await refetchInvestors()
      const s3Url = urlData?.data?.investorAccountsCsvUrl?.url as string
      window.open(s3Url, '_self')
    } catch (e) {
      notify(
        'An error occurred while downloading the document. Please refresh or try again.',
        'error'
      )
    }
  }

  const getAllLegalEntitiesCsvUrl = async () => {
    try {
      const urlData = await refetch()
      const s3Url = urlData?.data?.legalEntitiesCsvUrl?.url as string
      window.open(s3Url, '_self')
    } catch (e) {
      notify(
        'An error occurred while downloading the document. Please refresh or try again.',
        'error'
      )
    }
  }

  const formatInvestorAccount = (editingInvestorAccount: {
    uuid: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
  }) => ({
    uuid: editingInvestorAccount.uuid,
    firstName: editingInvestorAccount.firstName,
    lastName: editingInvestorAccount.lastName,
    email: editingInvestorAccount.email,
    phoneNumber: editingInvestorAccount.phoneNumber,
  })

  const investorAccounts =
    data?.getAllInvestorAccounts?.investorAccounts?.map(
      formatInvestorAccount as any
    ) || []

  const bulkActions = [
    {
      text: 'Bulk Investor Update',
      onClick: () => {
        setUpdateModalOpen(true)
      },
    },
    {
      text: 'Bulk Document',
      onClick: () => {
        setDocumentModalOpen(true)
      },
    },
    // todo: resurface this once finished refactoring to transactions
    {
      text: 'Export All Investors',
      onClick: () => {
        getAllInvestorAccountsCsvUrl()
      },
    },
    {
      text: 'Export All Legal Entities',
      onClick: () => {
        getAllLegalEntitiesCsvUrl()
      },
    },
  ]

  const investorActions = [
    {
      text: 'Create New Investor and Legal Entity',
      onClick: () => {
        setInitiateInvestorOnboardingModalOpen(true)
      },
    },
    {
      text: 'Create Legal Entity for Existing Investor',
      onClick: () => {
        setCreateInvestorLegalEntityModalOpen(true)
      },
    },
  ]

  return (
    <>
      <PageTitleContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h1">Investor Management</Typography>
          <div>
            <PopoverMenu
              text="Investor Onboarding"
              buttonProps={{
                variant: 'outlined',
                sx: {
                  marginRight: '1rem',
                },
              }}
              popoverProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  horizontal: 'right',
                  vertical: 'top',
                },
              }}
              actions={investorActions}
            />
            <PopoverMenu
              text="Bulk Actions"
              buttonProps={{
                variant: 'outlined',
              }}
              popoverProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  horizontal: 'right',
                  vertical: 'top',
                },
              }}
              actions={bulkActions}
            />
          </div>
        </div>
      </PageTitleContainer>
      {loading ? (
        <LoadingCard text="" />
      ) : (
        <DataTable
          title=""
          data={investorAccounts as Core_InvestorAccount[]}
          columns={columns}
          options={
            options(
              CustomRowRender(
                openEditInvestorAccountModal,
                openDeleteInvestorAccountModal
              )
            ) as any
          }
        />
      )}
      <InitiateInvestorOnboardingModal
        isOpen={initiateInvestorOnboardingModalOpen}
        close={closeInitiateInvestorOnboardingModal}
      />
      <CreateInvestorLegalEntityModal
        isOpen={createInvestorLegalEntityModalOpen}
        close={closeCreateInvestorLegalEntityModal}
      />
      <CreateUpdateModal
        isBulk
        isOpen={updateModalOpen}
        close={closeUpdateModal}
      />
      <CreateDocumentModal
        isBulk
        isOpen={documentModalOpen}
        close={closeDocumentModal}
      />
      <EditInvestorAccountModal
        investorAccount={investorAccount}
        isOpen={editInvestorAccountModalOpen}
        close={closeEditInvestorAccountModal}
      />
      <DeleteInvestorAccountModal
        investorAccount={investorAccount}
        isOpen={deleteInvestorAccountModalOpen}
        close={closeDeleteInvestorAccountModal}
      />
    </>
  )
}

export default InvestorManagementPage
