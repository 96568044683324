import { Paper } from '@flock/flock-component-library'
import {
  Core_OrderV2Task,
  Core_OrderV2TaskResolutionData_DataOneof_PersonalInformationCompletedTaskResolutionData,
  Core_PersonalInformationCompletedTaskResolutionData,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { Box, Grid, Modal, styled, Typography } from '@mui/material'
import React from 'react'
import JsonField from '../JsonField'

export const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type SectionProps = {
  title: string
  answers: any
  sectionFields: string[]
  legalEntityUuid: string
}

const isAddressDocumentArray = [
  'poaDocuments',
  'actionDocuments',
  'idDocumentPhoto',
]

const formatAnswers = (value: any): any => {
  if (Array.isArray(value)) {
    return value.map((elem) => formatAnswers(elem))
  }

  try {
    const prefixes = ['MARITAL_STATUS_']

    const prefix = new RegExp(`^(${prefixes.join('|')})`, 'g')
    return value.replace(prefix, '')
  } catch (e) {
    return value
  }
}

const Section = (props: SectionProps) => {
  const { title, answers, sectionFields, legalEntityUuid } = props
  const filteredFields = sectionFields.filter(
    (field: string) => field !== '__typename'
  )
  return (
    <Box key={title}>
      <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: '2rem' }}>
        <Grid container spacing={2}>
          {filteredFields.map((key) => {
            if (
              typeof answers[key] === 'object' &&
              !Array.isArray(answers[key]) &&
              Object.keys(answers[key] || {}).length !== 0
            ) {
              return (
                <JsonField
                  size={12}
                  jsonKey={key}
                  value={
                    <Section
                      title=""
                      sectionFields={Object.keys(answers[key])}
                      answers={answers[key]}
                      legalEntityUuid={legalEntityUuid}
                    />
                  }
                />
              )
            }
            if (
              Array.isArray(answers[key]) &&
              answers[key].length > 0 &&
              typeof answers[key][0] !== 'string'
            ) {
              return answers[key].map((data: any) => (
                <Section
                  title=""
                  sectionFields={Object.keys(data)}
                  answers={data}
                  legalEntityUuid={legalEntityUuid}
                />
              ))
            }
            return (
              <JsonField
                jsonKey={key}
                value={formatAnswers(answers[key])}
                isDocument={isAddressDocumentArray.includes(key)}
                legalEntityUuid={legalEntityUuid}
              />
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

type PropertyQuestionaireModalParams = {
  legalEntityUuid: string
  personalInformationTask: Core_OrderV2Task
  isOpen: boolean
  close: () => void
}

const renderPersonalInformationData = (
  property: Core_PersonalInformationCompletedTaskResolutionData,
  legalEntityUuid: string
) => (
  <>
    <Typography variant="h4">Personal Information</Typography>

    <Section
      title=""
      sectionFields={Object.keys(property)}
      answers={property}
      legalEntityUuid={legalEntityUuid}
    />
  </>
)

const PersonalInformationModal = (props: PropertyQuestionaireModalParams) => {
  const { personalInformationTask, isOpen, close, legalEntityUuid } = props
  const personalInformationData = personalInformationTask.resolutionData
    ?.data as Core_OrderV2TaskResolutionData_DataOneof_PersonalInformationCompletedTaskResolutionData

  return (
    <Modal open={isOpen} onClose={close}>
      <ModalWrapper>
        {renderPersonalInformationData(
          personalInformationData?.personalInformationCompletedTaskResolutionData!,
          legalEntityUuid
        )}
      </ModalWrapper>
    </Modal>
  )
}

export default PersonalInformationModal
