import React from 'react'
import {
  Typography,
  Paper,
  Button,
  useSnackbar,
} from '@flock/flock-component-library'
import {
  AdminDeleteEventDocument,
  AdminGetEventsDocument,
  Core_Event,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useMutation } from '@apollo/client'

import { formatDateString } from '../InvestorManagement/utils'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

const ButtonWrapper = styled('div')({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
})

const CancelButton = styled(Button)({
  marginRight: '0.5rem',
})

type DeleteEventModalParams = {
  existingEvent: Core_Event | undefined
  isOpen: boolean
  close: () => void
}

// TODO refactor to a DELETE Modal component too if this table format for displaying is reused

const DeleteEventModal = (props: DeleteEventModalParams) => {
  const { existingEvent, isOpen, close } = props
  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [deleteEvent, { loading }] = useMutation(AdminDeleteEventDocument)

  const onClick = async () => {
    const deleteEventInput = {
      eventUuid: existingEvent!.uuid,
    }
    try {
      await deleteEvent({
        variables: {
          deleteEventInput,
        },
        refetchQueries: [AdminGetEventsDocument],
      })
      notify('Successfully deleted event', 'success')
      close()
    } catch (e) {
      notify('Failed to delete event', 'error')
    }
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalWrapper>
        <Typography
          variant="h2"
          sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
        >
          Delete Event
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Event Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{existingEvent?.title || ''}</TableCell>
              <TableCell>{existingEvent?.eventType || ''}</TableCell>
              <TableCell>
                {formatDateString(existingEvent?.date || '')}
              </TableCell>
              <TableCell>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: existingEvent?.notes || '',
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ButtonWrapper>
          <CancelButton color="error" onClick={handleClose}>
            Cancel
          </CancelButton>
          <Button variant="contained" onClick={onClick}>
            {loading ? 'Deleting...' : 'Delete Event'}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  )
}

export default DeleteEventModal
