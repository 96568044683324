// Valuation utilities that are shared between multi family and single family valuations.

import {
  Core_MarketRentCompInput,
  Core_OfferValuationCompInput,
  Core_ValuationCategory,
} from '@flock/flock-gql-server/src/__generated__/graphql'

export const supervisorUuids = [
  '88bbdfd2-6644-4ef7-8424-99e41f7b76dc', // Peter
  '8c1547cd-4831-4e27-8f1b-3a24d6d465de', // Ryan
  'f602fe20-bcb8-4137-b528-45964e5326ef', // Jesse Staging
  'fb86cac3-1fa8-4fc4-969e-59754f377959', // Chad
  '12aeb70c-4fab-401c-985b-a121dbd115f8', // e2e test admin account
  'b8ac2c6f-150c-42ff-b1b9-2493aa6fd75f', // Zach
  'ef7c29f4-0913-4d74-a348-5818e942c228', // Karson
  '3b60452d-55f3-412e-927e-d58325217845', // Sydney
]

export const finalSubmitUuids = [
  '8c1547cd-4831-4e27-8f1b-3a24d6d465de', // Ryan
  'f602fe20-bcb8-4137-b528-45964e5326ef', // Jesse Staging
  'fb86cac3-1fa8-4fc4-969e-59754f377959', // Chad
  '12aeb70c-4fab-401c-985b-a121dbd115f8', // e2e test admin account
  'b8ac2c6f-150c-42ff-b1b9-2493aa6fd75f', // Zach
  'ef7c29f4-0913-4d74-a348-5818e942c228', // Karson
  '3b60452d-55f3-412e-927e-d58325217845', // Sydney
]

export type AllowedUIValuationType =
  | 'VALUATION_TYPE_FINAL'
  | 'VALUATION_TYPE_INITIAL'

export const GetUiValuationType = (inputType: any): AllowedUIValuationType => {
  if (typeof inputType === 'string') {
    if (
      inputType === 'VALUATION_TYPE_FINAL' ||
      inputType === 'VALUATION_TYPE_INITIAL'
    ) {
      return inputType
    }
  }
  return 'VALUATION_TYPE_INITIAL'
}

// based on condition score, e.g. 5 = excellent, return the value 5.
export const getConditionScoreFromCondition = (
  condition: string | null | undefined
) => {
  if (condition) {
    // get the first number of the string (1-5) and return it
    const score = condition.match(/\d+/)
    if (score) {
      return parseInt(score[0], 10)
    }
  }
  return undefined
}

// Offer Price Comps Functions

// Given an offer comps, returns true if any of the fields  are not empty
// used to check if an offer comp is missing an address if one of these fields are missing
// not including "source" here because you cannot deselect dropdown
const hasNonEmptyOfferFields = (
  comp: Core_OfferValuationCompInput
): boolean => {
  const fieldsToCheck = [
    'avmPrice',
    'baths',
    'beds',
    'halfBaths',
    'notes',
    'selected',
    'similarityScore',
    'soldDate',
    'squareFootage',
    'url',
    'yearBuild',
  ]
  return fieldsToCheck.some(
    (field) => !!comp[field as keyof Core_OfferValuationCompInput]
  )
}

// for selected offer comps, these are the required fields
const getMissingRequiredOfferFields = (
  comp: Core_OfferValuationCompInput,
  valuationCategory: Core_ValuationCategory | undefined | null
): string[] => {
  const requiredFields = [
    'source',
    'avmPrice',
    'similarityScore',
    'squareFootage',
    'conditionScore',
    'yearBuild',
    'soldDate',
  ]

  if (
    valuationCategory === Core_ValuationCategory.ValuationCategoryMultiFamily
  ) {
    requiredFields.push('numUnits')
  }

  return requiredFields.filter(
    (field) => !comp[field as keyof Core_OfferValuationCompInput]
  )
}

const validateOfferComps = (
  comps: Core_OfferValuationCompInput[],
  valuationCategory: Core_ValuationCategory | undefined | null,
  useCma: boolean,
  unitName?: string
): string[] => {
  const errors: string[] = []
  comps.forEach((comp, index) => {
    let compDescription = `Offer comp #${index + 1}`
    if (unitName) {
      compDescription += ` for unit ${unitName}`
    }
    if (hasNonEmptyOfferFields(comp) && !comp.address) {
      errors.push(`${compDescription} is missing an address.`)
    }

    if (comp.selected) {
      const missingFields = getMissingRequiredOfferFields(
        comp,
        valuationCategory
      )
      if (missingFields.length > 0) {
        errors.push(
          `${compDescription} is missing required fields: ${missingFields.join(
            ', '
          )}.`
        )
      }
    }
  })

  const selectedComps = comps.filter((comp) => comp.selected)
  if (selectedComps.length > 3) {
    if (unitName) {
      errors.push(
        `You can only select up to 3 offer comps for unit ${unitName}.`
      )
    } else {
      errors.push('You can only select up to 3 offer comps.')
    }
  }

  if (useCma && selectedComps.length !== 3) {
    errors.push('You must select exactly 3 offer comps when using CMA.')
  }

  return errors
}

const isOfferCompsInputValid = (
  valuationCategory: Core_ValuationCategory | undefined | null,
  comps: Core_OfferValuationCompInput[] | undefined | null,
  setOfferCompsErrorMessage: (message: string) => void,
  useCma: boolean,
  unitName?: string
): boolean => {
  setOfferCompsErrorMessage('')
  if (!comps) return true

  const errors = validateOfferComps(comps, valuationCategory, useCma, unitName)
  if (errors.length > 0) {
    setOfferCompsErrorMessage(errors.join('\n'))
    return false
  }

  return true
}

// Market Rent Comps Functions

// Given a rent comp, returns true if any of the fields  are not empty
// used to check if an offer comp is missing an address if one of these fields are missing
// not including "source" here because you cannot deselect dropdown
const hasNonEmptyRentFields = (comp: Core_MarketRentCompInput): boolean => {
  const fieldsToCheck = [
    'marketRent',
    'notes',
    'rentalDate',
    'selected',
    'squareFootage',
    'url',
  ]
  return fieldsToCheck.some(
    (field) => !!comp[field as keyof Core_MarketRentCompInput]
  )
}

// for selected rent comps, these are the required fields
const getMissingRequiredRentFields = (
  comp: Core_MarketRentCompInput
): string[] => {
  const requiredFields = ['marketRent', 'squareFootage', 'source']
  return requiredFields.filter(
    (field) => !comp[field as keyof Core_MarketRentCompInput]
  )
}

const validateRentComps = (
  comps: Core_MarketRentCompInput[],
  unitName?: string
): string[] => {
  const errors: string[] = []
  comps.forEach((comp, index) => {
    let compDescription = `Rent comp #${index + 1}`
    if (unitName) {
      compDescription += ` for unit ${unitName}`
    }
    if (hasNonEmptyRentFields(comp) && !comp.address) {
      errors.push(`${compDescription} is missing an address.`)
    }

    if (comp.selected) {
      const missingFields = getMissingRequiredRentFields(comp)
      if (missingFields.length > 0) {
        errors.push(
          `${compDescription} is missing required fields: ${missingFields.join(
            ', '
          )}.`
        )
      }
    }
  })

  const selectedComps = comps.filter((comp) => comp.selected)
  if (selectedComps.length > 3) {
    if (unitName) {
      errors.push(
        `You can only select up to 3 rent comps for unit ${unitName}.`
      )
    } else {
      errors.push('You can only select up to 3 rent comps.')
    }
  }

  return errors
}

const isRentCompsInputValid = (
  comps: Core_MarketRentCompInput[] | undefined | null,
  setRentCompsErrorMessage: (message: string) => void,
  unitName?: string
): boolean => {
  setRentCompsErrorMessage('')
  if (!comps) return true

  const errors = validateRentComps(comps, unitName)
  if (errors.length > 0) {
    setRentCompsErrorMessage(errors.join('\n'))
    return false
  }

  return true
}

// Combined Validation Function

export const getCompsValidationErrorMessage = (
  valuationCategory: Core_ValuationCategory | undefined | null,
  rentalComps: Core_MarketRentCompInput[] | undefined | null,
  offerComps: Core_OfferValuationCompInput[] | undefined | null,
  setOfferCompsErrorMessage: (message: string) => void,
  setRentCompsErrorMessage: (message: string) => void,
  useCma: boolean,
  unitName?: string
): string => {
  const rentCompsValid = isRentCompsInputValid(
    rentalComps,
    setRentCompsErrorMessage,
    unitName
  )
  const offerCompsValid = isOfferCompsInputValid(
    valuationCategory,
    offerComps,
    setOfferCompsErrorMessage,
    useCma,
    unitName
  )

  if (rentCompsValid && offerCompsValid) {
    return ''
  } else if (!rentCompsValid && !offerCompsValid) {
    return 'Some rent comps and some offer comps are missing required inputs, valuation not computed'
  } else if (!rentCompsValid) {
    return 'Some rent comps are missing required inputs, valuation not computed'
  } else {
    return 'Some offer comps are missing required inputs, valuation not computed'
  }
}
