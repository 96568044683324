import React from 'react'
import { Box, Modal, Typography } from '@mui/material'
import {
  FileUpload,
  FileUploadFileType,
  FileUploadMimeType,
  TrackedButton,
} from '@flock/shared-ui'
import useExportModal from './useExportModal'
import { ExportModalProps } from './exportModalTypes'
import { urgencyOptions } from '../individualSowProjectPageTypes'
import { monthToText } from '../AssetRow/assetRowTypes'

const SinglePageWrapper = (props: {
  shouldShow: boolean
  children: React.ReactNode
}) => {
  const { shouldShow, children } = props
  if (!shouldShow) {
    return null
  }

  return (
    <Box width="612px" height="792px" display="flex" flexDirection="column">
      <Box width="540px" height="792px" position="relative" paddingTop="36px">
        {children}
      </Box>
    </Box>
  )
}

const LabelledData = (props: { label: string; value: string }) => {
  const { label, value } = props
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="p4">{label}</Typography>
      <Typography variant="c1">{value}</Typography>
    </Box>
  )
}

const Footer = (props: {
  pageNumber: number
  formattedAddress: string
  inspectionDate: string
}) => {
  const { pageNumber, formattedAddress, inspectionDate } = props
  return (
    <Box
      position="absolute"
      width="540px"
      right="0px"
      bottom="24px"
      display="flex"
      justifyContent="space-between"
      alignSelf="flex-end"
    >
      {pageNumber % 2 === 0 ? (
        <>
          <Typography
            variant="c1m"
            sx={{
              fontSize: '10',
              lineHeight: '12px',
            }}
          >
            {pageNumber}
          </Typography>
          <Typography
            variant="c1m"
            sx={{
              fontSize: '10',
              lineHeight: '12px',
            }}
          >
            {formattedAddress}
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="c1m"
            sx={{
              fontSize: '10',
              lineHeight: '12px',
            }}
          >
            {inspectionDate}
          </Typography>
          <Typography
            variant="c1m"
            sx={{
              fontSize: '10',
              lineHeight: '12px',
            }}
          >
            {pageNumber}
          </Typography>
        </>
      )}
    </Box>
  )
}

const ExportModal = (props: ExportModalProps) => {
  const presentationalProps = useExportModal(props)
  const {
    open,
    onClose,
    pageConfigs,
    assetPageConfigs,
    tocPageConfigs,
    formattedAddress,
    inspectionDate,
    beds,
    baths,
    squareFootage,
    yearBuilt,
    immediateAddressableTotal,
    nonImmediateAddressableTotal,
    total,
    batchStart,
    batchEnd,

    onStartDownload,
    refToPrint,
    doneGenerating,

    onUpdateFiles,
    coverPhotoURL,

    downloading,
  } = presentationalProps

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        position="absolute"
        width="800px"
        height="80vh"
        p="16px"
        display="flex"
        flexDirection="column"
        sx={{
          top: '50px',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          backgroundColor: 'gray1.main',
          borderRadius: '8px',
          overflow: 'scroll',
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <TrackedButton
            variant="primary"
            size="smallForm"
            onClick={onStartDownload}
            disabled={!doneGenerating || downloading}
          >
            {downloading ? (
              'Downloading...'
            ) : (
              <>{doneGenerating ? 'Download' : 'Generating...'}</>
            )}
          </TrackedButton>
        </Box>
        <Box>
          <FileUpload
            label="Upload a cover image."
            accept={{
              [FileUploadMimeType.PNG]: [FileUploadFileType.PNG],
              [FileUploadMimeType.JPEG]: [
                FileUploadFileType.JPG,
                FileUploadFileType.JPEG,
              ],
            }}
            onChange={onUpdateFiles}
            onBlur={() => {}}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            ref={refToPrint}
            width="540px"
          >
            <Box>
              <SinglePageWrapper shouldShow={batchStart <= 0 && batchEnd > 0}>
                <Box display="flex" flexDirection="column" gap="200px">
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" flexDirection="column" gap="4px">
                      <Typography variant="c1m" color="moneyGreen.main">
                        Inspection & Scope
                      </Typography>
                      <Typography
                        variant="h3"
                        color="moneyGreen.main"
                        sx={{
                          fontFamily: 'Outfit',
                          fontWeight: '500',
                        }}
                      >
                        {formattedAddress.split(',')[0]}
                        <br />
                        {formattedAddress.split(',').slice(1).join(',')}
                      </Typography>
                    </Box>
                    <Typography variant="c1m">
                      Completed {inspectionDate}
                    </Typography>
                  </Box>
                  {coverPhotoURL && (
                    <Box width="540px" height="405px">
                      <Box
                        width="1620px"
                        height="1215px"
                        sx={{
                          backgroundImage: `url(${coverPhotoURL})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',

                          transform: 'scale(0.333333)',
                          transformOrigin: 'top left',
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </SinglePageWrapper>
              {tocPageConfigs.map((tocPageConfig) => {
                const { pageNumber, items } = tocPageConfig
                const truePageNumber = pageNumber + 2
                return (
                  <SinglePageWrapper
                    key={pageNumber}
                    shouldShow={
                      truePageNumber - 1 >= batchStart &&
                      truePageNumber - 1 < batchEnd
                    }
                  >
                    <Box
                      id={`page-toc-${pageNumber}`}
                      className="page-wrapper"
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      <Box display="flex" flexDirection="column" gap="4px">
                        <Typography variant="c1m" color="moneyGreen.main">
                          {formattedAddress}
                        </Typography>
                        <Typography variant="ctam" color="moneyGreen.main">
                          Table of Contents
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="4px">
                        {items.map((item) => {
                          const {
                            location,
                            pageNumber: childPageNumber,
                            isAsset,
                          } = item
                          return (
                            <Box key={location} display="flex" gap="4px">
                              <Typography variant="p4" width="16px">
                                {isAsset
                                  ? pageConfigs.length +
                                    tocPageConfigs.length +
                                    3 +
                                    childPageNumber
                                  : tocPageConfigs.length + 3 + childPageNumber}
                              </Typography>
                              <Typography variant="p4">{location}</Typography>
                            </Box>
                          )
                        })}
                        <Footer
                          pageNumber={truePageNumber}
                          formattedAddress={formattedAddress}
                          inspectionDate={inspectionDate}
                        />
                      </Box>
                    </Box>
                  </SinglePageWrapper>
                )
              })}
              <SinglePageWrapper
                shouldShow={
                  tocPageConfigs.length + 1 >= batchStart &&
                  tocPageConfigs.length + 1 < batchEnd
                }
              >
                <Box display="flex" flexDirection="column" gap="12px">
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography variant="c1m" color="moneyGreen.main">
                      {formattedAddress}
                    </Typography>
                    <Typography variant="ctam" color="moneyGreen.main">
                      Details
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="8px">
                    <Typography variant="c1" color="moneyGreen.main">
                      Home Details
                    </Typography>
                    <Box display="flex" flexDirection="row" gap="24px">
                      <LabelledData label="Beds" value={beds.toString()} />
                      <LabelledData label="Baths" value={baths.toString()} />
                      <LabelledData
                        label="Sq. Ft."
                        value={squareFootage.toLocaleString()}
                      />
                      <LabelledData
                        label="Year Built"
                        value={yearBuilt.toString()}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="8px">
                    <Typography variant="c1" color="moneyGreen.main">
                      Scope of Work Details
                    </Typography>
                    <Box display="flex" flexDirection="row" gap="24px">
                      <LabelledData
                        label="Immediately addressable total"
                        value={`$${Math.round(
                          immediateAddressableTotal
                        ).toLocaleString()}`}
                      />
                      <LabelledData
                        label="Non-immediately addressable total"
                        // Rounded to nearest dollar
                        value={`$${Math.round(
                          nonImmediateAddressableTotal
                        ).toLocaleString()}`}
                      />
                      <LabelledData
                        label="Total"
                        value={`$${Math.round(total).toLocaleString()}`}
                      />
                    </Box>
                  </Box>
                </Box>
                <Footer
                  pageNumber={tocPageConfigs.length + 2}
                  formattedAddress={formattedAddress}
                  inspectionDate={inspectionDate}
                />
              </SinglePageWrapper>
              {pageConfigs.map((pageConfig) => {
                const { pageNumber, location, items, continued } = pageConfig
                const truePageNumber = pageNumber + tocPageConfigs.length + 3
                return (
                  <SinglePageWrapper
                    key={`${pageNumber}-deficiency`}
                    shouldShow={
                      truePageNumber - 1 >= batchStart &&
                      truePageNumber - 1 < batchEnd
                    }
                  >
                    <Box
                      id={`page-${location}-${pageNumber}`}
                      className="page-wrapper"
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      {/* Header */}
                      <Box display="flex" flexDirection="column" gap="4px">
                        <Typography variant="c1m" color="moneyGreen.main">
                          {formattedAddress}
                        </Typography>
                        <Typography variant="p2" fontWeight="500">
                          {location} {continued ? '(cont.)' : ''}
                        </Typography>
                      </Box>

                      {/* Deficiencies */}
                      <Box display="flex" flexDirection="column" gap="8px">
                        <Typography variant="c1" color="moneyGreen.main">
                          Deficiencies {continued ? '(cont.)' : ''}
                        </Typography>
                        {items.map((item) => {
                          const { deficiency, category, solutions, media } =
                            item
                          return (
                            <Box key={deficiency} display="flex" gap="12px">
                              <Box
                                minWidth="4px"
                                alignSelf="stretch"
                                sx={{ backgroundColor: 'moneyGreen.main' }}
                              />

                              <Box
                                display="flex"
                                flexDirection="column"
                                gap={media.length > 0 ? '8px' : '4px'}
                              >
                                {/* Deficiency Header and Images */}
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap="8px"
                                >
                                  <Box display="flex" gap="4px">
                                    <Typography variant="c1">
                                      {category} |
                                    </Typography>
                                    <Typography variant="p3">
                                      {deficiency}
                                    </Typography>
                                  </Box>
                                  {media.length > 0 && (
                                    <Box
                                      display="flex"
                                      gap="12px"
                                      width="100%"
                                      flexWrap="wrap"
                                    >
                                      {media.map((singleMedia) => {
                                        const { uuid, s3Url } = singleMedia
                                        return (
                                          <Box width="120px" height="92px">
                                            <Box
                                              key={uuid}
                                              width="480px"
                                              height="368px"
                                              sx={{
                                                backgroundImage: `url(${s3Url})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',

                                                transform: 'scale(0.25)',
                                                transformOrigin: 'top left',
                                              }}
                                            />
                                          </Box>
                                        )
                                      })}
                                    </Box>
                                  )}
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap="8px"
                                >
                                  {solutions.map((solution) => {
                                    const {
                                      uuid,
                                      category: solutionCategory,
                                      description,
                                      quantity,
                                      unitPrice,
                                      adjustment,
                                      urgency,
                                    } = solution

                                    let displayedUrgency = urgencyOptions.find(
                                      (u) => u.value === urgency
                                    )?.label

                                    if (
                                      urgency ===
                                        'non_immediately_addressable' &&
                                      adjustment !== 1
                                    ) {
                                      displayedUrgency += ` (${
                                        adjustment * 100
                                      }%)`
                                    }

                                    const totalCost =
                                      quantity * unitPrice * adjustment

                                    return (
                                      <Box
                                        key={uuid}
                                        display="flex"
                                        gap="4px"
                                        alignItems="flex-start"
                                      >
                                        <Box
                                          display="flex"
                                          alignItems="flex-start"
                                          width="388px"
                                        >
                                          <Typography variant="p4">
                                            <Typography
                                              variant="c1m"
                                              display="inline"
                                            >
                                              {solutionCategory} | &nbsp;
                                            </Typography>
                                            {description}
                                          </Typography>
                                        </Box>
                                        <Box
                                          display="flex"
                                          width="56px"
                                          justifyContent="flex-end"
                                        >
                                          <Typography
                                            variant="p4"
                                            textAlign="right"
                                          >
                                            {displayedUrgency}
                                          </Typography>
                                        </Box>
                                        <Box
                                          display="flex"
                                          width="72px"
                                          justifyContent="flex-end"
                                        >
                                          <Typography
                                            variant="p4"
                                            textAlign="right"
                                          >
                                            ${totalCost.toLocaleString()}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    )
                                  })}
                                </Box>
                              </Box>
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>
                    <Footer
                      pageNumber={truePageNumber}
                      formattedAddress={formattedAddress}
                      inspectionDate={inspectionDate}
                    />
                  </SinglePageWrapper>
                )
              })}
              {assetPageConfigs.map((pageConfig) => {
                const { pageNumber, location, items, continued } = pageConfig
                const truePageNumber =
                  pageNumber + pageConfigs.length + tocPageConfigs.length + 3
                return (
                  <SinglePageWrapper
                    key={`${pageNumber}-asset`}
                    shouldShow={
                      truePageNumber - 1 >= batchStart &&
                      truePageNumber - 1 < batchEnd
                    }
                  >
                    <Box
                      id={`page-asset-${location}-${pageNumber}`}
                      className="page-wrapper"
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      {/* Header */}
                      <Box display="flex" flexDirection="column" gap="4px">
                        <Typography variant="p4">{formattedAddress}</Typography>
                        <Typography variant="p2" fontWeight="500">
                          {location} {continued ? '(cont.)' : ''}
                        </Typography>
                      </Box>

                      {/* Assets */}
                      <Box display="flex" flexDirection="column" gap="8px">
                        {items.map((item) => {
                          const {
                            item: itemDescription,
                            category,
                            media,
                            month,
                            year,
                          } = item
                          return (
                            <Box
                              key={itemDescription}
                              display="flex"
                              gap="12px"
                            >
                              <Box
                                minWidth="4px"
                                alignSelf="stretch"
                                sx={{ backgroundColor: 'moneyGreen.main' }}
                              />

                              <Box
                                display="flex"
                                flexDirection="column"
                                gap="12px"
                              >
                                {/* Deficiency Header and Images */}
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap="8px"
                                >
                                  <Box display="flex" gap="4px">
                                    <Typography variant="c1">
                                      {category} |
                                    </Typography>
                                    <Typography variant="p3">
                                      {itemDescription} - {monthToText(month)} |{' '}
                                      {year}
                                    </Typography>
                                  </Box>
                                  {media.length > 0 && (
                                    <Box
                                      display="flex"
                                      gap="12px"
                                      width="100%"
                                      flexWrap="wrap"
                                    >
                                      {media.map((singleMedia) => {
                                        const { uuid, s3Url } = singleMedia
                                        return (
                                          <Box width="120px" height="92px">
                                            <Box
                                              key={uuid}
                                              width="480px"
                                              height="368px"
                                              sx={{
                                                backgroundImage: `url(${s3Url})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',

                                                transform: 'scale(0.25)',
                                                transformOrigin: 'top left',
                                              }}
                                            />
                                          </Box>
                                        )
                                      })}
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>
                    <Footer
                      pageNumber={truePageNumber}
                      formattedAddress={formattedAddress}
                      inspectionDate={inspectionDate}
                    />
                  </SinglePageWrapper>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ExportModal
