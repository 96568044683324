import { Box, Typography } from '@mui/material'
import React from 'react'

const StatusButton = (props: {
  label: string
  selected: boolean
  icon: React.ReactNode
  onClick: () => void
}) => {
  const { label, selected, icon, onClick } = props
  return (
    <Box
      display="flex"
      alignItems="center"
      p="8px 12px"
      gap="4px"
      sx={{
        backgroundColor: selected ? 'green2.main' : 'green1.main',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        '&:hover': {
          backgroundColor: 'green2.main',
        },
      }}
      onClick={onClick}
    >
      {icon}
      <Typography variant="c1">{label}</Typography>
    </Box>
  )
}

export default StatusButton
