import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { useSowSaveContext } from './SowSaveContextProvider'

const SowSaveStateRenderer = () => {
  const { saveLoading, sowError, sowLastSaved } = useSowSaveContext()

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-end">
      <Typography variant="c1" textAlign="right" color="green4.main">
        {saveLoading && <CircularProgress size="12px" />}{' '}
        {sowLastSaved ? `Last saved: ${sowLastSaved}` : ''}
      </Typography>
      <Typography variant="c1" textAlign="right" color="errorRed.main">
        {sowError}
      </Typography>
    </Box>
  )
}

export default SowSaveStateRenderer
