import React from 'react'
import { useSnackbar } from '@flock/flock-component-library'
import { formattedDollarsToCents } from '@flock/utils'
import { useMutation } from '@apollo/client'
import {
  AdminCreatePropertyDocument,
  AdminSearchPropertiesDocument,
  Core_CreatePropertyRequestInput,
  Core_UnitParamsInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  GOOGLE_MAPS_API_KEY,
  InputType,
  LibraryThemeProvider,
} from '@flock/shared-ui'
import { cleanTimeFromDatetime } from '../../utils'
import GridFormModal from '../shared/GridFormModal'

type CreatePropertyParams = {
  propertyInformation: any[]
  hasFloodInsurance: string
  investorName: string
  isDown: string
  isFloodZone: string
  legalAddress: string
  parcelNumber: string
  propertyType: string
  sqft: string
  subscriberName: string
  transactionType: string
  yearBuilt: string
  acquisitionDate: string
  contributionDate: string
  acquisitionPriceCents: string
  transactionCostCents: string
  initialCapexEstimateCents: string
  submarketRentDeductionCents: string
  daysInRenoDeductionCents: string
}

type CreatePropertyModalParams = {
  isOpen: boolean
  close: () => void
}

const CreatePropertyModal = (props: CreatePropertyModalParams) => {
  const { isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createProperty, { loading }] = useMutation(AdminCreatePropertyDocument)

  const onSubmit = async (createPropertyParams: CreatePropertyParams) => {
    const {
      propertyInformation,
      hasFloodInsurance,
      investorName,
      isFloodZone,
      legalAddress,
      parcelNumber,
      propertyType,
      yearBuilt,
      subscriberName,
      transactionType,
      acquisitionDate,
      contributionDate,
      acquisitionPriceCents,
      transactionCostCents,
      initialCapexEstimateCents,
      submarketRentDeductionCents,
      daysInRenoDeductionCents,
    } = createPropertyParams
    const createPropertyInput: Core_CreatePropertyRequestInput = {
      unitParams: propertyInformation.map((property) => {
        const {
          address,
          unit,
          beds,
          baths,
          halfBaths,
          sqft,
          notes,
          isDown,
          underwrittenRentCents,
          yardiId,
          occupancyStatusAtAcquisition,
        } = property
        return {
          address: address?.formattedAddress,
          unit,
          beds: beds ? parseInt(beds, 10) : undefined,
          baths: baths ? parseInt(baths, 10) : undefined,
          bathsDouble: baths ? parseFloat(baths) : undefined,
          halfBaths: halfBaths ? parseInt(halfBaths, 10) : undefined,
          sqft: sqft ? parseInt(sqft, 10) : undefined,
          notes,
          isDown: isDown ? isDown === 'true' : undefined,
          underwrittenRentCents: underwrittenRentCents
            ? formattedDollarsToCents(underwrittenRentCents)
            : undefined,
          yardiId,
          occupancyStatusAtAcquisition,
        } as Core_UnitParamsInput
      }),
      hasFloodInsurance: hasFloodInsurance
        ? hasFloodInsurance === 'true'
        : undefined,
      investorName,
      isFloodZone: isFloodZone ? isFloodZone === 'true' : undefined,
      legalAddress,
      parcelNumber,
      propertyType,
      subscriberName,
      transactionType,
      yearBuilt: yearBuilt ? parseInt(yearBuilt, 10) : undefined,
      acquisitionDate: acquisitionDate
        ? cleanTimeFromDatetime(acquisitionDate)
        : undefined,
      contributionDate: contributionDate
        ? cleanTimeFromDatetime(contributionDate)
        : undefined,
      acquisitionPriceCents: acquisitionPriceCents
        ? formattedDollarsToCents(acquisitionPriceCents)
        : undefined,
      transactionCostCents: transactionCostCents
        ? formattedDollarsToCents(transactionCostCents)
        : undefined,
      initialCapexEstimateCents: initialCapexEstimateCents
        ? formattedDollarsToCents(initialCapexEstimateCents)
        : undefined,
      submarketRentDeductionCents: submarketRentDeductionCents
        ? formattedDollarsToCents(submarketRentDeductionCents)
        : undefined,
      daysInRenoDeductionCents: daysInRenoDeductionCents
        ? formattedDollarsToCents(daysInRenoDeductionCents)
        : undefined,
    }

    try {
      await createProperty({
        variables: {
          input: createPropertyInput,
        },
        refetchQueries: [AdminSearchPropertiesDocument],
      })
      notify('Successfully created property.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to create property.', 'error')
    }
  }

  return (
    <LibraryThemeProvider>
      <GridFormModal
        open={isOpen}
        loading={loading}
        title="Create Property"
        onClose={() => handleClose()}
        onSubmit={onSubmit}
        gridProps={{
          height: '600px',
          overflow: 'auto',
          spacing: 1,
        }}
        inputConfigs={[
          {
            name: 'propertyType',
            type: InputType.Dropdown,
            required: true,
            props: {
              type: 'text',
              label: 'Property Type',
              fullWidth: true,
              options: [
                {
                  label: 'SFR',
                  value: 'SFR',
                },
                {
                  label: 'Townhouse',
                  value: 'Townhouse',
                },
                {
                  label: 'Duplex',
                  value: 'Duplex',
                },
                {
                  label: 'Triplex',
                  value: 'Triplex',
                },
                {
                  label: 'Fourplex',
                  value: 'Fourplex',
                },
              ],
            },
          },
          {
            name: 'propertyInformation',
            type: InputType.Multiform,
            required: true,
            props: {
              title: 'Property Record',
              minForms: 1,
              maxForms: 4,
              inputConfigs: [
                {
                  name: 'address',
                  type: InputType.Address,
                  required: true,
                  props: {
                    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
                    label: 'Please enter the address',
                    fullWidth: true,
                  },
                },
                {
                  name: 'unit',
                  type: InputType.Text,
                  props: {
                    type: 'text',
                    label: 'Unit',
                    fullWidth: true,
                  },
                },
                {
                  name: 'beds',
                  type: InputType.Text,
                  required: true,
                  props: {
                    type: 'text',
                    label: 'Beds',
                    fullWidth: true,
                  },
                },
                {
                  name: 'baths',
                  type: InputType.Text,
                  required: true,
                  props: {
                    type: 'text',
                    label: 'Baths',
                    fullWidth: true,
                  },
                },
                {
                  name: 'halfBaths',
                  type: InputType.Text,
                  required: true,
                  props: {
                    type: 'text',
                    label: 'Half Baths',
                    fullWidth: true,
                  },
                },
                {
                  name: 'sqft',
                  type: InputType.Text,
                  required: true,
                  props: {
                    type: 'text',
                    label: 'Square Feet',
                    fullWidth: true,
                  },
                },
                {
                  name: 'notes',
                  type: InputType.Text,
                  props: {
                    type: 'text',
                    label: 'Notes',
                    fullWidth: true,
                  },
                },
                {
                  name: 'isDown',
                  type: InputType.Dropdown,
                  required: true,
                  props: {
                    type: 'text',
                    label: 'Is Down?',
                    fullWidth: true,
                    options: [
                      {
                        label: 'Yes',
                        value: 'true',
                      },
                      {
                        label: 'No',
                        value: 'false',
                      },
                    ],
                  },
                },
                {
                  name: 'underwrittenRentCents',
                  type: InputType.Text,
                  props: {
                    type: 'money',
                    label: 'Underwritten Rent',
                    fullWidth: true,
                    placeholder: '$0.00',
                  },
                },
                {
                  name: 'yardiId',
                  type: InputType.Text,
                  props: {
                    type: 'text',
                    label: 'Yardi ID',
                    fullWidth: true,
                  },
                },
                {
                  name: 'occupancyStatusAtAcquisition',
                  type: InputType.Dropdown,
                  required: true,
                  props: {
                    type: 'text',
                    label: 'Occupancy Status at Acquisition',
                    fullWidth: true,
                    options: [
                      {
                        label: 'Occupied',
                        value: 'O',
                      },
                      {
                        label: 'Vacant',
                        value: 'V',
                      },
                    ],
                  },
                },
              ],
              incrementText: '+ Add Unit',
              decrementText: '- Remove Unit',
            },
          },
          {
            name: 'yearBuilt',
            type: InputType.Text,
            required: true,
            props: {
              type: 'text',
              label: 'Year Built',
              fullWidth: true,
            },
          },
          {
            name: 'acquisitionDate',
            type: InputType.DatePicker,
            required: true,
            props: {
              label: 'Acquisition Date',
              fullWidth: true,
            },
          },
          {
            name: 'contributionDate',
            type: InputType.DatePicker,
            required: true,
            props: {
              label: 'Contribution Date',
              fullWidth: true,
            },
          },
          {
            name: 'acquisitionPriceCents',
            type: InputType.Text,
            required: true,
            props: {
              type: 'money',
              label: 'Acquisition Price',
              placeholder: '$0.00',
              fullWidth: true,
            },
          },
          {
            name: 'transactionCostCents',
            type: InputType.Text,
            required: true,
            props: {
              type: 'money',
              label: 'Transaction Cost',
              placeholder: '$0.00',
              fullWidth: true,
            },
          },
          {
            name: 'initialCapexEstimateCents',
            type: InputType.Text,
            props: {
              type: 'money',
              label: 'Initial Capex Estimate',
              placeholder: '$0.00',
              fullWidth: true,
            },
          },
          {
            name: 'submarketRentDeductionCents',
            type: InputType.Text,
            props: {
              type: 'money',
              label: 'Submarket Rent Deduction',
              placeholder: '$0.00',
              fullWidth: true,
            },
          },
          {
            name: 'daysInRenoDeductionCents',
            type: InputType.Text,
            props: {
              type: 'money',
              label: 'Days in Reno Deduction',
              placeholder: '$0.00',
              fullWidth: true,
            },
          },
          {
            name: 'isFloodZone',
            type: InputType.Dropdown,
            props: {
              type: 'text',
              label: 'Is Flood Zone?',
              fullWidth: true,
              options: [
                {
                  label: 'Yes',
                  value: 'true',
                },
                {
                  label: 'No',
                  value: 'false',
                },
              ],
            },
          },
          {
            name: 'hasFloodInsurance',
            type: InputType.Dropdown,
            props: {
              type: 'text',
              label: 'Has Flood Insurance?',
              fullWidth: true,
              options: [
                {
                  label: 'Yes',
                  value: 'true',
                },
                {
                  label: 'No',
                  value: 'false',
                },
              ],
            },
          },
          {
            name: 'investorName',
            type: InputType.Text,
            required: true,
            props: {
              type: 'text',
              label: 'Investor Name',
              fullWidth: true,
            },
          },
          {
            name: 'subscriberName',
            type: InputType.Text,
            required: true,
            props: {
              type: 'text',
              label: 'Subscriber Name',
              fullWidth: true,
            },
          },
          {
            name: 'transactionType',
            type: InputType.Dropdown,
            required: true,
            props: {
              type: 'text',
              label: 'Transaction Type',
              fullWidth: true,
              options: [
                {
                  label: 'Contribution',
                  value: 'Contribution',
                },
                {
                  label: 'Purchase',
                  value: 'Purchase',
                },
                {
                  label: '1031 Replacement',
                  value: '1031 Replacement',
                },
              ],
            },
          },
          {
            name: 'legalAddress',
            type: InputType.Text,
            props: {
              type: 'text',
              label: 'Legal Address',
              fullWidth: true,
            },
          },
          {
            name: 'parcelNumber',
            type: InputType.Text,
            props: {
              type: 'text',
              label: 'Parcel Number',
              fullWidth: true,
            },
          },
        ]}
      />
    </LibraryThemeProvider>
  )
}

export default CreatePropertyModal
