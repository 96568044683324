import React, { useState, useMemo, ChangeEvent, useCallback } from 'react'
import {
  Typography,
  DataTable,
  LoadingCard,
  useSnackbar,
  flockColors,
} from '@flock/flock-component-library'
import { styled } from '@mui/material/styles'
import { TextField, InputAdornment, TableCell, TableRow } from '@mui/material'
import { ArrowForwardIos, Search } from '@mui/icons-material'
import { debounce } from 'lodash'
import { useQuery } from '@apollo/client'
import { PopoverMenu } from '@flock/shared-ui'
import {
  AdminSearchPropertiesDocument,
  Core_SearchPropertiesObject,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { OCCUPANCY_STATUS_URL } from '../../constants'
import CreatePropertyModal from './CreatePropertyModal'
import CreatePropertyFromLeadModal from './CreatePropertyFromLeadModal'

const SearchWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '1rem',
})

const SearchField = styled(TextField)({
  width: '100%',

  '& > div > input': {
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
  },
})

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
  display: 'flex',
  justifyContent: 'space-between',
})

export const StyledPropertiesRow = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

const CustomPropertiesRowRender = () => (data: [string, string]) => {
  const [address, propertyUuid] = data

  return (
    <StyledPropertiesRow
      onClick={() => {
        navigate(`${OCCUPANCY_STATUS_URL}/${propertyUuid}`)
      }}
      style={{ cursor: 'pointer' }}
      data-cy="propertyDetailsButton"
    >
      <TableCell>{address}</TableCell>
      <TableCell align="right">
        <ArrowForwardIos />
      </TableCell>
    </StyledPropertiesRow>
  )
}

const DataLakePage = (_: RouteComponentProps) => {
  const { notify } = useSnackbar()

  let querySearch = ''
  if (typeof window !== `undefined`) {
    const { search } = window.location
    const params = new URLSearchParams(search)
    querySearch = params.get('search') || ''
    querySearch = querySearch.toLowerCase()
  }

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    sort: true,
    selectableRows: 'none',
    responsive: 'standard',
    customRowRender: CustomPropertiesRowRender(),
    elevation: 0,
  }

  const propertyColumns = [
    { name: 'formattedAddress', label: 'Address' },
    {
      name: 'propertyUuid',
      label: 'propertyUuid',
      options: { display: false },
    },
    {
      name: 'actionParams',
      options: {
        customHeadLabelRender: () => '',
        draggable: false,
        download: false,
        filter: false,
        print: false,
        sort: false,
      },
    },
  ]

  const [searchString, setSearchString] = useState(querySearch)

  const [createPropertyModalOpen, setCreatePropertyModalOpen] = useState(false)
  const [createPropertyModalFromLeadOpen, setCreatePropertyModalFromLeadOpen] =
    useState(false)

  const { loading, data } = useQuery(AdminSearchPropertiesDocument, {
    variables: {
      input: {
        searchString,
      },
    },
    onError: () => {
      notify('Failed to get properties', 'error')
    },
  })

  const properties: Core_SearchPropertiesObject[] = (
    data?.searchProperties?.properties || []
  ).map((item) => item as Core_SearchPropertiesObject)

  const setSearchStringToSearchFieldInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value.toLowerCase())
    },
    []
  )

  const debounceSetSearchStringToSearchFieldInput = useMemo(
    () => debounce(setSearchStringToSearchFieldInput, 700),
    [setSearchStringToSearchFieldInput]
  )

  const createPropertyMenu = [
    {
      text: 'With Form',
      onClick: () => {
        setCreatePropertyModalOpen(true)
      },
    },
    {
      text: 'With Lead UUID',
      onClick: () => {
        setCreatePropertyModalFromLeadOpen(true)
      },
    },
  ]

  return (
    <>
      <PageTitleContainer>
        <Typography variant="h1">Search For Properties in Data Lake</Typography>
        <PopoverMenu
          text="Create New Property"
          buttonProps={{
            variant: 'outlined',
          }}
          popoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          }}
          actions={createPropertyMenu}
        />
      </PageTitleContainer>
      <SearchWrapper>
        <SearchField
          variant="outlined"
          placeholder="Search Properties"
          defaultValue={querySearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={debounceSetSearchStringToSearchFieldInput}
          data-cy="leadSearchField"
        />
      </SearchWrapper>
      {loading ? (
        <LoadingCard text="Loading..." />
      ) : (
        <DataTable
          title=""
          data={properties}
          columns={propertyColumns}
          options={options as any}
        />
      )}
      <CreatePropertyModal
        isOpen={createPropertyModalOpen}
        close={() => {
          setCreatePropertyModalOpen(false)
        }}
      />
      <CreatePropertyFromLeadModal
        isOpen={createPropertyModalFromLeadOpen}
        close={() => {
          setCreatePropertyModalFromLeadOpen(false)
        }}
      />
    </>
  )
}

export default DataLakePage
