import { Box, MenuItem, MenuList, Popover } from '@mui/material'
import React, { useEffect, useState, MouseEvent } from 'react'
// @ts-ignore
import ReactImageMagnify from '@milosmladenovicwork/react-image-magnify'
import { getImageSize } from 'react-image-size'

type MagnifiableImageProps = {
  src: string
  onDeleteImage: () => void
}

const MagnifiableImage = (props: MagnifiableImageProps) => {
  const { src, onDeleteImage } = props
  const [magnification, setMagnification] = useState(1)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [imageDimensions, setImageDimensions] = useState({
    width: 480,
    height: 360,
  })
  const [smallDimensions, setSmallDimensions] = useState({
    width: 480,
    height: 360,
  })
  const [popoverPosition, setPopoverPosition] = useState<null | {
    top: number
    left: number
  }>(null)

  const handleScroll = (e: any) => {
    if (!isMouseOver) return
    e.preventDefault()

    const delta = Math.sign(e.deltaY)
    setMagnification((prev: number) => Math.max(1, prev - delta * 0.1))
  }

  const handleRightClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setPopoverPosition({ top: e.clientY, left: e.clientX })
  }

  const handleClosePopover = () => {
    setPopoverPosition(null)
  }

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false })
    return () => window.removeEventListener('wheel', handleScroll)
  }, [isMouseOver])

  const setImageSize = async () => {
    try {
      const newDimensions = await getImageSize(src)
      setImageDimensions(newDimensions)
      // Get dimensions so image fits into the small image container which is 480x360
      // Handle the case where the image is taller than it is wide
      if (newDimensions.height / newDimensions.width > 360 / 480) {
        setSmallDimensions({
          width: (newDimensions.width / newDimensions.height) * 360,
          height: 360,
        })
      } else {
        setSmallDimensions({
          width: 480,
          height: (newDimensions.height / newDimensions.width) * 480,
        })
      }
    } catch (error) {
      console.error('Error loading image dimensions:', error)
    }
  }

  useEffect(() => {
    setImageSize()
  }, [src])

  return (
    <Box
      width="100%"
      height="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      onContextMenu={handleRightClick}
      zIndex={99999}
    >
      <ReactImageMagnify
        smallImage={{
          src,
          width: smallDimensions.width,
          height: smallDimensions.height,
        }}
        largeImage={{
          src,
          width: (imageDimensions.width / 2) * magnification,
          height: (imageDimensions.height / 2) * magnification,
        }}
        enlargedImageContainerStyle={{
          left: 'unset',
          right: '100%',
          marginLeft: 'unset',
          marginRight: '10px',
        }}
      />
      <Popover
        open={Boolean(popoverPosition)}
        onClose={handleClosePopover}
        anchorReference="anchorPosition"
        anchorPosition={popoverPosition as any}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box display="flex" flexDirection="column">
          <MenuList>
            <MenuItem
              onClick={() => {
                handleClosePopover()
                onDeleteImage()
              }}
              sx={{
                color: 'errorRed.main',
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </Box>
  )
}

export default MagnifiableImage
