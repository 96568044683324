import { useMutation } from '@apollo/client'
import { ConfirmModal } from '@flock/flock-component-library'
import {
  AdminDeleteWorkOrderDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_DeletePropertyWorkOrderRequestInput,
  Core_PropertyWorkOrder,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import React from 'react'

type DeleteWorkOrderModalParams = {
  workOrder: Core_PropertyWorkOrder
  isOpen: boolean
  close: () => void
}

const DeleteWorkOrderModal = (props: DeleteWorkOrderModalParams) => {
  const { workOrder, isOpen, close } = props
  const { notify } = useSnackbar()
  const [deleteWorkorder, { loading }] = useMutation(
    AdminDeleteWorkOrderDocument
  )

  const deleteWorkOrderInput: Core_DeletePropertyWorkOrderRequestInput = {
    workOrderUuid: workOrder.uuid,
  }

  const onSubmit = async () => {
    try {
      await deleteWorkorder({
        variables: {
          input: deleteWorkOrderInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify(
        `Successfully deleted work order ${workOrder.orderNumber}.`,
        'success'
      )
      close()
    } catch (e) {
      notify('Failed to delete work order.', 'error')
    }
  }

  return (
    <ConfirmModal
      title="Delete Work Order"
      content={
        loading
          ? 'deleting...'
          : `Are you sure you want to delete work order number: ${workOrder.orderNumber}?`
      }
      open={isOpen}
      onClose={close}
      onSubmit={onSubmit}
    />
  )
}

export default DeleteWorkOrderModal
