import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { CheckboxLabel } from '../printCollateralPageTypes'
import { Header } from '../SharedPrintCollateralComponents'
import { UpdateRenderStateParams } from './collateralRendererTypes'
import SinglePagePrintWrapper from './SinglePagePrintWrapper'

const questionAnswerSets = [
  {
    section: 'Exchange Qualifications',
    data: [
      {
        question: 'Can I exchange my home with residents and a lease in place?',
        answer: [
          'Yes. Flock will accept homes with residents in place. The lease will be transferred at closing and honored for the remainder of the term. When the lease term is over, Flock may offer a new lease to the residents. ',
          'The current rental rate is evaluated while underwriting the home and may impact your final contribution value into the fund. When the current rental rate falls below Flock’s underwritten market rate, a “sub-market rent deduction” will be applied to your transaction at closing. The deduction accounts for the below-market rent that is inherited through the lease and ensures that every home entering the fund meets yield requirements at no cost to current fund Owners.',
        ],
      },
      {
        question:
          'Can I exchange my home with delinquent residents or an ongoing eviction? ',
        answer: [
          'Yes. Flock understands the headaches that come with delinquent residents and we’re no strangers to the eviction process. Adjustments may be made to your final contribution value into the fund at closing to account for the costs of stabilizing your home. Accounting for these costs at closing from the individual exchanging the home protects the other fund Owners. In the future, when we transact on other homes with delinquencies and evictions, neither you nor the fund will be financially responsible for stabilization. ',
        ],
      },
      {
        question: 'Can I exchange my home if it is owned through my IRA?',
        answer: [
          'Yes. If your home is owned through your IRA, you’re able to exchange it with Flock. We suggest consulting with your custodian or CPA to understand any unique circumstances the exchange may trigger. ',
        ],
      },
      {
        question:
          'When does Flock close transactions and finalize the exchange?',
        answer: [
          'Flock will complete your transaction only on the last business day of each month. Closings are scheduled for the end of the month to align with your investment date (the 1st of the following month), as well as our financial and performance reporting schedules. Your exchange is complete after your home has closed and your investment account has been funded. ',
        ],
      },
    ],
  },
  {
    section: 'The Exchange Process + Entering Flock’s Fund',
    data: [
      {
        question: 'Once I join Flock, do I still own my house?',
        answer: [
          "When you exchange with Flock, title of your home is transferred to Flock’s partnership. Instead of your home, you now own units (you can think of these as 'shares') in the Partnership containing every house in Flock’s portfolio. Put simply, you’ll own a portion of hundreds of homes across the country without bearing any responsibilities that come with home ownership! You’ll have access to Flock’s Owner Portal, which provides real-time information on every home in the portfolio and transparency into individual and fund-wide performance.",
        ],
      },
      {
        question: 'Can I own units in Flock with any entity of my choice?',
        answer: [
          'Yes. You can take ownership of Flock’s fund using any ownership structure you would like. It does not need to match the ownership structure of the home you are exchanging. That said, while entities do not need to match, the underlying owners must remain consistent.',
          'This can be slightly confusing, complicated, and cumbersome, and Flock is here to talk through your desired investment plan. We also suggest consulting with your CPA to confirm your decisions are favorable from a personal tax perspective.',
        ],
      },
      {
        question: 'What entity types can own units in Flock’s fund?',
        answer: [
          'Flock can accommodate almost any type of entity as long as it meets accredited investor requirements. Individual persons, trusts (revocable and irrevocable), LLCs, corporations, general partnerships, and limited partnerships are the most common investor types.',
        ],
      },
      {
        question:
          'What is an accredited investor and how do I know if I qualify?',
        answer: [
          'Being an accredited investor is a requirement to join Flock. This means that you meet a certain income or net worth threshold determined by the SEC. Don’t worry, Flock makes accreditation verification easy!',
        ],
      },
      {
        question: 'How long does the Flock onboarding process take?',
        answer: [
          'Flock’s onboarding process generally requires two weeks to complete all title and fund subscription tasks. Our tech-enabled onboarding process allows you to easily complete all title tasks quickly and efficiently compared to a traditional sale; there is no manual paperwork! You’ll have a dedicated Flock representative at your side for any questions that arise.',
        ],
      },
      {
        question:
          'When does the onboarding process begin? And what are the main steps?',
        answer: [
          'The onboarding process begins upon execution of the Contribution Agreement (the Contribution Agreement is Flock’s version of a traditional Purchase and Sale Contract). After the Contribution Agreement is signed, you will complete 6 steps in your dedicated online portal that encompass all required questions and documentation to successfully exchange your home and enter the fund.',
        ],
      },
    ],
  },
  {
    section: 'The Exchange Process + Entering Flock’s Fund (cont.)',
    data: [
      {
        question:
          'When do I notify my residents or property manager that I am exchanging my home with Flock?',
        answer: [
          'For the smoothest transition, residents and property managers should be notified of the exchange once the Contribution Agreement is signed.',
          'If your property manager will provide Flock with property information during onboarding, their cooperation is imperative to successfully completing your transaction. Flock will even work directly with your property manager if that is preferred.',
          'Residents must be notified of the change in management no later than 2 weeks prior to your closing date. Once notified, Flock will reach out and introduce new management and likewise welcome them.',
        ],
      },
      {
        question:
          'Can I use my preferred notary, title company, or closing attorney during the onboarding process?',
        answer: [
          'No. Flock has specific title partnerships in every market that are intimately familiar with the unique structure of Flock transactions. We utilize our preferred partners to ensure your closing is on-time and correct. Our title partners will provide notaries and closing attorneys when necessary, and in all markets, we provide a mobile notary option for your convenience.',
        ],
      },
      {
        question:
          'When can I cancel insurance on the home I am exchanging into Flock?',
        answer: [
          'You can cancel your insurance coverage of the home as of 11:59pm on your closing date, which is the last business day of the month. Flock will send you a reminder during the onboarding process for this task.',
        ],
      },
    ],
  },
  {
    section: 'Owning with Flock',
    data: [
      {
        question: 'Can I access cash flow with Flock and how is it taxed?',
        answer: [
          'Flock allows clients to access allotted cash flow quarterly and, if needed, utilize a Top Up Allowance to further request up to 2% of their account value annually in additional cash flow. While the Top Up Allowance can be effective for clients with specific cash needs, there is an additional charge of 10% of cash received. This charge is a debit to account value (not actual cash flow) and will be posted to Client Portals for record-keeping purposes.',
          'That said, historically, 1 in 3 clients do not access cash flow and instead look to compound returns over-time by remaining invested to bolster their nest egg further. Cash flow settings are dynamic and can be changed quarterly via Client Portal to suit evolving needs.',
          'Cash flow taken is generally non-taxable as it reduces cost basis. Conversely, remaining invested and not accessing cash flow contributes positively to cost basis. Because Flock is a Partnership, Owners of the fund will instead be taxed on pro-rata net income generated by the Partnership. Flock passes-through deductions like interest expense and depreciation to limit taxable net income for all Owners.',
          'Taking cash beyond 2-3% annually in the first 2-3 years of owning Flock may have tax impacts. Actual impacts are expected to be limited and generally in the form of capital gains tax on the amount of cash exceeding this threshold.',
        ],
      },
      {
        question: 'What other liquidity options do I have with Flock?',
        answer: [
          'Your offer specifies your minimum hold period that begins at your contribution date. After the hold period, liquidity requests beyond your allotment and 2% Top Up Allowance must be requested in writing 180 days in advance.',
          'With any liquidity request, we suggest consulting with your CPA or advisor to understand the impacts of this potential taxable event.',
          'Fully redeeming from Flock may trigger taxes you initially wanted to defer. It will also decrease your position to long-term compounded appreciation and reduce your heir’s advantageous step-up in basis. Flock may soon offer a loan product that could help achieve your liquidity goals without compromising the benefits you are receiving by owning Flock. If this is of interest to you, please relay to your dedicated Flock Real Estate Advisor.',
        ],
      },
    ],
  },
  {
    section: 'Taxes',
    data: [
      {
        question:
          'What tax documents will I receive as a Flock Owner and when?',
        answer: [
          'Flock’s tax advisor, KPMG, will prepare annual tax documents for all Owners. Flock will ensure documents are available to download, securely from your portal, by April 1. Below is an outline of what you can expect to receive:',
          '1. Federal Schedule K-1: Includes footnotes describing your individual tax facts based on your exchange with Flock, cost basis, etc.',
          '2. State Schedule K-1: K-1s for states where Flock is operational – this is largely for informational purposes only, except for the state where you reside (you are responsible for addressing any amounts owed and filing requirements associated – to be clear, this is business as usual, you file state taxes where you live!)',
          '3. Schedule K-3: Data from this form is of little to no use if you don’t have other foreign-sourced income or aren’t paying taxes in foreign jurisdictions (the IRS requires that the Schedule K-3 be produced alongside the Schedule K-1 regardless)',
          '4. Composite Election Packet: This is a packet of confirmations allowing Flock to file via composite in relevant states (states where Flock is operational where you do not reside), meaning on your behalf. If you elect to participate, Flock will handle all filing requirements. Any tax owed will be paid from your quarterly cash flow allotment or account debit. The completion of this packet is required by KPMG if you’d like to take advantage of this amenity. Note that without electing to participate in composite filing, you would have to file in every state where Flock is operational, as needed.',
        ],
      },
      {
        question:
          'Will the title company for my transaction issue and send the IRS a 1099-S for the “sale” of the home exchanged with Flock?',
        answer: [
          'The title company is required to submit the 1099-S to the IRS, however, the form does not represent your tax liability. Instead, the annual Schedule K-1 documents will include the necessary information for you to report your transaction.',
        ],
      },
      {
        question:
          'What is the difference between joining Flock and buying shares of a REIT (real estate investment trust)?',
        answer: [
          'Joining Flock is similar to selling your house and buying shares of a REIT. One major difference is that when you join Flock, you avoid the tax consequences of a sale. Instead of buying shares with after-tax proceeds, you’ll preserve value through a tax-deferred exchange for shares in a diversified, managed portfolio of houses.',
        ],
      },
    ],
  },
]

type QuestionAnswerProps = {
  question: string
  answer: string[]
}

const QuestionAnswer = (props: QuestionAnswerProps) => {
  const { question, answer } = props
  return (
    <Box display="flex" flexDirection="column" gap="4px">
      <Typography variant="p4" fontWeight={500}>
        {question}
      </Typography>
      {answer.map((ans) => (
        <Typography key={ans} variant="p4">
          {ans}
        </Typography>
      ))}
    </Box>
  )
}

type FaqPagesProps = {
  updateRenderState: (params: UpdateRenderStateParams) => void
  coverName: string
  pageOffset: number
  batchStart: number
  batchEnd: number
}

const FaqPages = (props: FaqPagesProps) => {
  const { updateRenderState, coverName, pageOffset, batchStart, batchEnd } =
    props

  useEffect(() => {
    updateRenderState({
      section: CheckboxLabel.FAQS,
      doneRendering: true,
      numPages: questionAnswerSets.length,
    })
  }, [])

  return (
    <>
      {questionAnswerSets.map((section, idx) => {
        const { section: sectionTitle, data } = section
        const truePageNumber = pageOffset + idx
        return (
          <SinglePagePrintWrapper
            shouldShow={
              truePageNumber >= batchStart && truePageNumber < batchEnd
            }
            key={sectionTitle}
            pageNumber={pageOffset + idx}
            coverName={coverName}
          >
            <Box display="flex" flexDirection="column" gap="12px">
              <Header
                section="Frequently Asked Questions"
                title={sectionTitle}
              />
              {data.map((qa) => (
                <QuestionAnswer key={qa.question} {...qa} />
              ))}
            </Box>
          </SinglePagePrintWrapper>
        )
      })}
    </>
  )
}

export default FaqPages
