import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { ThemeProvider, Typography } from '@mui/material'
import { gql, useQuery } from '@apollo/client'
import { GridForm, InputType } from '@flock/shared-ui/src/components/GridForm'
import { useSnackbar } from '@flock/flock-component-library'
import { flockTheme } from '@flock/shared-ui/src/theme'
import { LibraryThemeProvider } from '@flock/shared-ui'
import { RouteComponentProps } from '@reach/router'
import { AdminGetStabilizedOccupancyReportUrlDocument } from '@flock/flock-gql-server/src/__generated__/graphql'

const PageContainer = styled('div')({
  minWidth: '70vw',
})

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
})

const SubHeaderContainer = styled('div')({
  marginTop: '1rem',
  marginBottom: '1rem',
  paddingLeft: '3rem',
})

export const GET_STABILIZED_OCCUPANCY_REPORT_URL = gql`
  query AdminGetStabilizedOccupancyReportUrl(
    $input: Core_GetStabilizedOccupancyReportRequestInput!
  ) {
    getStabilizedOccupancyReport(input: $input) {
      url
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DataLakeReportsPage = (_: RouteComponentProps) => {
  const { notify } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const { refetch: getStabilizedOccupancyReportUrl } = useQuery(
    AdminGetStabilizedOccupancyReportUrlDocument,
    {
      skip: true,
      notifyOnNetworkStatusChange: true,
    }
  )

  type FormInputs = {
    startDate: Date
    endDate: Date
    groupBy: string
  }

  const getStabilizedOccupancyReportUrlHelper = async (inputs: FormInputs) => {
    setLoading(true)
    const { startDate, endDate, groupBy } = inputs

    const currdate = new Date()
    if (startDate > currdate || endDate > currdate) {
      notify('Start or End Date should not be after current date', 'error')
    }

    const differenceInDays: number = Math.round(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    )

    if (differenceInDays < 0) {
      notify('End date should be after start date', 'error')
      return
    }
    if (differenceInDays > 366) {
      notify('Start and end date range should be less than a year', 'error')
      return
    }

    try {
      const urlData = await getStabilizedOccupancyReportUrl({
        input: {
          startDate,
          endDate,
          groupBy,
        },
      })
      const s3Url = urlData?.data?.getStabilizedOccupancyReport?.url
      window.open(s3Url as string, '_self')
    } catch (e) {
      notify(
        'An error occurred while downloading the document. Please refresh or try again.',
        'error'
      )
    }
    setLoading(false)
  }

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <PageContainer>
          <PageTitleContainer>
            <Typography variant="h2"> Data Lake Reporting</Typography>
          </PageTitleContainer>
          <SubHeaderContainer>
            <Typography variant="h3">
              {' '}
              Stabilized Daily Occupancy Report
            </Typography>
          </SubHeaderContainer>
          <SubHeaderContainer>
            <GridForm
              onSubmit={(result) => {
                getStabilizedOccupancyReportUrlHelper(result)
              }}
              ctaText="Submit"
              ctaBoxProps={{
                pb: '32px',
                width: '100%',
              }}
              loading={loading}
              inputConfigs={[
                {
                  name: 'startDate',
                  type: InputType.DatePicker,
                  required: true,
                  props: {
                    label: 'Select Start Date',
                  },
                  gridItemProps: {
                    sm: 5,
                  },
                },
                {
                  name: 'filler',
                  type: InputType.CustomComponent,
                  props: {
                    component: <div />,
                  },
                  gridItemProps: {
                    sm: 2,
                  },
                },
                {
                  name: 'endDate',
                  type: InputType.DatePicker,
                  required: true,
                  props: {
                    label: 'Select End Date',
                  },
                  gridItemProps: {
                    sm: 5,
                  },
                  defaultValue: new Date(),
                },
                {
                  name: 'groupBy',
                  type: InputType.Dropdown,
                  required: true,
                  props: {
                    type: 'text',
                    label: 'Group By',
                    fullWidth: true,
                    options: [
                      {
                        label: 'MSA',
                        value: 'msa',
                      },
                      {
                        label: 'Property Manager',
                        value: 'property_manager',
                      },
                    ],
                  },
                },
              ]}
            />
          </SubHeaderContainer>
          <br />
          <br />
        </PageContainer>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default DataLakeReportsPage
