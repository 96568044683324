import React from 'react'
import {
  Box,
  TextField,
  ThemeProvider,
  Typography,
  CssBaseline,
  CircularProgress,
} from '@mui/material'
import {
  FlockLogo,
  flockTheme,
  LibraryThemeProvider,
  flockSquareTheme,
  SearchIcon,
} from '@flock/shared-ui'

import CreateSowProjectModule from './CreateSowProjectModule/CreateSowProjectModule'
import useSowProjectsPage from './useSowProjectsPage'
import { SowProjectsPageProps } from './sowProjectsPageTypes'
import SowProjectsPageRow, {
  SowProjectsPageHeaderRow,
} from './SowProjectsPageRow'

const SowProjectsPage = (props: SowProjectsPageProps) => {
  const presentationalProps = useSowProjectsPage(props)
  const { searchQuery, setSearchQuery, sowProjects, loading } =
    presentationalProps

  return (
    <ThemeProvider theme={flockSquareTheme}>
      <CssBaseline />
      <LibraryThemeProvider theme={flockSquareTheme}>
        <Box
          minHeight="100vh"
          px="120px"
          py="32px"
          sx={{
            backgroundColor: flockTheme.palette.green0.main,
          }}
        >
          <Box display="flex" flexDirection="column" gap="24px">
            {/* Header */}

            <Box display="flex" justifyContent="space-between" gap="32px">
              <Box display="flex" alignItems="center" gap="16px">
                <FlockLogo />
                <Box width="1px" height="32px" bgcolor="rgba(0, 0, 0, 0.1)" />
                <Typography color="moneyGreen.main" variant="cta">
                  Scope of Work
                </Typography>
              </Box>
              <TextField
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
                sx={{
                  minWidth: '352px',
                }}
              />
            </Box>

            {/* Creation module */}
            <CreateSowProjectModule />
            {/* Project list */}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="16px"
            >
              <SowProjectsPageHeaderRow />
              {loading ? (
                <CircularProgress />
              ) : (
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  gap="16px"
                >
                  {sowProjects.map((project) => (
                    <SowProjectsPageRow key={project.uuid} {...project} />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default SowProjectsPage
