import React from 'react'
import { ThemeProvider } from '@mui/system'
import { Box, Button, Typography } from '@mui/material'
import {
  flockTheme,
  LibraryThemeProvider,
  FileUpload,
  FileUploadMimeType,
  FileUploadFileType,
} from '@flock/shared-ui'

import usePrintCollateralPage from './usePrintCollateralPage'
import { PrintCollateralPageProps } from './printCollateralPageTypes'
import SowRenderer from './SowRenderer/SowRenderer'

type PropertyDataRowProps = {
  label: string
  value: string
}

const PropertyDataRow = (props: PropertyDataRowProps) => {
  const { label, value } = props

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="fit-content"
      sx={{
        minWidth: '216px',
      }}
    >
      <Typography variant="p5">{label}</Typography>
      <Typography variant="p5">{value}</Typography>
    </Box>
  )
}

const PrintCollateralPage = (props: PrintCollateralPageProps) => {
  const {
    onUpdateFiles,
    propertySOWs,
    toRender,
    setNextUnitToRender,
    toPDF,
    refToPrint,
  } = usePrintCollateralPage(props)

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <Box display="flex" flexDirection="column" gap="48px" paddingTop="64px">
          <Typography variant="h2">Print Collateral</Typography>
          <Box>
            <FileUpload
              label="Upload the SOW CSV file."
              accept={{
                [FileUploadMimeType.CSV]: [FileUploadFileType.CSV],
              }}
              onChange={onUpdateFiles}
              onBlur={() => {}}
            />
          </Box>
          <Box>
            <Button size="small" onClick={() => toPDF()}>
              Save to PDF
            </Button>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            ref={refToPrint}
            width="452px"
          >
            {propertySOWs.map((propertySOW) => {
              const {
                addressStreet,
                addressCityStateZip,
                sowCreationDate,
                immediatelyAddressableTotal,
                nonImmediatelyAddressableTotal,
                total,
                dataSets,
              } = propertySOW
              return (
                <Box>
                  {dataSets.map((unitSOW, idx) => {
                    const { name, items, summaryItems } = unitSOW
                    if (idx > toRender) {
                      return null
                    }
                    return (
                      <SowRenderer
                        key={name || addressStreet}
                        addressStreet={addressStreet}
                        unitIdx={idx}
                        unitName={name}
                        items={items}
                        setNextUnitToRender={setNextUnitToRender}
                        summaryItems={summaryItems}
                        FirstPageHeaderComponent={() => (
                          <Box
                            display="flex"
                            height="136px"
                            justifyContent="space-between"
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                              height="fit-content"
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap="8px"
                              >
                                <Typography variant="p4">
                                  Scope of Work
                                </Typography>
                                <Typography variant="p2">
                                  {addressStreet}
                                  <br />
                                  {addressCityStateZip}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                              >
                                <PropertyDataRow
                                  label="SOW Creation Date"
                                  value={sowCreationDate}
                                />
                                <PropertyDataRow
                                  label="Immediately Addressable Total"
                                  value={immediatelyAddressableTotal}
                                />
                                <PropertyDataRow
                                  label="Non-Immediately Addressable Total"
                                  value={nonImmediatelyAddressableTotal}
                                />
                                <PropertyDataRow label="Total" value={total} />
                              </Box>
                            </Box>
                          </Box>
                        )}
                        OtherPageHeaderComponent={() => (
                          <Box display="flex">
                            <Typography
                              variant="p4"
                              sx={{
                                textTransform: 'uppercase',
                              }}
                            >
                              {addressStreet} - {name} SOW
                            </Typography>
                          </Box>
                        )}
                      />
                    )
                  })}
                </Box>
              )
            })}
          </Box>
        </Box>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default PrintCollateralPage
