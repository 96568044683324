import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import {
  AdminGetAllInvestorAccountsDocument,
  AdminGetInvestorAccountDocument,
  AdminUpdateInvestorAccountDocument,
  Core_InvestorAccount,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'

import ActionFormModal from '../shared/ActionFormModal'

type EditInvestorAccountParams = {
  uuid: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

type EditInvestorAccountModalParams = {
  investorAccount: Core_InvestorAccount | undefined
  isOpen: boolean
  close: () => void
}

const EditInvestorAccountModal = (props: EditInvestorAccountModalParams) => {
  const { investorAccount, isOpen, close } = props

  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [editInvestorAccount, { loading }] = useMutation(
    AdminUpdateInvestorAccountDocument
  )

  const onSubmit = async (
    editInvestorAccountParams: EditInvestorAccountParams
  ) => {
    const { firstName, lastName, email, phoneNumber } =
      editInvestorAccountParams
    const editInvestorAccountInput = {
      uuid: investorAccount?.uuid as string,
      firstName,
      lastName,
      email,
      phoneNumber,
    }
    try {
      await editInvestorAccount({
        variables: {
          input: editInvestorAccountInput,
        },
        refetchQueries: [
          AdminGetAllInvestorAccountsDocument,
          AdminGetInvestorAccountDocument,
        ],
      })
      notify('Successfully updated investor', 'success')
      close()
    } catch (e) {
      notify('Failed to update legal entity', 'error')
    }
  }
  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update Investor Account"
      inputConfigs={[
        {
          inputName: 'firstName',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Name',
            defaultValue: investorAccount?.firstName,
            fullWidth: true,
          },
        },
        {
          inputName: 'lastName',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Name',
            defaultValue: investorAccount?.lastName,
            fullWidth: true,
          },
        },
        {
          inputName: 'email',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Email',
            defaultValue: investorAccount?.email,
            fullWidth: true,
          },
        },
        {
          inputName: 'phoneNumber',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Phone Number',
            defaultValue: investorAccount?.phoneNumber,
            fullWidth: true,
          },
        },
      ]}
    />
  )
}

export default EditInvestorAccountModal
