import React from 'react'
import { Tab, ThemeProvider } from '@mui/material'
import { flockTheme, LibraryThemeProvider } from '@flock/shared-ui'
import { RouteComponentProps } from '@reach/router'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import SingleFamilyComputeLeadValuationTab from './SingleFamilyComputeLeadValuationTab/SingleFamilyComputeLeadValuationTab'
import MultiFamilyComputeLeadValuationTab from './MultiFamilyComputeLeadValuationTab/MultiFamilyComputeLeadValuationTab'
import useComputeLeadValuationV1Page from './useComputeLeadValuationV1Page'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ComputeLeadValuationV1Page = (_: RouteComponentProps) => {
  const {
    tabValue,
    handleTabChange,
    leadData,
    operatorData,
    valuationHistory,
    valuationHistoryLoading,
  } = useComputeLeadValuationV1Page()

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <TabContext value={tabValue}>
          <TabList onChange={handleTabChange}>
            <Tab value="1" label="Single Family" />
            <Tab value="2" label="Multi Family" />
          </TabList>

          <TabPanel value="1">
            <SingleFamilyComputeLeadValuationTab
              leadData={leadData}
              operatorData={operatorData}
              valuationHistory={valuationHistory}
              valuationHistoryLoading={valuationHistoryLoading}
            />
          </TabPanel>
          <TabPanel value="2">
            <MultiFamilyComputeLeadValuationTab
              leadData={leadData}
              operatorData={operatorData}
              valuationHistory={valuationHistory}
              valuationHistoryLoading={valuationHistoryLoading}
            />
          </TabPanel>
        </TabContext>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default ComputeLeadValuationV1Page
