import {
  Button,
  Typography,
  DataTable,
  useSnackbar,
  flockColors,
  LoadingCard,
} from '@flock/flock-component-library'

import { styled, TableCell, TableRow } from '@mui/material'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Refresh } from '@mui/icons-material'
import {
  AdminGetOperatorDocument,
  AdminGetTasksDocument,
  AdminSearchLeadsDocument,
  AdminUpdateTaskDocument,
  Core_Task,
} from '@flock/flock-gql-server/src/__generated__/graphql'

import { TaskRole, TaskStatus, taskTypesByRole } from './taskTypes'
import CreateOperatorModal from './CreateOperatorModal'

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '4rem',
  marginBottom: '3rem',
  alignItems: 'flex-start',
})

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
})

const Wrapper = styled('div')({
  paddingBottom: '5rem',
})

type TaskManagementPageProps = {
  path: string
  role: TaskRole
}

const TaskManagementPage = (props: TaskManagementPageProps) => {
  const { path, role } = props
  const taskTypes = taskTypesByRole[role]
  const [lastUpdated, setLastUpdated] = useState('')
  const [claimedData, setClaimedData] = useState([] as Core_Task[])
  const [unclaimedData, setUnclaimedData] = useState([] as Core_Task[])
  const { notify } = useSnackbar()

  const [updateTask] = useMutation(AdminUpdateTaskDocument)

  const { data: operatorData } = useQuery(AdminGetOperatorDocument, {
    variables: {
      input: {},
    },
    onError: () => {
      notify('Failed to get operator info', 'error')
    },
  })

  const [
    getUnclaimedTasks,
    { data: unclaimedTaskData, error: unclaimedError },
  ] = useLazyQuery(AdminGetTasksDocument, {
    variables: {
      searchTasksInput: {
        unclaimedOnly: true,
        statuses: [TaskStatus.NOT_STARTED],
        types: taskTypes,
      },
    },
    fetchPolicy: 'no-cache',
    onError: () => {
      notify('Failed to get tasks', 'error')
    },
  })

  const unclaimedLeadUUIDs = unclaimedTaskData?.searchTasks?.tasks.map(
    (task) => task.sourceUuid
  )
  const uniqueUnclaimedLeadUuids = Array.from(
    new Set(unclaimedLeadUUIDs)
  ) as string[]

  const [getClaimedTasks, { data: claimedTaskData, error: claimedError }] =
    useLazyQuery(AdminGetTasksDocument, {
      variables: {
        searchTasksInput: {
          operatorUuid: operatorData?.operator?.operator?.uuid as string,
          statuses: [TaskStatus.NOT_STARTED, TaskStatus.IN_PROGRESS],
          types: taskTypes,
        },
      },
      fetchPolicy: 'no-cache',
      onError: () => {
        notify('Failed to get tasks', 'error')
      },
      onCompleted: () => {
        setClaimedData(claimedTaskData?.searchTasks?.tasks || [])
      },
    })

  const claimedLeadUUIDs = claimedTaskData?.searchTasks?.tasks.map(
    (task) => task.sourceUuid
  )

  const uniqueClaimedLeadUuids = Array.from(
    new Set(claimedLeadUUIDs)
  ) as string[]

  const [getTaskLeads, { data: leadData, error: leadError }] = useLazyQuery(
    AdminSearchLeadsDocument,
    {
      variables: {
        searchLeadsInput: {
          uuids: [...uniqueClaimedLeadUuids, ...uniqueUnclaimedLeadUuids],
        },
      },
      fetchPolicy: 'no-cache',
      onError: () => {
        notify('Failed to get leads for claimed tasks', 'error')
      },
      onCompleted: () => {
        setUnclaimedData(unclaimedTaskData?.searchTasks?.tasks || [])
      },
    }
  )

  const refreshTasks = useCallback(async () => {
    getUnclaimedTasks()
    getTaskLeads()
    if (operatorData?.operator?.operator?.uuid as string) {
      getClaimedTasks()
    }

    const now = new Date()
    setLastUpdated(now.toLocaleTimeString())
  }, [getClaimedTasks, getUnclaimedTasks, getTaskLeads, operatorData])

  useEffect(() => {
    refreshTasks()
  }, [refreshTasks])

  if (claimedError || unclaimedError || leadError) {
    return (
      <LoadingCard text="Failed to load tasks. Please refresh." hideLoader />
    )
  }

  const allTaskLeads = leadData?.searchLeads?.leads || []

  const clickTask = async (taskUuid: string, claimed: boolean) => {
    try {
      if (!claimed) {
        // We update the state of the task view based on the update task being successful
        const selectedTask = unclaimedData.find(
          (task) => task?.uuid === taskUuid
        )
        const newUnclaimedData = unclaimedData.filter(
          (task) => task?.uuid !== taskUuid
        )
        const newClaimedData = claimedData.concat([selectedTask!])
        setClaimedData(newClaimedData)
        setUnclaimedData(newUnclaimedData)
        await updateTask({
          variables: {
            updateTaskInput: {
              taskUuid,
              operatorUuid: operatorData?.operator?.operator?.uuid,
              status: TaskStatus.IN_PROGRESS,
            },
          },
        })
        notify('Successfully claimed task')
        refreshTasks()
      } else {
        // only open in a new tab when continuing a task
        window.open(`${path}/${taskUuid}`, '_blank')
      }
    } catch (e) {
      // if there was an error we refresh notify failure and refresh tasks
      notify('Failed to claim task. Please try again.', 'error')
      const selectedTask = claimedData.find((task) => task?.uuid === taskUuid)
      const newClaimedData = claimedData.filter(
        (task) => task?.uuid !== taskUuid
      )
      const newUnclaimedData = unclaimedData.concat([selectedTask!])
      setClaimedData(newClaimedData)
      setUnclaimedData(newUnclaimedData)
    }
  }

  const CustomRowRender = (
    rowData: [
      string,
      string,
      undefined,
      undefined,
      undefined,
      undefined,
      string,
      string,
      string,
      string,
      string
    ]
  ) => {
    const [
      uuid,
      type,
      ,
      ,
      ,
      ,
      description,
      slaDeadline,
      sourceUuid,
      status,
      operatorUuid,
    ] = rowData
    const slaDeadlineDate = new Date(slaDeadline)

    const rowLead = allTaskLeads.find((lead) => lead?.uuid === sourceUuid)

    let slaColor = flockColors.black
    if (slaDeadlineDate && slaDeadlineDate.getTime() < new Date().getTime()) {
      slaColor = flockColors.red
    }

    const inProgress = status === TaskStatus.IN_PROGRESS

    let ctaText = 'Start'
    if (inProgress) {
      ctaText = 'Continue'
    }
    if (!operatorUuid) {
      ctaText = 'Claim'
    }

    return (
      <TableRow key={uuid}>
        <TableCell>{_.startCase(type)}</TableCell>
        <TableCell>{rowLead?.fullName}</TableCell>
        <TableCell>{rowLead?.source}</TableCell>
        <TableCell>
          {_.startCase(rowLead?.transactionType?.slice(17))}
        </TableCell>
        <TableCell>
          {JSON.parse(rowLead?.answers || '{}')?.propertyType}
        </TableCell>
        <TableCell>
          {description?.replace('Create a valuation for ', '')}
        </TableCell>
        <TableCell sx={{ color: slaColor }}>
          {slaDeadlineDate.toLocaleString()}
        </TableCell>
        <TableCell align="right">
          <Button
            variant={inProgress ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => clickTask(uuid, inProgress)}
          >
            {ctaText}
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  const columns = [
    { name: 'uuid', label: 'UUID', options: { display: false } },
    { name: 'type', label: 'Type' },
    { name: 'leadName', label: 'Lead Name' },
    { name: 'source', label: 'Source' },
    { name: 'transactionType', label: 'Transaction Type' },
    { name: 'propertyType', label: 'Property Type' },
    { name: 'description', label: 'Address' },
    { name: 'slaDeadline', label: 'SLA Deadline' },
    { name: 'sourceUuid', label: 'Source UUID', options: { display: false } },
    { name: 'status', label: 'Status', options: { display: false } },
    { name: 'operatorUuid', label: 'Operator', options: { display: false } },
    { name: '', label: '' },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    sort: true,
    selectableRows: 'none',
    responsive: 'standard',
    elevation: 0,
    rowsPerPage: 100,
    sortOrder: {
      name: 'slaDeadline',
      direction: 'desc',
    },
    customRowRender: CustomRowRender,
  }

  return (
    <Wrapper>
      <Header>
        <Typography variant="h1">Tasks - {_.startCase(role)}</Typography>
        <ButtonWrapper>
          <Button variant="outlined" onClick={refreshTasks}>
            <Refresh />
          </Button>
          <Typography
            variant="body2"
            sx={{ opacity: 0.7, marginTop: '0.5rem' }}
          >
            Last Updated: {lastUpdated}
          </Typography>
        </ButtonWrapper>
      </Header>
      <CreateOperatorModal />
      <>
        <Typography
          variant="h3"
          sx={{ marginTop: '2rem', marginBottom: '2rem' }}
        >
          Claimed
        </Typography>
        <DataTable
          title=""
          data={claimedData}
          columns={columns}
          options={options as any}
        />
        <Typography
          variant="h3"
          sx={{ marginTop: '2rem', marginBottom: '2rem' }}
        >
          Unclaimed
        </Typography>
        <DataTable
          title=""
          data={unclaimedData}
          columns={columns}
          options={options as any}
        />
      </>
    </Wrapper>
  )
}

export default TaskManagementPage
