import React, { useState } from 'react'
import {
  Button,
  useSnackbar,
  InputType,
  FileUploadFileType,
} from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'

import { AdminCreateAddressDocumentDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'

type UploadAddressDocumentParams = {
  name: string
  notes: string
  takenDate: Date
  uploadedFile: File[]
  addressDocumentType: string
}

type UploadAddressDocumentProps = {
  addressUuid: string
  buttonLabel?: string
  defaultType?: string
}

const UploadAddressDocumentModal = (props: UploadAddressDocumentProps) => {
  const { addressUuid, buttonLabel, defaultType } = props
  const { notify } = useSnackbar()
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [createAddressDocument] = useMutation(
    AdminCreateAddressDocumentDocument
  )

  const onSubmit = async (result: UploadAddressDocumentParams) => {
    setLoading(true)
    const { name, notes, takenDate, uploadedFile, addressDocumentType } = result
    const files = uploadedFile
    try {
      const createAddressDocumentInput = {
        addressUuid,
        name,
        notes,
        takenDate,
        type: addressDocumentType,
        files,
      }
      await createAddressDocument({
        variables: {
          input: createAddressDocumentInput,
        },
        refetchQueries: 'active',
      })
      notify('Successfully created address document', 'success')
      setOpen(false)
    } catch (e) {
      notify(`Failed to create address document ${e}`, 'error')
    }
    setLoading(false)
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        {buttonLabel}
      </Button>
      <ActionFormModal
        open={open}
        setOpen={() => setOpen(false)}
        loading={loading}
        onSubmit={onSubmit}
        actionText={buttonLabel as string}
        inputConfigs={[
          {
            inputName: 'uploadedFile',
            inputType: InputType.FileUpload,
            required: true,
            props: {
              accept: [
                FileUploadFileType.CSV,
                FileUploadFileType.DOCX,
                FileUploadFileType.DOC,
                FileUploadFileType.PDF,
                FileUploadFileType.XLS,
                FileUploadFileType.XLSX,
                FileUploadFileType.TXT,
              ],
              label: 'Upload Document',
            },
          },
          {
            inputName: 'name',
            inputType: InputType.Text,
            required: true,
            props: {
              label: 'Name',
              type: 'text',
              fullWidth: true,
            },
          },
          {
            inputName: 'notes',
            inputType: InputType.Text,
            props: {
              label: 'Notes',
              type: 'text',
              fullWidth: true,
            },
          },
          {
            inputName: 'addressDocumentType',
            inputType: InputType.Dropdown,
            required: true,
            props: {
              label: 'Type', // add address type to https://github.com/flock-homes/flock-monorepo/blob/master/go/entities/address.go
              fullWidth: true,
              defaultValue: defaultType,
              options: [
                {
                  text: 'Inspection Report',
                  value: 'report',
                },
                {
                  text: 'Invoice',
                  value: 'invoice',
                },
                {
                  text: 'Current Lease',
                  value: 'current_lease',
                },
                {
                  text: 'Housecanary Report',
                  value: 'housecanary',
                },
                {
                  text: 'Scope of Work',
                  value: 'scope_of_work',
                },
                {
                  text: 'Investment Memo',
                  value: 'investment_memo',
                },
                {
                  text: 'Other',
                  value: 'other',
                },
              ],
            },
          },
        ]}
      />
    </>
  )
}

UploadAddressDocumentModal.defaultProps = {
  buttonLabel: 'Upload Address Document',
  defaultType: 'other',
}

export default UploadAddressDocumentModal
