import { gql, useMutation } from '@apollo/client'
import {
  AdminGetAccountDocumentsDocument,
  Core_AccountDocument,
  AdminDeleteAccountDocumentDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { AnyInputConfig, InputType, useSnackbar } from '@flock/shared-ui'
import { Typography } from '@mui/material'
import React, { useState } from 'react'
import GridFormModal from '../../shared/GridFormModal'

export const DELETE_ACCOUNT_DOCUMENT = gql`
  mutation AdminDeleteAccountDocument(
    $input: Core_DeleteAccountDocumentRequestInput!
  ) {
    deleteAccountDocument(input: $input) {
      _empty
    }
  }
`

type DeleteAccountDocumentModalProps = {
  accountId: string
  selectedDocument: Core_AccountDocument
  open: boolean
  onClose: () => void
}

const DeleteAccountDocumentModal = (props: DeleteAccountDocumentModalProps) => {
  const { open, onClose, selectedDocument, accountId } = props
  const { notify } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [deleteAccountDocument] = useMutation(
    AdminDeleteAccountDocumentDocument
  )

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'confirmation',
      type: InputType.CustomComponent,
      props: {
        component: (
          <Typography>
            Are you sure you want to delete &quot;{selectedDocument?.name}
            &quot;?
          </Typography>
        ),
      },
    },
  ]

  const onSubmit = async () => {
    setLoading(true)

    const { uuid } = selectedDocument
    try {
      await deleteAccountDocument({
        variables: {
          input: {
            accountId,
            documentUuid: uuid,
          },
        },
        refetchQueries: [AdminGetAccountDocumentsDocument],
      })
      notify('Successfully deleted Account document', 'success')
      onClose()
    } catch (e) {
      notify('Failed to delete Account document', 'error')
    }
    setLoading(false)
  }

  return (
    <GridFormModal
      title="Delete Document"
      open={open}
      onClose={onClose}
      inputConfigs={inputConfigs}
      onSubmit={onSubmit}
      formProps={{
        defaultValues: {
          ...selectedDocument,
        },
      }}
      gridProps={{
        spacing: 3,
      }}
      ctaText="Confirm"
      loading={loading}
    />
  )
}

export default DeleteAccountDocumentModal
