import { styled, Box, Chip } from '@mui/material'
import { RouteComponentProps, useParams } from '@reach/router'
import {
  Button,
  CircularProgress,
  flockColors,
  OpacityLink,
  Typography,
  useSnackbar,
} from '@flock/flock-component-library'

import { navigate } from 'gatsby'
import React, { useState } from 'react'
import _ from 'lodash'
import { useQuery, useMutation } from '@apollo/client'
import {
  AdminGetOperatorDocument,
  AdminGetTaskDocument,
  AdminUpdateTaskDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import TaskRejectionModal, { RejectionResult } from './TaskRejectionModal'
import LoadingCard from '../LoadingCard'
import { TaskRole, TaskStatus, taskUrlsByRole, Task } from './taskTypes'

const PageTitle = styled(Typography)({
  marginTop: '4rem',
  marginBottom: '0.5rem',
})

const ButtonRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '2rem',
})

type IndividualTaskPageProps = RouteComponentProps & {
  role: TaskRole
}

const IndividualTaskPage = (props: IndividualTaskPageProps) => {
  const { role } = props
  const { taskUuid } = useParams()
  const [updateTask] = useMutation(AdminUpdateTaskDocument)
  const [rejectionModalOpen, setRejectionModalOpen] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const { notify } = useSnackbar()

  const { loading: loadingTask, data: taskData } = useQuery(
    AdminGetTaskDocument,
    {
      variables: {
        input: {
          taskUuid,
        },
      },
      onError: () => {
        notify('Failed to get task data', 'error')
      },
    }
  )
  const processedTaskData = taskData?.task?.task || {}

  const hideCompleteRejectButtons =
    processedTaskData?.type === Task.CREATE_VALUATION || loadingTask

  const { type, status, description, requestData, slaDeadline, operatorUuid } =
    processedTaskData
  const processedRequestData = requestData ? JSON.parse(requestData) : {}
  const camelRequestData = _.mapKeys(processedRequestData, (__, k) =>
    _.camelCase(k)
  )
  const { requestDetail, rejectionOptions, taskUrls } = camelRequestData

  const { loading: loadingOwnOperator, data: ownOperatorData } = useQuery(
    AdminGetOperatorDocument,
    {
      variables: {
        input: {},
      },
      onError: () => {
        notify('Failed to get operator data', 'error')
      },
    }
  )

  const { data: operatorData } = useQuery(AdminGetOperatorDocument, {
    skip: !operatorUuid,
    variables: {
      input: {
        operatorUuid,
      },
    },
    onError: () => {
      notify('Failed to get requesting operator data', 'error')
    },
  })

  const operatorName = operatorData?.operator?.operator?.fullName

  const handleRejection = async (rejectionResult: RejectionResult) => {
    setLoadingSubmit(true)
    try {
      await updateTask({
        variables: {
          updateTaskInput: {
            taskUuid,
            status: TaskStatus.REJECTED,
            resolutionData: JSON.stringify({
              rejection_reason: rejectionResult.rejectionReason,
              rejection_detail: `(${operatorName}): ${rejectionResult.rejectionDetail}`,
            }),
          },
        },
      })

      notify(`Successfully rejected ${_.startCase(type as string)} task.`)

      navigate(taskUrlsByRole[role], { replace: true })
    } catch (e) {
      notify('An error has occurred. Please try again.', 'error')
    }
    setLoadingSubmit(false)
  }

  const handleCompletion = async () => {
    setLoadingSubmit(true)
    try {
      await updateTask({
        variables: {
          updateTaskInput: {
            taskUuid,
            status: TaskStatus.COMPLETED,
          },
        },
      })
      notify(
        `Successfully completed ${_.startCase(type as string)} task.`,
        'success'
      )
      navigate(taskUrlsByRole[role], { replace: true })
    } catch (e: any) {
      let errorMessage = 'An error has occurred. Please try again.'
      if (e.message && (e.message as string).includes('no valuation exists')) {
        errorMessage = (e.message as string).replace(
          '500 - Internal Server Error: server: UpdateTask: could not update task: ',
          ''
        )
      }
      notify(errorMessage, 'error')
    }
    setLoadingSubmit(false)
  }

  const claimTask = async () => {
    setLoadingSubmit(true)
    try {
      await updateTask({
        variables: {
          updateTaskInput: {
            taskUuid,
            operatorUuid: ownOperatorData?.operator?.operator?.uuid,
            status: TaskStatus.IN_PROGRESS,
          },
        },
        refetchQueries: [AdminGetTaskDocument],
      })
      notify(`Successfully claimed ${_.startCase(type as string)} task.`)
    } catch (e) {
      notify('An error has occurred. Please try again.', 'error')
    }
    setLoadingSubmit(false)
  }

  const deadline = new Date(slaDeadline)

  const statusColorMap: { [key: string]: string } = {
    [TaskStatus.NOT_STARTED]: flockColors.darkGray,
    [TaskStatus.IN_PROGRESS]: flockColors.aquamarine,
    [TaskStatus.COMPLETED]: flockColors.blue,
    [TaskStatus.REJECTED]: flockColors.red,
  }

  if (loadingTask || loadingOwnOperator) {
    return <LoadingCard text="Loading Task..." />
  }

  return (
    <>
      <>
        <PageTitle variant="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          Task - {_.startCase(type as string)}
          <Chip
            label={_.startCase(status as string)}
            sx={{
              color: 'white',
              backgroundColor:
                statusColorMap[status as string] || flockColors.lightGray,
              marginLeft: '0.75rem',
            }}
          />
          {operatorName && (
            <Chip
              label={`${
                status === TaskStatus.NOT_STARTED ? 'Assigned to' : 'Claimed by'
              } ${operatorName}`}
              sx={{
                color: 'white',
                backgroundColor: flockColors.orange,
                marginLeft: '0.75rem',
              }}
            />
          )}
        </PageTitle>
        <Typography variant="h2">{description}</Typography>
        <Typography
          variant="h3"
          sx={{ marginTop: '2rem', marginBottom: '0.5rem' }}
        >
          SLA Deadline
        </Typography>
        <Typography>{deadline.toLocaleString()}</Typography>
        {requestDetail && (
          <>
            <Typography
              variant="h3"
              sx={{ marginTop: '2rem', marginBottom: '0.5rem' }}
            >
              Request Detail
            </Typography>
            <Typography>{requestDetail}</Typography>
          </>
        )}
        {taskUrls && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 'fit-content',
            }}
          >
            <Typography
              variant="h3"
              sx={{ marginTop: '2rem', marginBottom: '0.5rem' }}
            >
              Links
            </Typography>
            {taskUrls.map((taskUrl: { label: string; url: string }) => (
              <OpacityLink
                key={taskUrl.label}
                onClick={() => {
                  window.open(taskUrl.url, '_blank')
                }}
              >
                {taskUrl.label}
              </OpacityLink>
            ))}
          </Box>
        )}
        <ButtonRow>
          {loadingSubmit ? (
            <CircularProgress size={32} />
          ) : (
            <>
              {operatorName && status !== TaskStatus.NOT_STARTED ? (
                <>
                  {hideCompleteRejectButtons ? (
                    <Typography>
                      Please complete or reject the valuation on the SAAM
                      valuations page.
                    </Typography>
                  ) : (
                    <>
                      {rejectionOptions && rejectionOptions.length > 0 && (
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{ width: '8rem' }}
                          onClick={() => {
                            setRejectionModalOpen(true)
                          }}
                          disabled={loadingSubmit}
                        >
                          Reject
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ marginLeft: '1rem', width: '8rem' }}
                        onClick={handleCompletion}
                        disabled={loadingSubmit}
                      >
                        Complete
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginLeft: '1rem', width: '8rem' }}
                    onClick={claimTask}
                    disabled={loadingSubmit}
                  >
                    {operatorUuid ? 'Start' : 'Claim'}
                  </Button>
                </>
              )}
            </>
          )}
        </ButtonRow>
        {rejectionOptions && (
          <TaskRejectionModal
            open={rejectionModalOpen}
            onClose={() => {
              setRejectionModalOpen(false)
            }}
            rejectionOptions={rejectionOptions}
            handleRejection={handleRejection}
          />
        )}
      </>
    </>
  )
}

export default IndividualTaskPage
