import {
  Button,
  CircularProgress,
  Paper,
  Typography,
} from '@flock/flock-component-library'
import { Box, Modal, styled } from '@mui/material'
import React, { useState } from 'react'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

const ButtonWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
})

type ConfirmModalText = {
  title: string
  text: string
  onSubmit: () => Promise<void>
}

const ConfirmModal = (props: ConfirmModalText) => {
  const { title, text, onSubmit } = props
  const [open, setOpen] = useState(false)

  const [loading, setLoading] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const submitAndClose = async () => {
    setLoading(true)
    await onSubmit()
    setLoading(false)
    closeModal()
  }
  return (
    <>
      <Button
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => {
          openModal()
        }}
      >
        {title}
      </Button>
      <Modal open={open} onClose={closeModal}>
        <ModalWrapper>
          <Typography
            variant="h2"
            sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
          >
            {title}
          </Typography>
          <Box sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
            <Typography variant="body1">{text}</Typography>
          </Box>

          <ButtonWrapper>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Button variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={submitAndClose}
                  sx={{
                    marginLeft: '1rem',
                  }}
                >
                  Confirm
                </Button>
              </>
            )}
          </ButtonWrapper>
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default ConfirmModal
