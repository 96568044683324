import { gql } from '@apollo/client'

// eslint-disable-next-line import/prefer-default-export
export const CREATE_DOCUMENT_WITH_UPDATE = gql`
  mutation AdminCreateDocumentWithUpdate(
    $createDocumentWithUpdateInput: CreateDocumentWithUpdateInput!
  ) {
    createDocumentWithUpdate(input: $createDocumentWithUpdateInput) {
      updateUuid
      legalEntityUuid
      documentUuid
      documentType
      dateAdded
      title
      subtitle
      tags
      descriptionHTML
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export const CREATE_BULK_DOCUMENT_WITH_UPDATE = gql`
  mutation AdminCreateBulkDocumentWithUpdate(
    $createBulkDocumentWithUpdateInput: CreateBulkDocumentWithUpdateInput!
  ) {
    createBulkDocumentWithUpdate(input: $createBulkDocumentWithUpdateInput) {
      updated
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export const CREATE_UPDATE = gql`
  mutation AdminCreateUpdate($createUpdateInput: CreateUpdateInput!) {
    createUpdate(input: $createUpdateInput) {
      updateUuid
      legalEntityUuid
      title
      subtitle
      tags
      descriptionHTML
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export const CREATE_BULK_UPDATE = gql`
  mutation AdminCreateBulkUpdate(
    $createBulkUpdateInput: CreateBulkUpdateInput!
  ) {
    createBulkUpdate(input: $createBulkUpdateInput) {
      updated
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export const CREATE_DOCUMENT = gql`
  mutation AdminCreateDocument($createDocumentInput: CreateDocumentInput!) {
    createDocument(input: $createDocumentInput) {
      legalEntityUuid
      documentUuid
      documentType
      dateAdded
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export const CREATE_BULK_DOCUMENT = gql`
  mutation AdminCreateBulkDocument(
    $createBulkDocumentInput: CreateBulkDocumentInput!
  ) {
    createBulkDocument(input: $createBulkDocumentInput) {
      updated
    }
  }
`

export const CREATE_LEGAL_ENTITY = gql`
  mutation AdminCreateLegalEntityMutation(
    $createLegalEntityInput: Core_CreateLegalEntityRequestInput!
  ) {
    createLegalEntity(input: $createLegalEntityInput) {
      legalEntity {
        uuid
        name
        type
        email
        primaryMailingAddressUuid
        secondaryMailingAddressUuid
        phoneNumber
        dateJoined
        cashFlowAllotment
        holdingExpiryDate
      }
    }
  }
`

export const EDIT_LEGAL_ENTITY = gql`
  mutation AdminEditLegalEntityMutation(
    $editLegalEntityInput: Core_UpdateLegalEntityRequestInput!
  ) {
    editLegalEntity(input: $editLegalEntityInput) {
      legalEntity {
        uuid
        name
        email
        phoneNumber
        cashFlowAllotment
        cashFlowSelection
        holdingExpiryDate
      }
    }
  }
`

export const CREATE_DISTRIBUTION = gql`
  mutation AdminCreateDistributionMutation(
    $createDistributionInput: Core_CreateLegalEntityDistributionRequestInput!
  ) {
    createDistribution(input: $createDistributionInput) {
      distribution {
        uuid
        legalEntityUuid
        amountCents
        distributionDate
        isReinvestment
      }
    }
  }
`

export const UPDATE_DISTRIBUTION = gql`
  mutation AdminUpdateDistributionMutation(
    $updateDistributionInput: Core_UpdateDistributionRequestInput!
  ) {
    updateDistribution(input: $updateDistributionInput) {
      distribution {
        uuid
        amountCents
        distributionDate
        isReinvestment
      }
    }
  }
`

export const DELETE_DISTRIBUTION = gql`
  mutation AdminDeleteDistributionMutation(
    $deleteDistributionInput: Core_DeleteDistributionRequestInput!
  ) {
    deleteDistribution(input: $deleteDistributionInput) {
      _empty
    }
  }
`

export const DELETE_LEGAL_ENTITIES_DOCUMENT = gql`
  mutation AdminDeleteLegalEntitiesDocumentMutation(
    $deleteLegalEntitiesDocumentInput: Core_DeleteLegalEntityDocumentRequestInput!
  ) {
    deleteLegalEntitiesDocument(input: $deleteLegalEntitiesDocumentInput) {
      _empty
    }
  }
`

export const DELETE_LEGAL_ENTITY = gql`
  mutation AdminDeleteLegalEntity(
    $deleteLegalEntityInput: Core_DeleteLegalEntityRequestInput!
  ) {
    deleteLegalEntity(input: $deleteLegalEntityInput) {
      _empty
    }
  }
`
