import { TableRow, TableCell, IconButton } from '@mui/material'
import { DownloadSimple } from 'phosphor-react'
import React from 'react'
import { snakeToTitleCase } from '../../utils'

export const addressPhotoColumns = [
  { name: 'uuid', options: { display: false } },
  { name: 'name', label: 'Name' },
  { name: 'takenDate', label: 'Taken Date' },
  { name: 'notes', label: 'Notes' },
  { name: 'type', label: 'Type' },
  { name: 'presignedUrl', label: 'Download' },
]

export const addressDocumentColumns = [
  { name: 'uuid', options: { display: false } },
  { name: 'name', label: 'Name' },
  { name: 'notes', label: 'Notes' },
  { name: 'type', label: 'Type' },
  { name: 'presignedUrl', label: 'Download' },
]

export const addressPhotosRenderer =
  (setAddressPhotoUuid: any) =>
  (data: [string, string, string, string, string]) => {
    const [uuid, name, takenDate, notes, type] = data
    const takenDateFormat = new Date(takenDate)

    return (
      <TableRow key={uuid}>
        <TableCell>{name}</TableCell>
        <TableCell>{takenDateFormat.toLocaleDateString()}</TableCell>
        <TableCell>{notes}</TableCell>
        <TableCell>{snakeToTitleCase(type)}</TableCell>
        <TableCell>
          <IconButton
            aria-label="Download"
            onClick={() => {
              setAddressPhotoUuid(uuid)
            }}
            size="large"
          >
            <DownloadSimple size="1.25rem" />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

export const addressDocumentsRenderer =
  (setAddressDocumentUuid: any) =>
  (data: [string, string, string, string, string]) => {
    const [uuid, name, notes, type] = data

    return (
      <TableRow key={uuid}>
        <TableCell>{name}</TableCell>
        <TableCell>{notes}</TableCell>
        <TableCell>{snakeToTitleCase(type)}</TableCell>
        <TableCell>
          <IconButton
            aria-label="Download"
            onClick={() => {
              setAddressDocumentUuid(uuid)
            }}
            size="large"
          >
            <DownloadSimple size="1.25rem" />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }
