import React, { useState } from 'react'
import {
  Typography,
  Button,
  LoadingCard,
  PopoverMenu,
  useSnackbar,
  OpacityLink,
  DataTable,
} from '@flock/flock-component-library'
import {
  AdminGetBrokerDocument,
  AdminGetEventsDocument,
  AdminGetOperatorDocument,
  AdminProvisionBrokerAccountDocument,
  AdminSearchLeadsDocument,
  Core_Broker,
  Core_Event,
  Core_Event as Event,
  Core_Lead,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { styled } from '@mui/material/styles'
import { TableRow, TableCell } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useMutation, useQuery } from '@apollo/client'

import { RouteComponentProps, useParams } from '@reach/router'

import {
  FLOCK_LANDING_URL,
  FLOCK_PARTNER_SIMULATOR_URL,
  SALES_LEAD_MANAGEMENT_URL,
} from '../../constants'
import CreateEventModal from './CreateEventModal'
import EditEventModal from './EditEventModal'
import DeleteEventModal from './DeleteEventModal'

import {
  CustomLeadRowRender,
  leadColumns,
  formatLead,
} from '../HomeOps/LeadRowRender'
import { formatDateString } from '../InvestorManagement/utils'
import ConfirmModal from '../TaskManagement/ConfirmModal'
import UpdateBrokerModal from './UpdateBrokerModal'
import DeleteBrokerModal from './DeleteBrokerModal'

const tableOptions = (customRowRender: (data: any) => any) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender,
  elevation: 0,
})

const Heading = styled('div')({
  marginTop: '4rem',
  marginBottom: '1rem',
})

const eventsColumns = [
  { name: 'title', label: 'Title' },
  { name: 'eventType', label: 'Event Type' },
  { name: 'notes', label: 'Notes' },
  { name: 'date', label: 'Event Date' },
  { name: 'uuid', label: ' ' },
]

const EventsCustomRowRender =
  (
    openEditEvent: (existingEvent: Event) => void,
    openDeleteEvent: (existingEvent: Event) => void
  ) =>
  (data: [string, string, string, string, string]) => {
    const [title, eventType, notes, date, uuid] = data
    const event: Event = { title, eventType, notes, date, uuid }
    return (
      <TableRow>
        <TableCell>{title}</TableCell>
        <TableCell>{eventType}</TableCell>
        <TableCell>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: notes }} />
        </TableCell>
        <TableCell>{formatDateString(date)}</TableCell>
        <TableCell>
          <EditIcon onClick={() => openEditEvent(event)} />
          <DeleteOutlineIcon onClick={() => openDeleteEvent(event)} />
        </TableCell>
      </TableRow>
    )
  }

const PageTitleContainer = styled('div')({
  marginTop: '3rem',
  marginBottom: '2rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IndividualBrokerPage = (_: RouteComponentProps) => {
  const [eventModalOpen, setEventModalOpen] = useState<boolean>(false)
  const [editEventModalOpen, setEditEventModalOpen] = useState<boolean>(false)
  const [deleteEventModalOpen, setDeleteEventModalOpen] =
    useState<boolean>(false)
  const [existingEvent, setExistingEvent] = useState<Event>()

  const openEditEventModal = (event: Event) => {
    setEditEventModalOpen(true)
    setExistingEvent(event)
  }

  const openDeleteEventModal = (event: Event) => {
    setDeleteEventModalOpen(true)
    setExistingEvent(event)
  }

  const closeEventModal = () => {
    setEventModalOpen(false)
  }
  const closeEditEventModal = () => {
    setEditEventModalOpen(false)
  }
  const closeDeleteEventModal = () => {
    setDeleteEventModalOpen(false)
  }

  const { brokerUuid } = useParams()
  const { notify } = useSnackbar()

  const { loading: brokerLoading, data: brokerData } = useQuery(
    AdminGetBrokerDocument,
    {
      variables: {
        input: {
          brokerUuid,
        },
      },
      onError: () => {
        notify('Failed to get broker data', 'error')
      },
    }
  )

  const { data: operatorData } = useQuery(AdminGetOperatorDocument, {
    variables: {
      input: {
        operatorUuid: brokerData?.broker?.broker?.operatorUuid as string,
      },
    },
    onError: () => {
      notify('Failed to get assigned operator', 'error')
    },
    skip: brokerLoading || !brokerData,
  })

  const { loading: leadsLoading, data: leadsData } = useQuery(
    AdminSearchLeadsDocument,
    {
      variables: {
        searchLeadsInput: {
          brokerUuid,
        },
      },
      onError: () => {
        notify('Failed to get broker leads', 'error')
      },
    }
  )

  const [createBrokerLogin] = useMutation(AdminProvisionBrokerAccountDocument)

  const { loading: eventsLoading, data: eventsData } = useQuery(
    AdminGetEventsDocument,
    {
      variables: {
        input: {
          entityUuid: brokerUuid,
        },
      },
      onError: () => {
        notify('Failed to get broker events', 'error')
      },
    }
  )

  const formatEvent = (event: {
    title: any
    eventType: any
    notes: any
    date: any
    uuid: string
  }) => ({
    title: event.title,
    eventType: event.eventType,
    notes: event.notes,
    date: event.date,
    uuid: event.uuid,
  })

  if (brokerLoading) {
    return <LoadingCard text="Loading broker..." />
  }

  const broker = brokerData?.broker?.broker as Core_Broker
  const { firstName, lastName, email, phoneNumber, websiteUrl, shortLink } =
    broker

  const leads = leadsData?.searchLeads?.leads?.map(formatLead as any) || []

  const events = eventsData?.events?.events?.map(formatEvent as any) || []

  const onCreateLogin = async () => {
    try {
      await createBrokerLogin({
        variables: {
          brokerUuid: { brokerUuid },
        },
      })
      notify('Successfully provisioned broker login', 'success')
    } catch (e: any) {
      if (e?.message?.includes('409')) {
        notify(
          'Broker already has a login with the associated email. Please tell the broker to reset their password from the login screen.',
          'error'
        )
      } else {
        notify('Failed to provision a broker account', 'error')
      }
    }
  }

  const actions = [
    <ConfirmModal
      title="Create Broker Login"
      text="Create a login for brokers? This will send an email to the broker prompting them to sign in and change their password."
      onSubmit={onCreateLogin}
    />,
    <UpdateBrokerModal existingBroker={broker} />,
    <DeleteBrokerModal existingBroker={broker} title="Delete Broker" />,
  ]

  const links = [
    {
      text: 'Open Referral Page',
      onClick: () => {
        window.open(
          `${FLOCK_LANDING_URL}/disable-tracking/?redirectUrl=${FLOCK_LANDING_URL}/partner/${shortLink}`,
          '_blank'
        )
      },
    },
    {
      text: 'Open Partner Portal',
      onClick: () => {
        window.open(
          `${FLOCK_PARTNER_SIMULATOR_URL}/app/simulator/${brokerUuid}/app/transactions`,
          '_blank'
        )
      },
    },
  ]

  return (
    <>
      <Heading sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h1">Broker</Typography>
        <div>
          <PopoverMenu
            text="Links"
            buttonProps={{
              variant: 'outlined',
              sx: {
                marginRight: '1rem',
              },
            }}
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            actions={links}
          />
          <PopoverMenu
            text="Actions"
            buttonProps={{
              variant: 'contained',
              'data-cy': 'actionsButton',
            }}
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            actions={actions}
          />
        </div>
      </Heading>
      {brokerLoading ? (
        <LoadingCard text="" />
      ) : (
        <>
          <Typography>
            {firstName} {lastName}
          </Typography>
          <Typography>{phoneNumber}</Typography>
          <Typography>{email}</Typography>
          {websiteUrl && (
            <OpacityLink href={websiteUrl} target="_blank">
              Website
            </OpacityLink>
          )}
          <Typography>
            Sales Assignee: {operatorData?.operator?.operator?.fullName}
          </Typography>
        </>
      )}
      <Typography variant="h2" sx={{ paddingTop: '2rem' }}>
        Leads
      </Typography>
      {leadsLoading ? (
        <LoadingCard text="Loading broker leads..." />
      ) : (
        <DataTable
          title=""
          data={leads as Core_Lead[]}
          columns={leadColumns}
          options={
            tableOptions(
              CustomLeadRowRender(SALES_LEAD_MANAGEMENT_URL, notify)
            ) as any
          }
        />
      )}
      <PageTitleContainer>
        <Typography variant="h2">Events</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setEventModalOpen(true)
          }}
        >
          Create Event
        </Button>
      </PageTitleContainer>
      {eventsLoading ? (
        <LoadingCard text="Loading broker events..." />
      ) : (
        <DataTable
          title=""
          data={events as Core_Event[]}
          columns={eventsColumns}
          options={
            tableOptions(
              EventsCustomRowRender(openEditEventModal, openDeleteEventModal)
            ) as any
          }
        />
      )}
      <CreateEventModal
        entityUuid={brokerUuid}
        entityType="broker"
        isOpen={eventModalOpen}
        close={closeEventModal}
      />
      <EditEventModal
        existingEvent={existingEvent}
        isOpen={editEventModalOpen}
        close={closeEditEventModal}
      />
      <DeleteEventModal
        existingEvent={existingEvent}
        isOpen={deleteEventModalOpen}
        close={closeDeleteEventModal}
      />
    </>
  )
}

export default IndividualBrokerPage
