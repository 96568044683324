import React, { useState } from 'react'
import {
  Button,
  useSnackbar,
  FileUploadFileType,
  InputType,
} from '@flock/flock-component-library'
import { AdminGetLeadDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'

import ActionFormModal from '../shared/ActionFormModal'
import { CREATE_ADDRESS_PHOTO } from '../../graphql/mutations/propertyAssets'

type UploadAddressPhotoParams = {
  name: string
  notes: string
  takenDate: string
  uploadedFile: File[]
  addressPhotoType: string
}

type UploadAddressPhotoProps = {
  addressUuid: string
}

const UploadAddressPhotoModal = (props: UploadAddressPhotoProps) => {
  const { addressUuid } = props
  const { notify } = useSnackbar()
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [createAddressPhoto] = useMutation(CREATE_ADDRESS_PHOTO)

  const onSubmit = async (result: UploadAddressPhotoParams) => {
    setLoading(true)
    const { name, notes, takenDate, uploadedFile, addressPhotoType } = result
    const files = uploadedFile
    try {
      const createAddressPhotoInput = {
        addressUuid,
        name,
        notes,
        takenDate,
        type: addressPhotoType,
        files,
        isPublic: true,
        isPrimary: false,
      }
      await createAddressPhoto({
        variables: {
          input: createAddressPhotoInput,
        },
        refetchQueries: [AdminGetLeadDocument],
      })
      notify('Successfully created address photo', 'success')
      setOpen(false)
    } catch (e) {
      notify(`Failed to create address photo ${e}`, 'error')
    }
    setLoading(false)
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Upload Address Photo
      </Button>
      <ActionFormModal
        open={open}
        setOpen={() => setOpen(false)}
        loading={loading}
        onSubmit={onSubmit}
        actionText="Upload Address Photo"
        inputConfigs={[
          {
            inputName: 'uploadedFile',
            inputType: InputType.FileUpload,
            required: true,
            props: {
              accept: [
                FileUploadFileType.PNG,
                FileUploadFileType.JPEG,
                FileUploadFileType.JPG,
              ],
              label: 'Upload Photo',
            },
          },
          {
            inputName: 'takenDate',
            inputType: InputType.DatePicker,
            required: true,
            props: {
              label: 'Taken Date',
              fieldProps: {
                fullWidth: true,
              },
            },
          },
          {
            inputName: 'name',
            inputType: InputType.Text,
            required: true,
            props: {
              label: 'Name',
              type: 'text',
              fullWidth: true,
            },
          },
          {
            inputName: 'notes',
            inputType: InputType.Text,
            props: {
              label: 'Notes',
              type: 'text',
              fullWidth: true,
            },
          },
          {
            inputName: 'addressPhotoType',
            inputType: InputType.Dropdown,
            required: true,
            props: {
              label: 'Type', // add address type to https://github.com/flock-homes/flock-monorepo/blob/master/go/entities/address.go
              fullWidth: true,
              defaultValue: 'other',
              options: [
                {
                  text: 'After Renovation',
                  value: 'after_renovation',
                },
                {
                  text: 'Before Renovation',
                  value: 'before_renovation',
                },
                {
                  text: 'Inspection',
                  value: 'inspection',
                },
                {
                  text: 'Lead',
                  value: 'lead',
                },
                {
                  text: 'Other',
                  value: 'other',
                },
              ],
            },
          },
        ]}
      />
    </>
  )
}

export default UploadAddressPhotoModal
