import React from 'react'
import { Paper } from '@flock/flock-component-library'
import { Modal, styled } from '@mui/material'
import IndividualOrderPageV2 from './IndividualOrderPageV2'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type IndividualOrderV2ModalParams = {
  orderUuid: string
  isOpen: boolean
  close: () => void
}

const IndividualOrderV2Modal = (props: IndividualOrderV2ModalParams) => {
  const { orderUuid, isOpen, close } = props
  return (
    <Modal open={isOpen} onClose={close}>
      <ModalWrapper>
        <IndividualOrderPageV2 orderUuid={orderUuid} />
      </ModalWrapper>
    </Modal>
  )
}

export default IndividualOrderV2Modal
