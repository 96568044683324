import { Box } from '@mui/material'
import React from 'react'

type SolutionRowContainerProps = {
  children: React.ReactNode[]
}

const SolutionRowContainer = (props: SolutionRowContainerProps) => {
  const { children } = props

  return (
    <Box display="flex" gap="4px" width="100%" px="8px">
      <Box minWidth="148px" maxWidth="148px" lineHeight="16px">
        {children[0]}
      </Box>
      <Box width="100%" lineHeight="16px">
        {children[1]}
      </Box>
      <Box minWidth="160px" maxWidth="160px" lineHeight="16px">
        {children[2]}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        minWidth="36px"
        maxWidth="36px"
        lineHeight="16px"
      >
        {children[3]}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        minWidth="58px"
        maxWidth="58px"
        lineHeight="16px"
      >
        {children[4]}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        minWidth="40px"
        maxWidth="40px"
        lineHeight="16px"
      >
        {children[5]}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        minWidth="56px"
        maxWidth="56px"
        lineHeight="16px"
        overflow="hidden"
        textOverflow="clip"
      >
        {children[6]}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        minWidth="40px"
        maxWidth="40px"
        lineHeight="16px"
      >
        {children[7]}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        minWidth="72px"
        maxWidth="72px"
        lineHeight="16px"
      >
        {children[8]}
      </Box>
    </Box>
  )
}

export default SolutionRowContainer
