import React from 'react'
import { Box, styled, Typography } from '@mui/material'

type HeaderProps = {
  section: string
  title: string
}
export const Header = (props: HeaderProps) => {
  const { section, title } = props
  return (
    <Box display="flex" flexDirection="column" gap="4px">
      <Typography variant="p4" sx={{ fontWeight: 500 }}>
        {section}
      </Typography>
      <Typography variant="p2" sx={{ fontWeight: 500 }}>
        {title}
      </Typography>
    </Box>
  )
}

export const OutlinedBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid #CBCDCF',
})

export const FilledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '12px',
  borderRadius: '8px',
  backgroundColor: 'rgba(225, 238, 239, 0.50)',
})
