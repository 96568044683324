import { InputType, useSnackbar, AnyInputConfig } from '@flock/shared-ui'
import { gql, useMutation } from '@apollo/client'
import {
  AdminAddLeaseAndTenantInfoCreateAddressDocumentDocument,
  AdminAddLeaseAndTenantInfoModalCreateOrUpdateAddressDetailsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { AddLeaseAndTenantInfoProps } from './addLeaseAndTenantInfoModalTypes'

export const CREATE_OR_UPDATE_ADDRESS_DETAILS = gql`
  mutation AdminAddLeaseAndTenantInfoModalCreateOrUpdateAddressDetails(
    $addressDetailsInput: Core_CreateOrUpdateAddressDetailsRequestInput!
  ) {
    createOrUpdateAddressDetails(input: $addressDetailsInput) {
      _empty
    }
  }
`

export const CREATE_ADDRESS_DOCUMENT = gql`
  mutation AdminAddLeaseAndTenantInfoCreateAddressDocument(
    $input: CreateAddressDocumentInput!
  ) {
    createAddressDocument(input: $input) {
      addressDocuments {
        name
        documentUuid
      }
    }
  }
`

const useAddLeaseAndTenantInfoModal = (props: AddLeaseAndTenantInfoProps) => {
  const { address, onClose } = props
  const { notify } = useSnackbar()
  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'currentLeaseFile',
      type: InputType.FileUpload,
      required: true,
      props: {
        label: 'Please upload the current lease agreement.',
      },
    },
    {
      name: 'tenantInformation',
      type: InputType.Multiform,
      props: {
        title: 'Tenant Information',
        minForms: 1,
        inputConfigs: [
          {
            name: 'tenantName',
            type: InputType.Text,
            props: {
              label: 'Name',
              format: 'text',
            },
          },
          {
            name: 'tenantEmail',
            type: InputType.Text,
            props: {
              label: 'Email',
              format: 'email',
            },
          },
          {
            name: 'tenantPhone',
            type: InputType.Text,
            props: {
              format: 'phone',
              label: 'Phone Number',
            },
          },
        ],
        incrementText: 'Add Additional Tenant',
        decrementText: 'Remove Tenant',
      },
    },
    {
      name: 'leaseStartDate',
      type: InputType.DatePicker,
      required: true,
      props: {
        label: 'Lease Start Date',
        defaultValue: new Date(),
      },
    },
    {
      name: 'leaseEndDate',
      type: InputType.DatePicker,
      required: true,
      props: {
        label: 'Lease End Date',
        defaultValue: new Date(),
      },
    },
    {
      name: 'monthlyRent',
      type: InputType.Text,
      props: {
        label: 'Monthly Rent',
        format: 'dollars',
      },
    },
    {
      name: 'utilitiesPaidByTenant',
      type: InputType.RadioSelect,
      required: true,
      props: {
        label: 'Utilities Paid by Tenant?',
        defaultValue: '',
        options: [
          {
            label: 'No',
            value: 'N',
          },
          {
            label: 'Yes',
            value: 'Y',
          },
        ],
      },
    },
    {
      name: 'utilitiesAmount',
      type: InputType.Text,
      renderIf: (watchedFields: any) =>
        watchedFields.utilitiesPaidByTenant === 'Y',

      props: {
        label: 'Utilities Amount',
        format: 'dollars',
      },
    },
  ]

  const [createOrUpdateAddressDetails] = useMutation(
    AdminAddLeaseAndTenantInfoModalCreateOrUpdateAddressDetailsDocument
  )
  const [createAddressDocument] = useMutation(
    AdminAddLeaseAndTenantInfoCreateAddressDocumentDocument
  )

  const onSubmit = async (addTenantAndLeaseParams: any) => {
    const createAddressDocumentInput = {
      addressUuid: address?.uuid,
      name: `lease-file-${address?.formattedAddress}`,
      type: 'current_lease',
      files: addTenantAndLeaseParams?.currentLeaseFile,
    }
    try {
      if (addTenantAndLeaseParams?.currentLeaseFile?.length > 0) {
        await createAddressDocument({
          variables: {
            input: createAddressDocumentInput,
          },
        })
      }
      await createOrUpdateAddressDetails({
        variables: {
          addressDetailsInput: {
            addressUuid: address?.uuid,
            leaseInformation: JSON.stringify(addTenantAndLeaseParams),
          },
        },
        refetchQueries: 'active',
      })
      notify('Successfully updated tenant and lease info', 'success')
      onClose()
    } catch (e) {
      notify('Error updating tenant and lease Info', 'error')
    }
  }

  return { inputConfigs, onSubmit }
}

export default useAddLeaseAndTenantInfoModal
