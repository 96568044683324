import {
  AdminAddressValuationsGetAddressValuationHistoryDocument,
  AdminAddressValuationsRejectAddressValuationDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { gql, useMutation } from '@apollo/client'
import { InputType, AnyInputConfig, useSnackbar } from '@flock/shared-ui'
import { RejectValuationModalProps } from './rejectValuationModalTypes'

export const REJECT_VALUATION_V1 = gql`
  mutation AdminAddressValuationsRejectAddressValuation(
    $input: Core_RejectAddressValuationRequestInput!
  ) {
    rejectAddressValuation(input: $input) {
      _empty
    }
  }
`

const useRejectValuationModal = (props: RejectValuationModalProps) => {
  const { canRejectValuation } = props
  const [rejectValuation, { loading }] = useMutation(
    AdminAddressValuationsRejectAddressValuationDocument
  )
  const { notify } = useSnackbar()

  const onSubmit = async (
    formInputs: any,
    addressId: string,
    operatorUuid: string,
    onClose: () => void,
    valuationUuid?: string
  ) => {
    if (!canRejectValuation()) {
      return
    }
    const { rejectionReason } = formInputs
    try {
      await rejectValuation({
        variables: {
          input: {
            addressId,
            operatorUuid,
            rejectionReason,
            valuationUuid,
          },
        },
        refetchQueries: [
          AdminAddressValuationsGetAddressValuationHistoryDocument,
        ],
      })
      onClose()
      notify('Rejected valuation', 'success')
    } catch (e) {
      notify(
        'Failed to reject valuation. Make sure you have claimed the create_valuation task for this lead',
        'error'
      )
    }
  }

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'rejectionReason',
      type: InputType.Dropdown,
      required: true,
      props: {
        label: 'Rejection Reason',
        options: [
          {
            label: 'Out of market',
            value: 'out_of_market',
          },
          {
            label: 'Property condition',
            value: 'property_condition',
          },
          {
            label: 'Pool',
            value: 'pool',
          },
          {
            label: 'Hoa leasing restrictions',
            value: 'hoa_leasing_restrictions',
          },
          {
            label: 'Property type restrictions',
            value: 'property_type_restrictions',
          },
          {
            label: 'Yield constrained',
            value: 'yield_constrained',
          },
          {
            label: 'LTV constrained',
            value: 'ltv_constrained',
          },
          {
            label: 'Self-reported value too high',
            value: 'self_reported_value_too_high',
          },
          {
            label: 'Needs more detail',
            value: 'needs_more_detail',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ],
      },
    },
  ]
  return { onSubmit, inputConfigs, loading }
}

export default useRejectValuationModal
