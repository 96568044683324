import React from 'react'
import { TableCell, TableRow, styled } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { Core_InvestmentTransaction as InvestmentTransaction } from '@flock/flock-gql-server/src/__generated__/graphql'
import { formatIntegerCents } from '@flock/utils'
import { Delete } from '@mui/icons-material'

const CenteredContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'row',
})

export const investmentTransactionColumns = [
  { name: 'uuid', label: 'UUID', options: { display: false } },
  { name: 'effectiveDate', label: 'Effective Date' },
  { name: 'transactionType', label: 'Transaction Type' },
  { name: 'equityValueChangeCents', label: 'Equity Value Change' },
  { name: 'shareCountChange', label: 'Share Count Change' },
  { name: 'notes', label: 'Notes' },
  { name: 'isPublished', label: 'Is Published' },
  { name: '', label: '' },
]

const InvestmentTransactionRowRender =
  (
    openUpdateInvestmentTransaction: (
      existingInvestmentTransaction: InvestmentTransaction
    ) => void,
    openDeleteInvestmentTransaction: (
      existingInvestmentTransaction: InvestmentTransaction
    ) => void
  ) =>
  (data: [string, string, string, number, number, string, boolean]) => {
    const [
      uuid,
      effectiveDate,
      transactionType,
      equityValueChangeCents,
      shareCountChange,
      notes,
      isPublished,
    ] = data

    const investmentTransaction: InvestmentTransaction = {
      uuid,
      equityValueChangeCents,
      shareCountChange,
      transactionType,
      effectiveDate,
      notes,
      isPublished,
    }

    const effectiveDate2 = new Date(effectiveDate)

    return (
      <TableRow>
        <TableCell>
          <CenteredContent>
            {effectiveDate2.toLocaleDateString('en-US')}
          </CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>{transactionType}</CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>
            {formatIntegerCents(equityValueChangeCents)}
          </CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>
            {formatIntegerCents(shareCountChange, false)}
          </CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>{notes}</CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>{isPublished ? 'Yes' : 'No'}</CenteredContent>
        </TableCell>
        <TableCell>
          <EditIcon
            onClick={() =>
              openUpdateInvestmentTransaction(investmentTransaction)
            }
          />
        </TableCell>
        <TableCell>
          <Delete
            onClick={() =>
              openDeleteInvestmentTransaction(investmentTransaction)
            }
          />
        </TableCell>
      </TableRow>
    )
  }

export default InvestmentTransactionRowRender
