import React from 'react'
import { Close, AccountCircle, KeyboardArrowDown } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { Popover, useMediaQuery } from '@mui/material'
import { slide as SlideMenu } from 'react-burger-menu'
import slugify from 'slugify'
import WebFont from '../webfontloader'
import flockColors from '../flockColors/flockColors'
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '../constants/constants'
import useTracking, { TrackingConfig } from '../useTracking/useTracking'
import TrackingContextProvider from '../TrackingContextProvider/TrackingContextProvider'

WebFont.load({
  google: {
    families: ['Lato:300,400,bold&display=swap'],
  },
})

const HeaderContainer = styled('div')(
  ({ headerColor }: { headerColor: string }) => ({
    width: '100%',
    height: '4rem',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    zIndex: 999,
    transition: 'background-color 0.5s ease',
    backgroundColor: headerColor,

    [MOBILE_BREAKPOINT]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      width: '100%',
    },
  })
)

const Menu = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  height: '100%',
  flex: 1,
})

const TextLogoContainer = styled('div')({})

const TextLogo = styled('span')({
  fontSize: '1.875rem',
  fontFamily: 'Lato',
  letterSpacing: '0.625rem',
  textDecoration: 'none',
  fontWeight: 400,
  color: flockColors.lightGray,
  transition: 'color 0.5s ease',
  paddingLeft: '5.0625rem',
  cursor: 'pointer',

  '&:hover': {
    color: flockColors.white,
  },

  [TABLET_BREAKPOINT]: {
    paddingLeft: '1rem',
  },

  [MOBILE_BREAKPOINT]: {
    paddingLeft: '0.5rem',
    fontSize: '1.5rem',
  },
})

const MenuItemsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '100%',
  flex: 1,
  paddingRight: '5.0625rem',

  [TABLET_BREAKPOINT]: {
    paddingRight: '1rem',
  },

  [MOBILE_BREAKPOINT]: {
    display: 'none',
    paddingRight: 'unset',
  },
})

const MenuItemContainer = styled('a')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: flockColors.lightGray,
  cursor: 'pointer',
  justifyContent: 'center',
  transition: 'opacity 0.5s ease',

  '&:hover': {
    opacity: 1,
  },

  [MOBILE_BREAKPOINT]: {
    height: 'unset',
  },
})

const MenuItemLink = styled('span')({
  fontWeight: 'bold',
  fontSize: '1rem',
  fontFamily: 'Lato',
  marginLeft: '2rem',
  marginRight: '2rem',
  color: flockColors.white,

  [TABLET_BREAKPOINT]: {
    marginLeft: '1.25rem',
    marginRight: '1.25rem',
  },

  [MOBILE_BREAKPOINT]: {
    fontSize: '1.25rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    textAlign: 'center',
    width: '100%',
  },
})

const SubmenuPopover = styled(Popover)({
  marginTop: '0.5rem',
})

const SubmenuWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const SubmenuItemLink = styled('a')({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  paddingTop: '.5rem',
  paddingBottom: '.5rem',
  color: flockColors.blue,
  opacity: 0.9,
  textDecoration: 'unset',
  transition: 'color 1s ease',
  '&:hover': {
    opacity: 1,
  },
})

const ProfileIcon = styled(AccountCircle)({
  color: flockColors.white,
})

const DropdownIcon = styled(KeyboardArrowDown)({
  color: flockColors.white,
})

type MenuItemProps = {
  text: string
  href?: string
  onClick: () => void
  submenuOptions?: SubmenuItemConfig[]
  color?: string
  selected?: boolean
  selectable?: boolean
  icon?: string
  trackingConfig?: TrackingConfig
}

type SubmenuItemConfig = {
  text: string
  mobileText: string
  href: string
  selected?: boolean
  mobileOnly?: boolean
  color?: string
  onClick: () => void
  trackingConfig?: TrackingConfig
}

const MenuItem = (props: MenuItemProps) => {
  const {
    text,
    href,
    onClick,
    submenuOptions,
    trackingConfig,
    selectable,
    selected,
    color,
  } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(null)

  const { track } = useTracking()

  const trackingName = trackingConfig?.name || slugify(text || '')

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    track(trackingName, trackingConfig?.properties)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const isMobile = useMediaQuery(MOBILE_BREAKPOINT)

  let extraStyles: any = { color }
  if (selectable) {
    extraStyles = {
      color,
      position: 'relative',
      opacity: selected ? 1 : 0.8,
      transition: `opacity 0.3s ease`,
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: selected
          ? `radial-gradient(ellipse at bottom, ${flockColors.blue} 2%, ${color} 40%)`
          : 'inherit',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        transition: `opacity 0.3s ease`,
        opacity: selected ? 1 : 0,
        zIndex: -1,
      },
    }
  }

  if (submenuOptions) {
    if (isMobile) {
      return (
        <>
          {submenuOptions.map((option) => {
            if (option.mobileOnly) {
              if (option.selected) {
                return (
                  <MenuItemContainer
                    key={option.text}
                    href={option.href}
                    onClick={(e: React.MouseEvent) => {
                      if (option.onClick) {
                        option.onClick()
                        const itemTrackingName =
                          option.trackingConfig?.name ||
                          slugify(option.text.toLowerCase() || '')
                        track(
                          itemTrackingName,
                          option.trackingConfig?.properties
                        )
                        e.preventDefault()
                        e.stopPropagation()
                      }
                    }}
                    sx={extraStyles}
                  >
                    <MenuItemLink
                      sx={{
                        color: flockColors.blue,
                        backgroundColor: flockColors.white,
                      }}
                    >
                      {option.mobileText}
                    </MenuItemLink>
                  </MenuItemContainer>
                )
              } else {
                return (
                  <MenuItemContainer
                    key={option.text}
                    href={option.href}
                    onClick={(e: React.MouseEvent) => {
                      if (option.onClick) {
                        option.onClick()
                        const itemTrackingName =
                          option.trackingConfig?.name ||
                          slugify(option.text.toLowerCase() || '')
                        track(
                          itemTrackingName,
                          option.trackingConfig?.properties
                        )
                        e.preventDefault()
                        e.stopPropagation()
                      }
                    }}
                    sx={extraStyles}
                  >
                    <MenuItemLink>{option.mobileText}</MenuItemLink>
                  </MenuItemContainer>
                )
              }
            } else {
              return null
            }
          })}
        </>
      )
    } else {
      return (
        <>
          <MenuItemContainer
            href="#"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              handleClick(e)
              e.preventDefault()
              e.stopPropagation()
            }}
            sx={extraStyles}
            data-cy={`${text.toLowerCase().replace(' ', '-')}-nav`}
          >
            <ProfileIcon />
            <MenuItemLink
              sx={{
                marginLeft: '.25rem',
                marginRight: '.25rem',

                [TABLET_BREAKPOINT]: {
                  marginLeft: '.25rem',
                  marginRight: '.25rem',
                },
              }}
            >
              {text}
            </MenuItemLink>
            <DropdownIcon />
          </MenuItemContainer>
          <SubmenuPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            disableScrollLock
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <SubmenuWrapper>
              {submenuOptions.map((option) => {
                if (option.mobileOnly) {
                  return null
                } else if (option.selected) {
                  return (
                    <SubmenuItemLink
                      key={option.text}
                      href={option.href || ''}
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        if (option.onClick) {
                          option.onClick()
                          const itemTrackingName =
                            option.trackingConfig?.name ||
                            slugify(option.text.toLowerCase() || '')
                          track(
                            itemTrackingName,
                            option.trackingConfig?.properties
                          )
                          e.preventDefault()
                          e.stopPropagation()
                        }
                      }}
                      sx={{
                        color: flockColors.white,
                        backgroundColor: flockColors.blue,
                      }}
                    >
                      {option.text}
                    </SubmenuItemLink>
                  )
                } else {
                  return (
                    <SubmenuItemLink
                      key={option.text}
                      href={option.href || ''}
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        if (option.onClick) {
                          option.onClick()
                          const itemTrackingName =
                            option.trackingConfig?.name ||
                            slugify(option.text.toLowerCase() || '')
                          track(
                            itemTrackingName,
                            option.trackingConfig?.properties
                          )
                          e.preventDefault()
                          e.stopPropagation()
                        }
                      }}
                      sx={{ color: option.color }}
                    >
                      {option.text}
                    </SubmenuItemLink>
                  )
                }
              })}
            </SubmenuWrapper>
          </SubmenuPopover>
        </>
      )
    }
  } else {
    return (
      <MenuItemContainer
        href={href}
        onClick={(e: React.MouseEvent) => {
          onClick()
          track(trackingName, trackingConfig?.properties)
          e.preventDefault()
          e.stopPropagation()
        }}
        sx={extraStyles}
        data-cy={`${text.toLowerCase().replace(' ', '-')}-nav`}
      >
        <MenuItemLink>{text}</MenuItemLink>
      </MenuItemContainer>
    )
  }
}

MenuItem.defaultProps = {
  submenuItemConfig: undefined,
  selected: false,
  selectable: false,
  color: flockColors.black,
}

const BurgerWrapper = styled('div')({
  display: 'none',
  [MOBILE_BREAKPOINT]: {
    display: 'block',
  },
})

const BurgerTitle = styled('span')({
  fontSize: '1.875rem',
  fontFamily: 'Lato',
  letterSpacing: '0.625rem',
  textDecoration: 'none',
  fontWeight: 300,
  color: flockColors.white,
  transition: 'color 0.5s ease',
  textAlign: 'center',
  width: '100%',
  paddingBottom: '2rem',
  outline: 'none',
})

const RadialGradient = styled('div')({
  position: 'absolute',
  background: `transparent radial-gradient(ellipse at top, ${flockColors.lightBlue} 10%, rgba(255, 255, 255, 0.1) 70%, rgba(255, 255, 255, 0.1) 70%)`,
  minHeight: '10rem',
  height: '40%',
  width: '100%',
  zIndex: -10,

  [MOBILE_BREAKPOINT]: {
    minHeight: 'unset',
    maxHeight: '10rem',
  },
})

export type DarkHeaderProps = {
  onLogoClick: () => void
  hideGradient?: boolean
  selectable?: boolean
  menuItemConfigs?: MenuItemProps[]
  trackingConfig?: TrackingConfig
  color?: string
}

const DarkHeader = (props: DarkHeaderProps) => {
  const {
    onLogoClick,
    selectable,
    hideGradient,
    trackingConfig,
    menuItemConfigs,
    color,
  } = props
  const headerColor = color || flockColors.black

  const trackingName = trackingConfig?.name || 'header'

  const burgerStyles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '1.563rem',
      height: '1.25rem',
      right: '1.5rem',
      top: '1.45rem',
    },
    bmBurgerBars: {
      background: flockColors.white,
    },
    bmBurgerBarsHover: {
      background: flockColors.white,
    },
    bmCrossButton: {
      height: '3rem',
      width: '3rem',
      color: flockColors.white,
    },
    bmCross: {},
    bmMenuWrap: {
      position: 'fixed',
      width: '100%',
      height: '100%',
    },
    bmMenu: {
      background: headerColor,
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
      overflow: 'hidden',
    },
    bmMorphShape: {
      fill: flockColors.white,
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em',
    },
    bmItem: {
      display: 'inline-block',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  }

  return (
    <TrackingContextProvider
      name={trackingName}
      initialTrackingProperties={trackingConfig?.properties}
    >
      <HeaderContainer headerColor={headerColor}>
        <TextLogoContainer>
          <TextLogo onClick={onLogoClick}>FLOCK</TextLogo>
        </TextLogoContainer>
        <Menu>
          <MenuItemsWrapper>
            {menuItemConfigs?.map((item) => (
              <MenuItem
                key={item.text}
                {...item}
                selectable={selectable || false}
                color={headerColor}
              />
            ))}
          </MenuItemsWrapper>
        </Menu>
      </HeaderContainer>
      <BurgerWrapper>
        {/** @ts-ignore */}
        <SlideMenu right styles={burgerStyles} customCrossIcon={<Close />}>
          <BurgerTitle onClick={onLogoClick}>FLOCK</BurgerTitle>
          {menuItemConfigs?.map((item) => (
            <MenuItem key={item.text} {...item} color={headerColor} />
          ))}
        </SlideMenu>
      </BurgerWrapper>
      {!hideGradient && <RadialGradient />}
    </TrackingContextProvider>
  )
}

DarkHeader.defaultProps = {
  hideGradient: false,
  hideBirds: false,
  hideSideBirds: false,
  selectable: false,
  menuItemConfigs: [],
  opacity: 1,
  trackingConfig: {
    name: 'header',
  },
}

export default DarkHeader
