import React from 'react'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'

import { GridForm } from '@flock/shared-ui'
import RejectValuationModal from '../RejectValuationModal/RejectValuationModal'
import ValuationHistoryModal from '../ValuationHistoryModal/ValuationHistoryModal'
import FetchRentalCompsModal from './FetchRentalCompsModal/FetchRentalCompsModal'
import FetchOfferCompsModal from './FetchOfferCompsModal/FetchOfferCompsModal'
import ValuationSummary from './ValuationSummary/ValuationSummary'

import useSingleFamilyComputeAddressValuationTab from './useSingleFamilyComputeAddressValuationTab'
import { SingleFamilyComputeAddressValuationTabProps } from './singleFamilyComputeAddressValuationTabTypes'

const SingleFamilyComputeAddressValuationTab = (
  props: SingleFamilyComputeAddressValuationTabProps
) => {
  const { valuationHistory, valuationHistoryLoading, addressData } = props
  const {
    computeValuationFormFormProps,
    computeValuationFormInputConfigs,
    loading,
    addressId,
    operatorUuid,
    setRejectValuationModalOpen,
    rejectValuationModalOpen,
    setValuationHistoryModalOpen,
    valuationHistoryModalOpen,
    submitComputeValuation,
    computedValuationResult,
    valuationInputs,
    validateFormData,
    canRejectValuation,
    canSubmitValuation,
    openValuationHistoryModal,
    openRejectValuationModal,
    fetchRentalCompsModalOpen,
    setFetchRentalCompsModalOpen,
    formattedAddress,
    parseRentalCompsForPrefill,
    fetchOfferCompsModalOpen,
    setFetchOfferCompsModalOpen,
    parseOfferCompsForPrefill,
    injectedFormValues,
    computeValuationLoading,
  } = useSingleFamilyComputeAddressValuationTab(props)

  return (
    <>
      <Typography variant="h2">Single Family Valuation</Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size="100px" />
        </Box>
      ) : (
        <>
          <RejectValuationModal
            addressId={addressId}
            operatorUuid={operatorUuid}
            open={rejectValuationModalOpen}
            onClose={() => setRejectValuationModalOpen(false)}
            canRejectValuation={canRejectValuation}
            valuationUuid={computedValuationResult?.uuid}
          />
          <ValuationHistoryModal
            open={valuationHistoryModalOpen}
            onClose={() => setValuationHistoryModalOpen(false)}
            valuationHistory={valuationHistory}
            valuationHistoryLoading={valuationHistoryLoading}
          />
          <FetchRentalCompsModal
            open={fetchRentalCompsModalOpen}
            onClose={() => setFetchRentalCompsModalOpen(false)}
            address={formattedAddress}
            parseRentalCompsForPrefill={parseRentalCompsForPrefill}
          />
          <FetchOfferCompsModal
            open={fetchOfferCompsModalOpen}
            onClose={() => setFetchOfferCompsModalOpen(false)}
            address={formattedAddress}
            parseOfferCompsForPrefill={parseOfferCompsForPrefill}
          />
          <Grid container pb="40px">
            <Grid item xs={9} sx={{ overflowX: 'scroll' }}>
              <GridForm
                onSubmit={(formValues) => {
                  submitComputeValuation(formValues)
                }}
                onUpdatedFormatted={validateFormData}
                inputConfigs={computeValuationFormInputConfigs}
                injectedFormValues={injectedFormValues}
                ctaText="Compute Valuation"
                ctaButtonProps={{ sx: { width: '140px' } }}
                formProps={computeValuationFormFormProps}
                loading={computeValuationLoading}
              />
            </Grid>
            <Grid item xs={3} position="relative">
              <Box position="absolute">
                <Box
                  position="fixed"
                  top="100px"
                  bottom="0"
                  sx={{ overflowY: 'scroll' }}
                >
                  <ValuationSummary
                    computedValuationResult={computedValuationResult}
                    operatorUuid={operatorUuid}
                    addressId={addressId}
                    valuationInputs={valuationInputs}
                    canSubmitValuation={canSubmitValuation}
                    openValuationHistoryModal={openValuationHistoryModal}
                    openRejectValuationModal={openRejectValuationModal}
                    addressData={addressData}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default SingleFamilyComputeAddressValuationTab
