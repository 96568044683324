import React from 'react'
import { Button, Modal, Paper, Typography } from '@mui/material'
import { GridForm } from '@flock/shared-ui'
import useUploadAddressDocumentModal from './useUploadAddressDocumentModal'
import { UploadAddressDocumentModalProps } from './uploadAddressDocumentModalTypes'

const UploadAddressDocumentModal = (props: UploadAddressDocumentModalProps) => {
  const { buttonLabel, ctaText } = props
  const { inputConfigs, onSubmit, open, setOpen } =
    useUploadAddressDocumentModal(props)

  return (
    <>
      <Button onClick={() => setOpen(true)} size="mini">
        {buttonLabel}
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Paper
          sx={{
            position: 'absolute',
            top: '80px',
            left: '50%',
            transform: 'translate(-50%, 0)',
            maxHeight: '75vh',
            overflowY: 'scroll',
            padding: '32px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Typography variant="h3">{buttonLabel}</Typography>
          <GridForm
            inputConfigs={inputConfigs}
            onSubmit={onSubmit}
            ctaText={(ctaText || buttonLabel) as string}
            ctaButtonProps={{ sx: { width: '160px' } }}
          />
        </Paper>
      </Modal>
    </>
  )
}

UploadAddressDocumentModal.defaultProps = {
  buttonLabel: 'Upload Address Document',
  defaultType: 'other',
}

export default UploadAddressDocumentModal
