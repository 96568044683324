import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { formatIntegerCents, formattedDollarsToCents } from '@flock/utils'
import { useMutation } from '@apollo/client'
import {
  AdminGetPropertyOccupancyDataDocument,
  AdminUpdateFeeDocument,
  Core_PropertyFee,
  Core_UpdatePropertyFeeRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'

type UpdateFeeParams = {
  feeType: string
  feeCents: string
}

type UpdateFeeModalParams = {
  feeInfo: Core_PropertyFee
  isOpen: boolean
  close: () => void
}

const UpdateFeeModal = (props: UpdateFeeModalParams) => {
  const { feeInfo, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [updateFee, { loading }] = useMutation(AdminUpdateFeeDocument)

  const onSubmit = async (updateFeeParams: UpdateFeeParams) => {
    const { feeType, feeCents } = updateFeeParams

    const updatePropertyInput: Core_UpdatePropertyFeeRequestInput = {
      feeParams: {
        uuid: feeInfo.uuid,
        feeType,
        feeCents: formattedDollarsToCents(feeCents),
      },
    }

    try {
      await updateFee({
        variables: {
          input: updatePropertyInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify('Successfully updated fee.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to update fee.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update Fee"
      inputConfigs={[
        {
          inputName: 'feeType',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Fee Type',
            fullWidth: true,
            options: [
              {
                text: 'Annual HOA Dues',
                value: 'AnnualHOADues',
              },
              {
                text: 'Annual Taxes',
                value: 'AnnualTaxes',
              },
              {
                text: 'Prorated Annual Taxes',
                value: 'ProratedAnnualTaxes',
              },
              {
                text: 'Property Tax Accrual',
                value: 'PropertyTaxAccrual',
              },
              {
                text: 'Annual Insurance Premium',
                value: 'AnnualInsurancePremium',
              },
              {
                text: 'Flood Insurance',
                value: 'FloodInsurance',
              },
            ],
            defaultValue: feeInfo.feeType,
          },
        },
        {
          inputName: 'feeCents',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Fee Amount',
            placeholder: '$0.00',
            fullWidth: true,
            defaultValue: feeInfo.feeCents
              ? formatIntegerCents(feeInfo.feeCents)
              : '',
          },
        },
      ]}
    />
  )
}

export default UpdateFeeModal
