import { useSnackbar } from '@flock/flock-component-library'
import Papa from 'papaparse'
import { useCallback, useEffect, useState } from 'react'
import { usePDF } from 'react-to-pdf'
import {
  PrintCollateralPageProps,
  PrintCollateralPagePresentationalProps,
  PropertySOWDataSet,
  SOWDataRow,
  UnitSOWDataSet,
} from './printCollateralPageTypes'

async function parseCSV(file: File): Promise<PropertySOWDataSet> {
  const content = await file.text()

  return new Promise((resolve, reject) => {
    Papa.parse(content, {
      complete: (result) => {
        try {
          const data = result.data as string[][]
          const propertySOWDataSet: PropertySOWDataSet = {
            addressStreet: '',
            addressCityStateZip: '',
            sowCreationDate: '',
            immediatelyAddressableTotal: '',
            nonImmediatelyAddressableTotal: '',
            total: '',
            dataSets: [],
          }

          let currentUnit: UnitSOWDataSet | null = null
          let isSummary = false

          for (let i = 0; i < data.length; i += 1) {
            const row = data[i]

            const summaryItemValue = row[2]
            if (row[1] === 'SOW Creation Date') {
              propertySOWDataSet.sowCreationDate = summaryItemValue
            } else if (row[1] === 'Address') {
              const addressParts = summaryItemValue.split(',')
              propertySOWDataSet.addressStreet = addressParts[0].trim()
              propertySOWDataSet.addressCityStateZip = addressParts
                .slice(1)
                .join(',')
                .trim()
            } else if (row[1] === 'Immediately Addressable Total') {
              propertySOWDataSet.immediatelyAddressableTotal = summaryItemValue
            } else if (row[1] === 'Non-Immediately Addressable Total') {
              propertySOWDataSet.nonImmediatelyAddressableTotal =
                summaryItemValue
            } else if (row[1] === 'Total' && summaryItemValue) {
              propertySOWDataSet.total = summaryItemValue
            } else if (row[4] && row[4].includes('Immediate')) {
              if (currentUnit) {
                // Sort current unit by category name alphabetically
                currentUnit.items.sort((a, b) => {
                  if (a.category.includes('COMPLETION')) {
                    return -1
                  } else if (b.category.includes('COMPLETION')) {
                    return 1
                  } else {
                    return a.category.localeCompare(b.category)
                  }
                })
                propertySOWDataSet.dataSets.push(currentUnit)
              }
              currentUnit = {
                // Remove parentheses from names
                name: row[1].replace(/\([^)]*\)/g, ''),
                items: [],
                summaryItems: [],
              }
              isSummary = false
            } else if (row[1] === '' && currentUnit) {
              isSummary = true
            } else if (currentUnit && row[1] !== 'Category') {
              const defaultValue = isSummary ? '' : '-'
              const sowDataRow: SOWDataRow = {
                category: row[1].toUpperCase(),
                item: isSummary ? row[1] : row[2],
                description: row[3],
                healthAndSafety: row[4] || defaultValue,
                firstTurn: row[5] || defaultValue,
                threeYearsOfRemainingLife: row[6] || defaultValue,
                nonImmediatelyAddressable: row[7] || defaultValue,
                remainingUsefulLifeAdjustment: row[8] || defaultValue,
                cost: row[9] !== '$0' ? row[9] : defaultValue,
              }

              if (!isSummary) {
                currentUnit.items.push(sowDataRow)
              } else {
                currentUnit.summaryItems.push(sowDataRow)
              }
            }
          }

          if (currentUnit) {
            // Sort current unit by category name alphabetically
            currentUnit.items.sort((a, b) => {
              if (a.category.includes('COMPLETION')) {
                return -1
              } else if (b.category.includes('COMPLETION')) {
                return 1
              } else {
                return a.category.localeCompare(b.category)
              }
            })

            propertySOWDataSet.dataSets.push(currentUnit)
          }

          resolve(propertySOWDataSet)
        } catch (error) {
          reject(error)
        }
      },
      error: (error: any) => {
        reject(error)
      },
    })
  })
}

const usePrintCollateralPage: (
  props: PrintCollateralPageProps
) => PrintCollateralPagePresentationalProps = (_: PrintCollateralPageProps) => {
  const { notify } = useSnackbar()

  const [sowFiles, setSowFiles] = useState<File[]>([])
  const [propertySOWs, setPropertySOWs] = useState<PropertySOWDataSet[]>([])

  // We need to render each unit sequentially so this is used to set which one to render
  // next to avoid issues with height detection.
  const [toRender, setToRender] = useState<number>(0)

  const { toPDF, targetRef } = usePDF({
    filename: 'sow.pdf',
    page: {
      margin: {
        top: 0,
        right: 0,
        bottom: 28.222222222222,
        left: 28.22222222222,
      },
      format: [215.9, 279.4],
    },
    canvas: {},
    overrides: {
      canvas: {
        scale: 4,
      },
    },
  })

  const setNextUnitToRender = () => {
    setToRender((prev) => prev + 1)
  }

  const onUpdateFiles = (files: File[]) => {
    setSowFiles(files)
  }

  const processPropertyData = useCallback(async () => {
    const propertyDataSets: PropertySOWDataSet[] = []

    try {
      for (let i = 0; i < sowFiles.length; i += 1) {
        const file = sowFiles[i]
        const propertyDataSet = await parseCSV(file)
        propertyDataSets.push(propertyDataSet)
      }
    } catch (error) {
      notify('Failed to process CSV file', 'error')
    }
    setPropertySOWs(propertyDataSets)
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notify, JSON.stringify(sowFiles)])

  useEffect(() => {
    if (sowFiles.length > 0) {
      processPropertyData()
    }
  }, [sowFiles.length, processPropertyData])

  return {
    onUpdateFiles,
    toRender,
    setNextUnitToRender,
    toPDF,
    refToPrint: targetRef,
    propertySOWs,
  }
}

export default usePrintCollateralPage
