import React, { useState } from 'react'
import {
  Typography,
  DataTable,
  useSnackbar,
  flockColors,
} from '@flock/flock-component-library'
import { styled } from '@mui/material/styles'
import {
  TableCell,
  TableRow,
  CircularProgress,
  Button,
  Box,
} from '@mui/material'
import { useQuery } from '@apollo/client'
import {
  AdminGetPropertyInfoDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_PropertyDelinquency,
  Core_PropertyFee,
  Core_PropertyFinancingDetail,
  Core_PropertyLease,
  Core_PropertyOccupancyStatus,
  Core_PropertyWorkOrder,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { RouteComponentProps, useParams } from '@reach/router'
import { PlusIcon } from '@flock/shared-ui'
import { Delete, Edit } from '@mui/icons-material'
import { formatIntegerCents } from '@flock/utils'
import { formatCents, formatDateString } from '../InvestorManagement/utils'
import DetailedLeaseModal from './DetailedLeaseModal'
import DetailedWorkOrderModal from './DetailedWorkOrderModal'
import CreateLeaseModal from './CreateLeaseModal'
import CreateWorkOrderModal from './CreateWorkOrderModal'
import DeleteLeaseModal from './DeleteLeaseModal'
import DeleteWorkOrderModal from './DeleteWorkOrderModal'
import UpdatePropertyModal from './UpdatePropertyModal'
import AuditLogModal from '../InvestorManagement/AuditLogModal'
import UpdateLeaseModal from './UpdateLeaseModal'
import UpdateFeeModal from './UpdateFeeModal'
import CreateFeeModal from './CreateFeeModal'
import DeleteFeeModal from './DeleteFeeModal'
import UpdateFinancingModal from './UpdateFinancingModal'
import UploadPropertyPhotoModal from './UploadPropertyPhotoModal'
import CreateDispositionModal from './CreateDispositionModal'
import CreateFinancingModal from './CreateFinancingModal'
import DeleteDelinquencyModal from './DeleteDelinquencyModal'
import CreateDelinquencyModal from './CreateDelinquencyModal'

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const HoveredTableCell = styled(TableCell)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

const formatDateStringOrNull = (dateString: string | null | undefined) =>
  dateString ? formatDateString(dateString) : ''

const LeaseCustomRowRender =
  (
    openDetailedLeaseModal: (lease: Core_PropertyLease) => void,
    openUpdateLeaseModal: (lease: Core_PropertyLease) => void,
    openDeleteLeaseModal: (lease: Core_PropertyLease) => void
  ) =>
  (
    data: [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      boolean,
      number,
      string,
      string,
      number,
      number,
      number,
      string,
      number,
      string,
      string,
      boolean,
      boolean
    ]
  ) => {
    const [
      uuid,
      propertyUuid,
      tenantName,
      leaseStartDate,
      moveInDate,
      leaseEndDate,
      moveOutDate,
      isRenewed,
      monthlyRentCents,
      leaseType,
      leaseOriginator,
      rentDueDateEachMonth,
      gracePeriodDays,
      securityDepositCents,
      renewalOfferSentDate,
      renewalOfferAmountCents,
      renewalOfferStatus,
      renewalOfferResponseDate,
      isSectionEight,
      isLeaseBreak,
    ] = data

    const propertyLease: Core_PropertyLease = {
      uuid,
      propertyUuid,
      tenantName,
      leaseStartDate,
      moveInDate,
      leaseEndDate,
      moveOutDate,
      isRenewed,
      monthlyRentCents,
      leaseType,
      leaseOriginator,
      rentDueDateEachMonth,
      gracePeriodDays,
      securityDepositCents,
      renewalOfferSentDate,
      renewalOfferAmountCents,
      renewalOfferStatus,
      renewalOfferResponseDate,
      isSectionEight,
      isLeaseBreak,
    }
    // Uncomment this code when we want to add back edit restriction for current lease
    // const currDate = new Date()
    // const leaseStartDateObj = new Date(leaseStartDate)
    // const leaseEndDateObj = new Date(leaseEndDate)
    // const isCurrentLease =
    //   currDate >= leaseStartDateObj && currDate <= leaseEndDateObj

    const isRenewedString = isRenewed ? 'Yes' : 'No'

    return (
      <TableRow key={uuid}>
        <TableCell>{tenantName}</TableCell>
        <TableCell>{formatDateStringOrNull(leaseStartDate)}</TableCell>
        <TableCell>{formatDateStringOrNull(moveInDate)}</TableCell>
        <TableCell>{formatDateStringOrNull(leaseEndDate)}</TableCell>
        <TableCell>{formatDateStringOrNull(moveOutDate)}</TableCell>
        <TableCell>{isRenewedString}</TableCell>
        <TableCell>{formatCents(monthlyRentCents)}</TableCell>
        <TableCell>{leaseType}</TableCell>
        <HoveredTableCell onClick={() => openDetailedLeaseModal(propertyLease)}>
          <PlusIcon width="32px" />
        </HoveredTableCell>
        {/* {!isCurrentLease ? ( */}
        <HoveredTableCell onClick={() => openUpdateLeaseModal(propertyLease)}>
          <Edit />
        </HoveredTableCell>
        {/* ) : (
          <TableCell />
        )} */}
        <HoveredTableCell onClick={() => openDeleteLeaseModal(propertyLease)}>
          <Delete />
        </HoveredTableCell>
      </TableRow>
    )
  }

const tableOptions = (customRowRender: (data: any) => any) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender,
  elevation: 0,
})

const leaseColumns = [
  { name: 'uuid', label: 'Name', options: { display: false } },
  { name: 'propertyUuid', label: 'property UUID', options: { display: false } },
  { name: 'tenantName', label: 'Name' },
  { name: 'leaseStartDate', label: 'Start Date' },
  { name: 'moveInDate', label: 'Move In Date' },
  { name: 'leaseEndDate', label: 'End Date' },
  { name: 'moveOutDate', label: 'Move Out Date' },
  { name: 'isRenewed', label: 'Is Renewal' },
  { name: 'monthlyRentCents', label: 'Monthly Rent' },
  { name: 'leaseType', label: 'Lease Type' },
  {
    name: 'leaseOriginator',
    label: 'Lease Originator',
    options: { display: false },
  },
  {
    name: 'rentDueDateEachMonth',
    label: 'Rent Due Date',
    options: { display: false },
  },
  {
    name: 'gracePeriodDays',
    label: 'Grace Period',
    options: { display: false },
  },
  {
    name: 'securityDepositCents',
    label: 'Security Deposit',
    options: { display: false },
  },
  {
    name: 'renewalOfferSentDate',
    label: 'Renewal Offer Sent Date',
    options: { display: false },
  },
  {
    name: 'renewalOfferAmountCents',
    label: 'Renewal Offer Amount',
    options: { display: false },
  },
  {
    name: 'renewalOfferStatus',
    label: 'Renewal Offer Status',
    options: { display: false },
  },
  {
    name: 'renewalOfferResponseDate',
    label: 'Renewal Offer Response Date',
    options: { display: false },
  },
  {
    name: 'isSectionEight',
    label: 'Section 8',
    options: { display: false },
  },
  {
    name: 'isLeaseBreak',
    label: 'Lease Break',
    options: { display: false },
  },
  { name: '', label: 'More Detail' },
  { name: '', label: 'Edit' },
  { name: '', label: 'Delete' },
]

const OccupancyStatusCustomRowRender =
  () =>
  (
    data: [
      string,
      string,
      string,
      string,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      string
    ]
  ) => {
    const [
      propertyUuid,
      effectiveStartDatetime,
      effectiveEndDatetime,
      occupancyStatus,
      isOccupied,
      isInRenewalPeriod,
      isNotice,
      isRentReady,
      isDown,
      isRented,
      isMaintenance,
      isDelinquent,
      isOngoingEviction,
      isHoldover,
    ] = data

    return (
      <TableRow key={propertyUuid}>
        <TableCell>{formatDateStringOrNull(effectiveStartDatetime)}</TableCell>
        <TableCell>{formatDateStringOrNull(effectiveEndDatetime)}</TableCell>
        <TableCell>{occupancyStatus.toString()}</TableCell>
        <TableCell>{isOccupied.toString()}</TableCell>
        <TableCell>{isInRenewalPeriod.toString()}</TableCell>
        <TableCell>{isNotice.toString()}</TableCell>
        <TableCell>{isRentReady.toString()}</TableCell>
        <TableCell>{isDown.toString()}</TableCell>
        <TableCell>{isRented.toString()}</TableCell>
        <TableCell>{isMaintenance.toString()}</TableCell>
        <TableCell>{isDelinquent.toString()}</TableCell>
        <TableCell>{isOngoingEviction.toString()}</TableCell>
        <TableCell>{isHoldover.toString()}</TableCell>
      </TableRow>
    )
  }

const occupancyStatusColumns = [
  { name: 'propertyUuid', label: 'Property UUID', options: { display: false } },
  { name: 'effectiveStartDatetime', label: 'Effective Start Date' },
  { name: 'effectiveEndDatetime', label: 'Effective End Date' },
  { name: 'occupancyStatus', label: 'Occupancy Status' },
  { name: 'isOccupied', label: 'Occupied' },
  { name: 'isInRenewalPeriod', label: 'In Renewal Period' },
  { name: 'isNotice', label: 'Notice' },
  { name: 'isRentReady', label: 'Rent Ready' },
  { name: 'isDown', label: 'Down' },
  { name: 'isRented', label: 'Rented' },
  { name: 'isMaintenance', label: 'Maintenance' },
  { name: 'isDelinquent', label: 'Delinquent' },
  { name: 'isOngoingEviction', label: 'Ongoing Eviction' },
  { name: 'isHoldover', label: 'Holdover' },
]

const WorkOrderCustomRowRender =
  (
    openDetailedWorkOrderModal: (workOrder: Core_PropertyWorkOrder) => void,
    openDeleteWorkOrderModal: (workOrder: Core_PropertyWorkOrder) => void
  ) =>
  (
    data: [
      string,
      string,
      string,
      string,
      string,
      string,
      number,
      string,
      string,
      string,
      string,
      number,
      string,
      string,
      string,
      string,
      string,
      string
    ]
  ) => {
    const [
      uuid,
      propertyUuid,
      priorityStatus,
      orderType,
      orderNumber,
      orderDescription,
      orderCostCents,
      orderStatus,
      orderVendor,
      orderCreationDate,
      createdBy,
      orderEstimatedCostCents,
      estimationDate,
      scheduledStartDate,
      scheduledCompletionDate,
      workStartDate,
      workCompletionDate,
      workCancellationDate,
    ] = data

    const propertyWorkOrder: Core_PropertyWorkOrder = {
      uuid,
      propertyUuid,
      priorityStatus,
      orderType,
      orderNumber,
      orderDescription,
      orderCostCents,
      orderStatus,
      orderVendor,
      orderCreationDate,
      createdBy,
      orderEstimatedCostCents,
      estimationDate,
      scheduledStartDate,
      scheduledCompletionDate,
      workStartDate,
      workCompletionDate,
      workCancellationDate,
    }

    return (
      <TableRow key={uuid}>
        <TableCell>{priorityStatus}</TableCell>
        <TableCell>{orderType}</TableCell>
        <TableCell>{orderNumber}</TableCell>
        <TableCell>{orderStatus}</TableCell>
        <TableCell>{formatDateStringOrNull(orderCreationDate)}</TableCell>
        <TableCell>{formatDateStringOrNull(workCompletionDate)}</TableCell>
        <TableCell>{formatDateStringOrNull(workCancellationDate)}</TableCell>
        <HoveredTableCell
          onClick={() => openDetailedWorkOrderModal(propertyWorkOrder)}
        >
          <PlusIcon width="32px" />
        </HoveredTableCell>
        <HoveredTableCell
          onClick={() => openDeleteWorkOrderModal(propertyWorkOrder)}
        >
          <Delete />
        </HoveredTableCell>
      </TableRow>
    )
  }

const workOrderColumns = [
  { name: 'uuid', label: 'UUID', options: { display: false } },
  { name: 'propertyUuid', label: 'Property UUID', options: { display: false } },
  { name: 'priorityStatus', label: 'Priority Status' },
  { name: 'orderType', label: 'Order Type' },
  { name: 'orderNumber', label: 'Order Number' },
  {
    name: 'orderDescription',
    label: 'Order Description',
    options: { display: false },
  },
  {
    name: 'orderCostCents',
    label: 'Order Cost Cents',
    options: { display: false },
  },
  { name: 'orderStatus', label: 'Order Status' },
  { name: 'orderVendor', label: 'Order Vendor', options: { display: false } },
  { name: 'orderCreationDate', label: 'Order Creation Date' },
  { name: 'createdBy', label: 'Created By', options: { display: false } },
  {
    name: 'orderEstimatedCostCents',
    label: 'Order Estimated Cost Cents',
    options: { display: false },
  },
  {
    name: 'estimationDate',
    label: 'Estimation Date',
    options: { display: false },
  },
  {
    name: 'scheduledStartDate',
    label: 'Scheduled Start Date',
    options: { display: false },
  },
  {
    name: 'scheduledCompletionDate',
    label: 'Scheduled Completion Date',
    options: { display: false },
  },
  {
    name: 'workStartDate',
    label: 'Work Start Date',
    options: { display: false },
  },
  { name: 'workCompletionDate', label: 'Work Completion Date' },
  { name: 'workCancellationDate', label: 'Work Cancellation Date' },
  { name: '', label: 'More Detail' },
  { name: '', label: 'Delete' },
]

const DelinquencyCustomRowRender =
  (
    openDeleteDelinquencyModal: (delinquency: Core_PropertyDelinquency) => void
  ) =>
  (
    data: [
      string,
      string,
      string,
      string,
      number,
      number,
      string,
      string,
      string
    ]
  ) => {
    const [
      uuid,
      propertyUuid,
      chargeDate,
      delinquencyDate,
      chargeAmountCents,
      delinquencyAmountCents,
      delinquencyType,
      paymentDate,
      badDebtDate,
    ] = data

    const delinquency: Core_PropertyDelinquency = {
      uuid,
      propertyUuid,
      chargeDate,
      delinquencyDate,
      chargeAmountCents,
      delinquencyAmountCents,
      delinquencyType,
      paymentDate,
      badDebtDate,
    }

    return (
      <TableRow key={uuid}>
        <TableCell>{formatDateStringOrNull(chargeDate)}</TableCell>
        <TableCell>{formatDateStringOrNull(delinquencyDate)}</TableCell>
        <TableCell>{formatCents(chargeAmountCents)}</TableCell>
        <TableCell>{formatCents(delinquencyAmountCents)}</TableCell>
        <TableCell>{delinquencyType}</TableCell>
        <TableCell>{formatDateStringOrNull(paymentDate)}</TableCell>
        <TableCell>{formatDateStringOrNull(badDebtDate)}</TableCell>
        <HoveredTableCell
          onClick={() => openDeleteDelinquencyModal(delinquency)}
        >
          <Delete />
        </HoveredTableCell>
      </TableRow>
    )
  }

const delinquencyTableOptions = (
  customRowRender: (
    data: [
      string,
      string,
      string,
      string,
      number,
      number,
      string,
      string,
      string
    ]
  ) => any
) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender,
  elevation: 0,
})

const delinquencyColumns = [
  { name: 'uuid', label: 'UUID', options: { display: false } },
  { name: 'propertyUuid', label: 'property UUID', options: { display: false } },
  { name: 'chargeDate', label: 'Charge Date' },
  { name: 'delinquencyDate', label: 'Delinquency Date' },
  { name: 'chargeAmountCents', label: 'Charge Amount' },
  { name: 'delinquencyAmountCents', label: 'Delinquency Amount' },
  { name: 'delinquencyType', label: 'Delinquency Type' },
  { name: 'paymentDate', label: 'Payment Date' },
  { name: 'badDebtDate', label: 'Bad Debt Date' },
  { name: '', label: 'Delete' },
]

const FeeCustomRowRender =
  (
    openEditFeeModal: (fee: Core_PropertyFee) => void,
    openDeleteFeeModal: (fee: Core_PropertyFee) => void
  ) =>
  (data: [string, string, string, number, string]) => {
    const [uuid, propertyUuid, feeType, feeCents, createdAt] = data

    const propertyFee: Core_PropertyFee = {
      uuid,
      propertyUuid,
      feeType,
      feeCents,
      createdAt,
    }

    return (
      <TableRow key={feeType}>
        <TableCell>{feeType}</TableCell>
        <TableCell>{formatCents(feeCents)}</TableCell>
        <TableCell>{formatDateStringOrNull(createdAt)}</TableCell>
        <HoveredTableCell onClick={() => openEditFeeModal(propertyFee)}>
          <Edit />
        </HoveredTableCell>
        <HoveredTableCell onClick={() => openDeleteFeeModal(propertyFee)}>
          <Delete />
        </HoveredTableCell>
      </TableRow>
    )
  }

const feeColumns = [
  { name: 'uuid', label: 'UUID', options: { display: false } },
  { name: 'propertyUuid', label: 'property UUID', options: { display: false } },
  { name: 'feeType', label: 'Fee Type' },
  { name: 'feeCents', label: 'Fee Amount' },
  { name: 'createdAt', label: 'Created Date' },
  { name: '', label: 'Edit' },
  { name: '', label: 'Delete' },
]

const FinancingCustomRowRender =
  (openEditFinancingModal: (financing: Core_PropertyFinancingDetail) => void) =>
  (data: [string, string, string, string, string]) => {
    const [
      uuid,
      propertyUuid,
      legalHoldingEntity,
      collateralOne,
      collateralTwo,
    ] = data

    const propertyFinancingDetails: Core_PropertyFinancingDetail = {
      uuid,
      propertyUuid,
      legalHoldingEntity,
      collateralOne,
      collateralTwo,
    }

    return (
      <TableRow key={uuid}>
        <TableCell>{legalHoldingEntity}</TableCell>
        <TableCell>{collateralOne}</TableCell>
        <TableCell>{collateralTwo}</TableCell>
        <HoveredTableCell
          onClick={() => openEditFinancingModal(propertyFinancingDetails)}
        >
          <Edit />
        </HoveredTableCell>
      </TableRow>
    )
  }

const financingColumns = [
  { name: 'uuid', label: 'UUID', options: { display: false } },
  { name: 'propertyUuid', label: 'property UUID', options: { display: false } },
  { name: 'legalHoldingEntity', label: 'Legal Holding Entity' },
  { name: 'collateralOne', label: 'Collateral One' },
  { name: 'collateralTwo', label: 'Collateral Two' },
  { name: '', label: 'Edit' },
]

const OccupancyStatusPage = (_: RouteComponentProps) => {
  const { propertyUuid } = useParams()
  const { notify } = useSnackbar()

  const [isCreateLeaseModalOpen, setIsCreateLeaseModalOpen] =
    useState<boolean>(false)

  const [isCreateWorkOrderModalOpen, setIsCreateWorkOrderModalOpen] =
    useState<boolean>(false)

  const [isCreateFeeModalOpen, setIsCreateFeeModalOpen] =
    useState<boolean>(false)

  const [isCreateFinancingModalOpen, setIsCreateFinancingModalOpen] =
    useState<boolean>(false)

  const [isDetailedLeaseModalOpen, setIsDetailedLeaseModalOpen] =
    useState<boolean>(false)

  const [selectedLease, setSelectedLease] = useState<Core_PropertyLease>(
    {} as Core_PropertyLease
  )

  const [isUpdateLeaseModalOpen, setIsUpdateLeaseModalOpen] =
    useState<boolean>(false)

  const [isDeleteLeaseModalOpen, setIsDeleteLeaseModalOpen] =
    useState<boolean>(false)

  const [isUpdatePropertyModalOpen, setUpdatePropertyModalOpen] =
    useState<boolean>(false)
  const [auditLogModalOpen, setAuditLogModalOpen] = useState<boolean>(false)

  const [isCreateDispositionModalOpen, setCreateDispositionModalOpen] =
    useState<boolean>(false)

  const openDetailedLeaseModal = (lease: Core_PropertyLease) => {
    setSelectedLease(lease)
    setIsDetailedLeaseModalOpen(true)
  }
  const openEditLeaseModal = (lease: Core_PropertyLease) => {
    setSelectedLease(lease)
    setIsUpdateLeaseModalOpen(true)
  }
  const openDeleteLeaseModal = (lease: Core_PropertyLease) => {
    setSelectedLease(lease)
    setIsDeleteLeaseModalOpen(true)
  }

  const [isDetailedWorkOrderModalOpen, setIsDetailedWorkOrderModalOpen] =
    useState<boolean>(false)

  const [isDeleteWorkOrderModalOpen, setIsDeleteWorkOrderModalOpen] =
    useState<boolean>(false)

  const [selectedWorkOrder, setSelectedWorkOrder] =
    useState<Core_PropertyWorkOrder>({} as Core_PropertyWorkOrder)

  const openDeleteWorkOrderModal = (workOrder: Core_PropertyWorkOrder) => {
    setSelectedWorkOrder(workOrder)
    setIsDeleteWorkOrderModalOpen(true)
  }

  const openDetailedWorkOrderModal = (workOrder: Core_PropertyWorkOrder) => {
    setSelectedWorkOrder(workOrder)
    setIsDetailedWorkOrderModalOpen(true)
  }

  const [isCreateDelinquencyModalOpen, setIsCreateDelinquencyModalOpen] =
    useState<boolean>(false)

  const [isDeleteDelinquencyModalOpen, setIsDeleteDelinquencyModalOpen] =
    useState<boolean>(false)

  const [selectedDelinquency, setSelectedDelinquency] =
    useState<Core_PropertyDelinquency>({} as Core_PropertyDelinquency)

  const openDeleteDelinquencyModal = (
    delinquency: Core_PropertyDelinquency
  ) => {
    setSelectedDelinquency(delinquency)
    setIsDeleteDelinquencyModalOpen(true)
  }

  const [isUpdateFeeModalOpen, setIsUpdateFeeModalOpen] =
    useState<boolean>(false)

  const [isDeleteFeeModalOpen, setIsDeleteFeeModalOpen] =
    useState<boolean>(false)

  const [selectedFee, setSelectedFee] = useState<Core_PropertyFee>(
    {} as Core_PropertyFee
  )

  const [isUpdateFinancingModalOpen, setIsUpdateFinancingModalOpen] =
    useState<boolean>(false)

  const [selectedFinancing, setSelectedFinancing] =
    useState<Core_PropertyFinancingDetail>({} as Core_PropertyFinancingDetail)

  const openEditFeeModal = (fee: Core_PropertyFee) => {
    setSelectedFee(fee)
    setIsUpdateFeeModalOpen(true)
  }
  const openDeleteFeeModal = (fee: Core_PropertyFee) => {
    setSelectedFee(fee)
    setIsDeleteFeeModalOpen(true)
  }

  const openEditFinancingModal = (financing: Core_PropertyFinancingDetail) => {
    setSelectedFinancing(financing)
    setIsUpdateFinancingModalOpen(true)
  }

  const { loading: loadingInfoData, data: propertyInfoData } = useQuery(
    AdminGetPropertyInfoDocument,
    {
      variables: {
        input: {
          propertyUuid,
        },
      },
      onError: () => {
        notify('Failed to get property info data', 'error')
      },
    }
  )

  const propertyAddressData =
    propertyInfoData?.getPropertyInfo?.propertyInfo?.address
  const propertyData = propertyInfoData?.getPropertyInfo?.propertyInfo

  const { loading, data } = useQuery(AdminGetPropertyOccupancyDataDocument, {
    variables: {
      input: {
        propertyUuid,
      },
    },
    onError: () => {
      notify('Failed to get property occupancy status data', 'error')
    },
  })

  const propertyLeases =
    data?.getPropertyOccupancyData?.propertyOccupancyData?.leases || []

  const occupancyStatuses =
    data?.getPropertyOccupancyData?.propertyOccupancyData?.occupancyStatuses ||
    []

  const propertyWorkOrders =
    data?.getPropertyOccupancyData?.propertyOccupancyData?.workOrders || []

  const propertyDelinquencies =
    data?.getPropertyOccupancyData?.propertyOccupancyData?.delinquencies || []

  const propertyFees =
    data?.getPropertyOccupancyData?.propertyOccupancyData?.fees || []

  const propertyFinancingDetails =
    data?.getPropertyOccupancyData?.propertyOccupancyData?.financingDetail

  let propertyFinancingDetailsArray: Core_PropertyFinancingDetail[] = []
  if (
    propertyFinancingDetails &&
    propertyFinancingDetails?.propertyUuid !==
      '00000000-0000-0000-0000-000000000000'
  ) {
    propertyFinancingDetailsArray = [propertyFinancingDetails]
  }

  return (
    <>
      <PageTitleContainer>
        <Typography variant="h1">Property Data</Typography>
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setUpdatePropertyModalOpen(true)
            }}
          >
            Update Property Details
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setCreateDispositionModalOpen(true)
            }}
            sx={{ marginLeft: '0.5rem' }}
          >
            Create Disposition
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setAuditLogModalOpen(true)
            }}
            sx={{ marginLeft: '0.5rem' }}
          >
            View Audit Log
          </Button>
        </div>
      </PageTitleContainer>
      {loadingInfoData ? (
        <CircularProgress size={32} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <Typography variant="h1">
              {propertyAddressData?.formattedAddress}
            </Typography>
            <br />
            <Typography variant="h3">UUID: {propertyData?.uuid}</Typography>
            <br />
            <Box
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                }}
              >
                <Typography variant="p1">
                  Property Type: {propertyData?.propertyType}
                </Typography>
                <Typography variant="p1">
                  Year Built: {propertyData?.yearBuilt}
                </Typography>
                <Typography variant="p1">
                  Square Feet: {propertyData?.sqft}
                </Typography>
                <Typography variant="p1">Beds: {propertyData?.beds}</Typography>
                <Typography variant="p1">
                  Baths: {propertyData?.baths}
                </Typography>
                <Typography variant="p1">
                  Half Baths: {propertyData?.halfBaths}
                </Typography>
                <Typography variant="p1">
                  Acquisition Date:{' '}
                  {formatDateStringOrNull(propertyData?.acquisitionDate)}
                </Typography>
                <Typography variant="p1">
                  Contribution Date:{' '}
                  {formatDateStringOrNull(propertyData?.contributionDate)}
                </Typography>
                <Typography variant="p1">
                  Notes: {propertyData?.notes}
                </Typography>
                <Typography variant="p1">
                  Yardi ID: {propertyData?.yardiId}
                </Typography>
                <Typography variant="p1">
                  Deposit Transfer Amount:{' '}
                  {formatIntegerCents(
                    propertyData?.depositTransferAmountCents || 0
                  )}
                </Typography>
                {propertyData?.isDisposed && (
                  <>
                    <Typography variant="p1">
                      Is Disposed: {propertyData?.isDisposed ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant="p1">
                      Disposition Date:{' '}
                      {formatDateStringOrNull(propertyData?.dispositionDate)}
                    </Typography>
                  </>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  pl: '32px',
                }}
              >
                <Typography variant="p1">
                  Subscriber Name: {propertyData?.subscriberName}
                </Typography>
                <Typography variant="p1">
                  Investor Name: {propertyData?.investorName}
                </Typography>
                <Typography variant="p1">
                  Transaction Type: {propertyData?.transactionType}
                </Typography>
                <Typography variant="p1">
                  Is Flood Zone: {propertyData?.isFloodZone ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="p1">
                  Has Flood Insurance:{' '}
                  {propertyData?.hasFloodInsurance ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="p1">
                  Legal Address: {propertyData?.legalAddress}
                </Typography>
                <Typography variant="p1">
                  Parcel Number: {propertyData?.parcelNumber}
                </Typography>
                <Typography variant="p1">
                  Is Down: {propertyData?.isDown ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="p1">
                  Occupancy Status at Acquisition:{' '}
                  {propertyData?.occupancyStatusAtAcquisition}
                </Typography>
                <Typography variant="p1">
                  Initial Capex :{' '}
                  {formatIntegerCents(propertyData?.initialCapexCents || 0)}
                </Typography>
                <Typography variant="p1">
                  Acquisition Cost:{' '}
                  {formatIntegerCents(propertyData?.acquisitionCostCents || 0)}
                </Typography>
                <Typography variant="p1">
                  Underwritten Rent:{' '}
                  {formatIntegerCents(propertyData?.underwrittenRentCents || 0)}
                </Typography>
                <Typography variant="p1">
                  Property Manager: {propertyData?.propertyManager}
                </Typography>
                {propertyData?.isDisposed && (
                  <>
                    <Typography variant="p1">
                      Disposition Price:{' '}
                      {formatCents(propertyData?.dispositionPriceCents || 0)}
                    </Typography>
                    <Typography variant="p1">
                      Disposition Net Proceeds:{' '}
                      {formatCents(
                        propertyData?.dispositionNetProceedsCents || 0
                      )}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pl: '32px',
            }}
          >
            {propertyAddressData?.primaryPhotoUrl ? (
              <Box
                width="500px"
                height="350px"
                sx={{
                  borderRadius: '4px',
                  backgroundImage: propertyAddressData?.primaryPhotoUrl
                    ? `url(${propertyAddressData?.primaryPhotoUrl})`
                    : ``,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  position: 'relative',
                }}
              />
            ) : (
              <UploadPropertyPhotoModal
                addressUuid={propertyData?.addressUuid || ''}
                propertyUuid={propertyUuid}
              />
            )}
          </Box>
        </Box>
      )}
      <br />
      <br />
      <Typography variant="h1">Occupancy Status Info</Typography>
      {loading ? (
        <CircularProgress size={32} />
      ) : (
        <DataTable
          title=""
          data={occupancyStatuses as Core_PropertyOccupancyStatus[]}
          columns={occupancyStatusColumns}
          options={tableOptions(OccupancyStatusCustomRowRender()) as any}
        />
      )}
      <br />
      <br />
      <PageTitleContainer>
        <Typography variant="h1">Property Lease Info</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsCreateLeaseModalOpen(true)
          }}
        >
          Create New Lease
        </Button>
      </PageTitleContainer>
      {loading ? (
        <CircularProgress size={32} />
      ) : (
        <DataTable
          title=""
          data={propertyLeases as Core_PropertyLease[]}
          columns={leaseColumns}
          options={
            tableOptions(
              LeaseCustomRowRender(
                openDetailedLeaseModal,
                openEditLeaseModal,
                openDeleteLeaseModal
              )
            ) as any
          }
        />
      )}
      <br />
      <br />
      <PageTitleContainer>
        <Typography variant="h1">Work Order Info</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsCreateWorkOrderModalOpen(true)
          }}
        >
          Create New Work Order
        </Button>
      </PageTitleContainer>
      {loading ? (
        <CircularProgress size={32} />
      ) : (
        <DataTable
          title=""
          data={propertyWorkOrders as Core_PropertyWorkOrder[]}
          columns={workOrderColumns}
          options={
            tableOptions(
              WorkOrderCustomRowRender(
                openDetailedWorkOrderModal,
                openDeleteWorkOrderModal
              )
            ) as any
          }
        />
      )}
      <br />
      <br />
      <PageTitleContainer>
        <Typography variant="h1">Delinquency Info</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsCreateDelinquencyModalOpen(true)
          }}
        >
          Create New Delinquency
        </Button>
      </PageTitleContainer>
      {loading ? (
        <CircularProgress size={32} />
      ) : (
        <DataTable
          title=""
          data={propertyDelinquencies as Core_PropertyDelinquency[]}
          columns={delinquencyColumns}
          options={delinquencyTableOptions(
            DelinquencyCustomRowRender(openDeleteDelinquencyModal)
          )}
        />
      )}
      <br />
      <br />
      <PageTitleContainer>
        <Typography variant="h1">Property Fee Info</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsCreateFeeModalOpen(true)
          }}
        >
          Create New Fee
        </Button>
      </PageTitleContainer>
      {loading ? (
        <CircularProgress size={32} />
      ) : (
        <DataTable
          title=""
          data={propertyFees as Core_PropertyFee[]}
          columns={feeColumns}
          options={
            tableOptions(
              FeeCustomRowRender(openEditFeeModal, openDeleteFeeModal)
            ) as any
          }
        />
      )}
      <br />
      <br />
      <PageTitleContainer>
        <Typography variant="h1">Property Financing Details</Typography>
        {propertyFinancingDetailsArray.length < 1 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsCreateFinancingModalOpen(true)
            }}
          >
            Create New Financing
          </Button>
        )}
      </PageTitleContainer>
      {loading ? (
        <CircularProgress size={32} />
      ) : (
        <DataTable
          title=""
          data={propertyFinancingDetailsArray}
          columns={financingColumns}
          options={
            tableOptions(
              FinancingCustomRowRender(openEditFinancingModal)
            ) as any
          }
        />
      )}
      <CreateLeaseModal
        propertyUuid={propertyUuid}
        isOpen={isCreateLeaseModalOpen}
        close={() => setIsCreateLeaseModalOpen(false)}
      />
      <CreateWorkOrderModal
        propertyUuid={propertyUuid}
        isOpen={isCreateWorkOrderModalOpen}
        close={() => setIsCreateWorkOrderModalOpen(false)}
      />
      <CreateFeeModal
        propertyUuid={propertyUuid}
        isOpen={isCreateFeeModalOpen}
        close={() => setIsCreateFeeModalOpen(false)}
      />
      <CreateDelinquencyModal
        propertyUuid={propertyUuid}
        isOpen={isCreateDelinquencyModalOpen}
        close={() => setIsCreateDelinquencyModalOpen(false)}
      />
      <DetailedLeaseModal
        lease={selectedLease}
        isOpen={isDetailedLeaseModalOpen}
        close={() => setIsDetailedLeaseModalOpen(false)}
      />
      <DetailedWorkOrderModal
        workOrder={selectedWorkOrder}
        isOpen={isDetailedWorkOrderModalOpen}
        close={() => setIsDetailedWorkOrderModalOpen(false)}
      />
      <DeleteLeaseModal
        lease={selectedLease}
        isOpen={isDeleteLeaseModalOpen}
        close={() => setIsDeleteLeaseModalOpen(false)}
      />
      <DeleteWorkOrderModal
        workOrder={selectedWorkOrder}
        isOpen={isDeleteWorkOrderModalOpen}
        close={() => setIsDeleteWorkOrderModalOpen(false)}
      />
      <DeleteFeeModal
        fee={selectedFee}
        isOpen={isDeleteFeeModalOpen}
        close={() => setIsDeleteFeeModalOpen(false)}
      />
      <DeleteDelinquencyModal
        delinquency={selectedDelinquency}
        isOpen={isDeleteDelinquencyModalOpen}
        close={() => setIsDeleteDelinquencyModalOpen(false)}
      />
      <UpdateLeaseModal
        leaseUuid={selectedLease?.uuid || ''}
        leaseInfo={selectedLease}
        isOpen={isUpdateLeaseModalOpen}
        close={() => setIsUpdateLeaseModalOpen(false)}
      />
      <UpdateFeeModal
        feeInfo={selectedFee}
        isOpen={isUpdateFeeModalOpen}
        close={() => setIsUpdateFeeModalOpen(false)}
      />
      <CreateFinancingModal
        propertyUuid={propertyUuid}
        isOpen={isCreateFinancingModalOpen}
        close={() => setIsCreateFinancingModalOpen(false)}
      />
      <UpdateFinancingModal
        financing={selectedFinancing}
        isOpen={isUpdateFinancingModalOpen}
        close={() => setIsUpdateFinancingModalOpen(false)}
      />
      <UpdatePropertyModal
        propertyUuid={propertyUuid}
        propertyInfo={propertyData}
        isOpen={isUpdatePropertyModalOpen}
        close={() => setUpdatePropertyModalOpen(false)}
      />
      <CreateDispositionModal
        propertyUuid={propertyUuid}
        propertyInfo={propertyData}
        isOpen={isCreateDispositionModalOpen}
        close={() => setCreateDispositionModalOpen(false)}
      />
      <AuditLogModal
        open={auditLogModalOpen}
        onClose={() => setAuditLogModalOpen(false)}
        entityUuid={propertyUuid}
      />
    </>
  )
}

export default OccupancyStatusPage
