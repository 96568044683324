import React from 'react'
import { Tab, ThemeProvider } from '@mui/material'
import { flockTheme, LibraryThemeProvider } from '@flock/shared-ui'
import { RouteComponentProps } from '@reach/router'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import SingleFamilyComputeAddressValuationTab from './SingleFamilyComputeAddressValuationTab/SingleFamilyComputeAddressValuationTab'
import MultiFamilyComputeAddressValuationTab from './MultiFamilyComputeAddressValuationTab/MultiFamilyComputeAddressValuationTab'
import useComputeAddressValuationPage from './useComputeAddressValuationPage'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ComputeAddressValuationPage = (_: RouteComponentProps) => {
  const {
    tabValue,
    handleTabChange,
    operatorData,
    valuationHistory,
    valuationHistoryLoading,
    addressData,
  } = useComputeAddressValuationPage()

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <TabContext value={tabValue}>
          <TabList onChange={handleTabChange}>
            <Tab value="1" label="Single Family" />
            <Tab value="2" label="Multi Family" />
          </TabList>

          <TabPanel value="1">
            <SingleFamilyComputeAddressValuationTab
              operatorData={operatorData}
              valuationHistory={valuationHistory}
              valuationHistoryLoading={valuationHistoryLoading}
              addressData={addressData}
            />
          </TabPanel>
          <TabPanel value="2">
            <MultiFamilyComputeAddressValuationTab
              operatorData={operatorData}
              valuationHistory={valuationHistory}
              valuationHistoryLoading={valuationHistoryLoading}
              addressData={addressData}
            />
          </TabPanel>
        </TabContext>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default ComputeAddressValuationPage
