import { useMutation } from '@apollo/client'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import {
  AdminAdminUpdateLeadDocument,
  AdminGetLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { dollarsToInteger } from '@flock/utils'
import React, { useState } from 'react'
import ActionFormModal from '../shared/ActionFormModal'

type UnitDetail = {
  name: string
  propertyType: string
  bedCount: number
  bathCount: number
  halfBathCount: number
  squareFootage: number
  basement: string
  mortgageAmount: string
}

const multifamilyInputConfigs = [
  {
    inputName: 'name',
    inputType: InputType.Text,
    required: true,
    props: {
      type: 'text',
      label: 'Name',
    },
  },
  {
    inputName: 'bedCount',
    inputType: InputType.Text,
    required: true,
    props: {
      type: 'number',
      label: 'Bedrooms',
    },
  },
  {
    inputName: 'bathCount',
    inputType: InputType.Text,
    required: true,
    props: {
      type: 'number',
      label: 'Full Bathrooms',
    },
  },
  {
    inputName: 'halfBathCount',
    inputType: InputType.Text,
    required: true,
    props: {
      type: 'number',
      label: 'Half Bathrooms',
    },
  },
  {
    inputName: 'squareFootage',
    inputType: InputType.Text,
    required: true,
    props: {
      type: 'number',
      label: 'Above Ground sqft',
      placeholder: 'sqft',
    },
  },
  {
    inputName: 'basement',
    inputType: InputType.Dropdown,
    required: true,
    props: {
      label: 'Basement',
      options: [
        {
          value: 'none',
          text: 'No Basement',
        },
        {
          value: 'fullyFinished',
          text: 'Fully finished',
        },
        {
          value: 'partiallyFinished',
          text: 'Partially finished',
        },
        {
          value: 'unfinished',
          text: 'Unfinished',
        },
      ],
    },
  },
]

type EditLeadParams = Partial<UnitDetail> & {
  unitDetails?: UnitDetail[]
  transactionTypeOverride: string
}

type EditLeadAnswersModalProps = {
  open: boolean
  onClose: () => void
  leadUuid: string
  answers: { [key: string]: any }
  transactionType: string
}

const EditLeadAnswersModal = (props: EditLeadAnswersModalProps) => {
  const { open, onClose, leadUuid, answers, transactionType } = props
  const [loading, setLoading] = useState(false)
  const [updateLead] = useMutation(AdminAdminUpdateLeadDocument)

  const { notify } = useSnackbar()

  const onSubmit = async (editLeadParams: EditLeadParams) => {
    const {
      propertyType,
      bedCount,
      bathCount,
      halfBathCount,
      squareFootage,
      mortgageAmount,
      basement,
      unitDetails,
      transactionTypeOverride,
    } = editLeadParams

    let result = {}
    if (propertyType === 'multifamily') {
      const formattedUnitDetails = unitDetails!.map(
        (unitDetail: UnitDetail) =>
          ({
            name: unitDetail.name,
            bedCount: parseInt(
              unitDetail.bedCount.toString().replace(/,/g, ''),
              10
            ),
            bathCount: parseInt(
              unitDetail.bathCount.toString().replace(/,/g, ''),
              10
            ),
            halfBathCount: parseInt(
              unitDetail.halfBathCount.toString().replace(/,/g, ''),
              10
            ),
            squareFootage: parseInt(
              unitDetail.squareFootage.toString().replace(/,/g, ''),
              10
            ),
            basement: unitDetail.basement,
          } as UnitDetail)
      )
      result = {
        ...answers,
        propertyType,
        mortgageAmount: mortgageAmount ? dollarsToInteger(mortgageAmount) : 0,
        unitDetails: formattedUnitDetails,
      }
    } else {
      result = {
        ...answers,
        bedCount: bedCount
          ? parseInt(bedCount.toString().replace(/,/g, ''), 10)
          : bedCount,
        bathCount: bathCount
          ? parseInt(bathCount.toString().replace(/,/g, ''), 10)
          : bathCount,
        halfBathCount: halfBathCount
          ? parseInt(halfBathCount.toString().replace(/,/g, ''), 10)
          : halfBathCount,
        squareFootage: squareFootage
          ? parseInt(squareFootage.toString().replace(/,/g, ''), 10)
          : squareFootage,
        basement,
        unitDetails: answers.unitDetails || [],
        propertyType,
        mortgageAmount: mortgageAmount ? dollarsToInteger(mortgageAmount) : 0,
      }
    }

    setLoading(true)
    try {
      await updateLead({
        variables: {
          updateLeadInput: {
            leadUuid,
            answers: JSON.stringify(result),
            transactionType: transactionTypeOverride,
          },
        },
        refetchQueries: [AdminGetLeadDocument],
      })
      notify('Successfully updated lead.', 'success')
      onClose()
    } catch (e) {
      notify('Failed to update lead details', 'error')
    }
    setLoading(false)
  }

  let prefillData: { [key: string]: any } = {}
  const {
    propertyType,
    unitDetails,
    bedCount,
    bathCount,
    halfBathCount,
    squareFootage,
    basement,
    mortgageAmount,
  } = answers
  // All new leads should have unitDetails but some old leads may not.
  if (propertyType === 'multifamily' && unitDetails) {
    unitDetails.forEach((unitDetail: UnitDetail, idx: number) => {
      prefillData[`name${idx}`] = unitDetail.name
      prefillData[`bedCount${idx}`] = unitDetail.bedCount
      prefillData[`bathCount${idx}`] = unitDetail.bathCount
      prefillData[`halfBathCount${idx}`] = unitDetail.halfBathCount
      prefillData[`squareFootage${idx}`] = unitDetail.squareFootage
      prefillData[`basement${idx}`] = unitDetail.basement
    })
    prefillData.unitDetails = unitDetails.length
    prefillData.transactionTypeOverride = transactionType
  } else {
    prefillData = {
      bedCount,
      bathCount,
      halfBathCount,
      squareFootage,
      basement,
      transactionTypeOverride: transactionType,
    }
  }
  prefillData.mortgageAmount = String(mortgageAmount)
  prefillData.propertyType = propertyType

  const inputConfigs = [
    {
      inputName: 'propertyType',
      inputType: InputType.Dropdown,
      required: true,
      props: {
        label: 'Property Type',
        defaultValue: propertyType,
        options: [
          {
            value: 'singlefamily',
            text: 'Single Family Detached Home',
          },
          {
            value: 'multifamily',
            text: 'Duplex, Triplex, or other small Multifamily',
          },
          {
            value: 'townhouse',
            text: 'Attached Townhouse',
          },
        ],
      },
    },
    {
      inputName: 'bedCount',
      inputType: InputType.Text,
      required: true,
      renderExpression: 'propertyType != "multifamily"',
      props: {
        type: 'number',
        label: 'Bedrooms',
      },
    },
    {
      inputName: 'bathCount',
      inputType: InputType.Text,
      required: true,
      renderExpression: 'propertyType != "multifamily"',
      props: {
        type: 'number',
        label: 'Full Bathrooms',
      },
    },
    {
      inputName: 'halfBathCount',
      inputType: InputType.Text,
      required: true,
      renderExpression: 'propertyType != "multifamily"',
      props: {
        type: 'number',
        label: 'Half Bathrooms',
      },
    },
    {
      inputName: 'squareFootage',
      inputType: InputType.Text,
      required: true,
      renderExpression: 'propertyType != "multifamily"',
      props: {
        type: 'number',
        label: 'Above Ground sqft',
        placeholder: 'sqft',
      },
    },
    {
      inputName: 'transactionTypeOverride',
      inputType: InputType.Dropdown,
      required: true,
      props: {
        label: 'Transaction Type',
        options: [
          {
            value: 'TRANSACTION_TYPE_SINGLE_721',
            text: 'Single 721 Transaction Type',
          },
          {
            value: 'TRANSACTION_TYPE_PORTFOLIO_721',
            text: 'Portfolio 721 Transaction Type',
          },
        ],
      },
    },
    {
      inputName: 'basement',
      inputType: InputType.Dropdown,
      required: true,
      renderExpression: 'propertyType != "multifamily"',
      props: {
        label: 'Basement',
        options: [
          {
            value: 'none',
            text: 'No Basement',
          },
          {
            value: 'fullyFinished',
            text: 'Fully finished',
          },
          {
            value: 'partiallyFinished',
            text: 'Partially finished',
          },
          {
            value: 'unfinished',
            text: 'Unfinished',
          },
        ],
      },
    },
    {
      inputName: 'unitDetails',
      inputType: InputType.MultiForm,
      watchField: 'propertyType',
      renderOn: 'multifamily',
      props: {
        label: 'Unit Details',
        minForms: 1,
        maxForms: 4,
        inputConfigs: multifamilyInputConfigs,
        incrementText: 'Add Unit',
        decrementText: 'Remove Unit',
      },
    },
    {
      inputName: 'mortgageAmount',
      inputType: InputType.Text,
      required: true,
      props: {
        type: 'money',
        label: 'Mortgage',
        placeholder: '$0',
      },
    },
  ]

  return (
    <ActionFormModal
      open={open}
      setOpen={onClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Edit Lead Details"
      formProps={{
        defaultValues: {
          unitDetails: 1,
          ...prefillData,
        },
      }}
      inputConfigs={inputConfigs}
    />
  )
}

export default EditLeadAnswersModal
