import { Box, Button, ThemeProvider, Typography } from '@mui/material'
import { flockTheme, LibraryThemeProvider } from '@flock/shared-ui'
import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import CreateStaticDocumentModal from './CreateStaticDocumentModal'
import LoadingCard from '../LoadingCard'
import StaticDocumentsTable from './StaticDocumentsTable'
import { useStaticDocuments } from '../hooks/useStaticDocuments'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StaticDocumentsPage = (_: RouteComponentProps) => {
  const [createDocumentModalOpen, setCreateDocumentModalOpen] = useState(false)

  const { staticDocumentConfigs, loading } = useStaticDocuments()

  if (loading) {
    return <LoadingCard text="Loading documents" />
  }

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <Box
          display="flex"
          flexDirection="column"
          gap="48px"
          sx={{ pt: '64px' }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            gap="32px"
          >
            <Typography variant="h2">Lead Documents</Typography>
            <Box>
              <Button
                variant="secondary"
                size="small"
                onClick={() => setCreateDocumentModalOpen(true)}
              >
                New Document
              </Button>{' '}
            </Box>
          </Box>
          {staticDocumentConfigs.map((config) => (
            <StaticDocumentsTable key={config.title} {...config} />
          ))}
          <CreateStaticDocumentModal
            open={createDocumentModalOpen}
            onClose={() => setCreateDocumentModalOpen(false)}
          />
        </Box>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default StaticDocumentsPage
