import React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import { ValuationTrailNodeRendererProps } from './valuationTrailNodeRendererTypes'

const ValuationTrailNodeRenderer = (props: ValuationTrailNodeRendererProps) => {
  const { name, description, value, nodes } = props
  let displayedName = name
  if (displayedName === 'root') {
    displayedName = 'View Breakdown'
  }
  return (
    <Accordion sx={{ border: '1px gray solid', margin: '8px', boxShadow: 3 }}>
      <AccordionSummary>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h3">{displayedName}</Typography>
          <Typography variant="h3">{value}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ fontWeight: 'bold' }} display="inline">
          Description:{' '}
        </Typography>
        <Typography display="inline">{description}</Typography>
        {nodes?.map((node) => (
          <ValuationTrailNodeRenderer key={node.name} {...node} />
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default ValuationTrailNodeRenderer
