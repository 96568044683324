import { Paper } from '@flock/flock-component-library'
import {
  Core_OrderV3Task,
  Core_OrderV3PropertyQuestionnaireCompletedTaskResolutionDataPerUnit,
  Core_OrderV3TaskResolutionData_DataOneof_PropertyQuestionnaireCompletedTaskResolutionData,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import React from 'react'
import JsonField from '../JsonField'
import { AddressRender } from '../OrderOnboardingV2/OrderV2Utils'

export const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type SectionProps = {
  title: string
  answers: any
  sectionFields: string[]
}

const isAddressDocumentArray = [
  'propertyManagementAgreement',
  'depreciationSchedule',
]

const formatAnswers = (value: any): any => {
  if (Array.isArray(value)) {
    return value.map((elem) => formatAnswers(elem))
  }

  try {
    const prefixes = [
      'PROPERTY_QUESTIONNAIRE_STAKEHOLDER_',
      'PROPERTY_QUESTIONNAIRE_UTILITY_',
    ]

    const prefix = new RegExp(`^(${prefixes.join('|')})`, 'g')
    return value.replace(prefix, '')
  } catch (e) {
    return value
  }
}

const Section = (props: SectionProps) => {
  const { title, answers, sectionFields } = props
  const filteredFields = sectionFields.filter(
    (field: string) => field !== '__typename'
  )
  return (
    <Box key={title}>
      <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: '2rem' }}>
        <Grid container spacing={2}>
          {filteredFields.map((key) => {
            if (
              typeof answers[key] === 'object' &&
              !Array.isArray(answers[key]) &&
              Object.keys(answers[key] || {}).length !== 0
            ) {
              return (
                <JsonField
                  size={12}
                  jsonKey={key}
                  value={
                    <Section
                      title=""
                      sectionFields={Object.keys(answers[key])}
                      answers={answers[key]}
                    />
                  }
                />
              )
            }
            if (
              Array.isArray(answers[key]) &&
              answers[key].length > 0 &&
              typeof answers[key][0] !== 'string'
            ) {
              return answers[key].map((data: any) => (
                <Section
                  title=""
                  sectionFields={Object.keys(data)}
                  answers={data}
                />
              ))
            }
            return (
              <JsonField
                size={6}
                jsonKey={key}
                value={formatAnswers(answers[key])}
                isAddressDocument={isAddressDocumentArray.includes(key)}
              />
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

type PropertyQuestionaireModalParams = {
  questionnaireTask: Core_OrderV3Task
  isOpen: boolean
  close: () => void
}

const renderUnitData = (
  unitData: Core_OrderV3PropertyQuestionnaireCompletedTaskResolutionDataPerUnit[]
) =>
  unitData.map(
    (
      unit: Core_OrderV3PropertyQuestionnaireCompletedTaskResolutionDataPerUnit
    ) => (
      <Accordion key={unit.addressUuid}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h4">
            <AddressRender addressUuid={unit.addressUuid} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {unit.hasLease && (
            <Section
              title="Lease Info"
              sectionFields={Object.keys(unit.leaseInfo!)}
              answers={unit.leaseInfo}
            />
          )}
          {unit.hasLease &&
            !unit.hasPropertyManager &&
            unit.propertyManager && (
              <Section
                title="Rent and Security Deposit"
                sectionFields={Object.keys(unit.propertyManager!)}
                answers={unit.propertyManager}
              />
            )}
          {unit.hasPropertyManager && unit.propertyManager && (
            <Section
              title="Property Manager"
              sectionFields={Object.keys(unit.propertyManager!)}
              answers={unit.propertyManager}
            />
          )}
          {unit.hasHoa && (
            <Section
              title="HOA"
              sectionFields={Object.keys(unit.hoa!)}
              answers={unit.hoa}
            />
          )}
          {unit.logistics && (
            <Section
              title="Logistics"
              sectionFields={Object.keys(unit.logistics!)}
              answers={unit.logistics}
            />
          )}
          {unit.utilities && (
            <Section
              title="Utilities"
              sectionFields={Object.keys(unit.utilities!)}
              answers={unit.utilities}
            />
          )}
        </AccordionDetails>
      </Accordion>
    )
  )

const PropertyQuestionaireModal = (props: PropertyQuestionaireModalParams) => {
  const { questionnaireTask, isOpen, close } = props
  const questionnaireData = questionnaireTask.resolutionData
    ?.data as Core_OrderV3TaskResolutionData_DataOneof_PropertyQuestionnaireCompletedTaskResolutionData

  return (
    <Modal open={isOpen} onClose={close}>
      <ModalWrapper>
        {renderUnitData(
          questionnaireData.propertyQuestionnaireCompletedTaskResolutionData
            ?.perUnitData || []
        )}
      </ModalWrapper>
    </Modal>
  )
}

export default PropertyQuestionaireModal
