import jsPDF from 'jspdf'
import { useEffect, useRef, useState } from 'react'
import generatePDF from 'react-to-pdf'
import { PDFDocument } from 'pdf-lib'
import { mergePdfs } from '../../../utils'
import { CheckboxOption } from '../printCollateralPageTypes'

export const PRINT_BATCH_SIZE = 5
export const PRINT_PDF_SCALE = 4

type UsePdfDownloadProps = {
  filename: string
  totalPages: number
  onDownloadComplete: () => void
  caTemplatesMap: Record<string, File>
  checkboxes: CheckboxOption[]
}

const usePdfDownload = (props: UsePdfDownloadProps) => {
  const {
    filename,
    totalPages,
    onDownloadComplete,
    caTemplatesMap,
    checkboxes,
  } = props
  const [doneGenerating, setDoneGenerating] = useState(false)

  const [batchToPrint, setBatchToPrint] = useState(-1)
  const [batchStart, setBatchStart] = useState(-9999)
  const [batchEnd, setBatchEnd] = useState(-9999)
  const [downloading, setDownloading] = useState(false)
  const [builtPDFs, setBuiltPDFs] = useState<jsPDF[]>([])
  const [downloadComplete, setDownloadComplete] = useState(false)
  const targetRef = useRef<HTMLDivElement | null>()

  // Batch downloading logic
  const onStartDownload = () => {
    setBatchStart(0)
    setBatchEnd(PRINT_BATCH_SIZE)
    setBatchToPrint(-1)
    setDownloading(true)
  }

  const generatePDFBatch = async () => {
    // need to subtract ca template num pages from total pages.
    // so we need to set numPages for the CA templates so the table of contents works correctly.
    // but the CA templates are not part of this dynamic rendering, and therefore should not be included in the batching numbers.
    // rather they are added the end when mergePDFs is called.

    let newTotalPages = totalPages
    for (let i = 0; i < checkboxes.length; i += 1) {
      const checkbox = checkboxes[i]
      if (
        checkbox.checked &&
        checkbox.label.startsWith('Contribution Agreement')
      ) {
        const labelSplit = checkbox.label.split(' ')
        const state = labelSplit[labelSplit.length - 1] // Example Contribution Agreement - OH -> grabs the OH
        const caFile = caTemplatesMap[state]
        const pdfDoc = await PDFDocument.load(await caFile.arrayBuffer())
        newTotalPages -= pdfDoc.getPageCount()
      }
    }

    if (downloading) {
      if (batchStart <= newTotalPages) {
        // If the batchToPrint is -1, it means that the PDF generation has not started,
        // otherwise, download the currently displayed PDF
        if (batchToPrint !== -1) {
          const builtPDF = await generatePDF(targetRef, {
            filename: `${filename.replace(
              /[^a-zA-Z0-9]/g,
              '_'
            )}-${batchToPrint}.pdf`,
            page: {
              margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              format: [
                (215.9 * PRINT_PDF_SCALE) / 4,
                (279.4 * PRINT_PDF_SCALE) / 4,
              ],
            },
            canvas: {},
            method: 'build',
            overrides: {
              canvas: {
                scale: PRINT_PDF_SCALE,
              },
            },
          })

          setBuiltPDFs([...builtPDFs, builtPDF])
        }
        const newBatchToPrint = batchToPrint + 1

        const newBatchStart = newBatchToPrint * PRINT_BATCH_SIZE
        const newBatchEnd = newBatchStart + PRINT_BATCH_SIZE
        setBatchStart(newBatchStart)
        setBatchEnd(newBatchEnd)
        setBatchToPrint(newBatchToPrint)
      } else {
        setDownloadComplete(true)
      }
    } else {
      setDownloading(false)
      setBatchToPrint(-1)
      setBatchStart(-9999)
      setBatchEnd(9999)
      setDownloadComplete(false)
    }
  }

  const handleDownload = async () => {
    const arrayBuffers = builtPDFs.map((pdf) => pdf.output('arraybuffer'))
    // add ca templates based on checkboxes
    for (let i = 0; i < checkboxes.length; i += 1) {
      const checkbox = checkboxes[i]
      if (
        checkbox.checked &&
        checkbox.label.startsWith('Contribution Agreement')
      ) {
        const labelSplit = checkbox.label.split(' ')
        const state = labelSplit[labelSplit.length - 1] // Example Contribution Agreement - OH -> grabs the OH
        const caFile = caTemplatesMap[state]
        arrayBuffers.push(await caFile.arrayBuffer())
      }
    }

    const mergedPdf = await mergePdfs(arrayBuffers)
    const blob = new Blob([mergedPdf], { type: 'application/pdf' })
    // Download the blob
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${filename.replace(/[^a-zA-Z0-9]/g, '_')}.pdf`
    a.click()
    onDownloadComplete()
  }

  useEffect(() => {
    generatePDFBatch()
  }, [downloading, batchToPrint])

  useEffect(() => {
    if (downloadComplete) {
      handleDownload()
    }
  }, [downloadComplete])

  return {
    doneGenerating,
    setDoneGenerating,
    onStartDownload,
    downloading,
    refToPrint: targetRef,
    batchStart,
    batchEnd,
    onDownloadComplete,
  }
}

export default usePdfDownload
