import { ThemeProvider } from '@mui/material'
import { Theme } from '@mui/system'
import React from 'react'
import { flockTheme } from '../../theme'

type LibraryThemeProviderProps = {
  theme?: Partial<Theme>
  children: React.ReactNode
}

const LibraryThemeProvider = (props: LibraryThemeProviderProps) => {
  const { theme, children } = props
  return <ThemeProvider theme={theme as Theme}>{children}</ThemeProvider>
}

export default LibraryThemeProvider

LibraryThemeProvider.defaultProps = {
  theme: flockTheme,
}
