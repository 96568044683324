import { useQuery } from '@apollo/client'
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
  useSnackbar,
  useRegister,
} from '@flock/flock-component-library'
import {
  AdminOrderOnboardingV2GetContributorNameDocument,
  AdminOrderOnboardingV2GetDocumentDownloadUrlDocument,
  Core_OrderV2Task,
  Core_OrderV2TaskKey,
  Core_OrderV2TaskResolutionData_DataOneof_ContributionAgreementSignedTaskResolutionData,
  Core_OrderV2TaskResolutionData_DataOneof_SubscriptionDocumentsSignedTaskResolutionData,
  Core_OrderV2TaskStatus,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { orderV2TaskToUserView } from '@flock/utils'
import { Tooltip, AccordionDetails, styled } from '@mui/material'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import PersonalInformationModal from './OwnerInformationModal'
import PropertyQuestionaireModal from './PropertyQuestionnaireModal'

const FieldLabel = styled(Typography)({
  fontWeight: 'bold',
  opacity: 0.5,
})

type LabelledFieldProps = {
  label: string
  value: string
}

export const LabeledField = (props: LabelledFieldProps) => {
  const { label, value } = props
  return (
    <Grid item xs={3} flexDirection="column">
      <FieldLabel>{label}</FieldLabel>
      <Typography variant="h2">{value}</Typography>
    </Grid>
  )
}

export const ContributorName = (props: { legalEntityUuid: string }) => {
  const { legalEntityUuid } = props
  const { loading, data: legalEntityData } = useQuery(
    AdminOrderOnboardingV2GetContributorNameDocument,
    {
      variables: {
        input: {
          legalEntityUuid,
        },
      },
    }
  )

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <LabeledField
          label="Contributor Name"
          value={legalEntityData?.legalEntity?.legalEntity?.name as string}
        />
      )}
    </>
  )
}

const StyledAccordionDetails = styled(AccordionDetails)({
  paddingTop: '2px',
  paddingBottom: '2px',
})
const DefaultTaskRowRenderer = (props: { task: Core_OrderV2Task }) => {
  const { task } = props
  const { register } = useFormContext()
  const userView = orderV2TaskToUserView[task.key]

  const checkboxProps = useRegister(register, task.taskUuid)

  return (
    <Tooltip
      title={
        userView.customerActionDescription && (
          <div style={{ fontSize: '1rem' }}>
            {`Customer sees: ${userView.customerActionDescription}`}
          </div>
        )
      }
      placement="top"
      arrow
    >
      <div>
        {task.key === Core_OrderV2TaskKey.OrderV2TaskKeyUnspecified ? (
          userView.adminActionDescription
        ) : (
          // show failed tasks as completed, but disabled so we can look into it further
          <Checkbox
            label={userView.adminActionDescription}
            defaultChecked={
              task.status ===
                Core_OrderV2TaskStatus.OrderV2TaskStatusCompleted ||
              task.status === Core_OrderV2TaskStatus.OrderV2TaskStatusFailed
            }
            disabled={
              task.status === Core_OrderV2TaskStatus.OrderV2TaskStatusFailed
            }
            {...checkboxProps}
          />
        )}
      </div>
    </Tooltip>
  )
}

const DocumentDownloadButton = (props: {
  label: string
  legalEntityUuid: string
  documentUuid: string
}) => {
  const { label, legalEntityUuid, documentUuid } = props
  const [downloading, setDownloading] = useState(false)
  const { notify } = useSnackbar()
  const { refetch } = useQuery(
    AdminOrderOnboardingV2GetDocumentDownloadUrlDocument,
    {
      skip: true,
    }
  )

  const downloadDocument = async () => {
    try {
      setDownloading(true)
      const urlData = await refetch({
        input: {
          legalEntityUuid,
          documentUuid,
        },
      })
      window.open(
        urlData?.data?.legalEntityDocumentPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the file. Please refresh or try again.',
        'error'
      )
    }
    setDownloading(false)
  }
  return (
    <Button
      variant="outlined"
      onClick={downloadDocument}
      disabled={downloading}
      sx={{
        marginLeft: '2rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      }}
    >
      {label}
    </Button>
  )
}

const OpenModalButton = (props: {
  setOpenModal: (status: boolean) => void
  text: string
}) => {
  const { setOpenModal, text } = props
  return (
    <Button
      variant="outlined"
      onClick={() => setOpenModal(true)}
      sx={{
        marginLeft: '2rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      }}
    >
      {text}
    </Button>
  )
}

export type RenderProcessProps = {
  tasks: Core_OrderV2Task[]
  legalEntityUuid?: string
  propertyQuestionnaireModalOpen?: boolean
  setPropertyQuestionnaireModalOpen?: (status: boolean) => void
  ownerInformationModalOpen?: boolean
  setOwnerInformationModalOpen?: (status: boolean) => void
}

export type TaskProcessUtils = {
  processName: string
  processString: string
  ProcessComponent: (props: RenderProcessProps) => JSX.Element
}

const RenderContributionAgreementProcess = (props: RenderProcessProps) => {
  const { tasks, legalEntityUuid } = props
  return (
    <>
      {tasks.map((task: Core_OrderV2Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
          {task.key ===
            Core_OrderV2TaskKey.OrderV2TaskKeyContributionAgreementSigned &&
            task.status === Core_OrderV2TaskStatus.OrderV2TaskStatusCompleted &&
            task.resolutionData?.data && (
              <DocumentDownloadButton
                label="Download Contribution Agreement"
                documentUuid={
                  (
                    task.resolutionData
                      .data as Core_OrderV2TaskResolutionData_DataOneof_ContributionAgreementSignedTaskResolutionData
                  ).contributionAgreementSignedTaskResolutionData
                    ?.contributionAgreementSignedDocumentUuid || ''
                }
                legalEntityUuid={legalEntityUuid || ''}
              />
            )}
        </StyledAccordionDetails>
      ))}
    </>
  )
}
const RenderPropertyQuestionnaireProcess = (props: RenderProcessProps) => {
  const {
    tasks,
    propertyQuestionnaireModalOpen,
    setPropertyQuestionnaireModalOpen,
  } = props
  return (
    <>
      {tasks.map((task: Core_OrderV2Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
          {task.key ===
            Core_OrderV2TaskKey.OrderV2TaskKeyPropertyQuestionnaireCompleted &&
            task.status === Core_OrderV2TaskStatus.OrderV2TaskStatusCompleted &&
            task.resolutionData?.data && (
              <>
                <PropertyQuestionaireModal
                  questionnaireTask={task}
                  isOpen={propertyQuestionnaireModalOpen || false}
                  close={() =>
                    setPropertyQuestionnaireModalOpen
                      ? setPropertyQuestionnaireModalOpen(false)
                      : undefined
                  }
                />
                <OpenModalButton
                  setOpenModal={() =>
                    setPropertyQuestionnaireModalOpen
                      ? setPropertyQuestionnaireModalOpen(true)
                      : undefined
                  }
                  text="Property Questionnaire"
                />
              </>
            )}
        </StyledAccordionDetails>
      ))}
    </>
  )
}
const RenderPropertyOnboardingProcess = (props: RenderProcessProps) => {
  const { tasks } = props
  return (
    <>
      {tasks.map((task: Core_OrderV2Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
        </StyledAccordionDetails>
      ))}
    </>
  )
}
const RenderTitleAndClosingProcess = (props: RenderProcessProps) => {
  const { tasks } = props
  return (
    <>
      {tasks.map((task: Core_OrderV2Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          {task.key ===
            Core_OrderV2TaskKey.OrderV2TaskKeyTitleCommitmentRequested && (
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Title Commitment
            </Typography>
          )}
          {task.key ===
            Core_OrderV2TaskKey.OrderV2TaskKeyContributionAgreementSubmittedToTitle && (
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Closing with the Title Company
            </Typography>
          )}
          {task.key === Core_OrderV2TaskKey.OrderV2TaskKeyLeaseSent && (
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Title Company Signed Closing Package Items
            </Typography>
          )}
          <DefaultTaskRowRenderer task={task} />
        </StyledAccordionDetails>
      ))}
    </>
  )
}
const RenderInformationProcess = (props: RenderProcessProps) => {
  const {
    tasks,
    ownerInformationModalOpen,
    setOwnerInformationModalOpen,
    legalEntityUuid,
  } = props

  return (
    <>
      {tasks.map((task: Core_OrderV2Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
          {task.key ===
            Core_OrderV2TaskKey.OrderV2TaskKeyPersonalInformationCompletedOrderprocess &&
            task.status === Core_OrderV2TaskStatus.OrderV2TaskStatusCompleted &&
            task.resolutionData?.data && (
              <>
                <PersonalInformationModal
                  personalInformationTask={task}
                  isOpen={ownerInformationModalOpen || false}
                  close={() =>
                    setOwnerInformationModalOpen
                      ? setOwnerInformationModalOpen(false)
                      : undefined
                  }
                  legalEntityUuid={legalEntityUuid || ''}
                />
                <OpenModalButton
                  setOpenModal={() =>
                    setOwnerInformationModalOpen
                      ? setOwnerInformationModalOpen(true)
                      : undefined
                  }
                  text="Personal Information"
                />
              </>
            )}
        </StyledAccordionDetails>
      ))}
    </>
  )
}

const RenderLpOnboardingProcess = (props: RenderProcessProps) => {
  const { tasks, legalEntityUuid } = props
  return (
    <>
      {tasks.map((task: Core_OrderV2Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
          {task.key ===
            Core_OrderV2TaskKey.OrderV2TaskKeySubscriptionDocumentsSignedOrderprocess &&
            task.status === Core_OrderV2TaskStatus.OrderV2TaskStatusCompleted &&
            task.resolutionData?.data && (
              <DocumentDownloadButton
                label="Download Signed Subscription Booklet"
                documentUuid={
                  (
                    task.resolutionData
                      .data as Core_OrderV2TaskResolutionData_DataOneof_SubscriptionDocumentsSignedTaskResolutionData
                  ).subscriptionDocumentsSignedTaskResolutionData
                    ?.subscriptionBookletSignedDocumentUuid || ''
                }
                legalEntityUuid={legalEntityUuid || ''}
              />
            )}
        </StyledAccordionDetails>
      ))}
    </>
  )
}

export const propertyContributionProcessUtils = [
  {
    processName: 'contributionAgreementProcess',
    processString: 'Contribution Agreement',
    ProcessComponent: RenderContributionAgreementProcess,
  },
  {
    processName: 'propertyQuestionnaireProcess',
    processString: 'Property Questionnaire',
    ProcessComponent: RenderPropertyQuestionnaireProcess,
  },
  {
    processName: 'propertyOnboardingProcess',
    processString: 'Property Onboarding',
    ProcessComponent: RenderPropertyOnboardingProcess,
  },
  {
    processName: 'titleAndClosingProcess',
    processString: 'Title and Closing',
    ProcessComponent: RenderTitleAndClosingProcess,
  },
]

export const orderProcessUtils = [
  {
    processName: 'informationProcess',
    processString: 'Personal Information',
    ProcessComponent: RenderInformationProcess,
  },
  {
    processName: 'lpOnboardingProcess',
    processString: 'Limited Partner Onboarding',
    ProcessComponent: RenderLpOnboardingProcess,
  },
]
