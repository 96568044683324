import React from 'react'
import { Core_PropertyLease } from '@flock/flock-gql-server/src/__generated__/graphql'
import { Box, Modal, Paper, styled, Typography } from '@mui/material'
import { formatCents, formatDateString } from '../InvestorManagement/utils'

const formatDateStringOrEmpty = (dateString: string | null | undefined) =>
  dateString ? formatDateString(dateString) : ''

const formatCentsOrEmpty = (cents: number | null | undefined) =>
  cents ? formatCents(cents) : ''

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type RowProps = {
  label: string
  value: string | number | undefined | null
}

const DataRow = (props: RowProps) => {
  const { label, value } = props
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          borderBottom: '1px dotted black',
        }}
      >
        <Typography variant="p2">{label}:</Typography>
        <Typography variant="p2">{value}</Typography>
      </Box>
    </>
  )
}

type DetailedLeaseModalParams = {
  lease: Core_PropertyLease
  isOpen: boolean
  close: () => void
}

const DetailedLeaseModal = (props: DetailedLeaseModalParams) => {
  const { lease, isOpen, close } = props

  const handleClose = () => {
    close()
  }

  const isRenewed = lease.isRenewed ? 'Yes' : 'No'

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalWrapper>
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="h3">Detailed Lease Info </Typography>

            <DataRow label="Tenant Name" value={lease.tenantName} />
            <DataRow
              label="Lease Start Date"
              value={formatDateStringOrEmpty(lease.leaseStartDate)}
            />
            <DataRow
              label="Move In Date"
              value={formatDateStringOrEmpty(lease.moveInDate)}
            />
            <DataRow
              label="Monthly Rent"
              value={formatCentsOrEmpty(lease.monthlyRentCents)}
            />
            <DataRow
              label="Lease End Date"
              value={formatDateStringOrEmpty(lease.leaseEndDate)}
            />
            <DataRow
              label="Move Out Date"
              value={formatDateStringOrEmpty(lease.moveOutDate)}
            />
            <DataRow label="Lease Type" value={lease.leaseType} />
            <DataRow label="Lease Originator" value={lease.leaseOriginator} />

            <DataRow
              label="Rent Due Date Each Month"
              value={lease.rentDueDateEachMonth}
            />
            <DataRow label="Grace Period Days" value={lease.gracePeriodDays} />
            <DataRow label="Is Renewed" value={isRenewed} />
            <DataRow
              label="Security Deposit"
              value={formatCentsOrEmpty(lease.securityDepositCents)}
            />
            <DataRow
              label="Renewal Offer Sent Date"
              value={formatDateStringOrEmpty(lease.renewalOfferSentDate)}
            />
            <DataRow
              label="Renewal Offer Amount"
              value={formatCentsOrEmpty(lease.renewalOfferAmountCents)}
            />
            <DataRow
              label="Renewal Offer Status"
              value={lease.renewalOfferStatus}
            />
            <DataRow
              label="Renewal Offer Response Date"
              value={formatDateStringOrEmpty(lease.renewalOfferResponseDate)}
            />
            <DataRow
              label="Section 8"
              value={lease.isSectionEight ? 'Yes' : 'No'}
            />
            <DataRow
              label="Lease Break"
              value={lease.isLeaseBreak ? 'Yes' : 'No'}
            />
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default DetailedLeaseModal
