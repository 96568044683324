import React, { useState } from 'react'
import {
  Typography,
  Paper,
  Button,
  Form,
  useSnackbar,
  InputType,
} from '@flock/flock-component-library'
import {
  AdminGetLeadDocument,
  AdminUpdateLeadDocument,
  Core_Lead,
  Core_ValidatedAddress,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { styled } from '@mui/material/styles'
import { Modal, Box } from '@mui/material'
import { useMutation } from '@apollo/client'

import { formatAddressString, formatCityStateZip } from '@flock/utils'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type ValuationModalProps = {
  lead: Core_Lead
}

type SubmitOverrideParams = {
  equityAmount: string
}

const EquityOverrideModal = (props: ValuationModalProps) => {
  const { lead } = props
  const { notify } = useSnackbar()
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const [updateLead, { loading }] = useMutation(AdminUpdateLeadDocument)

  const onSubmit = async (result: SubmitOverrideParams) => {
    const { equityAmount } = result
    const leadAnswersMap = JSON.parse(lead.answers ? lead.answers : '')
    leadAnswersMap.equityOverride = parseInt(equityAmount, 10)
    const answersStringToSubmit = JSON.stringify(leadAnswersMap)
    const updateLeadInput = {
      leadUuid: lead.uuid,
      answers: answersStringToSubmit,
    }
    try {
      await updateLead({
        variables: {
          updateLeadInput,
        },
        refetchQueries: [AdminGetLeadDocument],
      })
      notify(
        `Updated lead ${lead.uuid} with an override equity amount of $${equityAmount}`,
        'success'
      )
      setOpen(false)
    } catch (e) {
      notify('Failed to override equity amount', 'error')
    }
  }

  return (
    <>
      <Button
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => {
          openModal()
        }}
      >
        Override Equity Amount
      </Button>
      <Modal open={open} onClose={closeModal}>
        <ModalWrapper>
          <Typography
            variant="h2"
            sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
          >
            Override Equity Amount
          </Typography>
          <Box sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
            <Typography variant="body1">{lead.fullName}</Typography>
            <Typography variant="body1">
              {lead.address ? formatAddressString(lead.address) : ''}
            </Typography>
            <Typography variant="body1">
              {formatCityStateZip(lead?.address as Core_ValidatedAddress)}
            </Typography>
            <Typography variant="body1">
              <br />
              This will override the value of the shares to be issued shown on
              the offer page.
            </Typography>
          </Box>
          <Form
            onSubmit={onSubmit}
            ctaText={loading ? 'Submitting...' : 'Override Equity Amount'}
            ctaProps={{
              disabled: loading,
            }}
            inputConfigs={[
              {
                inputName: 'equityAmount',
                inputType: InputType.Text,
                required: true,
                props: {
                  fullWidth: true,
                  type: 'number',
                  label: 'Equity Amount',
                  placeholder: '$0',
                },
              },
            ]}
          />
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default EquityOverrideModal
