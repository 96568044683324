import { Core_SalesforceScenario } from '@flock/flock-gql-server/src/__generated__/graphql'
import { RouteComponentProps } from '@reach/router'

export type PrintCollateralPageProps = RouteComponentProps

export enum CheckboxLabel {
  COVER = 'Cover',
  PORTOFOLIO_OVERVIEW = 'Portfolio Overview',
  TOP_UP_CASH_FLOW = 'Top Up Cash Flow',
  ALLOTMENT_CASH_FLOW = 'Allotment Cash Flow',
  REINVESTED_CASH_FLOW = 'Reinvested Cash Flow',
  VALUATION_COMPS = 'Valuation Comps',
  FAQS = 'FAQs',
}

export type CheckboxOption = {
  id: string
  label: CheckboxLabel
  checked: boolean
}

export type FormValues = {
  checkboxes: CheckboxOption[]
  scenario: string
  coverName: string
}

export type CollateralData = {
  checkboxes: CheckboxOption[]
  scenario: Core_SalesforceScenario
  coverName: string
  caTemplatesMap: Record<string, File>
}
