import { useState } from 'react'

export type SowPhoto = {
  url: string
  addPhotosIndex: number // - 1 means it's not an added photo
}

const usePhotoGallery = () => {
  const [photos, setPhotos] = useState<SowPhoto[]>([])
  const [selectedPhoto, setSelectedPhoto] = useState<SowPhoto>({
    url: '',
    addPhotosIndex: -1,
  })
  const [photosToAdd, setPhotosToAdd] = useState<File[]>([])

  const onAddPhotos = (newFiles: File[]) => {
    let updatedFiles = photosToAdd.slice()
    updatedFiles = updatedFiles.concat(newFiles)
    setPhotosToAdd(updatedFiles)

    // Convert the new files to URLs
    const newPhotos = newFiles.map((file, idx) => ({
      url: URL.createObjectURL(file),
      addPhotosIndex: idx,
    }))

    setPhotos([...photos, ...newPhotos])
  }

  const onDeletePhoto = (photo: SowPhoto) => {
    const newPhotos = photos.filter((p) => p.url !== photo.url)
    setPhotos(newPhotos)

    if (photo.addPhotosIndex !== -1) {
      const newPhotosToAdd = photosToAdd.slice()
      newPhotosToAdd.splice(photo.addPhotosIndex, 1)
      setPhotosToAdd(newPhotosToAdd)
    }

    setSelectedPhoto(
      newPhotos.length > 0 ? newPhotos[0] : { url: '', addPhotosIndex: -1 }
    )
  }

  return {
    photos,
    selectedPhoto,
    photosToAdd,
    onAddPhotos,
    onDeletePhoto,
    setPhotos,
    setSelectedPhoto,
    setPhotosToAdd,
  }
}

export default usePhotoGallery
