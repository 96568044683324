import { useMutation } from '@apollo/client'
import { Button, useSnackbar } from '@flock/flock-component-library'
import {
  AnyInputConfig,
  InputType,
  LibraryThemeProvider,
} from '@flock/shared-ui'
import { AdminCreateTaskDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import React, { useState } from 'react'
import GridFormModal from '../shared/GridFormModal'

type ValuationTaskModalProps = {
  leadUuid: string
  operatorUuid: string
}
const newDate = new Date()
const tempDate: Date = new Date(newDate.setDate(newDate.getDate() + 1))
const inputConfigs: AnyInputConfig[] = [
  {
    name: 'valuationTaskDate',
    type: InputType.DateTimePicker,
    props: {
      defaultValue: tempDate,
      disablePast: true,
    },
  },
]
const ValuationTaskModal = (props: ValuationTaskModalProps) => {
  const { leadUuid, operatorUuid } = props
  const [valuationTaskModalOpen, setValuationTaskModalOpen] = useState(false)

  const [loading, setLoading] = useState(false)

  const { notify } = useSnackbar()
  const [createValuationTaskForLead] = useMutation(AdminCreateTaskDocument)

  const onSubmit = async (params: { valuationTaskDate: string }) => {
    setLoading(true)
    try {
      await createValuationTaskForLead({
        variables: {
          createTaskInput: {
            type: 'create_valuation',
            sourceType: 'lead',
            sourceUuid: leadUuid,
            slaDeadline: params.valuationTaskDate || tempDate,
            operatorUuid,
          },
        },
      })
      notify(
        'Successfully created a valuation task for investments.',
        'success'
      )
    } catch (e) {
      notify('Failed to create a valuation task', 'error')
    }
    setLoading(false)
  }

  return (
    <LibraryThemeProvider>
      <Button
        onClick={() => {
          setValuationTaskModalOpen(true)
        }}
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        data-cy="requestValuationTaskButton"
      >
        Request Valuation Task
      </Button>
      <GridFormModal
        open={valuationTaskModalOpen}
        onClose={() => setValuationTaskModalOpen(false)}
        loading={loading}
        onSubmit={onSubmit}
        title="Request Valuation Task"
        inputConfigs={inputConfigs}
      />
    </LibraryThemeProvider>
  )
}

export default ValuationTaskModal
