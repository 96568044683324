import { useMutation } from '@apollo/client'
import { AdminUpdateScopeOfWorkAssetDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import { useEffect, useState } from 'react'
import { useSowSaveContext } from '../SowSaveContextProvider'
import { AssetRowProps, AssetRowPresentationalProps } from './assetRowTypes'

const useAssetRow: (props: AssetRowProps) => AssetRowPresentationalProps = (
  props: AssetRowProps
) => {
  const { uuid, endOfLifeDate, month, year } = props
  const [remainingLife, setRemainingLife] = useState('0%')
  const { notify } = useSnackbar()
  const { onSaveSuccess, onSaveError, setSaveLoading, refetchProject } =
    useSowSaveContext()

  useEffect(() => {
    const now = new Date()
    const endOfLife = new Date(endOfLifeDate)
    const totalMonths =
      (endOfLife.getFullYear() - (year || now.getFullYear())) * 12 +
      (endOfLife.getMonth() - (year ? month : now.getMonth()))
    const remainingMonths =
      (endOfLife.getFullYear() - now.getFullYear()) * 12 +
      (endOfLife.getMonth() - now.getMonth())
    const remaining = Math.floor((remainingMonths / totalMonths) * 100)
    if (remaining < 0 || endOfLife < now) {
      setRemainingLife('0%')
    } else {
      setRemainingLife(`${remaining}%`)
    }
  }, [endOfLifeDate, month, year])

  const [updateSOWAsset] = useMutation(AdminUpdateScopeOfWorkAssetDocument)
  const updateAsset = async (status: string) => {
    setSaveLoading(true)

    try {
      await updateSOWAsset({
        variables: {
          input: {
            uuid,
            status,
          },
        },
        // refetchQueries: [],
      })
      onSaveSuccess()
      await refetchProject()
    } catch (e) {
      notify('Failed to update asset', 'error')
      onSaveError(`Failed to update asset: ${e.message}`)
    }
  }

  return {
    ...props,
    remainingLife,
    updateAsset,
  }
}

export default useAssetRow
