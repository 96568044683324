import React from 'react'
import { TableCell, TableRow, styled } from '@mui/material'
import { formatIntegerCents } from '@flock/utils'

const CenteredContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'row',
})

export const stagedInvestmentTransactionColumns = [
  {
    name: 'legalEntityName',
    label: 'Legal Entity Name',
    options: { sortThirdClickReset: true },
  },
  {
    name: 'effectiveDate',
    label: 'Effective Date',
    options: { sortThirdClickReset: true },
  },
  {
    name: 'transactionType',
    label: 'Transaction Type',
    options: { sortThirdClickReset: true },
  },
  {
    name: 'equityValueChangeCents',
    label: 'Equity Value Change',
    options: { sortThirdClickReset: true },
  },
  {
    name: 'shareCountChange',
    label: 'Share Count Change',
    options: { sortThirdClickReset: true },
  },
  { name: 'notes', label: 'Notes', options: { sortThirdClickReset: true } },
  { name: '', label: '' },
]

const StagedInvestmentTransactionRowRender = (
  data: [string, string, string, number, number, string]
) => {
  const [
    legalEntityName,
    effectiveDate,
    transactionType,
    equityValueChangeCents,
    shareCountChange,
    notes,
  ] = data

  const effectiveDate2 = new Date(effectiveDate)

  return (
    <TableRow>
      <TableCell>
        <CenteredContent>{legalEntityName}</CenteredContent>
      </TableCell>
      <TableCell>
        <CenteredContent>
          {effectiveDate2.toLocaleDateString('en-US')}
        </CenteredContent>
      </TableCell>
      <TableCell>
        <CenteredContent>{transactionType}</CenteredContent>
      </TableCell>
      <TableCell>
        <CenteredContent>
          {formatIntegerCents(equityValueChangeCents)}
        </CenteredContent>
      </TableCell>
      <TableCell>
        <CenteredContent>
          {formatIntegerCents(shareCountChange, false)}
        </CenteredContent>
      </TableCell>
      <TableCell>
        <CenteredContent>{notes}</CenteredContent>
      </TableCell>
    </TableRow>
  )
}

export default StagedInvestmentTransactionRowRender
