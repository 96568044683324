import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { formattedDollarsToCents } from '@flock/utils'
import {
  AdminCreateWorkOrderDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_CreatePropertyWorkOrderRequest,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'
import ActionFormModal from '../shared/ActionFormModal'
import { cleanTimeFromDatetime } from '../../utils'

type CreateWorkOrderParams = {
  propertyUuid: string
  priorityStatus: string
  orderType: string
  orderNumber: string
  orderDescription: string
  orderCostCents: string
  orderStatus: string
  orderVendor: string
  orderCreationDate: string
  createdBy: string
  orderEstimatedCostCents: string
  estimationDate: string
  scheduledStartDate: string
  scheduledCompletionDate: string
  workStartDate: string
  workCompletionDate: string
  workCancellationDate: string
}

type CreateWorkOrderModalParams = {
  propertyUuid: string
  isOpen: boolean
  close: () => void
}

const CreateWorkOrderModal = (props: CreateWorkOrderModalParams) => {
  const { propertyUuid, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createWorkOrder, { loading }] = useMutation(
    AdminCreateWorkOrderDocument
  )

  const onSubmit = async (createWorkOrderParams: CreateWorkOrderParams) => {
    const {
      priorityStatus,
      orderType,
      orderNumber,
      orderDescription,
      orderCostCents,
      orderStatus,
      orderVendor,
      orderCreationDate,
      createdBy,
      orderEstimatedCostCents,
      estimationDate,
      scheduledStartDate,
      scheduledCompletionDate,
      workStartDate,
      workCompletionDate,
      workCancellationDate,
    } = createWorkOrderParams
    const createWorkOrderInput: Core_CreatePropertyWorkOrderRequest = {
      workOrderParams: {
        propertyUuid,
        priorityStatus,
        orderType,
        orderNumber,
        orderDescription,
        orderCostCents: formattedDollarsToCents(orderCostCents),
        orderStatus,
        orderVendor,
        orderCreationDate: cleanTimeFromDatetime(orderCreationDate),
        createdBy,
        orderEstimatedCostCents: formattedDollarsToCents(
          orderEstimatedCostCents
        ),
        estimationDate: cleanTimeFromDatetime(estimationDate),
        scheduledStartDate: cleanTimeFromDatetime(scheduledStartDate),
        scheduledCompletionDate: cleanTimeFromDatetime(scheduledCompletionDate),
        workStartDate: cleanTimeFromDatetime(workStartDate),
        workCompletionDate: cleanTimeFromDatetime(workCompletionDate),
        workCancellationDate: cleanTimeFromDatetime(workCancellationDate),
      },
    }

    try {
      await createWorkOrder({
        variables: {
          input: createWorkOrderInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify('Successfully created work order.', 'success')
    } catch (e) {
      notify('Failed to create work order.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Create Work Order"
      inputConfigs={[
        {
          inputName: 'orderNumber',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Order Number',
            placeholder: 'enter order number',
            fullWidth: true,
          },
        },
        {
          inputName: 'priorityStatus',
          inputType: InputType.Dropdown,
          props: {
            type: 'text',
            label: 'Priority Status',
            fullWidth: true,
            options: [
              {
                text: 'Low',
                value: 'low',
              },
              {
                text: 'Normal',
                value: 'normal',
              },
              {
                text: 'High',
                value: 'high',
              },
            ],
          },
        },
        {
          inputName: 'orderType',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Order Type',
            placeholder: 'enter order type',
            fullWidth: true,
          },
        },
        {
          inputName: 'orderDescription',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Order Description',
            placeholder: 'enter order type',
            fullWidth: true,
          },
        },
        {
          inputName: 'orderCostCents',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Order Cost',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },

        // todo: replace this with dropdown
        {
          inputName: 'orderStatus',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Order Status',
            placeholder: 'Enter order status',
            fullWidth: true,
          },
        },
        {
          inputName: 'orderVendor',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Order Vendor',
            placeholder: 'Enter order Vendor',
            fullWidth: true,
          },
        },
        {
          inputName: 'orderCreationDate',
          inputType: InputType.DatePicker,
          required: true,
          props: {
            label: 'Order Creation Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'createdBy',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Order Created By',
            placeholder: 'Enter order created by',
            fullWidth: true,
          },
        },
        {
          inputName: 'orderEstimatedCostCents',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Order Estimated Cost',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },
        {
          inputName: 'estimationDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Estimation Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'scheduledStartDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Scheduled Start Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'scheduledCompletionDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Scheduled Completion Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'workStartDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Work Start Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'workCompletionDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Work Completion Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'workCancellationDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Work Cancellation Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
      ]}
    />
  )
}

export default CreateWorkOrderModal
