import { useEffect, useState } from 'react'
import { SOWDataRow } from '../printCollateralPageTypes'
import {
  SowRendererProps,
  SowRendererPresentationalProps,
  SowPageConfig,
} from './sowRendererTypes'

const MAX_PAGE_HEIGHT = 575

const useSowRenderer: (
  props: SowRendererProps
) => SowRendererPresentationalProps = (props: SowRendererProps) => {
  const { unitName, items, summaryItems, setNextUnitToRender } = props

  const [pageNumber, setPageNumber] = useState(0)
  const [renderedItemsIndex, setRenderedItemsIndex] = useState(0)
  const [allComplete, setAllComplete] = useState(false)
  const [pageConfigs, setPageConfigs] = useState<SowPageConfig[]>([
    {
      pageNumber: 0,
      items: [],
      summaryItems: [],
    },
  ])

  // This useEffect is responsible for rendering the items into the pageConfigs
  // It will render the items into the pageConfigs until the page height is greater than the MAX_PAGE_HEIGHT
  // If the page height is greater than the MAX_PAGE_HEIGHT, it will move the last item to the next page
  // If the last item is a summary item, it will move the summary items to the next page
  // If all items are rendered, it will add the summary items to the pageConfigs
  useEffect(() => {
    if (allComplete) {
      return
    }

    // Get current page's height
    const currentPageHeight = document.getElementById(
      `page-${unitName}-${pageNumber}`
    )?.clientHeight
    let newRenderedItemsIndex = renderedItemsIndex
    const newPageConfigs = [...pageConfigs]

    if (currentPageHeight && currentPageHeight > MAX_PAGE_HEIGHT) {
      // If the summary items are added to the PageConfigs, remove them and move them to the next page
      if (newPageConfigs[pageNumber].summaryItems.length > 0) {
        newPageConfigs[pageNumber].summaryItems = []
        setPageNumber(pageNumber + 1)
        setPageConfigs([
          ...newPageConfigs,
          {
            pageNumber: pageNumber + 1,
            items: [],
            summaryItems,
          },
        ])
      } else {
        // If the current page's height is greater then the max height, remove the last item from the current page config
        const itemToMoveToNextPage = newPageConfigs[pageNumber].items.pop()
        setPageNumber(pageNumber + 1)
        setPageConfigs([
          ...pageConfigs,
          {
            pageNumber: pageNumber + 1,
            items: [itemToMoveToNextPage as SOWDataRow],
            summaryItems: [],
          },
        ])
      }
    } else if (newPageConfigs[pageNumber].summaryItems.length > 0) {
      setAllComplete(true)
      setNextUnitToRender()
    } else if (items[renderedItemsIndex]) {
      newPageConfigs[pageNumber].items.push(items[renderedItemsIndex])
      newRenderedItemsIndex += 1
      setPageConfigs(newPageConfigs)
    } else {
      // If all items are rendered, add summary items
      newPageConfigs[pageNumber].summaryItems = summaryItems
      setPageConfigs(newPageConfigs)
    }

    setRenderedItemsIndex(newRenderedItemsIndex)
  }, [JSON.stringify(pageConfigs)])

  return {
    ...props,
    pageConfigs,
  }
}

export default useSowRenderer
