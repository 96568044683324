import React from 'react'
import { GridForm } from '@flock/shared-ui'
import { Paper, Modal, Typography } from '@mui/material'
import useRejectValuationModal from './useRejectValuationModal'
import { RejectValuationModalProps } from './rejectValuationModalTypes'

const RejectValuationModal = (props: RejectValuationModalProps) => {
  const { leadUuid, valuationUuid, operatorUuid, open, onClose } = props
  const { inputConfigs, onSubmit, loading } = useRejectValuationModal(props)
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '80px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          width: '560px',
          maxHeight: '75vh',
          overflowY: 'scroll',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Typography variant="h3">Reject Valuation</Typography>
        <GridForm
          inputConfigs={inputConfigs}
          onSubmit={(formResult) => {
            onSubmit(formResult, leadUuid, operatorUuid, onClose, valuationUuid)
          }}
          ctaText="Reject Valuation"
          loading={loading}
          ctaButtonProps={{ sx: { width: '120px' } }}
        />
      </Paper>
    </Modal>
  )
}

export default RejectValuationModal
