import { Button, Grid, Typography } from '@flock/flock-component-library'
import { Box, Divider } from '@mui/material'
import React from 'react'

type DataMapSectionProps = {
  title: string
  children: React.ReactNode

  actionText?: string
  onAction?: () => void
}

const DataMapSection = (props: DataMapSectionProps) => {
  const { title, children, actionText, onAction } = props
  return (
    <>
      <Typography
        variant="h2"
        style={{
          marginTop: '2rem',
          marginBottom: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        {actionText && (
          <Button variant="outlined" onClick={onAction}>
            {actionText}
          </Button>
        )}
      </Typography>
      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: '2rem' }}>
        <Grid container spacing={2}>
          {children}
        </Grid>
      </Box>
      <Divider />
    </>
  )
}

DataMapSection.defaultProps = {
  actionText: '',
  onAction: null,
}

export default DataMapSection
