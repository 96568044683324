import { UploadIcon, TrackedIconButton, CloseIcon } from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import MagnifiableImage from '../SowDeficiencyModal/MagnifiableImage'
import { SowPhoto } from './usePhotoGallery'

type PhotoGalleryProps = {
  photos: SowPhoto[]
  selectedPhoto: SowPhoto
  setSelectedPhoto: (photo: SowPhoto) => void
  onDeletePhoto: (photo: SowPhoto) => void
  onAddPhotos: (newFiles: File[]) => void
  onClose: () => void
}

const PhotoGallery = (props: PhotoGalleryProps) => {
  const {
    photos,
    selectedPhoto,
    setSelectedPhoto,
    onDeletePhoto,
    onAddPhotos,
    onClose,
  } = props

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onAddPhotos,
  })

  return (
    <Box display="flex" minWidth="584px" height="360px" gap="8px">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minWidth="96px"
        maxWidth="96px"
        gap="8px"
        sx={{
          overflowY: 'scroll',
        }}
      >
        {photos.map((photo) => (
          <Box
            key={photo.url}
            component="img"
            src={photo.url}
            alt="deficiency"
            width="100%"
            height="auto"
            sx={{
              cursor: 'pointer',
              border:
                photo.url === selectedPhoto.url ? '3px solid green' : 'none',
              borderColor: 'moneyGreen.main',
            }}
            onClick={() => {
              setSelectedPhoto(photo)
            }}
          />
        ))}
        <Box
          {...getRootProps({ refKey: 'ref' })}
          sx={{
            color: 'primary.main',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '2px dashed',
            borderRadius: '8px',
            cursor: 'pointer',
            width: '100%',
          }}
        >
          <input
            {...getInputProps()}
            data-cy="fileUploadInput"
            data-type="file-upload"
          />
          <Box
            display="flex"
            flexDirection="column"
            p="8px"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <UploadIcon width="32px" />
            <Typography variant="p4">Add</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        position="relative"
        display="flex"
        width="480px"
        height="360px"
        alignItems="flex-start"
      >
        {photos.length === 0 || !selectedPhoto.url ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            borderRadius="8px"
            sx={{
              backgroundColor: 'green1.main',
            }}
          >
            <Typography variant="cta" color="gray5.main">
              No photos to display
            </Typography>
          </Box>
        ) : (
          <MagnifiableImage
            src={selectedPhoto.url}
            onDeleteImage={() => onDeletePhoto(selectedPhoto)}
          />
        )}

        <Box
          display="flex"
          justifyContent="flex-end"
          position="absolute"
          right="8px"
          top="8px"
          sx={{
            zIndex: 99999999,
          }}
        >
          <TrackedIconButton
            sx={{
              backgroundColor: 'white5.main',

              '&:hover': {
                backgroundColor: 'white.main',
              },
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </TrackedIconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default PhotoGallery
