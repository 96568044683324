import React from 'react'
import { GridForm } from '@flock/shared-ui'
import { Modal, Paper, Typography } from '@mui/material'
import useAddLeaseAndTenantInfoModal from './useAddLeaseAndTenantInfoModal'
import { AddLeaseAndTenantInfoProps } from './addLeaseAndTenantInfoModalTypes'

const AddLeaseAndTenantInfoModal = (props: AddLeaseAndTenantInfoProps) => {
  const { open, onClose, address } = props
  const { inputConfigs, onSubmit } = useAddLeaseAndTenantInfoModal(props)

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Paper
          sx={{
            position: 'absolute',
            top: '80px',
            left: '50%',
            transform: 'translate(-50%, 0)',
            maxHeight: '75vh',
            overflowY: 'scroll',
            padding: '32px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Typography variant="h3">Add Tenant and Lease Info</Typography>
          <Typography
            variant="h5"
            sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
          >
            {address?.formattedAddress}
          </Typography>
          <GridForm
            onSubmit={onSubmit}
            inputConfigs={inputConfigs}
            ctaText="Submit"
          />
        </Paper>
      </Modal>
    </>
  )
}

export default AddLeaseAndTenantInfoModal
