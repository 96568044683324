import React, { useEffect, useState } from 'react'
import { DownloadSimple } from 'phosphor-react'
import {
  Typography,
  DataTable,
  Button,
  LoadingCard,
  useSnackbar,
  PopoverMenu,
  flockColors,
} from '@flock/flock-component-library'
import {
  AdminDeleteLegalEntitiesDocumentMutationDocument,
  AdminGetDocumentDownloadUrlDocument,
  AdminGetDocumentsDocument,
  AdminGetInvestorAccountDocument,
  AdminGetLegalEntitiesByInvestorAccountDocument,
  AdminGetUpdatesDocument,
  Core_LegalEntity,
  Core_LegalEntityDocument,
  Core_LegalEntityDocument as LegalEntityDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { styled } from '@mui/material/styles'
import { useMutation, useQuery } from '@apollo/client'
import { TableRow, TableCell, CircularProgress } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'

import { navigate, RouteComponentProps, useParams } from '@reach/router'
import { formatPhoneNumber } from '@flock/utils'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  DOCUMENTS_TYPE_INVESTOR_ACCOUNT,
  FLOCK_INVESTOR_SIMULATOR_URL,
  INVESTOR_MANAGEMENT_URL,
} from '../../constants'
import EditLegalEntityModal from './EditLegalEntityModal'
import DeleteLegalEntityModal from './DeleteLegalEntityModal'
import { formatDateString } from './utils'
import DeleteModal from '../FundAssets/DeleteModal'
import CreateUpdateModal from './CreateUpdateModal'
import EditInvestorAccountModal from './EditInvestorAccountModal'
import CreateDocumentModal from './CreateDocumentModal'

const HoveredTableCell = styled(TableCell)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

const formatLegalEntity = (
  editingLegalEntity:
    | {
        uuid: string
        name: string
        type: string
        email: string
        phoneNumber: string
        dateJoined: string
        cashDistributionEnabled: boolean
        flexibleDistributionPercent: number
      }
    | undefined
    | null
) => ({
  uuid: editingLegalEntity?.uuid,
  name: editingLegalEntity?.name,
  type: editingLegalEntity?.type,
  email: editingLegalEntity?.email,
  phoneNumber: editingLegalEntity?.phoneNumber,
  dateJoined: editingLegalEntity?.dateJoined,
  cashDistributionEnabled: editingLegalEntity?.cashDistributionEnabled,
  flexibleDistributionPercent: editingLegalEntity?.flexibleDistributionPercent,
})

const formatDocument = (
  document: Core_LegalEntityDocument | undefined | null
) => ({
  uuid: document?.uuid,
  fileName: document?.fileName,
  documentType: document?.documentType,
  dateAdded: document?.dateAdded,
})

const formatUpdate = (
  update:
    | {
        title: any
        tags: any
        descriptionHtml: any
        dateAdded: any
      }
    | undefined
    | null
) => ({
  title: update?.title,
  tags: update?.tags,
  descriptionHTML: update?.descriptionHtml,
  dateAdded: update?.dateAdded,
})

const columns = [
  { name: 'uuid', label: 'UUID', options: { display: false } },
  { name: 'name', label: 'Name' },
  { name: 'type', label: 'Type' },
  { name: 'email', label: 'Email' },
  { name: 'phoneNumber', label: 'Phone' },
  { name: 'dateJoined', lable: 'Date Joined', options: { display: false } },
  { name: 'flexibleDistributionPercent', label: 'Cash Distribution' },
  { name: '', label: '' },
  { name: '', label: '' },
]

const documentsColumns = [
  { name: 'uuid', options: { display: false } },
  { name: 'fileName', label: 'Document Name' },
  { name: 'documentType', label: 'Document Type' },
  { name: 'dateAdded', label: 'Date Added' },
  { name: '', label: '' },
  { name: '', label: '' },
]

const updatesColumns = [
  { name: 'title', label: 'Title' },
  { name: 'tags', label: 'Tags' },
  { name: 'descriptionHTML', label: 'Description' },
  { name: 'dateAdded', label: 'Date Added' },
]

const LegalEntityDocumentsCustomRowRender =
  (
    openDeleteLegalEntitiesDocumentModal: (
      document: LegalEntityDocument
    ) => void,
    setDocumentUuid: (uuid: string) => void
  ) =>
  (data: [string, string, string, string]) => {
    const [uuid, fileName, documentType, dateAdded] = data
    const deleteLegalEntitiesDocument = {
      uuid,
      fileName,
      documentType,
      dateAdded,
    }
    return (
      <TableRow key={uuid}>
        <TableCell>{fileName}</TableCell>
        <TableCell>{documentType}</TableCell>
        <TableCell>{formatDateString(dateAdded)}</TableCell>
        <HoveredTableCell
          onClick={() =>
            openDeleteLegalEntitiesDocumentModal(deleteLegalEntitiesDocument)
          }
        >
          <DeleteOutlineIcon />
        </HoveredTableCell>
        <HoveredTableCell
          onClick={() => {
            setDocumentUuid(uuid)
          }}
        >
          <DownloadSimple size="1.25rem" />
        </HoveredTableCell>
      </TableRow>
    )
  }

const options = (
  customRowRender: (
    data: [string, string, string, string, string, string, number]
  ) => any
) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender,
  elevation: 0,
})

const documentsTableOptions = (
  customRowRender: (data: [string, string, string, string]) => any
) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender,
  elevation: 0,
})

const updatesTableOptions = (
  customRowRender: (data: [string, string, string, string]) => any
) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender,
  elevation: 0,
})

const LegalEntityUpdatesCustomRowRender = (
  data: [string, string, string, string]
) => {
  const [title, tags, descriptionHTML, dateAdded] = data
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>{tags}</TableCell>
      <TableCell>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
      </TableCell>
      <TableCell>{formatDateString(dateAdded)}</TableCell>
    </TableRow>
  )
}

const PageTitleContainer = styled('div')({
  marginTop: '3rem',
  marginBottom: '2rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IndividualInvestorPage = (_: RouteComponentProps) => {
  const [legalEntity, setLegalEntity] = useState<Core_LegalEntity>()

  const [editLegalEntityModalOpen, setEditLegalEntityModalOpen] =
    useState<boolean>(false)
  const [deleteLegalEntityModalOpen, setDeleteLegalEntityModalOpen] =
    useState(false)

  const openEditLegalEntityModal = (
    editingLegalEntity: Partial<Core_LegalEntity>
  ) => {
    setEditLegalEntityModalOpen(true)
    setLegalEntity(editingLegalEntity as Core_LegalEntity)
  }
  const closeEditLegalEntityModal = () => {
    setEditLegalEntityModalOpen(false)
  }
  const [editInvestorAccountModalOpen, setEditInvestorAccountModalOpen] =
    useState<boolean>(false)

  const openDeleteLegalEntityModal = (
    deletingLegalEntity: Partial<Core_LegalEntity>
  ) => {
    setDeleteLegalEntityModalOpen(true)
    setLegalEntity(deletingLegalEntity as Core_LegalEntity)
  }
  const closeDeleteLegalEntityModal = () => {
    setDeleteLegalEntityModalOpen(false)
  }
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false)
  const [documentModalOpen, setDocumentModalOpen] = useState<boolean>(false)
  const [documentUuid, setDocumentUuid] = useState('')

  const [existingDocument, setExistingDocument] =
    useState<LegalEntityDocument>()
  const [
    deleteLegalEntitiesDocumentModalOpen,
    setDeleteLegalEntitiesDocumentModalOpen,
  ] = useState<boolean>(false)

  const openDeleteLegalEntitiesDocumentModal = (
    document: LegalEntityDocument
  ) => {
    setExistingDocument(document)
    setDeleteLegalEntitiesDocumentModalOpen(true)
  }
  const closeDeleteLegalEntitiesDocumentModal = () => {
    setDeleteLegalEntitiesDocumentModalOpen(false)
  }
  const closeEditInvestorAccountModal = () => {
    setEditInvestorAccountModalOpen(false)
  }
  const closeUpdateModal = () => {
    setUpdateModalOpen(false)
  }
  const closeDocumentModal = () => {
    setDocumentModalOpen(false)
  }

  const { investorAccountUuid } = useParams()
  const { notify } = useSnackbar()

  const { refetch: refetchDocument } = useQuery(
    AdminGetDocumentDownloadUrlDocument,
    {
      skip: true,
    }
  )

  const downloadDocument = async () => {
    try {
      const urlData = await refetchDocument({
        input: {
          documentUuid,
          legalEntityUuid: investorAccountUuid,
        },
      })
      window.open(
        urlData?.data?.legalEntityDocumentPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the document. Please refresh or try again.',
        'error'
      )
    }
  }

  useEffect(() => {
    if (documentUuid !== '') {
      downloadDocument()
    }
  })

  // define it here because we need investorAccountUuid from url
  const CustomRowRender =
    (
      openEditLegalEntity: (legalEntity: Partial<Core_LegalEntity>) => void,
      openDeleteLegalEntity: (legalEntity: Partial<Core_LegalEntity>) => void
    ) =>
    (data: [string, string, string, string, string, string, number]) => {
      const [
        uuid,
        name,
        type,
        email,
        phoneNumber,
        dateJoined,
        flexibleDistributionPercent,
      ] = data
      const editLegalEntity = {
        uuid,
        name,
        email,
        phoneNumber,
        dateJoined,
        flexibleDistributionPercent,
      }

      return (
        <TableRow>
          <TableCell>{name}</TableCell>
          <TableCell>{type}</TableCell>
          <TableCell>{email}</TableCell>
          <TableCell>{phoneNumber}</TableCell>
          <TableCell>
            {flexibleDistributionPercent > 0
              ? `${flexibleDistributionPercent}%`
              : 'No'}
          </TableCell>
          <HoveredTableCell
            onClick={() => openEditLegalEntity(editLegalEntity)}
          >
            <EditIcon />
          </HoveredTableCell>
          <HoveredTableCell
            onClick={() => openDeleteLegalEntity(editLegalEntity)}
          >
            <DeleteIcon />
          </HoveredTableCell>
          <HoveredTableCell
            align="right"
            onClick={() => {
              navigate(
                `${INVESTOR_MANAGEMENT_URL}/${investorAccountUuid}/legal-entity-management/${uuid}`
              )
            }}
            data-cy="legalEntity"
          >
            <ArrowForwardIos />
          </HoveredTableCell>
        </TableRow>
      )
    }

  const { loading: investorAccountLoading, data: investorAccountData } =
    useQuery(AdminGetInvestorAccountDocument, {
      variables: {
        input: {
          investorAccountUuid,
        },
      },
      onError: () => {
        notify('Failed to get investor account data', 'error')
      },
    })

  const { loading: legalEntitiesLoading, data: legalEntitiesData } = useQuery(
    AdminGetLegalEntitiesByInvestorAccountDocument,
    {
      variables: {
        input: {
          investorAccountUuid,
        },
      },
      onError: () => {
        notify('Failed to get legal entities for investor account', 'error')
      },
      onCompleted: () => {},
    }
  )

  const { loading: documentsLoading, data: documentsData } = useQuery(
    AdminGetDocumentsDocument,
    {
      variables: {
        input: {
          sourceUuid: investorAccountUuid,
          sourceType: DOCUMENTS_TYPE_INVESTOR_ACCOUNT,
          showHidden: true,
        },
      },
      onError: () => {
        notify('Failed to get legal entity documents', 'error')
      },
    }
  )

  const [deleteLegalEntitiesDocument] = useMutation(
    AdminDeleteLegalEntitiesDocumentMutationDocument
  )

  const { loading: updatesLoading, data: updatesData } = useQuery(
    AdminGetUpdatesDocument,
    {
      variables: {
        input: {
          investorUuid: investorAccountUuid,
        },
      },
      onError: () => {
        notify('Failed to get investor updates', 'error')
      },
    }
  )

  const investorAccountDocuments =
    documentsData?.getDocuments?.legalEntityDocuments?.map(formatDocument) || []

  const legalEntities =
    legalEntitiesData?.getLegalEntitiesByInvestorAccountUuid?.legalEntities.map(
      formatLegalEntity as any
    ) || []

  const investorAccountUpdates =
    updatesData?.getUpdates?.legalEntityUpdates?.map(formatUpdate as any) || []

  let investorAccount: any
  if (!investorAccountLoading) {
    investorAccount = investorAccountData?.investorAccount?.investorAccount
  }

  const links = [
    // re-enable when simulator is fixed
    {
      text: 'Open Investor View',
      onClick: () =>
        window.open(
          `${FLOCK_INVESTOR_SIMULATOR_URL}/app/simulator/${investorAccount.uuid}/app/account`,
          '_blank'
        ),
    },
    {
      text: 'Copy Survey Link',
      onClick: () =>
        // hardcoded survey link okay for now
        navigator.clipboard
          .writeText(
            `https://surveys.hotjar.com/eae91f27-f0c5-42ab-afec-676734f6991e?uuid=${investorAccount.uuid}`
          )
          .then(
            () => {
              notify('Copied survey link to clipboard!', 'success')
            },
            () => {
              notify('Failed to copy survey link to clipboard!', 'error')
            }
          ),
    },
  ]

  return (
    <>
      <PageTitleContainer>
        <Typography variant="h1">Investor Management</Typography>
        <div>
          <PopoverMenu
            text="Links"
            buttonProps={{
              variant: 'outlined',
            }}
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            actions={links}
          />
          <Button
            variant="contained"
            onClick={() => setEditInvestorAccountModalOpen(true)}
            sx={{ marginLeft: '0.5rem' }}
          >
            Edit
          </Button>
        </div>
      </PageTitleContainer>
      {investorAccountLoading ? (
        <LoadingCard text="" />
      ) : (
        <div>
          <Typography variant="body1">
            Name: {investorAccount.firstName} {investorAccount.lastName}
          </Typography>
          <Typography variant="body1">
            Email: {investorAccount.email}
          </Typography>
          <Typography variant="body1">
            Phone Number: {formatPhoneNumber(investorAccount.phoneNumber)}
          </Typography>
        </div>
      )}
      <PageTitleContainer>
        <Typography variant="h2">Legal Entities</Typography>
      </PageTitleContainer>
      {legalEntitiesLoading ? (
        <LoadingCard text="" />
      ) : (
        <DataTable
          title=""
          data={legalEntities as any[]}
          columns={columns}
          options={
            options(
              CustomRowRender(
                openEditLegalEntityModal,
                openDeleteLegalEntityModal
              )
            ) as any
          }
        />
      )}
      <PageTitleContainer>
        <Typography variant="h2">Updates</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setUpdateModalOpen(true)
          }}
        >
          Create Investor Update
        </Button>
      </PageTitleContainer>
      {updatesLoading ? (
        <CircularProgress size={32} />
      ) : (
        <DataTable
          title=""
          data={investorAccountUpdates as any}
          columns={updatesColumns}
          options={
            updatesTableOptions(LegalEntityUpdatesCustomRowRender) as any
          }
        />
      )}
      <PageTitleContainer>
        <Typography variant="h2">Investor Level Documents</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setDocumentModalOpen(true)
          }}
        >
          Create Document for Investor
        </Button>
      </PageTitleContainer>
      {documentsLoading ? (
        <CircularProgress size={32} />
      ) : (
        <DataTable
          title=""
          data={investorAccountDocuments}
          columns={documentsColumns}
          options={
            documentsTableOptions(
              LegalEntityDocumentsCustomRowRender(
                openDeleteLegalEntitiesDocumentModal,
                setDocumentUuid
              )
            ) as any
          }
        />
      )}
      <CreateUpdateModal
        investorAccountUuid={investorAccountUuid}
        isOpen={updateModalOpen}
        close={closeUpdateModal}
      />
      <EditLegalEntityModal
        legalEntity={legalEntity}
        isOpen={editLegalEntityModalOpen}
        close={closeEditLegalEntityModal}
      />
      <EditInvestorAccountModal
        investorAccount={investorAccount}
        isOpen={editInvestorAccountModalOpen}
        close={closeEditInvestorAccountModal}
      />
      <DeleteLegalEntityModal
        legalEntity={legalEntity}
        isOpen={deleteLegalEntityModalOpen}
        close={closeDeleteLegalEntityModal}
      />
      <DeleteModal
        displayHeaders={['Document Name', 'Document Type', 'Date Added']}
        displayCells={[
          existingDocument?.fileName || '',
          existingDocument?.documentType || '',
          new Date(existingDocument?.dateAdded || '').toLocaleDateString(
            'en-US'
          ),
        ]}
        onClick={async () => {
          try {
            const deleteLegalEntitiesDocumentInput = {
              documentUuid: existingDocument!.uuid as string,
            }
            await deleteLegalEntitiesDocument({
              variables: {
                deleteLegalEntitiesDocumentInput,
              },
              refetchQueries: [AdminGetDocumentsDocument],
            })
            notify('Successfully deleted document', 'success')
            closeDeleteLegalEntitiesDocumentModal()
          } catch (e) {
            notify('Failed to Delete document', 'error')
          }
        }}
        isOpen={deleteLegalEntitiesDocumentModalOpen}
        close={closeDeleteLegalEntitiesDocumentModal}
      />
      <CreateDocumentModal
        sourceUuid={investorAccountUuid}
        sourceType={DOCUMENTS_TYPE_INVESTOR_ACCOUNT}
        isOpen={documentModalOpen}
        close={closeDocumentModal}
      />
    </>
  )
}

export default IndividualInvestorPage
