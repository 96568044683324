import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { formattedDollarsToCents } from '@flock/utils'
import { useMutation } from '@apollo/client'
import {
  AdminGetPropertyInfoDocument,
  AdminUpdatePropertyDocument,
  Core_UpdatePropertyRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'
import { cleanTimeFromDatetime } from '../../utils'
import { formatCents } from '../InvestorManagement/utils'

type UpdatePropertyParams = {
  baths: string
  beds: string
  dispositionDate: string
  dispositionNetProceedsCents: string
  dispositionPriceCents: string
  halfBaths: string
  hasFloodInsurance: string
  investorName: string
  isDisposed: string
  isDown: string
  isFloodZone: string
  legalAddress: string
  notes: string
  parcelNumber: string
  propertyType: string
  sqft: string
  subscriberName: string
  transactionType: string
  yearBuilt: string
  yardiId: string
  occupancyStatusAtAcquisition: string
  initialCapexCents: string
  acquisitionCostCents: string
  underwrittenRentCents: string
  contributionDate: string
  acquisitionDate: string
  propertyManager: string
  depositTransferAmountCents: string
}

type UpdatePropertyModalParams = {
  propertyUuid: string
  propertyInfo: any
  isOpen: boolean
  close: () => void
}

const UpdatePropertyModal = (props: UpdatePropertyModalParams) => {
  const { propertyUuid, propertyInfo, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [updateProperty, { loading }] = useMutation(AdminUpdatePropertyDocument)

  const onSubmit = async (updatePropertyParams: UpdatePropertyParams) => {
    const {
      baths,
      beds,
      dispositionDate,
      dispositionNetProceedsCents,
      dispositionPriceCents,
      halfBaths,
      hasFloodInsurance,
      investorName,
      isDisposed,
      isDown,
      isFloodZone,
      legalAddress,
      notes,
      parcelNumber,
      propertyType,
      sqft,
      subscriberName,
      transactionType,
      yearBuilt,
      yardiId,
      occupancyStatusAtAcquisition,
      initialCapexCents,
      acquisitionCostCents,
      underwrittenRentCents,
      contributionDate,
      acquisitionDate,
      propertyManager,
      depositTransferAmountCents,
    } = updatePropertyParams
    const updatePropertyInput: Core_UpdatePropertyRequestInput = {
      uuid: propertyUuid,
      baths: baths ? parseInt(baths, 10) : undefined,
      beds: beds ? parseInt(beds, 10) : undefined,
      dispositionDate:
        dispositionDate && isDisposed
          ? cleanTimeFromDatetime(dispositionDate)
          : undefined,
      dispositionNetProceedsCents:
        dispositionNetProceedsCents && isDisposed
          ? formattedDollarsToCents(dispositionNetProceedsCents)
          : undefined,
      dispositionPriceCents:
        dispositionPriceCents && isDisposed
          ? formattedDollarsToCents(dispositionPriceCents)
          : undefined,
      halfBaths: halfBaths ? parseInt(halfBaths, 10) : undefined,
      hasFloodInsurance: hasFloodInsurance
        ? hasFloodInsurance === 'true'
        : undefined,
      investorName,
      isDisposed: isDisposed ? isDisposed === 'true' : undefined,
      isDown: isDown ? isDown === 'true' : undefined,
      isFloodZone: isFloodZone ? isFloodZone === 'true' : undefined,
      legalAddress,
      notes,
      parcelNumber,
      propertyType,
      sqft: sqft ? parseInt(sqft, 10) : undefined,
      subscriberName,
      transactionType,
      yearBuilt: yearBuilt ? parseInt(yearBuilt, 10) : undefined,
      yardiId,
      occupancyStatusAtAcquisition,
      initialCapexCents: initialCapexCents
        ? formattedDollarsToCents(initialCapexCents)
        : undefined,
      acquisitionCostCents: acquisitionCostCents
        ? formattedDollarsToCents(acquisitionCostCents)
        : undefined,
      underwrittenRentCents: underwrittenRentCents
        ? formattedDollarsToCents(underwrittenRentCents)
        : undefined,
      contributionDate: contributionDate
        ? cleanTimeFromDatetime(contributionDate)
        : undefined,
      acquisitionDate: acquisitionDate
        ? cleanTimeFromDatetime(acquisitionDate)
        : undefined,
      propertyManager,
      depositTransferAmountCents: depositTransferAmountCents
        ? formattedDollarsToCents(depositTransferAmountCents)
        : undefined,
    }

    try {
      await updateProperty({
        variables: {
          input: updatePropertyInput,
        },
        refetchQueries: [AdminGetPropertyInfoDocument],
      })
      notify('Successfully updated property.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to update property.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update Property"
      inputConfigs={[
        {
          inputName: 'propertyType',
          inputType: InputType.Dropdown,
          props: {
            type: 'text',
            label: 'Property Type',
            fullWidth: true,
            defaultValue: propertyInfo?.propertyType,
            options: [
              {
                text: 'SFR',
                value: 'SFR',
              },
              {
                text: 'Townhouse',
                value: 'Townhouse',
              },
              {
                text: 'Duplex',
                value: 'Duplex',
              },
              {
                text: 'Triplex',
                value: 'Triplex',
              },
              {
                text: 'Fourplex',
                value: 'Fourplex',
              },
            ],
          },
        },
        {
          inputName: 'beds',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Beds',
            fullWidth: true,
            defaultValue: propertyInfo?.beds,
          },
        },
        {
          inputName: 'baths',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Baths',
            fullWidth: true,
            defaultValue: propertyInfo?.baths,
          },
        },
        {
          inputName: 'halfBaths',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Half Baths',
            fullWidth: true,
            defaultValue: propertyInfo?.halfBaths,
          },
        },
        {
          inputName: 'sqft',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Square Feet',
            fullWidth: true,
            defaultValue: propertyInfo?.sqft,
          },
        },
        {
          inputName: 'isDisposed',
          inputType: InputType.Dropdown,
          props: {
            type: 'text',
            label: 'Is Disposed?',
            fullWidth: true,
            defaultValue: propertyInfo?.isDisposed ? 'true' : 'false',
            options: [
              {
                text: 'Yes',
                value: 'true',
              },
              {
                text: 'No',
                value: 'false',
              },
            ],
          },
        },
        {
          inputName: 'dispositionDate',
          inputType: InputType.DatePicker,
          watchField: 'isDisposed',
          renderOn: 'true',
          props: {
            label: 'Disposition Date',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'dispositionPriceCents',
          inputType: InputType.Text,
          watchField: 'isDisposed',
          renderOn: 'true',
          props: {
            type: 'money',
            label: 'Disposition Price',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },
        {
          inputName: 'dispositionNetProceedsCents',
          inputType: InputType.Text,
          watchField: 'isDisposed',
          renderOn: 'true',
          props: {
            type: 'money',
            label: 'Disposition Net Proceeds',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },
        {
          inputName: 'isDown',
          inputType: InputType.Dropdown,
          props: {
            type: 'text',
            label: 'Is Down?',
            fullWidth: true,
            defaultValue: propertyInfo?.isDown ? 'true' : 'false',
            options: [
              {
                text: 'Yes',
                value: 'true',
              },
              {
                text: 'No',
                value: 'false',
              },
            ],
          },
        },
        {
          inputName: 'isFloodZone',
          inputType: InputType.Dropdown,
          props: {
            type: 'text',
            label: 'Is Flood Zone?',
            fullWidth: true,
            defaultValue: propertyInfo?.isFloodZone ? 'true' : 'false',
            options: [
              {
                text: 'Yes',
                value: 'true',
              },
              {
                text: 'No',
                value: 'false',
              },
            ],
          },
        },
        {
          inputName: 'hasFloodInsurance',
          inputType: InputType.Dropdown,
          props: {
            type: 'text',
            label: 'Has Flood Insurance?',
            fullWidth: true,
            defaultValue: propertyInfo?.hasFloodInsurance ? 'true' : 'false',
            options: [
              {
                text: 'Yes',
                value: 'true',
              },
              {
                text: 'No',
                value: 'false',
              },
            ],
          },
        },
        {
          inputName: 'investorName',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Investor Name',
            fullWidth: true,
            defaultValue: propertyInfo?.investorName,
          },
        },
        {
          inputName: 'subscriberName',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Subscriber Name',
            fullWidth: true,
            defaultValue: propertyInfo?.subscriberName,
          },
        },
        {
          inputName: 'transactionType',
          inputType: InputType.Dropdown,
          props: {
            type: 'text',
            label: 'Transaction Type',
            fullWidth: true,
            defaultValue: propertyInfo?.transactionType,
            options: [
              {
                text: 'Contribution',
                value: 'Contribution',
              },
              {
                text: 'Purchase',
                value: 'Purchase',
              },
              {
                text: '1031 Replacement',
                value: '1031 Replacement',
              },
            ],
          },
        },
        {
          inputName: 'legalAddress',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Legal Address',
            fullWidth: true,
            defaultValue: propertyInfo?.legalAddress,
          },
        },
        {
          inputName: 'parcelNumber',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Parcel Number',
            fullWidth: true,
            defaultValue: propertyInfo?.parcelNumber,
          },
        },
        {
          inputName: 'notes',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Notes',
            fullWidth: true,
            defaultValue: propertyInfo?.notes,
          },
        },
        {
          inputName: 'yearBuilt',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Year Built',
            fullWidth: true,
            defaultValue: propertyInfo?.yearBuilt,
          },
        },
        {
          inputName: 'yardiId',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Yardi ID',
            fullWidth: true,
            defaultValue: propertyInfo?.yardiId,
          },
        },
        {
          inputName: 'occupancyStatusAtAcquisition',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Occupancy Status at Acquisition',
            fullWidth: true,
            defaultValue: propertyInfo?.occupancyStatusAtAcquisition,
          },
        },
        {
          inputName: 'initialCapexCents',
          inputType: InputType.Text,
          props: {
            type: 'money',
            label: 'Initial Capex',
            placeholder: '$0.00',
            fullWidth: true,
            defaultValue: formatCents(propertyInfo?.initialCapexCents),
          },
        },
        {
          inputName: 'acquisitionCostCents',
          inputType: InputType.Text,
          props: {
            type: 'money',
            label: 'Acquisition Cost',
            placeholder: '$0.00',
            fullWidth: true,
            defaultValue: formatCents(propertyInfo?.acquisitionCostCents),
          },
        },
        {
          inputName: 'underwrittenRentCents',
          inputType: InputType.Text,
          props: {
            type: 'money',
            label: 'Underwritten Rent',
            placeholder: '$0.00',
            fullWidth: true,
            defaultValue: formatCents(propertyInfo?.underwrittenRentCents),
          },
        },
        {
          inputName: 'contributionDate',
          inputType: InputType.DatePicker,
          props: {
            label: 'Contribution Date',
            defaultValue: propertyInfo?.contributionDate,
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'acquisitionDate',
          inputType: InputType.DatePicker,
          props: {
            label: 'Acquisition Date',
            defaultValue: propertyInfo?.acquisitionDate,
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'propertyManager',
          inputType: InputType.Dropdown,
          props: {
            type: 'text',
            label: 'Property Manager',
            fullWidth: true,
            defaultValue: propertyInfo?.propertyManager,
            options: [
              {
                text: 'Self-managed via Appfolio',
                value: 'Self-managed via Appfolio',
              },
              {
                text: 'Summit Residential',
                value: 'Summit Residential',
              },
              {
                text: 'Personal Touch',
                value: 'Personal Touch',
              },
              {
                text: 'The Rogers Group',
                value: 'The Rogers Group',
              },
              {
                text: 'Evernest',
                value: 'Evernest',
              },
            ],
          },
        },
        {
          inputName: 'depositTransferAmountCents',
          inputType: InputType.Text,
          props: {
            type: 'money',
            label: 'Deposit Transfer Amount',
            placeholder: '$0.00',
            fullWidth: true,
            defaultValue: formatCents(propertyInfo?.depositTransferAmountCents),
          },
        },
      ]}
    />
  )
}

export default UpdatePropertyModal
