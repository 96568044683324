import { useQuery } from '@apollo/client'
import { navigate, RouteComponentProps, useParams } from '@reach/router'
import React from 'react'
import {
  Button,
  LoadingCard,
  Typography,
  OpacityLink,
  useSnackbar,
} from '@flock/flock-component-library'
import { ArrowBackIos } from '@mui/icons-material'
import { formatAddressString, formatCityStateZip } from '@flock/utils'

import { Box, Grid } from '@mui/material'
import {
  AdminGetOrderDocument,
  AdminGetTaskDocument,
  Core_Order,
} from '@flock/flock-gql-server/src/__generated__/graphql'

import JsonField from '../JsonField'
import { HOME_OPS_URL, ORDER_ONBOARDING_URL } from '../../constants'

type QuestionnaireTaskAnswers = {
  address: string
  canContactPropertyManager: string
  comments: string
  costBasisKnown: string
  costBasisAmount: string
  costBasisMonth: string
  costBasisYear: string
  currentLeaseFile: string
  currentlyHasKeys: string
  depreciationSchedule: string
  didCashOutRefinance: string
  cashOutRefinanceDescription: string
  electricityProvider: string
  garageDoorCodes: string
  gasProvider: string
  hasHOA: string
  hasLease: string
  hasPropertyManager: string
  hasWasherDryer: string
  hoaCoversUtilities: string
  hoaEmail: string
  hoaName: string
  hoaPhone: string
  hoaUtilitiesCovered: string
  leaseEndDate: string
  leaseStartDate: string
  mailboxDetails: string
  monthlyRent: string
  nameOnUtilities: string
  otherNameOnUtilities: string
  otherRentCollector: string
  otherSecurityDepositHolder: string
  propertyManagementAgreement: string
  rentCollector: string
  rentRollFile: string
  securityDepositHolder: string
  tenantInformation: {
    tenantEmail: string
    tenantName: string
    tenantPhone: string
  }[]
  trashProvider: string
  washerDryerOwner: string
  waterProvider: string

  [key: string]: any
}

const leaseInformationFields = [
  'hasLease',
  'leaseStartDate',
  'leaseEndDate',
  'monthlyRent',
  'hasPropertyManager',
  'canContactPropertyManager',
  'rentCollector',
  'otherRentCollector',
  'securityDepositHolder',
  'otherSecurityDepositHolder',
  'costBasisKnown',
  'costBasisAmount',
  'costBasisMonth',
  'costBasisYear',
  'didCashOutRefinance',
  'cashOutRefinanceDescription',
  'currentLeaseFile',
  'rentRollFile',
  'propertyManagementAgreement',
  'depreciationSchedule',
]

const hoaFields = [
  'hasHOA',
  'hoaName',
  'hoaEmail',
  'hoaPhone',
  'hoaCoversUtilities',
  'hoaUtilitiesCovered',
]

const utilitiesFields = [
  'nameOnUtilities',
  'otherNameOnUtilities',
  'electricityProvider',
  'gasProvider',
  'waterProvider',
  'trashProvider',
]
const additionalInfoFields = [
  'hasWasherDryer',
  'washerDryerOwner',
  'garageDoorCodes',
  'mailboxDetails',
  'currentlyHasKeys',
  'comments',
]

const isDocument = [
  'currentLeaseFile',
  'rentRollFile',
  'propertyManagementAgreement',
  'depreciationSchedule',
]

type SectionProps = {
  title: string
  answers: QuestionnaireTaskAnswers
  sectionFields: string[]
  legalEntityUuid: string
}

const Section = (props: SectionProps) => {
  const { title, answers, sectionFields, legalEntityUuid } = props
  return (
    <Box key={title}>
      <Typography variant="h3" sx={{ marginBottom: '1rem' }}>
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: '2rem' }}>
        <Grid container spacing={2}>
          {sectionFields.map((key) => (
            <JsonField
              jsonKey={key}
              value={answers[key]}
              isDocument={isDocument.includes(key)}
              legalEntityUuid={legalEntityUuid}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OrderTaskPage = (_: RouteComponentProps) => {
  const { orderUuid, taskUuid } = useParams()
  const { notify } = useSnackbar()
  const {
    loading: taskLoading,
    data: taskData,
    error: taskError,
  } = useQuery(AdminGetTaskDocument, {
    variables: {
      input: {
        taskUuid,
      },
    },
    onError: () => {
      notify('Failed to get task data', 'error')
    },
  })

  const { loading: orderLoading, data: orderData } = useQuery(
    AdminGetOrderDocument,
    {
      variables: {
        input: {
          orderUuid,
        },
      },
      onError: () => {
        notify('Failed to get order data', 'error')
      },
    }
  )

  if (taskError) {
    return <LoadingCard text="Failed to get task data." hideLoader />
  }

  if (orderLoading || taskLoading) {
    return <LoadingCard text="Fetching task data..." />
  }

  const { contributorName, legalEntityUuid } = orderData?.order
    ?.order as Core_Order
  const parsedResolutionData = JSON.parse(
    taskData?.task?.task?.resolutionData as string
  )
  const parsedResolutionDataData = JSON.parse(parsedResolutionData.data)
  let allAnswers = parsedResolutionDataData.data
  if (!allAnswers) {
    allAnswers = [parsedResolutionDataData]
  }
  const addressString = formatAddressString(parsedResolutionDataData.address)
  const cityStateZip = formatCityStateZip(parsedResolutionDataData.address)

  return (
    <Box sx={{ marginTop: '3rem' }}>
      <OpacityLink
        onClick={() => {
          navigate(`${ORDER_ONBOARDING_URL}/${orderUuid}`)
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'black',
          fontWeight: 'bold',
        }}
      >
        <ArrowBackIos fontSize="small" /> Back to Order
      </OpacityLink>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
          marginTop: '1rem',
        }}
      >
        <Typography variant="h1">Property Questionnaire</Typography>
        <Button
          variant="outlined"
          onClick={() =>
            window.open(`${HOME_OPS_URL}/?search=${addressString}`, '_blank')
          }
        >
          Search Lead
        </Button>
      </Box>

      <Typography variant="h3" sx={{ marginBottom: '1rem' }}>
        Completed by {contributorName}
      </Typography>
      <>
        <Typography variant="h2" sx={{ marginBottom: '1rem' }}>
          {addressString}
          <br />
          {cityStateZip}
        </Typography>
        {allAnswers.map((answers: any) => (
          <>
            {answers.label && (
              <Typography variant="h3" sx={{ marginBottom: '1rem' }}>
                Unit: {answers.label}
              </Typography>
            )}
            <Section
              title="Lease Information"
              sectionFields={leaseInformationFields}
              answers={answers}
              legalEntityUuid={legalEntityUuid}
            />
            {answers.tenantInformation && answers.tenantInformation.length > 0 && (
              <>
                <Typography variant="h3" sx={{ marginBottom: '1rem' }}>
                  Tenant Information
                </Typography>
                {answers.tenantInformation.map(
                  (
                    tenantData: {
                      tenantName: string
                      tenantEmail: string
                      tenantPhone: string
                    },
                    idx: number
                  ) => (
                    <>
                      <Typography variant="h4" sx={{ marginBottom: '1rem' }}>
                        Tenant {idx + 1}
                      </Typography>
                      <Box
                        sx={{ flexGrow: 1 }}
                        style={{ marginBottom: '2rem' }}
                      >
                        <Grid container spacing={2}>
                          <JsonField
                            jsonKey="tenantName"
                            value={tenantData.tenantName}
                          />
                          <JsonField
                            jsonKey="tenantEmail"
                            value={tenantData.tenantEmail}
                          />
                          <JsonField
                            jsonKey="tenantPhone"
                            value={tenantData.tenantPhone}
                          />
                        </Grid>
                      </Box>
                    </>
                  )
                )}
              </>
            )}
            <Section
              title="HOA"
              sectionFields={hoaFields}
              answers={answers}
              legalEntityUuid={legalEntityUuid}
            />
            <Section
              title="Utilities"
              sectionFields={utilitiesFields}
              answers={answers}
              legalEntityUuid={legalEntityUuid}
            />
            <Section
              title="Additional Information"
              sectionFields={additionalInfoFields}
              answers={answers}
              legalEntityUuid={legalEntityUuid}
            />
          </>
        ))}
      </>
    </Box>
  )
}

export default OrderTaskPage
