import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import {
  AdminGetLegalEntitiesDistributionsDocument,
  AdminUpdateDistributionMutationDocument,
  Core_Distribution,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'

import { formattedDollarsToCents } from '@flock/utils'
import ActionFormModal from '../shared/ActionFormModal'

export type UpdateDistributionParams = {
  amount: string
  distributionDate: string
  distributionUuid: string
  isReinvestment: string
}

type UpdateDistributionsModalParams = {
  existingDistribution: Core_Distribution | undefined
  isOpen: boolean
  close: () => void
}

const UpdateDistributionsModal = (props: UpdateDistributionsModalParams) => {
  const { existingDistribution, isOpen, close } = props
  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [updateDistribution, { loading }] = useMutation(
    AdminUpdateDistributionMutationDocument
  )

  const onSubmit = async (
    updateDistributionParams: UpdateDistributionParams
  ) => {
    const { amount, distributionDate, isReinvestment } =
      updateDistributionParams
    const updateDistributionInput = {
      distributionUuid: existingDistribution?.uuid,
      amountCents: formattedDollarsToCents(amount.toString()),
      distributionDate: distributionDate || '',
      isReinvestment: isReinvestment === 'true',
    }
    try {
      await updateDistribution({
        variables: {
          updateDistributionInput,
        },
        refetchQueries: [AdminGetLegalEntitiesDistributionsDocument],
      })
      notify('Successfully updated distribution', 'success')
      close()
    } catch (e) {
      notify('Failed to update distribution', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update Distribution"
      inputConfigs={[
        {
          inputName: 'amount',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Distribution Amount',
            defaultValue: (existingDistribution?.amountCents || 0) / 100,
            fullWidth: true,
          },
        },
        {
          inputName: 'distributionDate',
          inputType: InputType.DatePicker,
          // @ts-ignore TODO fix props complaining
          props: {
            label: 'Distribution Date',
            defaultValue: existingDistribution?.distributionDate || '',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'isReinvestment',
          inputType: InputType.Radio,
          props: {
            label: 'Distribution type',
            defaultValue:
              existingDistribution?.isReinvestment?.toString() || 'false',
            options: [
              {
                text: 'Reinvestment',
                value: 'true',
              },
              {
                text: 'Cash',
                value: 'false',
              },
            ],
          },
        },
      ]}
    />
  )
}

export default UpdateDistributionsModal
