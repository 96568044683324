import { gql, useMutation } from '@apollo/client'
import { ConfirmModal } from '@flock/flock-component-library'
import {
  AdminDeleteTaskDocument,
  AdminGetTasksDocument,
  Core_DeleteTaskRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import React from 'react'

export const DELETE_TASK = gql`
  mutation AdminDeleteTask($input: Core_DeleteTaskRequestInput!) {
    deleteTask(input: $input) {
      _empty
    }
  }
`

type DeleteNewContributionModalParams = {
  taskUuid: string
  name: string
  isOpen: boolean
  close: () => void
}

const DeleteNewContributionModal = (
  props: DeleteNewContributionModalParams
) => {
  const { taskUuid, name, isOpen, close } = props
  const { notify } = useSnackbar()
  const [deleteTask, { loading }] = useMutation(AdminDeleteTaskDocument)

  const deleteTaskInput: Core_DeleteTaskRequestInput = {
    taskUuid,
  }

  const onSubmit = async () => {
    try {
      await deleteTask({
        variables: {
          input: deleteTaskInput,
        },
        refetchQueries: [AdminGetTasksDocument],
      })
      notify(`Successfully deleted new contribution for ${name}.`, 'success')
      close()
    } catch (e) {
      notify(`Failed to delete new contribution for ${name}.`, 'error')
    }
  }

  return (
    <ConfirmModal
      title="Delete Contribution"
      content={
        loading
          ? 'deleting...'
          : `Are you sure you want to delete new contribution for ${name}?`
      }
      open={isOpen}
      onClose={close}
      onSubmit={onSubmit}
    />
  )
}

export default DeleteNewContributionModal
