import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'
import {
  AdminCreateFinancingDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_CreatePropertyFinancingRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'

type CreateFinancingParams = {
  legalHoldingEntity: string
  collateralOne: string
  collateralTwo: string
}

type CreateFinancingModalParams = {
  propertyUuid: string
  isOpen: boolean
  close: () => void
}

const CreateFinancingModal = (props: CreateFinancingModalParams) => {
  const { propertyUuid, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createFinancing, { loading }] = useMutation(
    AdminCreateFinancingDocument
  )

  const onSubmit = async (createFinancingParams: CreateFinancingParams) => {
    const { legalHoldingEntity, collateralOne, collateralTwo } =
      createFinancingParams

    const createPropertyInput: Core_CreatePropertyFinancingRequestInput = {
      financingParams: {
        propertyUuid,
        legalHoldingEntity,
        collateralOne,
        collateralTwo,
      },
    }

    try {
      await createFinancing({
        variables: {
          input: createPropertyInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify('Successfully created financing.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to create financing.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Create Financing"
      inputConfigs={[
        {
          inputName: 'legalHoldingEntity',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Legal Holding Entity',
            fullWidth: true,
          },
        },
        {
          inputName: 'collateralOne',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Collateral One',
            fullWidth: true,
          },
        },
        {
          inputName: 'collateralTwo',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Collateral Two',
            fullWidth: true,
          },
        },
      ]}
    />
  )
}

export default CreateFinancingModal
