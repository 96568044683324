import { useState } from 'react'

import {
  AnyInputConfig,
  InputType,
  FileUploadMimeType,
  FileUploadFileType,
  useSnackbar,
} from '@flock/shared-ui'
import { gql, useMutation } from '@apollo/client'
import { AdminUploadAddressDocumentModalCreateAddressDocumentDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  UploadAddressDocumentModalProps,
  UploadAddressDocumentParams,
} from './uploadAddressDocumentModalTypes'

export const CREATE_ADDRESS_DOCUMENT = gql`
  mutation AdminUploadAddressDocumentModalCreateAddressDocument(
    $input: CreateAddressDocumentInput!
  ) {
    createAddressDocument(input: $input) {
      addressDocuments {
        name
        documentUuid
      }
    }
  }
`

const useUploadAddressDocumentModal = (
  props: UploadAddressDocumentModalProps
) => {
  const { defaultType, addressUuid } = props
  const { notify } = useSnackbar()

  const [open, setOpen] = useState<boolean>(false)

  const [createAddressDocument] = useMutation(
    AdminUploadAddressDocumentModalCreateAddressDocumentDocument
  )

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'uploadedFile',
      type: InputType.FileUpload,
      required: true,
      props: {
        accept: {
          [FileUploadMimeType.CSV]: [FileUploadFileType.CSV],
          [FileUploadMimeType.DOCX]: [FileUploadFileType.DOCX],
          [FileUploadMimeType.DOC]: [FileUploadFileType.DOC],
          [FileUploadMimeType.PDF]: [FileUploadFileType.PDF],
          [FileUploadMimeType.XLS]: [FileUploadFileType.XLS],
          [FileUploadMimeType.XLSX]: [FileUploadFileType.XLSX],
          [FileUploadMimeType.TXT]: [FileUploadFileType.TXT],
        },
        label: 'Upload Document',
      },
    },
    {
      name: 'name',
      type: InputType.Text,
      required: true,
      props: {
        label: 'Name',
        type: 'text',
        fullWidth: true,
      },
    },
    {
      name: 'notes',
      type: InputType.Text,
      props: {
        label: 'Notes',
        type: 'text',
        fullWidth: true,
      },
    },
    {
      name: 'addressDocumentType',
      type: InputType.Dropdown,
      required: true,
      defaultValue: defaultType,
      props: {
        label: 'Type', // add address type to https://github.com/flock-homes/flock-monorepo/blob/master/go/entities/address.go
        fullWidth: true,

        options: [
          {
            label: 'Inspection Report',
            value: 'report',
          },
          {
            label: 'Invoice',
            value: 'invoice',
          },
          {
            label: 'Current Lease',
            value: 'current_lease',
          },
          {
            label: 'Housecanary Report',
            value: 'housecanary',
          },
          {
            label: 'Scope of Work',
            value: 'scope_of_work',
          },
          {
            label: 'Investment Memo',
            value: 'investment_memo',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ],
      },
    },
  ]
  const onSubmit = async (result: UploadAddressDocumentParams) => {
    const { name, notes, takenDate, uploadedFile, addressDocumentType } = result
    const files = uploadedFile
    try {
      const createAddressDocumentInput = {
        addressUuid,
        name,
        notes,
        takenDate,
        type: addressDocumentType,
        files,
      }
      await createAddressDocument({
        variables: {
          input: createAddressDocumentInput,
        },
        refetchQueries: 'active',
      })
      notify('Successfully created address document', 'success')
      setOpen(false)
    } catch (e) {
      notify(`Failed to create address document ${e}`, 'error')
    }
  }

  return {
    inputConfigs,
    onSubmit,
    open,
    setOpen,
  }
}

export default useUploadAddressDocumentModal
