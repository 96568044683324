import React from 'react'
import { Box, Button, Modal, Paper, Typography } from '@mui/material'
import { FetchOfferCompsModalProps } from './fetchOfferCompsModalTypes'

import useFetchOfferCompsModal from './useFetchOfferCompsModal'

const FetchOfferCompsModal = (props: FetchOfferCompsModalProps) => {
  const { open, address, onClose } = props
  const { submitFetchOfferComps, loading } = useFetchOfferCompsModal(props)
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '80px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          width: '560px',
          maxHeight: '75vh',
          overflowY: 'scroll',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box display="flex" flexDirection="column" gap="32px">
          <Typography variant="h2">Fetch Offer Comps</Typography>
          <Typography variant="p2">
            Verify that you want to fetch offer comps for {address}. This may
            overwrite existing data on comps if they have the same address.
          </Typography>
          <Button
            size="smallForm"
            onClick={submitFetchOfferComps}
            disabled={loading}
          >
            {loading ? 'Fetching...' : 'Fetch Comps'}
          </Button>
        </Box>
      </Paper>
    </Modal>
  )
}

export default FetchOfferCompsModal
