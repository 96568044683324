import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'

import {
  AdminCreateBulkDocumentDocument,
  AdminCreateBulkDocumentWithUpdateDocument,
  AdminCreateDocumentDocument,
  AdminCreateDocumentWithUpdateDocument,
  AdminGetDocumentsDocument,
  AdminGetLegalEntitiesDocumentsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'

type CreateDocumentOrUpdateParams = {
  title: string
  subtitle: string
  descriptionHtml: string
  tags: string
  sendUpdateEmail: boolean
  uploadedFile: File[]
  documentType: string
  shouldCreateUpdate: boolean
}

type CreateDocumentProps = {
  sourceUuid?: string
  sourceType?: string
  isBulk?: boolean
  isOpen: boolean
  close: () => void
}

const CreateDocumentModal = (props: CreateDocumentProps) => {
  const { sourceUuid, sourceType, isBulk, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createDocument, { loading: createDocumentLoading }] = useMutation(
    AdminCreateDocumentDocument
  )

  const [createBulkDocument, { loading: createBulkDocumentLoading }] =
    useMutation(AdminCreateBulkDocumentDocument)

  const [createDocumentWithUpdate, { loading: documentWithUpdateLoading }] =
    useMutation(AdminCreateDocumentWithUpdateDocument)

  const [
    createBulkDocumentWithUpdate,
    { loading: createBulkDocumentWithUpdateLoading },
  ] = useMutation(AdminCreateBulkDocumentWithUpdateDocument)

  const onSubmit = async (result: CreateDocumentOrUpdateParams) => {
    const {
      title,
      subtitle,
      tags,
      sendUpdateEmail,
      uploadedFile,
      documentType,
      shouldCreateUpdate,
      descriptionHtml,
    } = result
    // CreateDocumentWithUpdate
    if (shouldCreateUpdate) {
      const file = uploadedFile[0]
      const createBulkDocumentWithUpdateInput = {
        title,
        subtitle,
        descriptionHtml,
        tags: [tags],
        sendUpdateEmail,
        fileName: file.name,
        documentType,
        file,
      }
      try {
        if (isBulk) {
          await createBulkDocumentWithUpdate({
            variables: {
              createBulkDocumentWithUpdateInput,
            },
          })
        } else {
          const createDocumentWithUpdateInput = {
            sourceUuid,
            sourceType,
            ...createBulkDocumentWithUpdateInput,
          }
          await createDocumentWithUpdate({
            variables: {
              createDocumentWithUpdateInput,
            },
            refetchQueries: [
              AdminGetLegalEntitiesDocumentsDocument,
              AdminGetDocumentsDocument,
            ],
          })
        }
        notify('Successfully created update and document', 'success')
        close()
      } catch (e) {
        notify('Failed to create document w update', 'error')
      }
    }
    // CreateDocument
    else {
      const file = uploadedFile[0]
      const createBulkDocumentInput = {
        fileName: file.name,
        documentType,
        file,
      }
      try {
        if (isBulk) {
          await createBulkDocument({
            variables: {
              createBulkDocumentInput,
            },
          })
        } else {
          const createDocumentInput = {
            sourceUuid,
            sourceType,
            ...createBulkDocumentInput,
          }
          await createDocument({
            variables: {
              createDocumentInput,
            },
            refetchQueries: [
              AdminGetLegalEntitiesDocumentsDocument,
              AdminGetDocumentsDocument,
            ],
          })
        }
        notify('Successfully created document', 'success')
        close()
      } catch (e) {
        notify(`Failed to create document ${e}`, 'error')
      }
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={
        createDocumentLoading ||
        createBulkDocumentLoading ||
        documentWithUpdateLoading ||
        createBulkDocumentWithUpdateLoading
      }
      onSubmit={onSubmit}
      actionText="Create Document"
      inputConfigs={[
        {
          inputName: 'uploadedFile',
          inputType: InputType.FileUpload,
          required: true,
          props: {
            label: 'Upload Document',
          },
        },
        {
          inputName: 'documentType',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            label: 'Document Type', // add document type to https://github.com/flock-homes/flock-monorepo/blob/master/go/entities/legal_entity_document.go
            fullWidth: true,
            options: [
              {
                text: 'Quarter Fund Performance',
                value: 'quarter_fund_performance',
              },
              {
                text: 'K1',
                value: 'k1',
              },
              {
                text: 'S1',
                value: 's1',
              },
              {
                text: 'Capital Account Statement',
                value: 'capital_account_statement',
              },
              {
                text: 'Contribution Confirmation Letter',
                value: 'contribution_confirmation_letter',
              },
              {
                text: 'Fund Document',
                value: 'fund_document',
              },
              {
                text: 'Account Transfer Confirmation Letter',
                value: 'account_transfer_confirmation_letter',
              },
              {
                text: 'Signed Contribution Agreement',
                value: 'contribution_agreement_signed',
              },
            ],
          },
        },
        {
          inputName: 'shouldCreateUpdate',
          inputType: InputType.Checkbox,
          props: {
            label: 'Create an update with this document.',
          },
        },
        {
          inputName: 'title',
          inputType: InputType.Text,
          required: true,
          watchField: 'shouldCreateUpdate',
          props: {
            label: 'Title',
            type: 'text',
            fullWidth: true,
          },
        },
        {
          inputName: 'subtitle',
          inputType: InputType.Text,
          required: true,
          watchField: 'shouldCreateUpdate',
          props: {
            label: 'Subtitle',
            type: 'text',
            fullWidth: true,
          },
        },
        {
          inputName: 'descriptionHtml',
          inputType: InputType.Editor,
          required: true,
          watchField: 'shouldCreateUpdate',
          props: {
            editorStyle: {
              border: 'solid #000',
              borderWidth: '0 1px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingTop: '5px',
            },
          },
        },
        {
          inputName: 'tags',
          inputType: InputType.Dropdown,
          required: true,
          watchField: 'shouldCreateUpdate',
          props: {
            label: 'Tags',
            fullWidth: true,
            options: [
              {
                text: 'Info',
                value: 'INFO',
              },
              {
                text: 'Update',
                value: 'UPDATE',
              },
              {
                text: 'Tax',
                value: 'TAX',
              },
            ],
          },
        },
        {
          inputName: 'sendUpdateEmail',
          inputType: InputType.Checkbox,
          watchField: 'shouldCreateUpdate',
          props: {
            label: 'Notify via Email',
          },
        },
      ]}
    />
  )
}

CreateDocumentModal.defaultProps = {
  isBulk: false,
  sourceUuid: '',
  sourceType: '',
}

export default CreateDocumentModal
