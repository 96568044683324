/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const CREATE_ADDRESS_PHOTO = gql`
  mutation AdminCreateAddressPhoto($input: CreateAddressPhotoInput!) {
    createAddressPhoto(input: $input) {
      uuid
    }
  }
`

export const CREATE_ADDRESS_DOCUMENT = gql`
  mutation AdminCreateAddressDocument($input: CreateAddressDocumentInput!) {
    createAddressDocument(input: $input) {
      addressDocuments {
        name
        documentUuid
      }
    }
  }
`
