import { useMutation } from '@apollo/client'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
  AdminGetCustomerDocument,
  AdminUpdateCustomerDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'

// yup validation overrides the rules of react-hook-form
// including when an input config is required, so should define required here
const validationSchema = Yup.object().shape({
  overrideOnboardingFee: Yup.boolean(),
  feeOverride: Yup.string().when(
    ['overrideOnboardingFee', 'waiveOnboardingFee'],
    {
      is: (overrideOnboardingFee: boolean, waiveOnboardingFee: boolean) =>
        overrideOnboardingFee && !waiveOnboardingFee,
      then: Yup.string()
        .required('Required field')
        .matches(/^0.0[0-6](\d+)?$/, 'Fee should be less than 0.07'),
    }
  ),
  brokerCommission: Yup.string().when('overrideBrokerCommission', {
    is: true,
    then: Yup.string()
      .required('Required field')
      .matches(
        /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
        'Commission should be a decimal representing a percentage of the offer price.'
      ),
  }),
  netYieldOverride: Yup.string().when('overrideNetYield', {
    is: true,
    then: Yup.string()
      .required('Required field')
      .matches(
        /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
        'Net yield should be a decimal.'
      ),
  }),
  closingCosts: Yup.string().when('overrideClosingCosts', {
    is: true,
    then: Yup.string()
      .required('Required field')
      .matches(
        /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
        'Closing costs should be a decimal representing a percentage of the offer price.'
      ),
  }),
  customerNameOverride: Yup.string().when('overrideCustomerName', {
    is: true,
    then: Yup.string().required('Customer name is required'),
  }),
  // https://github.com/jquense/yup/issues/549#issuecomment-855698808
  // value is number if numbers exist in it, string if you click on textfield but is empty,
  // null if you don't interact with it at all
  capexCosts: Yup.lazy((value) =>
    typeof value === 'string'
      ? Yup.string().when('setCapexCosts', {
          is: true,
          then: Yup.string().required('Required field'),
        })
      : Yup.object()
          .nullable()
          .when('setCapexCosts', {
            is: true,
            then: Yup.object()
              .required('Required field')
              .typeError('Required field'),
          })
  ),
  remodelCosts: Yup.lazy((value) =>
    typeof value === 'string'
      ? Yup.string().when('setRemodelCosts', {
          is: true,
          then: Yup.string().required('Required field'),
        })
      : Yup.object()
          .nullable()
          .when('setRemodelCosts', {
            is: true,
            then: Yup.object()
              .required('Required field')
              .typeError('Required field'),
          })
  ),
  cashTakeout: Yup.lazy((value) =>
    typeof value === 'string'
      ? Yup.string().when('overrideCashTakeout', {
          is: true,
          then: Yup.string().required('Required field'),
        })
      : Yup.object()
          .nullable()
          .when('overrideCashTakeout', {
            is: true,
            then: Yup.object()
              .required('Required field')
              .typeError('Required field'),
          })
  ),
  rentReduction: Yup.lazy((value) =>
    typeof value === 'string'
      ? Yup.string().when('overrideRentReduction', {
          is: true,
          then: Yup.string().required('Required field'),
        })
      : Yup.object()
          .nullable()
          .when('overrideRentReduction', {
            is: true,
            then: Yup.object()
              .required('Required field')
              .typeError('Required field'),
          })
  ),
  daysInRemodelDeduction: Yup.lazy((value) =>
    typeof value === 'string'
      ? Yup.string().when('overrideDaysInRemodelDeduction', {
          is: true,
          then: Yup.string().required('Required field'),
        })
      : Yup.object()
          .nullable()
          .when('overrideDaysInRemodelDeduction', {
            is: true,
            then: Yup.object()
              .required('Required field')
              .typeError('Required field'),
          })
  ),
  offerDisclaimer: Yup.string().when('overrideOfferDisclaimer', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  addressDisplayOverride: Yup.string().when('overrideAddress', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  cityStateZipDisplayOverride: Yup.string().when('overrideAddress', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  yearsToProject: Yup.lazy((value) =>
    typeof value === 'string'
      ? Yup.string().when('overrideGraphYears', {
          is: true,
          then: Yup.string().required('Required field'),
        })
      : Yup.object().when('overrideGraphYears', {
          is: true,
          then: Yup.object()
            .required('Required field')
            .typeError('Required field'),
        })
  ),
})

const inputConfigs = [
  {
    inputName: 'overrideCustomerStatus',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Customer Status',
    },
  },
  {
    inputName: 'customerStatusOverride',
    inputType: InputType.Dropdown,
    watchField: 'overrideCustomerStatus',
    props: {
      label: 'Customer Status',
      fullWidth: true,
      options: [
        {
          text: 'Get a valuation',
          value: 'get-an-offer',
        },
        {
          text: 'Discuss your offer',
          value: 'discuss-your-offer',
        },
        {
          text: 'Schedule an inspection (pre-inspection booked)',
          value: 'schedule-an-inspection-pre-inspection-booked',
        },
        {
          text: 'Schedule an inspection (booked)',
          value: 'schedule-an-inspection-booked',
        },
        {
          text: 'Schedule an inspection (post-inspection)',
          value: 'schedule-an-inspection-post-inspection',
        },
        {
          text: 'Review final valuation',
          value: 'review-final-offer',
        },
        {
          text: 'Sign contribution agreement',
          value: 'sign-contribution-agreement',
        },
        {
          text: 'Closing processes',
          value: 'closing-processes',
        },
      ],
    },
  },
  {
    inputName: 'overrideCustomerName',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Customer Name',
    },
  },
  {
    inputName: 'customerNameOverride',
    inputType: InputType.Text,
    watchField: 'overrideCustomerName',
    props: {
      label: 'Customer Name Override',
      type: 'text',
    },
  },
  {
    inputName: 'overridePortfolioAssetCount',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Portfolio Asset Count',
    },
  },
  {
    inputName: 'portfolioAssetCountOverride',
    inputType: InputType.Text,
    watchField: 'overridePortfolioAssetCount',
    props: {
      label: 'Portfolio Asset Count Override',
      type: 'text',
    },
  },
  {
    inputName: 'overridePortfolioDoorCount',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Portfolio Door Count',
    },
  },
  {
    inputName: 'portfolioDoorCountOverride',
    inputType: InputType.Text,
    watchField: 'overridePortfolioDoorCount',
    props: {
      label: 'Portfolio Door Count Override',
      type: 'text',
    },
  },
  {
    inputName: 'overridePortfolioCityState',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Portfolio City, State',
    },
  },
  {
    inputName: 'portfolioCityStateOverride',
    inputType: InputType.Text,
    watchField: 'overridePortfolioCityState',
    props: {
      label: 'Portfolio City, State Override',
      type: 'text',
    },
  },
  {
    inputName: 'overridePortfolioSFRDoors',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Portfolio SFR Doors',
    },
  },
  {
    inputName: 'portfolioSFRDoorsOverride',
    inputType: InputType.Text,
    watchField: 'overridePortfolioSFRDoors',
    props: {
      label: 'Portfolio SFR Doors Override',
      type: 'text',
    },
  },
  {
    inputName: 'overridePortfolioMultifamilyDoors',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Portfolio Multifamily Doors',
    },
  },
  {
    inputName: 'portfolioMultifamilyDoorsOverride',
    inputType: InputType.Text,
    watchField: 'overridePortfolioMultifamilyDoors',
    props: {
      label: 'Portfolio Multifamily Doors Override',
      type: 'text',
    },
  },
  {
    inputName: 'setCapexCosts',
    inputType: InputType.Checkbox,
    props: {
      label: 'Set Capex Costs',
    },
  },
  {
    inputName: 'capexCosts',
    inputType: InputType.Text,
    watchField: 'setCapexCosts',
    props: {
      label: 'Capex Costs',
      type: 'number',
    },
  },
  {
    inputName: 'setRemodelCosts',
    inputType: InputType.Checkbox,
    props: {
      label: 'Set Remodel Costs',
    },
  },
  {
    inputName: 'remodelCosts',
    inputType: InputType.Text,
    watchField: 'setRemodelCosts',
    props: {
      label: 'Remodel Costs',
      type: 'number',
    },
  },
  {
    inputName: 'overrideNetYield',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Net Yield',
    },
  },
  {
    inputName: 'netYieldOverride',
    inputType: InputType.Text,
    watchField: 'overrideNetYield',
    props: {
      label: 'Net Yield',
      type: 'text',
    },
  },
  {
    inputName: 'overrideHoldingPeriod',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Holding Period',
    },
  },
  {
    inputName: 'holdingPeriodOverride',
    inputType: InputType.Text,
    watchField: 'overrideHoldingPeriod',
    props: {
      label: 'Holding Period',
      type: 'number',
    },
  },
  {
    inputName: 'overrideMortgage',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Mortgage',
    },
  },
  {
    inputName: 'mortgageOverride',
    inputType: InputType.Text,
    watchField: 'overrideMortgage',
    props: {
      label: 'Mortgage Override',
      type: 'number',
    },
  },
  {
    inputName: 'showCashFlowDeductions',
    inputType: InputType.Checkbox,
    props: {
      label: 'Show Cash Flow Deductions',
    },
  },
  {
    inputName: 'hideRemodelCosts',
    inputType: InputType.Checkbox,
    props: {
      label: 'Hide Remodel Costs on Offer Page',
    },
  },
  {
    inputName: 'overrideOnboardingFee',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Onboarding Fee',
    },
  },
  {
    inputName: 'waiveOnboardingFee',
    inputType: InputType.Checkbox,
    watchField: 'overrideOnboardingFee',
    props: {
      label: 'Waive Onboarding Fee',
    },
  },
  {
    inputName: 'feeOverride',
    inputType: InputType.Text,
    renderExpression: 'not waiveOnboardingFee',
    watchField: 'overrideOnboardingFee',
    props: {
      label: 'Fee Override (enter as a decimal)',
      type: 'text',
    },
  },
  {
    inputName: 'overrideBrokerCommission',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Broker Commission',
    },
  },
  {
    inputName: 'brokerCommission',
    inputType: InputType.Text,
    watchField: 'overrideBrokerCommission',
    props: {
      label: 'Broker Commission (enter as a decimal)',
      type: 'text',
    },
  },
  {
    inputName: 'overrideClosingCosts',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Closing Costs',
    },
  },
  {
    inputName: 'closingCosts',
    inputType: InputType.Text,
    watchField: 'overrideClosingCosts',
    props: {
      label: 'Closing Costs (enter as a decimal)',
      type: 'text',
    },
  },

  {
    inputName: 'overrideCashTakeout',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Cash Takeout',
    },
  },
  {
    inputName: 'cashTakeout',
    inputType: InputType.Text,
    watchField: 'overrideCashTakeout',
    props: {
      label: 'Cash Takeout',
      type: 'number',
    },
  },
  {
    inputName: 'overrideRentReduction',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Rent Reduction',
    },
  },
  {
    inputName: 'rentReduction',
    inputType: InputType.Text,
    watchField: 'overrideRentReduction',
    props: {
      label: 'Rent Reduction',
      type: 'number',
    },
  },
  {
    inputName: 'overrideDaysInRemodelDeduction',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Days in Remodel Deduction',
    },
  },
  {
    inputName: 'daysInRemodelDeduction',
    inputType: InputType.Text,
    watchField: 'overrideDaysInRemodelDeduction',
    props: {
      label: 'Days In Remodel Deduction',
      type: 'number',
    },
  },
  {
    inputName: 'overrideOfferDisclaimer',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Offer Disclaimer',
    },
  },
  {
    inputName: 'offerDisclaimer',
    inputType: InputType.Text,
    watchField: 'overrideOfferDisclaimer',
    props: {
      label: 'Offer Disclaimer Override',
      type: 'text',
    },
  },
  {
    inputName: 'overrideAddress',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Address',
    },
  },
  {
    inputName: 'addressDisplayOverride',
    inputType: InputType.Text,
    watchField: 'overrideAddress',
    props: {
      label: 'Street Address Override',
      type: 'text',
    },
  },
  {
    inputName: 'cityStateZipDisplayOverride',
    inputType: InputType.Text,
    watchField: 'overrideAddress',
    props: {
      label: 'City, State, Zip Display Override',
      type: 'text',
    },
  },
  {
    inputName: 'overrideGraphYears',
    inputType: InputType.Checkbox,
    props: {
      label: 'Override Years to Project',
    },
  },
  {
    inputName: 'yearsToProject',
    inputType: InputType.Text,
    watchField: 'overrideGraphYears',
    props: {
      label: 'Years to Project on Scenario Analysis',
      type: 'number',
    },
  },
]

type EditCustomerOverridesParams = {
  waiveOnboardingFee: boolean
  overrideOnboardingFee: boolean
  overrideCustomerStatus: boolean
  customerStatusOverride: string
  overrideCustomerName: boolean
  customerNameOverride: string
  setCapexCosts: boolean
  capexCosts: string
  setRemodelCosts: boolean
  remodelCosts: string
  feeOverride: string
  overrideAddress: boolean
  addressDisplayOverride: string
  cityStateZipDisplayOverride: boolean
  overrideGraphYears: boolean
  yearsToProject: number

  showCashFlowDeductions: boolean
  overrideOfferDisclaimer: boolean
  offerDisclaimer: string
  overrideNetYield: boolean
  netYieldOverride: string
  overrideHoldingPeriod: boolean
  holdingPeriodOverride: number
  overrideMortgage: boolean
  mortgageOverride: number
  hideRemodelCosts: boolean
  overrideCashTakeout: boolean
  cashTakeout: string
  overrideClosingCosts: boolean
  closingCosts: string
  overrideBrokerCommission: boolean
  brokerCommission: string
  overrideRentReduction: boolean
  rentReduction: string
  overrideDaysInRemodelDeduction: boolean
  daysInRemodelDeduction: string
  portfolioAssetCountOverride: string
  portfolioDoorCountOverride: string
  portfolioCityStateOverride: string
  portfolioSFRDoorsOverride: string
  portfolioMultifamilyDoorsOverride: string
  overridePortfolioAssetCount: boolean
  overridePortfolioDoorCount: boolean
  overridePortfolioCityState: boolean
  overridePortfolioSFRDoors: boolean
  overridePortfolioMultifamilyDoors: boolean
}

type EditCustomerOverridesModalProps = {
  open: boolean
  onClose: () => void
  customerUuid: string
  overrides: { [key: string]: any }
}

const EditCustomerOverridesModal = (props: EditCustomerOverridesModalProps) => {
  const { open, onClose, customerUuid, overrides } = props
  const [loading, setLoading] = useState(false)
  const [updateCustomer] = useMutation(AdminUpdateCustomerDocument)

  const { notify } = useSnackbar()

  const onSubmit = async (editOverridesParams: EditCustomerOverridesParams) => {
    const {
      waiveOnboardingFee,
      overrideOnboardingFee,
      feeOverride,
      overrideCustomerStatus,
      customerStatusOverride,
      overrideCustomerName,
      customerNameOverride,
      setCapexCosts,
      capexCosts,
      setRemodelCosts,
      remodelCosts,
      overrideAddress,
      addressDisplayOverride,
      cityStateZipDisplayOverride,
      overrideGraphYears,
      yearsToProject,

      overrideOfferDisclaimer,
      offerDisclaimer,
      overrideCashTakeout,
      cashTakeout,
      overrideNetYield,
      netYieldOverride,
      overrideHoldingPeriod,
      holdingPeriodOverride,
      overrideMortgage,
      mortgageOverride,
      overrideRentReduction,
      rentReduction,
      overrideDaysInRemodelDeduction,
      daysInRemodelDeduction,
      overrideClosingCosts,
      closingCosts,
      overrideBrokerCommission,
      brokerCommission,
      showCashFlowDeductions,
      hideRemodelCosts,
      portfolioAssetCountOverride,
      portfolioDoorCountOverride,
      portfolioCityStateOverride,
      portfolioSFRDoorsOverride,
      portfolioMultifamilyDoorsOverride,
      overridePortfolioAssetCount,
      overridePortfolioDoorCount,
      overridePortfolioCityState,
      overridePortfolioSFRDoors,
      overridePortfolioMultifamilyDoors,
    } = editOverridesParams

    const result: { [key: string]: any } = {
      ...overrides,
      waiveOnboardingFee,
      overrideOnboardingFee,
      overrideAddress,
      overrideGraphYears,
      offerDisclaimer,
      overrideCashTakeout,
      overrideRentReduction,
      overrideDaysInRemodelDeduction,
      overrideOfferDisclaimer,
      overrideClosingCosts,
      overrideBrokerCommission,
      overrideCustomerStatus,
      overrideCustomerName,
      overrideNetYield,
      overrideHoldingPeriod,
      overrideMortgage,
    }

    if (overrideOnboardingFee && !waiveOnboardingFee) {
      result.feeOverride = parseFloat(feeOverride)
    }

    if (overrideCashTakeout) {
      result.cashTakeout = parseInt(cashTakeout, 10)
    }

    if (overrideHoldingPeriod) {
      result.holdingPeriodOverride = holdingPeriodOverride
    }

    if (overrideMortgage) {
      result.mortgageOverride = mortgageOverride
    }

    if (overrideRentReduction) {
      result.rentReduction = parseInt(rentReduction, 10)
    }

    result.showCashFlowDeductions = showCashFlowDeductions
    result.hideRemodelCosts = hideRemodelCosts

    if (overrideDaysInRemodelDeduction) {
      result.daysInRemodelDeduction = parseInt(daysInRemodelDeduction, 10)
    }

    if (overrideClosingCosts) {
      result.closingCosts = parseFloat(closingCosts)
    }

    if (overrideBrokerCommission) {
      result.brokerCommission = parseFloat(brokerCommission)
    }

    if (overrideNetYield) {
      result.netYieldOverride = parseFloat(netYieldOverride)
    }

    if (overrideCustomerStatus) {
      result.customerStatusOverride = customerStatusOverride
    }

    if (overrideCustomerName) {
      result.customerNameOverride = customerNameOverride
    }

    if (overridePortfolioAssetCount) {
      result.portfolioAssetCount = parseInt(portfolioAssetCountOverride, 10)
    }

    if (overridePortfolioDoorCount) {
      result.portfolioDoorCount = parseInt(portfolioDoorCountOverride, 10)
    }

    if (overridePortfolioCityState) {
      result.portfolioCityState = portfolioCityStateOverride
    }

    if (overridePortfolioSFRDoors) {
      result.portfolioSFRDoors = parseInt(portfolioSFRDoorsOverride, 10)
    }

    if (overridePortfolioMultifamilyDoors) {
      result.portfolioMultifamilyDoors = parseInt(
        portfolioMultifamilyDoorsOverride,
        10
      )
    }

    if (setCapexCosts) {
      result.capexCosts = parseInt(capexCosts, 10)
    }

    if (setRemodelCosts) {
      result.remodelCosts = parseInt(remodelCosts, 10)
    }

    if (overrideAddress) {
      result.addressDisplayOverride = addressDisplayOverride
      result.cityStateZipDisplayOverride = cityStateZipDisplayOverride
    }

    if (overrideGraphYears) {
      result.yearsToProject = yearsToProject
    }

    setLoading(true)
    try {
      await updateCustomer({
        variables: {
          input: {
            customerUuid,
            overrides: JSON.stringify(result),
          },
        },
        refetchQueries: [AdminGetCustomerDocument],
      })
      notify('Successfully updated customer overrides.', 'success')
      onClose()
    } catch (e) {
      notify('Failed to update customer overrides.', 'error')
    }
    setLoading(false)
  }

  return (
    <ActionFormModal
      open={open}
      setOpen={onClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Edit customer overrides"
      formProps={{
        defaultValues: {
          ...overrides,
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
      }}
      inputConfigs={inputConfigs}
    />
  )
}

export default EditCustomerOverridesModal
