import { useMutation } from '@apollo/client'
import { ConfirmModal } from '@flock/flock-component-library'
import {
  AdminDeleteLeaseDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_DeletePropertyLeaseRequestInput,
  // AdminGetPropertyOccupancyDataDocument,
  Core_PropertyLease,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import React from 'react'

type DeleteLeaseModalParams = {
  lease: Core_PropertyLease
  isOpen: boolean
  close: () => void
}

const DeleteLeaseModal = (props: DeleteLeaseModalParams) => {
  const { lease, isOpen, close } = props
  const { notify } = useSnackbar()

  const [deleteLease, { loading }] = useMutation(AdminDeleteLeaseDocument)
  // const [loading, setLoading] = useState(false)

  const deleteLeaseInput: Core_DeletePropertyLeaseRequestInput = {
    leaseUuid: lease.uuid,
  }

  const onSubmit = async () => {
    try {
      await deleteLease({
        variables: {
          input: deleteLeaseInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify(`Successfully deleted lease for ${lease.tenantName}.`, 'success')
      close()
    } catch (e) {
      notify('Failed to delete lease.', 'error')
    }
  }

  return (
    <ConfirmModal
      title="Delete Lease"
      content={
        loading
          ? 'deleting...'
          : `Are you sure you want to delete lease for ${lease.tenantName}?`
      }
      open={isOpen}
      onClose={close}
      onSubmit={onSubmit}
    />
  )
}

export default DeleteLeaseModal
