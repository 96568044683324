import React from 'react'
import { flockColors } from '@flock/flock-component-library'
import {
  TableCell,
  TableRow,
  Paper,
  Typography,
  Link,
  Button,
} from '@mui/material'
import { formatAddressString, formatCityStateZip } from '@flock/utils'
import { ArrowForwardIos, ContentCopy } from '@mui/icons-material'
import {
  Core_ValidatedAddress,
  Core_Lead,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { FLOCK_LANDING_URL } from '../../constants'

export const formatLead = (lead: Core_Lead | undefined | null) => {
  const address = `${formatAddressString(
    lead?.address as Core_ValidatedAddress
  )} ${formatCityStateZip(lead?.address as Core_ValidatedAddress)}`
  return {
    transactionType: lead?.transactionType,
    name: lead?.fullName,
    address,
    email: lead?.email,
    phoneNumber: lead?.phoneNumber,
    copyToClipboard: {
      lead,
    },
    leadActionsParams: {
      lead,
    },
  }
}

export const leadColumns = [
  { name: 'transactionType', label: 'Type' },
  { name: 'name', label: 'Name' },
  { name: 'address', label: 'Address' },
  { name: 'email', label: 'Email' },
  { name: 'phoneNumber', label: 'Phone' },
  {
    name: 'copyToClipboard',
    options: {
      customHeadLabelRender: () => '',
      draggable: false,
      download: false,
      filter: false,
      print: false,
      sort: false,
    },
  },
  {
    name: 'leadActionsParams',
    options: {
      customHeadLabelRender: () => '',
      draggable: false,
      download: false,
      filter: false,
      print: false,
      sort: false,
    },
  },
]

// TODO: parametrize this in terms of an onclick that takes (leadActionsParams) => void
export const CustomLeadRowRender =
  (path: string, notify: any) =>
  (data: [string, string, string, string, string, any, any]) => {
    const [
      transactionType,
      name,
      address,
      email,
      phoneNumber,
      leadActionsParams,
      copyToClipboard,
    ] = data

    const parsedTransactionType = transactionType
      .replace('TRANSACTION_TYPE_', '')
      .replace('_721', '')
      .replace('_', ' ')

    return (
      <TableRow
        style={{ cursor: 'pointer' }}
        data-cy="leadDetailsButton"
        sx={{
          '&:hover': {
            backgroundColor: flockColors.lighterGray,
            transition: 'background-color 0.5s ease',
          },
          textDecoration: 'none!important',
        }}
      >
        <TableCell
          // @ts-ignore
          component={Link}
          href={`${path}/${leadActionsParams.lead.uuid}`}
        >
          <Paper
            sx={{
              backgroundColor: 'wheat',
              width: 'fit-content',
              textDecoration: 'none!important',
            }}
          >
            <Typography
              sx={{ width: 'fit-content', textDecoration: 'none!important' }}
            >
              {parsedTransactionType}
            </Typography>
          </Paper>
        </TableCell>
        <TableCell
          // @ts-ignore
          component={Link}
          href={`${path}/${leadActionsParams.lead.uuid}`}
        >
          {name}
        </TableCell>
        <TableCell
          // @ts-ignore
          component={Link}
          href={`${path}/${leadActionsParams.lead.uuid}`}
        >
          <Link
            href={`${path}/${leadActionsParams.lead.uuid}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {address}
          </Link>
        </TableCell>
        <TableCell
          // @ts-ignore
          component={Link}
          href={`${path}/${leadActionsParams.lead.uuid}`}
        >
          {email}
        </TableCell>
        <TableCell
          // @ts-ignore
          component={Link}
          href={`${path}/${leadActionsParams.lead.uuid}`}
        >
          {phoneNumber}
        </TableCell>
        <TableCell align="right">
          <Button
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={() => {
              navigator.clipboard.writeText(
                `${FLOCK_LANDING_URL}/property-estimate/${copyToClipboard.lead.uuid}`
              )
              notify(`${name} offer page copied to Clipboard`)
            }}
          >
            <ContentCopy />
          </Button>
        </TableCell>
        <TableCell
          // @ts-ignore
          component={Link}
          href={`${path}/${leadActionsParams.lead.uuid}`}
          align="right"
        >
          <ArrowForwardIos />
        </TableCell>
      </TableRow>
    )
  }
