import React from 'react'
import {
  AccordionDetails,
  AccordionSummary,
  Modal,
  Paper,
  Typography,
} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import {
  Core_MultiFamilyValuation,
  Core_ValuationV1,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ValuationTrailNodeRenderer from './ValuationTrailNodeRenderer/ValuationTrailNodeRenderer'
import { ValuationTrailModalProps } from './valuationTrailModalTypes'

const ValuationTrailModal = (props: ValuationTrailModalProps) => {
  const { open, onClose, valuation, valuationTrailData } = props

  const getValuationInputs = (
    val: Core_MultiFamilyValuation | Core_ValuationV1 | null | undefined
  ) => {
    if (!val) {
      return null
    }
    // single family valuatin
    if ('inputs' in val) {
      const v = val as Core_ValuationV1
      return v.inputs
    } else if ('propertyInput' in val) {
      const v = val as Core_MultiFamilyValuation
      return {
        propertyInput: v.propertyInput,
        unitInputs: v.unitInputs,
      }
    }
    return null
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '80px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          width: '650px',
          maxHeight: '75vh',
          overflowY: 'scroll',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Typography variant="h3">Valuation Trail</Typography>
        <Accordion>
          <AccordionSummary>
            <Typography variant="h3">Inputs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{JSON.stringify(getValuationInputs(valuation), null, 2)}</pre>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>
            <Typography variant="h3">Outputs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{JSON.stringify(valuation?.outputs, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>
        <ValuationTrailNodeRenderer
          name={valuationTrailData.name}
          description={valuationTrailData.description}
          value={valuationTrailData.value}
          nodes={valuationTrailData.nodes}
        />
      </Paper>
    </Modal>
  )
}

export default ValuationTrailModal
