import React from 'react'
import { Box, CssBaseline, Modal, Typography } from '@mui/material'
import { FlockLogo, TrackedButton } from '@flock/shared-ui'
import { Core_SalesforceVerifiedProperty } from '@flock/flock-gql-server/src/__generated__/graphql'
import useCollateralRenderer from './useCollateralRenderer'
import { CollateralRendererProps } from './collateralRendererTypes'
import SinglePagePrintWrapper from './SinglePagePrintWrapper'
import PortfolioRenderer from './PortfolioPages/PortfolioRenderer'
import CashFlowPage from './CashFlowPage/CashFlowPage'
import CompsPage from './CompsPage/CompsPage'
import FaqPages from './FaqPages'
import { CheckboxLabel } from '../printCollateralPageTypes'
import TableOfContents from './TableOfContents/TableOfContents'

const CollateralRenderer = (props: CollateralRendererProps) => {
  const presentationalProps = useCollateralRenderer(props)
  const {
    collateralData,
    updateRenderState,
    renderStateMap,
    setRenderStatePageOffset,
    equityAmount,

    doneGenerating,
    setDoneGenerating,

    batchStart,
    batchEnd,
    onStartDownload,
    downloading,
    refToPrint,

    open,
    onClose,
  } = presentationalProps
  const { coverName, scenario, checkboxes } = collateralData || {}

  if (!checkboxes || !scenario || Object.keys(renderStateMap).length === 0) {
    return null
  }

  const {
    minimumHoldPeriod,
    portfolioFcfPercent,
    showCashFlowDeductions,

    addresses,
  } = scenario

  return (
    <CssBaseline>
      <Modal open={open} onClose={onClose}>
        <Box
          position="absolute"
          width="800px"
          height="80vh"
          p="16px"
          display="flex"
          flexDirection="column"
          sx={{
            top: '50px',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            backgroundColor: 'gray1.main',
            borderRadius: '8px',
            overflow: 'scroll',
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Box>
                <TrackedButton
                  variant="primary"
                  size="smallForm"
                  onClick={onStartDownload}
                  disabled={!doneGenerating || downloading}
                >
                  {downloading ? (
                    'Downloading...'
                  ) : (
                    <>{doneGenerating ? 'Download' : 'Generating...'}</>
                  )}
                </TrackedButton>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              ref={refToPrint}
              width="612px"
            >
              <Box>
                <SinglePagePrintWrapper
                  shouldShow={batchStart <= 0 && batchEnd > 0}
                  showBackground
                  hideFooter
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                    paddingBottom="36px"
                  >
                    <FlockLogo width="68px" />
                    <Typography
                      sx={{
                        width: '100%',
                        lineHeight: '120%',
                        fontWeight: 400,
                        fontSize: '40px',
                        color: 'moneyGreen.main',
                      }}
                    >
                      {coverName}
                    </Typography>
                  </Box>
                </SinglePagePrintWrapper>
                <SinglePagePrintWrapper
                  shouldShow={batchStart <= 0 && batchEnd > 0}
                  hideFooter
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                    pb="48px"
                  >
                    <Typography
                      variant="p6"
                      sx={{
                        lineHeight: '8px',
                        color: 'gray5.main',
                      }}
                    >
                      The document provided here has been disclosed solely in
                      connection with a potential transaction (the
                      “Opportunity”) between me and Flock Homes and all
                      information contained therein is confidential and shall
                      not be disclosed to any other person except my affiliates
                      or advisors who may be engaged by me in connection with
                      evaluating the Opportunity.
                    </Typography>
                  </Box>
                </SinglePagePrintWrapper>
                <TableOfContents
                  checkboxes={checkboxes}
                  addresses={addresses as Core_SalesforceVerifiedProperty[]}
                  renderStateMap={renderStateMap}
                  setRenderStatePageOffset={setRenderStatePageOffset}
                  setDoneGenerating={setDoneGenerating}
                  coverName={coverName as string}
                  batchStart={batchStart}
                  batchEnd={batchEnd}
                />

                {checkboxes?.map((checkbox) => {
                  const { label, checked } = checkbox
                  if (!checked) {
                    return null
                  }

                  const renderState = renderStateMap[label]
                  let offset = [0]
                  if (renderState?.offset?.length) {
                    offset = renderState.offset
                  }

                  switch (label) {
                    case CheckboxLabel.PORTOFOLIO_OVERVIEW:
                      return (
                        <PortfolioRenderer
                          equityAmount={equityAmount}
                          scenario={scenario}
                          updateRenderState={updateRenderState}
                          coverName={coverName as string}
                          pageOffset={offset[0]}
                          batchStart={batchStart}
                          batchEnd={batchEnd}
                        />
                      )
                    case CheckboxLabel.TOP_UP_CASH_FLOW:
                    case CheckboxLabel.ALLOTMENT_CASH_FLOW:
                    case CheckboxLabel.REINVESTED_CASH_FLOW:
                      return (
                        <CashFlowPage
                          key={label}
                          startingAccountValue={equityAmount}
                          minimumHoldPeriod={minimumHoldPeriod as number}
                          uwCashOnCashYield={portfolioFcfPercent as number}
                          showCashFlowDeductions={
                            showCashFlowDeductions as boolean
                          }
                          cashflowPageType={label}
                          updateRenderState={updateRenderState}
                          pageOffset={offset[0]}
                          coverName={coverName as string}
                          batchStart={batchStart}
                          batchEnd={batchEnd}
                        />
                      )
                    case CheckboxLabel.VALUATION_COMPS:
                      return scenario.addresses?.map((address, idx) => (
                        <CompsPage
                          key={address?.id}
                          scenarioValuationType={
                            scenario.valuationType as string
                          }
                          index={idx}
                          address={address as Core_SalesforceVerifiedProperty}
                          updateRenderState={updateRenderState}
                          coverName={coverName as string}
                          pageOffset={offset[idx]}
                          batchStart={batchStart}
                          batchEnd={batchEnd}
                        />
                      ))

                    case CheckboxLabel.FAQS:
                      return (
                        <FaqPages
                          coverName={coverName as string}
                          pageOffset={offset[0]}
                          updateRenderState={updateRenderState}
                          batchStart={batchStart}
                          batchEnd={batchEnd}
                        />
                      )
                    default:
                      return null
                  }
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </CssBaseline>
  )
}

export default CollateralRenderer
