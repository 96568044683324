import { useMutation } from '@apollo/client'
import { Button, useSnackbar, InputType } from '@flock/flock-component-library'
import { AdminCreateBrokerDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { BROKER_MANAGEMENT_URL } from '../../constants'
import ActionFormModal from '../shared/ActionFormModal'

const formInputConfigs = [
  {
    inputName: 'firstName',
    inputType: InputType.Text,
    required: true,
    props: {
      label: 'First Name',
      type: 'text',
      fullWidth: true,
    },
  },
  {
    inputName: 'lastName',
    inputType: InputType.Text,
    required: true,
    props: {
      label: 'Last Name',
      type: 'text',
      fullWidth: true,
    },
  },
  {
    inputName: 'email',
    inputType: InputType.Text,
    required: true,
    props: {
      label: 'Email',
      type: 'email',
      fullWidth: true,
    },
  },
  {
    inputName: 'phoneNumber',
    inputType: InputType.Phone,
    required: false,
    props: {
      label: 'Phone Number',
      fullWidth: true,
    },
  },
  {
    inputName: 'shortLink',
    inputType: InputType.Text,
    required: false,
    props: {
      label: 'Short Link (used for client referrals)',
      fullWidth: true,
    },
  },
  {
    inputName: 'websiteUrl',
    inputType: InputType.Text,
    props: {
      label: 'Website URL',
      fullWidth: true,
    },
  },
  {
    inputName: 'headshotUrl',
    inputType: InputType.Text,
    props: {
      label: 'Headshot Image URL',
      fullWidth: true,
    },
  },
]

type CreateBrokerInputs = {
  firstName: string
  lastName: string
  phoneNumber: string
  shortLink: string
  email: string
  websiteUrl: string
  headshotUrl: string
}

type CreateBrokerModalProps = {
  operatorUuid: string
}

const CreateBrokerModal = (props: CreateBrokerModalProps) => {
  const { operatorUuid } = props
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { notify } = useSnackbar()

  const [createBroker] = useMutation(AdminCreateBrokerDocument)

  const onSubmit = async (inputs: CreateBrokerInputs) => {
    setLoading(true)
    try {
      const source = 'admin'
      const dataResult = await createBroker({
        variables: {
          createBrokerInput: {
            ...inputs,
            operatorUuid,
            source,
          },
        },
      })
      notify('Successfully created broker', 'success')
      setOpen(false)
      navigate(
        `${BROKER_MANAGEMENT_URL}/${dataResult?.data?.createBroker?.broker?.uuid}`
      )
    } catch (e) {
      notify('Failed to create broker', 'error')
    }
    setLoading(false)
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Create Broker
      </Button>
      <ActionFormModal
        open={open}
        setOpen={setOpen}
        loading={loading}
        onSubmit={onSubmit}
        actionText="Create Broker"
        inputConfigs={formInputConfigs}
      />
    </>
  )
}

export default CreateBrokerModal
