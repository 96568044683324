import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { formatIntegerCents, formattedDollarsToCents } from '@flock/utils'
import { useMutation } from '@apollo/client'
import {
  AdminGetPropertyOccupancyDataDocument,
  AdminUpdateLeaseDocument,
  Core_PropertyLease,
  Core_UpdatePropertyLeaseRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'
import { cleanTimeFromDatetime } from '../../utils'

type UpdateLeaseParams = {
  tenantName: string
  leaseStartDate: string
  leaseEndDate: string
  moveInDate: string
  leaseType: string
  leaseOriginator: string
  monthlyRentCents: string
  rentDueDateEachMonth: string
  gracePeriodDays: string
  isRenewed: string
  securityDepositCents: string
  renewalOfferSentDate: string
  renewalOfferAmountCents: string
  renewalOfferStatus: string
  renewalOfferResponseDate: string
  moveOutDate: string
  lastMoveOutDate: string
  isSectionEight: string
  isLeaseBreak: string
}

type UpdateLeaseModalParams = {
  leaseUuid: string
  leaseInfo: Core_PropertyLease
  isOpen: boolean
  close: () => void
}

const UpdateLeaseModal = (props: UpdateLeaseModalParams) => {
  const { leaseUuid, leaseInfo, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [updateLease, { loading }] = useMutation(AdminUpdateLeaseDocument)

  const onSubmit = async (updateLeaseParams: UpdateLeaseParams) => {
    const {
      tenantName,
      leaseStartDate,
      leaseEndDate,
      moveInDate,
      leaseType,
      leaseOriginator,
      monthlyRentCents,
      rentDueDateEachMonth,
      gracePeriodDays,
      isRenewed,
      securityDepositCents,
      renewalOfferSentDate,
      renewalOfferAmountCents,
      renewalOfferStatus,
      renewalOfferResponseDate,
      moveOutDate,
      lastMoveOutDate,
      isSectionEight,
      isLeaseBreak,
    } = updateLeaseParams

    const updatePropertyInput: Core_UpdatePropertyLeaseRequestInput = {
      leaseUuid,
      leaseParams: {
        propertyUuid: leaseInfo?.propertyUuid,
        tenantName,
        leaseStartDate: cleanTimeFromDatetime(leaseStartDate),
        leaseEndDate: cleanTimeFromDatetime(leaseEndDate),
        moveInDate: cleanTimeFromDatetime(moveInDate),
        leaseType,
        leaseOriginator,
        monthlyRentCents: formattedDollarsToCents(monthlyRentCents),
        rentDueDateEachMonth: Number(rentDueDateEachMonth),
        gracePeriodDays: Number(gracePeriodDays),
        isRenewed: isRenewed === 'true',
        securityDepositCents: securityDepositCents
          ? formattedDollarsToCents(securityDepositCents)
          : null,
        renewalOfferSentDate: renewalOfferSentDate
          ? cleanTimeFromDatetime(renewalOfferSentDate)
          : null,
        renewalOfferAmountCents: renewalOfferAmountCents
          ? formattedDollarsToCents(renewalOfferAmountCents)
          : null,
        renewalOfferStatus: renewalOfferStatus || null,
        renewalOfferResponseDate: renewalOfferResponseDate
          ? formattedDollarsToCents(renewalOfferResponseDate)
          : null,
        moveOutDate: cleanTimeFromDatetime(moveOutDate),
        lastMoveOutDate: cleanTimeFromDatetime(lastMoveOutDate),
        isSectionEight: isSectionEight === 'true',
        isLeaseBreak: isLeaseBreak === 'true',
      },
    }

    try {
      await updateLease({
        variables: {
          input: updatePropertyInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify('Successfully updated lease.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to update lease.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update Lease"
      inputConfigs={[
        {
          inputName: 'tenantName',
          inputType: InputType.Text,
          props: {
            type: 'text',
            label: 'Tenant Name',
            placeholder: 'enter name here',
            fullWidth: true,
            defaultValue: leaseInfo.tenantName,
          },
        },
        {
          inputName: 'leaseStartDate',
          inputType: InputType.DatePicker,
          required: true,
          props: {
            label: 'Lease Start Date',
            fieldProps: {
              fullWidth: true,
            },
            defaultValue: leaseInfo.leaseStartDate,
          },
        },
        {
          inputName: 'leaseEndDate',
          inputType: InputType.DatePicker,
          required: true,
          props: {
            label: 'Lease End Date',
            fieldProps: {
              fullWidth: true,
            },
            defaultValue: leaseInfo.leaseEndDate,
          },
        },
        {
          inputName: 'moveInDate',
          inputType: InputType.DatePicker,
          required: true,
          props: {
            label: 'Move In Date',
            fieldProps: {
              fullWidth: true,
            },
            defaultValue: leaseInfo.moveInDate,
          },
        },
        {
          inputName: 'moveOutDate',
          inputType: InputType.DatePicker,
          props: {
            label: 'Move Out Date',
            fieldProps: {
              fullWidth: true,
            },
            defaultValue: leaseInfo.moveOutDate,
          },
        },
        {
          inputName: 'leaseType',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Lease Type',
            fullWidth: true,
            options: [
              {
                text: 'Fixed',
                value: 'fixed',
              },
              {
                text: 'Month to Month',
                value: 'mtm',
              },
            ],
            defaultValue: leaseInfo.leaseType,
          },
        },
        {
          inputName: 'monthlyRentCents',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Monthly Rent',
            placeholder: '$0.00',
            fullWidth: true,
            defaultValue: leaseInfo.monthlyRentCents
              ? formatIntegerCents(leaseInfo.monthlyRentCents)
              : '',
          },
        },
        {
          inputName: 'leaseOriginator',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Lease Originator',
            fullWidth: true,
            options: [
              {
                text: 'Flock',
                value: 'Flock',
              },
              {
                text: 'Inherited',
                value: 'Inherited',
              },
            ],
            defaultValue: leaseInfo.leaseOriginator,
          },
        },
        {
          inputName: 'isRenewed',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Is Renewed',
            fullWidth: true,
            options: [
              {
                text: '',
                value: undefined,
              },
              {
                text: 'Yes',
                value: 'true',
              },
              {
                text: 'No',
                value: 'false',
              },
            ],
            defaultValue: leaseInfo.isRenewed ? 'true' : 'false',
          },
        },
        {
          inputName: 'securityDepositCents',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Security Deposit',
            placeholder: '$0.00',
            fullWidth: true,
            defaultValue: leaseInfo.securityDepositCents
              ? formatIntegerCents(leaseInfo.securityDepositCents)
              : '',
          },
        },
        {
          inputName: 'renewalOfferSentDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Renewal Offer Sent Date',
            fieldProps: {
              fullWidth: true,
            },
            defaultValue: leaseInfo.renewalOfferSentDate,
          },
        },
        {
          inputName: 'renewalOfferAmountCents',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Renewal Offer Amount',
            placeholder: '$0.00',
            fullWidth: true,
            defaultValue: leaseInfo.renewalOfferAmountCents
              ? formatIntegerCents(leaseInfo.renewalOfferAmountCents)
              : '',
          },
        },
        {
          inputName: 'renewalOfferStatus',
          inputType: InputType.Dropdown,
          required: false,
          props: {
            type: 'text',
            label: 'Renewal Offer Status',
            fullWidth: true,
            options: [
              {
                text: '',
                value: undefined,
              },
              {
                text: 'Accepted',
                value: 'accepted',
              },
              {
                text: 'Pending',
                value: 'pending',
              },
              {
                text: 'Rejected',
                value: 'rejected',
              },
            ],
            defaultValue: leaseInfo.renewalOfferStatus,
          },
        },
        {
          inputName: 'renewalOfferResponseDate',
          inputType: InputType.DatePicker,
          required: false,
          props: {
            label: 'Renewal Offer Response Date',
            fieldProps: {
              fullWidth: true,
            },
            defaultValue: leaseInfo.renewalOfferResponseDate,
          },
        },
        {
          inputName: 'isSectionEight',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Section 8',
            fullWidth: true,
            options: [
              {
                text: '',
                value: undefined,
              },
              {
                text: 'No',
                value: 'false',
              },
              {
                text: 'Yes',
                value: 'true',
              },
            ],
            defaultValue: leaseInfo.isSectionEight ? 'true' : 'false',
          },
        },
        {
          inputName: 'isLeaseBreak',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Lease Break',
            fullWidth: true,
            options: [
              {
                text: '',
                value: undefined,
              },
              {
                text: 'No',
                value: 'false',
              },
              {
                text: 'Yes',
                value: 'true',
              },
            ],
            defaultValue: leaseInfo.isLeaseBreak ? 'true' : 'false',
          },
        },
      ]}
    />
  )
}

export default UpdateLeaseModal
