import React, { useState } from 'react'
import {
  Typography,
  Paper,
  Button,
  Form,
  useSnackbar,
  InputType,
} from '@flock/flock-component-library'
import {
  AdminCreateValuationDocument,
  Core_Address,
  Core_Lead,
  Core_Valuation,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { styled } from '@mui/material/styles'
import { Modal, Box } from '@mui/material'
import { useMutation } from '@apollo/client'

import { formatAddressString, formatCityStateZip } from '@flock/utils'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type ValuationModalProps = {
  lead: Core_Lead
  valuation: Core_Valuation
}

type SubmitRangeResult = {
  lowerValuation: string
  upperValuation: string
}

const ValuationRangeOverrideModal = (props: ValuationModalProps) => {
  const { lead, valuation } = props
  const { notify } = useSnackbar()
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const [createValuation, { loading }] = useMutation(
    AdminCreateValuationDocument
  )
  const onSubmit = async (result: SubmitRangeResult) => {
    const parsedJsonValuationDetails: { [key: string]: any } = JSON.parse(
      valuation?.valuationDetails || '{}'
    )
    const { lowerValuation, upperValuation } = result
    parsedJsonValuationDetails.adjustedValuationLower = parseInt(
      lowerValuation,
      10
    )
    parsedJsonValuationDetails.adjustedValuationUpper = parseInt(
      upperValuation,
      10
    )
    const valuationDetailsStringToSubmit = JSON.stringify(
      parsedJsonValuationDetails
    )
    try {
      await createValuation({
        variables: {
          createValuationInput: {
            leadUuid: lead.uuid,
            valuationDetails: valuationDetailsStringToSubmit,
            finalOfferPrice: 0,
          },
        },
      })
      notify(
        `Updated lead with a valuation range of $${lowerValuation}-$${upperValuation}`,
        'success'
      )
      closeModal()
    } catch (e) {
      notify('Failed to set a valuation range', 'error')
    }
  }

  return (
    <>
      <Button
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => {
          openModal()
        }}
      >
        Override Valuation Range
      </Button>
      <Modal open={open} onClose={closeModal}>
        <ModalWrapper>
          <Typography
            variant="h2"
            sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
          >
            Override Valuation Range
          </Typography>
          <Box sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
            <Typography variant="body1">{lead.fullName}</Typography>
            <Typography variant="body1">
              {lead.address ? formatAddressString(lead.address) : ''}
            </Typography>
            <Typography variant="body1">
              {formatCityStateZip(lead?.address as Core_Address)}
            </Typography>
          </Box>
          <Form
            onSubmit={onSubmit}
            ctaText={loading ? 'Submitting...' : 'Override Valuation Range'}
            ctaProps={{
              disabled: loading,
            }}
            inputConfigs={[
              {
                inputName: 'lowerValuation',
                inputType: InputType.Text,
                required: true,
                props: {
                  fullWidth: true,
                  type: 'number',
                  label: 'Lower Valuation',
                  placeholder: '$0.00',
                },
              },
              {
                inputName: 'upperValuation',
                inputType: InputType.Text,
                required: true,
                props: {
                  fullWidth: true,
                  type: 'number',
                  label: 'Upper Valuation',
                  placeholder: '$0.00',
                },
              },
            ]}
          />
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default ValuationRangeOverrideModal
