import { DataTable } from '@flock/flock-component-library'
import { Core_OrderV3 } from '@flock/flock-gql-server/src/__generated__/graphql'
import React, { useState } from 'react'
import DeleteOrderV3Modal from './DeleteOrderV3Modal'
import IndividualOrderV3Modal from './IndividualOrderV3Modal'
import OrderV3RowRender, {
  createOrderRows,
  orderColumns,
} from './OrderV3RowRender'
import { OrderMetadata } from './OrderV3Utils'

const options = (customRowRender: (data: any) => any) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender,
  elevation: 0,
})

const OrderV3Table = (props: { orders: Core_OrderV3[] }) => {
  const { orders } = props
  const [deleteOrderModalOpen, setDeleteOrderModalOpen] =
    useState<boolean>(false)
  const [existingOrder, setExistingOrder] = useState<OrderMetadata>(
    {} as OrderMetadata
  )
  const openDeleteOrderModal = (order: OrderMetadata) => {
    setDeleteOrderModalOpen(true)
    setExistingOrder(order)
  }
  const closeDeleteOrderModal = () => {
    setDeleteOrderModalOpen(false)
  }

  const [individualOrderV3ModalOpen, setIndividualOrderV3ModalOpen] =
    useState<boolean>(false)
  const openIndividualOrderV3Modal = (order: OrderMetadata) => {
    setIndividualOrderV3ModalOpen(true)
    setExistingOrder(order)
  }
  const closeIndividualOrderV3Modal = () => {
    setIndividualOrderV3ModalOpen(false)
  }
  const orderRows = createOrderRows(orders || [])

  return (
    <>
      <DataTable
        title=""
        data={orderRows}
        columns={orderColumns}
        options={
          options(
            OrderV3RowRender(openDeleteOrderModal, openIndividualOrderV3Modal)
          ) as any
        }
      />
      {existingOrder?.legalEntityUuid && (
        <DeleteOrderV3Modal
          orderMetadata={existingOrder}
          isOpen={deleteOrderModalOpen}
          close={closeDeleteOrderModal}
        />
      )}
      {existingOrder?.uuid && (
        <IndividualOrderV3Modal
          orderUuid={existingOrder?.uuid as string}
          isOpen={individualOrderV3ModalOpen}
          close={closeIndividualOrderV3Modal}
        />
      )}
    </>
  )
}

export default OrderV3Table
