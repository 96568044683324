import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'

import { formattedDollarsToCents } from '@flock/utils'
import {
  AdminCreateDistributionMutationDocument,
  AdminGetLegalEntitiesDistributionsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'

type CreateDistributionParams = {
  amount: string
  distributionDate: string
  isReinvestment: string
}

type CreateDistributionsModalParams = {
  legalEntityUuid: string
  isOpen: boolean
  close: () => void
}

const CreateDistributionsModal = (props: CreateDistributionsModalParams) => {
  const { legalEntityUuid, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createDistribution, { loading }] = useMutation(
    AdminCreateDistributionMutationDocument
  )

  const onSubmit = async (
    createDistributionParams: CreateDistributionParams
  ) => {
    const { amount, distributionDate, isReinvestment } =
      createDistributionParams
    const createDistributionInput = {
      legalEntityUuid,
      amountCents: formattedDollarsToCents(amount.toString()),
      distributionDate: distributionDate || '',
      isReinvestment: isReinvestment === 'true',
    }

    try {
      await createDistribution({
        variables: {
          createDistributionInput,
        },
        refetchQueries: [AdminGetLegalEntitiesDistributionsDocument],
      })
      notify('Successfully created distribution', 'success')
      close()
    } catch (e) {
      notify('Failed to create distribution', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Create Distribution"
      inputConfigs={[
        {
          inputName: 'amount',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Distribution Amount',
            placeholder: '$',
            fullWidth: true,
          },
        },
        {
          inputName: 'distributionDate',
          inputType: InputType.DatePicker,
          props: {
            label: 'Distribution Date',
            defaultValue: new Date(),
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'isReinvestment',
          inputType: InputType.Radio,
          props: {
            label: 'Distribution type',
            defaultValue: 'false',
            options: [
              {
                text: 'Reinvestment',
                value: 'true',
              },
              {
                text: 'Cash',
                value: 'false',
              },
            ],
          },
        },
      ]}
    />
  )
}

export default CreateDistributionsModal
